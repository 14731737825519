import PropTypes from 'prop-types';
import { ImageIcon, FontIcon } from './Icon.style';

const Icon = ({
  id,
  icon,
  src,
  size,
  color,
  rotate,
  flip,
  flipOnRtl,
  onClick,
  extendStyle,
  isImageIcon,
  'data-testid': dataTestId,
}) =>
  isImageIcon ? (
    <ImageIcon
      id={id}
      src={src}
      size={size}
      color={color}
      rotate={rotate}
      flip={flip}
      flipOnRtl={flipOnRtl}
      data-testid={dataTestId}
      onClick={onClick}
      extendStyle={extendStyle}
    />
  ) : (
    <FontIcon
      id={id}
      icon={icon}
      size={size}
      color={color}
      rotate={rotate}
      flip={flip}
      flipOnRtl={flipOnRtl}
      data-testid={dataTestId}
      onClick={onClick}
      extendStyle={extendStyle}
    />
  );

Icon.propTypes = {
  id: PropTypes.string,
  icon: PropTypes.string,
  src: PropTypes.string,
  isImageIcon: PropTypes.bool,
  size: PropTypes.number,
  color: PropTypes.string,
  rotate: PropTypes.bool,
  flip: PropTypes.bool,
  flipOnRtl: PropTypes.bool,
  'data-testid': PropTypes.string,
  onClick: PropTypes.func,
  extendStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

Icon.defaultProps = {
  id: '',
  icon: '',
  src: '',
  isImageIcon: false,
  size: 16,
  color: '',
  flip: false,
  flipOnRtl: false,
  extendStyle: '',
};

export default Icon;
