const stockActionTypes = {
  FETCH_STOCK: 'FETCH_STOCK',
  FETCH_STOCK_SUCCESS: 'FETCH_STOCK_SUCCESS',
  FETCH_STOCK_FAIL: 'FETCH_STOCK_FAIL',

  FETCH_STOCK_HISTORY: 'FETCH_STOCK_HISTORY',
  FETCH_STOCK_HISTORY_SUCCESS: 'FETCH_STOCK_HISTORY_SUCCESS',
  FETCH_STOCK_HISTORY_FAIL: 'FETCH_STOCK_HISTORY_FAIL',

  UPLOAD_STOCK: 'UPLOAD_STOCK',
  UPLOAD_STOCK_SUCCESS: 'UPLOAD_STOCK_SUCCESS',
  UPLOAD_STOCK_FAIL: 'UPLOAD_STOCK_FAIL',

  EXPORT_STOCK: 'EXPORT_STOCK',
  EXPORT_STOCK_SUCCESS: 'EXPORT_STOCK_SUCCESS',
  EXPORT_STOCK_FAIL: 'EXPORT_STOCK_FAIL',

  TOGGLE_ADD_STOCK_ITEMS_MODAL: 'TOGGLE_ADD_STOCK_ITEMS_MODAL',
  TOGGLE_EDIT_STOCK_ITEM_MODAL: 'TOGGLE_EDIT_STOCK_ITEM_MODAL',

  SEARCH_STOCK_ITEMS: 'SEARCH_STOCK_ITEMS',
  SEARCH_STOCK_ITEMS_SUCCESS: 'SEARCH_STOCK_ITEMS_SUCCESS',
  SEARCH_STOCK_ITEMS_FAIL: 'SEARCH_STOCK_ITEMS_FAIL',

  ADD_STOCK_ITEM: 'ADD_STOCK_ITEM',
  EDIT_STOCK_ITEM: 'EDIT_STOCK_ITEM',
  REMOVE_STOCK_ITEM: 'REMOVE_STOCK_ITEM',
  RESET_STOCK_ITEM: 'RESET_STOCK_ITEM',

  SAVE_STOCK_ITEMS: 'SAVE_STOCK_ITEMS',
  SAVE_STOCK_ITEMS_SUCCESS: 'SAVE_STOCK_ITEMS_SUCCESS',
  SAVE_STOCK_ITEMS_FAIL: 'SAVE_STOCK_ITEMS_FAIL',
};

export default stockActionTypes;
