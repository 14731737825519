import { Navigate } from 'react-router-dom';
import PharmacyLoginPage from 'pages/PharmacyLoginPage';
import { validateAuthCookie } from 'utils/functions/authentication';

const LoginRoute = () => {
  const isUserAuthenticated = validateAuthCookie();

  return isUserAuthenticated === false ? <PharmacyLoginPage /> : <Navigate to="/orders" />;
};

export default LoginRoute;
