/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, RowGrid, ExpandedRow, RowContainer, ShowDesktop, ShowDevice } from './Table.styles';

const TableRow = ({
  extendTableRow,
  onClickRecord,
  extendTableGrid,
  record,
  items,
  isExpandableRows,
  rowIndex,
}) => {
  const [isExpanded, toggleExpandingState] = useState(false);

  const onRowClick = () => {
    if (onClickRecord) onClickRecord(record);
    if (isExpandableRows) {
      const getdownCarretItem = document.getElementById(`rowitem-icon-${rowIndex}`);
      if (!isExpanded) {
        getdownCarretItem.style.transform = 'rotateZ(180deg)';
      } else {
        getdownCarretItem.style.transform = 'rotateZ(0deg)';
      }
      toggleExpandingState(!isExpanded);
    }
  };

  const getExpandedRowsHeight = id => {
    const expandedRows = document.getElementById(id);
    if (expandedRows) return expandedRows.offsetHeight;
    return 0;
  };

  const { expanded } = record;

  return (
    <RowContainer key={`${record.key}-${rowIndex}`}>
      <ShowDesktop>
        <Row extendTableRow={extendTableRow}>
          <RowGrid
            isClickable={onClickRecord || (isExpandableRows && expanded && expanded.length)}
            extendTableGrid={extendTableGrid}
            onClick={() => onRowClick()}
          >
            {items}
          </RowGrid>
        </Row>
        {expanded && expanded.length ? (
          <ExpandedRow
            totalHeight={getExpandedRowsHeight(`expandedRows-${rowIndex}`)}
            isExpanded={isExpanded}
          >
            <div style={{ width: '100%' }} id={`expandedRows-${rowIndex}`}>
              {expanded}
            </div>
          </ExpandedRow>
        ) : (
          ''
        )}
      </ShowDesktop>
      <ShowDevice>
        <Row extendTableRow={extendTableRow}>
          <RowGrid isClickable={false} extendTableGrid={extendTableGrid}>
            {items}
          </RowGrid>
        </Row>
      </ShowDevice>
    </RowContainer>
  );
};

TableRow.propTypes = {
  extendTableRow: PropTypes.array,
  onClickRecord: PropTypes.func,
  extendTableGrid: PropTypes.array,
  record: PropTypes.object,
  items: PropTypes.array,
  isExpandableRows: PropTypes.bool,
  rowIndex: PropTypes.number,
};

export default TableRow;
