import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Loader from 'modules/common/components/Loader';

import {
  DropdownMenuWrapper,
  DropdownMenuSelect,
  DropdownMenuText,
  DropdownMenuIcon,
  DropdownMenuEnvelop,
  DropdownMenuEnvelopItem,
} from './DropdownMenu.style';

const DropdownMenu = ({
  dropdownMenuItemsList,
  dropdownMenuSelectedObj,
  showLoader,
  handleDropdownMenuChange,
}) => {
  const dropdownMenuWrapperRef = useRef(null);
  const [isMenuEnvelopOpen, setIsMenuEnvelopOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        dropdownMenuWrapperRef.current &&
        dropdownMenuWrapperRef.current.contains(event.target) === false
      )
        setIsMenuEnvelopOpen(false);
    };
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [dropdownMenuWrapperRef]);

  const handleMenuSelectClick = () => {
    setIsMenuEnvelopOpen(prevState => !prevState);
  };

  const handleDropdownMenuItemClick = selectedObj => {
    setIsMenuEnvelopOpen(false);
    handleDropdownMenuChange(selectedObj);
  };

  return (
    <DropdownMenuWrapper ref={dropdownMenuWrapperRef}>
      <DropdownMenuSelect
        data-testid="dropdown-menu-select"
        onClick={() => handleMenuSelectClick()}
      >
        <DropdownMenuText data-testid="dropdown-menu-text">
          {dropdownMenuSelectedObj && dropdownMenuSelectedObj.name}
        </DropdownMenuText>
        {showLoader === false ? (
          <DropdownMenuIcon data-testid="dropdown-menu-icon" />
        ) : (
          <Loader data-testid="dropdown-menu-loader" width={14} height={14} thickness={3} />
        )}
      </DropdownMenuSelect>
      {isMenuEnvelopOpen && (
        <DropdownMenuEnvelop data-testid="dropdown-menu-envelope">
          {dropdownMenuItemsList.map(menuItem => (
            <DropdownMenuEnvelopItem
              data-testid={menuItem.key}
              key={menuItem.key}
              onClick={() =>
                handleDropdownMenuItemClick({
                  key: menuItem.key,
                  name: menuItem.name,
                })
              }
            >
              {menuItem.name}
            </DropdownMenuEnvelopItem>
          ))}
        </DropdownMenuEnvelop>
      )}
    </DropdownMenuWrapper>
  );
};

DropdownMenu.propTypes = {
  dropdownMenuItemsList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dropdownMenuSelectedObj: PropTypes.shape({
    name: PropTypes.string,
  }),
  showLoader: PropTypes.bool,
  handleDropdownMenuChange: PropTypes.func.isRequired,
  objectMapper: PropTypes.shape({
    key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
  }).isRequired,
};

DropdownMenu.defaultProps = {
  showLoader: false,
  dropdownMenuSelectedObj: {},
};

export default DropdownMenu;
