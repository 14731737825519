import { getAuthCookieInfo } from 'utils/functions/authentication';
import { fetchLoginInfo } from 'modules/accountManagement/actions';

const initApp = dispatch => {
  const { userKey } = getAuthCookieInfo();
  if (!userKey) {
    document.location.href = '/login';
  }
  const body = {
    userKey,
  };
  dispatch(fetchLoginInfo(body));
};

export default initApp;
