import PropTypes from 'prop-types';
import Button from 'modules/common/components/Button';
import { CounterContainer, CounterWrapper, CountLabel } from './Counter.style';

const Counter = ({
  key,
  count,
  label,
  onClickIncrease,
  onClickDecrease,
  onDelete,
  maxLimit,
  minLimit,
  extendButtonStyle,
  extendCounterWrapper,
}) => {
  let disableDecreasing = false;

  if (!onDelete && count === minLimit) {
    disableDecreasing = true;
  }

  const disableIncreasing = count === maxLimit;

  return (
    <CounterContainer key={key}>
      <CounterWrapper extendCounterWrapper={extendCounterWrapper}>
        <Button
          onClick={() => {
            if (count === 1 && onDelete) {
              onDelete();
            } else {
              onClickDecrease();
            }
          }}
          extendButtonStyle={extendButtonStyle}
          key="quantity-minus"
          iconName={count === 1 && onDelete ? 'e907' : 'e930'}
          iconSize={13}
          disabled={disableDecreasing}
        />
        <CountLabel>
          {count} {label}
        </CountLabel>
        <Button
          onClick={() => {
            onClickIncrease();
          }}
          extendButtonStyle={extendButtonStyle}
          key="quantity-plus"
          iconName="e932"
          iconSize={13}
          disabled={disableIncreasing}
        />
      </CounterWrapper>
    </CounterContainer>
  );
};

Counter.propTypes = {
  key: PropTypes.string,
  count: PropTypes.number,
  label: PropTypes.string,
  onClickIncrease: PropTypes.func,
  onClickDecrease: PropTypes.func,
  onDelete: PropTypes.func,
  maxLimit: PropTypes.number,
  minLimit: PropTypes.number,
  extendButtonStyle: PropTypes.arrayOf({}),
  extendCounterWrapper: PropTypes.arrayOf({}),
};

export default Counter;
