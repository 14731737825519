import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { usePrevious } from 'utils/functions/hooks';
import { status } from 'constants/general';
import Button from 'modules/common/components/Button';
import Popup from 'modules/common/components/Popup';
import { editStockItemCart, saveStockItems } from 'modules/stock/actions';
import {
  PopupTitleSpan,
  PopupWrapper,
  PopupTitle,
  PopupTitleWrapper,
  Container,
  PopupTitleContainer,
  extendLoaderStyle,
  CartContainer,
  ItemContainer,
  ItemDetails,
  ItemImage,
  ItemName,
  ItemDescription,
  RemoveItemIcon,
  ButtonsContainer,
  extendGrayButtonStyle,
  extendBlueButtonStyle,
  ItemsContainer,
  ItemWrapper,
  TableHeader,
  ItemContainerHeader,
  InputField,
  ErrorMessage,
} from './EditStockItemModal.style';

const onChangeAndValidation = (inputedValue, callBack) => {
  let isValidInput = true;
  const regex = /^\d+(?:\.\d{0,2})?$/;
  isValidInput = isValidInput && regex.test(inputedValue);
  if (isValidInput || !inputedValue) {
    if (callBack) callBack();
  }
};

const Item = ({
  itemKey,
  image,
  title,
  subTitle,
  removeItem,
  isEdit,
  sellingPrice,
  stockQuantity,
  pharmacyCode,
  onEditStockItemCart,
  isDuplicateError,
}) => (
  <ItemContainer isEdit={isEdit} key={itemKey}>
    <ItemWrapper isEdit>
      {!isEdit && (
        <RemoveItemIcon
          onClick={() => {
            removeItem();
          }}
        />
      )}
      {image && <ItemImage src={image} alt="item-image" />}
      <ItemDetails>
        <ItemName>{title}</ItemName>
        {subTitle && <ItemDescription>{subTitle}</ItemDescription>}
      </ItemDetails>
    </ItemWrapper>
    {isEdit && (
      <ItemWrapper isEdit>
        <InputField
          isDuplicateError={isDuplicateError}
          id="code-input"
          value={pharmacyCode}
          onChange={event => {
            onEditStockItemCart({ PharmacyCode: event.target.value });
          }}
        />
      </ItemWrapper>
    )}
    {isEdit && (
      <ItemWrapper isEdit>
        <InputField
          id="quantity-input"
          value={stockQuantity}
          placeholder="0"
          onChange={event => {
            onChangeAndValidation(event.target.value, () =>
              onEditStockItemCart({ StockQuantity: event.target.value || '' }),
            );
          }}
        />
      </ItemWrapper>
    )}
    {isEdit && (
      <ItemWrapper isEdit>
        <InputField
          id="price-login-input"
          value={sellingPrice}
          placeholder="0.00"
          onChange={event => {
            onChangeAndValidation(event.target.value, () =>
              onEditStockItemCart({ SellingPrice: event.target.value || '' }),
            );
          }}
        />
      </ItemWrapper>
    )}
  </ItemContainer>
);

Item.propTypes = {
  isDuplicateError: PropTypes.bool,
  itemKey: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  removeItem: PropTypes.func,
  isEdit: PropTypes.bool,
  sellingPrice: PropTypes.number,
  stockQuantity: PropTypes.number,
  pharmacyCode: PropTypes.string,
  onEditStockItemCart: PropTypes.func,
};

const EditStockItemModal = ({ onClose, ...props }) => {
  const [duplicateCode, setDuplicateCode] = useState('');

  const prevSavepharmacyStockItemsLoadStatus = usePrevious(props.savepharmacyStockItemsLoadStatus);
  useEffect(() => {
    if (
      prevSavepharmacyStockItemsLoadStatus &&
      props.savepharmacyStockItemsLoadStatus !== prevSavepharmacyStockItemsLoadStatus &&
      props.savepharmacyStockItemsLoadStatus === status.SUCCESS
    ) {
      setDuplicateCode('');
      onClose();
    }

    if (
      prevSavepharmacyStockItemsLoadStatus &&
      props.savepharmacyStockItemsLoadStatus !== prevSavepharmacyStockItemsLoadStatus &&
      props.savepharmacyStockItemsLoadStatus === status.FAIL &&
      props.savepharmacyStockItems?.response?.status === 409 &&
      props.savepharmacyStockItems?.response?.data?.PharmacyCode?.length
    ) {
      setDuplicateCode(props.savepharmacyStockItems?.response?.data?.PharmacyCode[0]);
    }
  }, [props.savepharmacyStockItemsLoadStatus]);

  const validateStockListData = () => {
    let isValid = true;
    props.stockItemsToBeAddedCart?.forEach(item => {
      const { SellingPrice, /* StockQuantity, */ PharmacyCode } = item;
      if (!SellingPrice || !PharmacyCode) {
        isValid = false;
      }
    });
    return isValid;
  };

  const onSave = () => {
    const cart = [];
    props.stockItemsToBeAddedCart?.forEach(item => {
      const { ProductKey, SellingPrice, StockQuantity, PharmacyCode } = item;
      cart.push({
        ProductKey,
        PharmacyCode,
        StockQuantity: parseFloat(StockQuantity),
        SellingPrice: parseFloat(SellingPrice),
      });
    });
    setDuplicateCode('');
    props.saveStockItems({
      ProuctStockWithPrices: cart,
    });
  };

  const saveView = () => (
    <div
      style={{
        width: '883px',
      }}
    >
      {/** Edit Items */}
      <div>
        {props.stockItemsToBeAddedCart?.length > 0 && (
          <CartContainer>
            <ItemContainerHeader isEdit key="header-table">
              <TableHeader>Item</TableHeader>
              <TableHeader>Pharmacy Code</TableHeader>
              <TableHeader>Quantity</TableHeader>
              <TableHeader>Price</TableHeader>
            </ItemContainerHeader>
            <ItemsContainer>
              {props.stockItemsToBeAddedCart.map(item => (
                <Item
                  isEdit
                  isDuplicateError={!!duplicateCode && duplicateCode === item?.PharmacyCode}
                  key={item?.ProductKey}
                  itemKey={item?.ProductKey}
                  image={item?.itemImage}
                  title={item?.itemName}
                  subTitle={item?.productShape}
                  sellingPrice={item?.SellingPrice}
                  stockQuantity={item?.StockQuantity}
                  pharmacyCode={item?.PharmacyCode}
                  onEditStockItemCart={payload => props.editStockItemCart({ ...item, ...payload })}
                />
              ))}
            </ItemsContainer>
          </CartContainer>
        )}
      </div>
      {duplicateCode && <ErrorMessage>Duplicate Pharmacy Code</ErrorMessage>}
      <ButtonsContainer>
        <Button
          onClick={() => {
            setDuplicateCode('');
            onClose();
          }}
          btnText="Cancel"
          extendButtonStyle={extendGrayButtonStyle}
          key="dismiss-popup"
          disabled={props.savepharmacyStockItemsLoadStatus === status.SUBMITING}
        />
        <Button
          onClick={() => {
            onSave();
          }}
          btnText="Save"
          extendButtonStyle={extendBlueButtonStyle}
          key="proceed-save-view"
          disabled={!validateStockListData()}
          extendLoaderStyle={extendLoaderStyle}
          isLoading={props.savepharmacyStockItemsLoadStatus === status.SUBMITING}
        />
      </ButtonsContainer>
    </div>
  );

  return (
    <Popup showCloseBtn={false}>
      <PopupWrapper>
        <PopupTitleContainer>
          <PopupTitleWrapper>
            <PopupTitle>Edit Item</PopupTitle>
            <PopupTitleSpan
              onClick={() => {
                setDuplicateCode('');
                onClose();
              }}
            />
          </PopupTitleWrapper>
        </PopupTitleContainer>

        <Container>{saveView()}</Container>
      </PopupWrapper>
    </Popup>
  );
};

EditStockItemModal.propTypes = {
  savepharmacyStockItems: PropTypes.shape({
    response: PropTypes.shape({
      status: PropTypes.number,
      data: PropTypes.shape({
        PharmacyCode: PropTypes.arrayOf(PropTypes.string),
      }),
    }),
  }),
  savepharmacyStockItemsLoadStatus: PropTypes.string,
  saveStockItems: PropTypes.func,
  editStockItemCart: PropTypes.func,
  onClose: PropTypes.func,
  stockItemsToBeAddedCart: PropTypes.arrayOf(PropTypes.shape({})),
};

const mapStateToProps = state => ({
  savepharmacyStockItems: state.pharmacyStock.savepharmacyStockItems,
  stockItemsToBeAddedCart: state.pharmacyStock.stockItemsToBeAddedCart,
  savepharmacyStockItemsLoadStatus: state.pharmacyStock.savepharmacyStockItemsLoadStatus,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      editStockItemCart,
      saveStockItems,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(EditStockItemModal);
