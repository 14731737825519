import pharmacyCommonStrings from 'modules/common/strings';

import { pharmacyContexts } from 'constants/contexts';

export const entityTypeEnum = {
  Pharmacy: 1,
  Courier: 2,
  VOPSUser: 3,
  PAPIUser: 4,
  ProgrammaticUser: 5,
  Lab: 6,
  Chemist: 7,
  LabsUser: 8,
};

export const userRoleEnum = {
  VOpsAdmin: 1,
  Courier: 2,
  Normal: 3,
  PAPIUser: 4,
  ProgrammaticUser: 5,
  Pharmacist: 6,
  EPharmacyCouriersManager: 7,
  EPharmacyOperationsManager: 8,
  EPharmacyCustomerCare: 9,
  EPharmacyMasterlist: 10,
  EPharmacyMarketing: 11,
  EPharmacyPurchase: 12,
  SponsoredAdsAdmin: 13,
  SponsoredAdsTeam: 14,
  SponsoredAdsOperation: 15,
  EPharmacyDeliveryCoordinator: 16,
  EPharmacyQualityManager: 17,
  HumanResources: 18,
  LabsAdmin: 19,
  Chemist: 20,
};

export const patientGenderEnum = {
  Male: 1,
  Female: 2,

  getKeyByValue(value) {
    return Object.keys(this).find(key => this[key] === value);
  },
};

export const sidebarItems = [
  {
    context: pharmacyContexts.orders,
    name: pharmacyCommonStrings.sidebar.ordersItemName,
    icon: '"\\e905"',
  },
  {
    context: pharmacyContexts.stock,
    name: pharmacyCommonStrings.sidebar.stockItemName,
    icon: '"\\e904"',
  },
  {
    context: pharmacyContexts.invoices,
    name: pharmacyCommonStrings.sidebar.invoicesItemName,
    icon: '"\\e900"',
  },
];

export const operationsAppEnums = {
  vlabs: '1',
  vpharmacy: '2',
};
