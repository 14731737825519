import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { entityTypeEnum, patientGenderEnum } from 'modules/common/enums';
import {
  fetchOrderDetails,
  fetchOrderItemsSuggestions,
  resetOrderItemsSuggestions,
  fetchOrderItemsSubmission,
  fetchOrderStatusChangeSubmission,
  scheduleOrderSubmission,
  getStocks,
  submitOrderItemsAlternatives,
  fetchItemAlternatives,
  fetchOrderRecommendedAlternatives,
  confirmOrder,
  returnOrder,
} from 'modules/orders/actions';
import ordersStrings from 'modules/orders/strings';
import { fetchInsuranceProvidersByKey } from 'modules/common/actions';
import { getProducts } from 'modules/common/api';

import {
  buttonDisabledOptions,
  dynamicPermissions,
  hideButtonBasedOnState,
  initialNextOrderState,
  orderStatusEnum,
  orderStatusToEnablePharmacyAssignMenu,
  orderStatusToEnablePharmacyEditInvoice,
  permissionsCategories,
  promoCodeStatusPending,
} from 'modules/orders/constants';
import OrderDetails from './OrderDetailsComponent';
import {
  getOrderStatusesDefaultTemplate,
  getOrderStatusesTobeDisplayedInProgressBar,
  getStatusName,
  getStatusColor,
} from './helper';

const OrderDetailsView = ({ orderId, ...props }) => {
  const dispatch = useDispatch();

  const orderDetails = useSelector(state => state.pharmacyOrders.orderDetails);
  const orderItemsSuggestions = useSelector(state => state.pharmacyOrders.orderItemsSuggestions);
  const loginInfo = useSelector(state => state.pharmacyAccountManagement.loginInfo);
  const scheduleOrderReasons = useSelector(state => state.pharmacyOrders.scheduleOrderReasons);
  const cancelOrderReasons = useSelector(state => state.pharmacyOrders.cancelOrderReasons);
  const rejectOrderReasons = useSelector(state => state.pharmacyOrders.rejectOrderReasons);
  const orderAlternatives = useSelector(state => state.pharmacyOrders.orderAlternatives);
  const [actionPopupStatus, setActionPopupStatus] = useState(0);
  const [mappedOrderStatus, setMappedOrderStatus] = useState([]);

  useEffect(() => {
    if (orderDetails.details?.patientInsuranceKey) {
      const insuranceCompanyKey = orderDetails.details?.patientInsurance?.insuranceProviderKey;
      dispatch(fetchInsuranceProvidersByKey(insuranceCompanyKey));
    }
  }, [dispatch, orderDetails]);

  useEffect(() => {
    const orderData = orderDetails?.details;
    const orderLogs = orderData?.orderStates;
    const onlinePaymentCardKey = orderData?.onlinePaymentCardKey;
    const optionalPaymentMethodKey = orderData?.optionalPaymentMethodKey;

    // Get Default Statuses bar
    const orderStatusesDefaultTemplate = getOrderStatusesDefaultTemplate(
      onlinePaymentCardKey,
      optionalPaymentMethodKey,
    );

    // Get Order statuses to be displayed from the order logs
    const orderLogsStatusList = getOrderStatusesTobeDisplayedInProgressBar(orderLogs);

    const fullProgressBarStyled = [];
    let fullProgressBar = [];
    for (let index = orderLogsStatusList.length - 1; index > -1; index -= 1) {
      if (orderStatusesDefaultTemplate.includes(orderLogsStatusList[index])) {
        const indexOfLastNormalState = orderStatusesDefaultTemplate.findIndex(
          val => val === orderLogsStatusList[index],
        );
        fullProgressBar = [
          ...orderLogsStatusList,
          ...orderStatusesDefaultTemplate.slice(indexOfLastNormalState + 1),
        ];
        fullProgressBar?.forEach(step => {
          fullProgressBarStyled.push({
            orderStatusName: getStatusName(step),
            orderStatusColor: getStatusColor(step, orderLogsStatusList),
            orderStatus: step,
          });
        });
        break;
      } else if (!fullProgressBarStyled.length && index === 0) {
        fullProgressBar = [...orderLogsStatusList, ...orderStatusesDefaultTemplate];
        fullProgressBar?.forEach(step => {
          fullProgressBarStyled.push({
            orderStatusName: getStatusName(step),
            orderStatusColor: getStatusColor(step, orderLogsStatusList),
            orderStatus: step,
          });
        });
      }
    }

    setMappedOrderStatus(fullProgressBarStyled);
  }, [orderDetails]);

  const ItemobjectMapper = item => ({
    key: item?.productKey,
    name: item?.productNameEn,
    price: item?.newPrice,
    productShapeId: item?.id,
    productShapeName: item?.productShapeTypeName,
  });

  const reloadOrderDetailsPage = () => {
    const body = {
      orderId,
    };
    dispatch(fetchOrderDetails(body));
  };

  const updateOrderItemsCallback = () => {
    reloadOrderDetailsPage();
  };

  const getItemsSuggestionsData = searchQuery => {
    dispatch(
      fetchOrderItemsSuggestions({
        searchTerm: searchQuery,
      }),
    );
  };

  const resetItemsSuggestionsData = () => {
    dispatch(resetOrderItemsSuggestions());
  };

  const submitOrderInvoiceDispensedItems = ({ orderItems }) => {
    const body = {
      orderId,
      data: {
        orderItems,
      },
    };

    dispatch(fetchOrderItemsSubmission(body));
  };

  const actionBtnClick = actionStatus => {
    setActionPopupStatus(actionStatus);
  };

  const actionPopupDismissBtn = () => {
    setActionPopupStatus(0);
  };

  const actionPopupConfirmBtn = actionPopupConfirmBtnCallbackFn => {
    const body = {
      data: {
        entityId: loginInfo.details.data.key,
        EntityTypeId: entityTypeEnum.Courier,
        orderKey: orderId,
        note: '',
        orderStateTypeId: actionPopupStatus,
      },
      callbackAction: actionPopupConfirmBtnCallbackFn,
    };

    dispatch(fetchOrderStatusChangeSubmission(body));
  };

  const actionPopupConfirmBtnCallback = shouldReloadOrderDetails => {
    setActionPopupStatus(0);

    if (shouldReloadOrderDetails) reloadOrderDetailsPage();
  };

  const scheduleOrderPopupConfirmBtn = (data, scheduleOrderPopupConfirmBtnCallbackFn) => {
    const body = {
      orderId,
      data,
      callbackAction: scheduleOrderPopupConfirmBtnCallbackFn,
    };

    dispatch(scheduleOrderSubmission(body));
  };

  const cancelOrderPopupConfirmBtn = (data, cancelOrderPopupConfirmBtnCallbackFn) => {
    const body = {
      orderId,
      data,
      callbackAction: cancelOrderPopupConfirmBtnCallbackFn,
    };
    dispatch(fetchOrderStatusChangeSubmission(body));
  };

  const rejectOrderPopupConfirmBtn = (data, rejectOrderPopupConfirmBtnCallbackFn) => {
    const body = {
      orderId,
      data,
      callbackAction: rejectOrderPopupConfirmBtnCallbackFn,
    };
    dispatch(fetchOrderStatusChangeSubmission(body));
  };

  const getItemsStocks = payload => {
    dispatch(getStocks(payload));
  };

  const submitItemsAlternatives = payload => {
    dispatch(submitOrderItemsAlternatives({ ...payload }));
  };

  const getItemAlternatives = payload => {
    dispatch(fetchItemAlternatives(payload));
  };

  const getOrderRecommendedAlternatives = () => {
    dispatch(fetchOrderRecommendedAlternatives(orderDetails?.details?.key));
  };

  const handleOrderRefreshBtnClick = () => {
    reloadOrderDetailsPage();
  };

  const confirmOrderAction = payload => {
    dispatch(confirmOrder(payload));
  };

  const returnOrderAction = payload => {
    dispatch(returnOrder(payload));
  };

  return (
    <OrderDetails
      updateOrderItemsCallback={updateOrderItemsCallback}
      orderId={orderId}
      returnOrderAction={returnOrderAction}
      confirmOrderAction={confirmOrderAction}
      fetchOrderStatusChangeSubmission={fetchOrderStatusChangeSubmission}
      reloadOrderDetailsPage={reloadOrderDetailsPage}
      stringsData={ordersStrings}
      orderDetails={orderDetails}
      orderServicesSuggestions={orderItemsSuggestions}
      loginInfo={loginInfo}
      actionPopupStatus={actionPopupStatus}
      handleOrderRefreshBtnClick={handleOrderRefreshBtnClick}
      getItemsSuggestionsData={getItemsSuggestionsData}
      resetServicesSuggestionsData={resetItemsSuggestionsData}
      submitOrderInvoiceDispensedItems={submitOrderInvoiceDispensedItems}
      actionBtnClick={actionBtnClick}
      actionPopupDismissBtn={actionPopupDismissBtn}
      actionPopupConfirmBtn={actionPopupConfirmBtn}
      actionPopupConfirmBtnCallback={actionPopupConfirmBtnCallback}
      scheduleOrderPopupConfirmBtn={scheduleOrderPopupConfirmBtn}
      cancelOrderPopupConfirmBtn={cancelOrderPopupConfirmBtn}
      rejectOrderPopupConfirmBtn={rejectOrderPopupConfirmBtn}
      scheduleOrderReasons={scheduleOrderReasons}
      cancelOrderReasons={cancelOrderReasons}
      rejectOrderReasons={rejectOrderReasons}
      orderStatusEnum={orderStatusEnum}
      orderStatusToEnableAssignMenu={orderStatusToEnablePharmacyAssignMenu}
      orderStatusToEnableEditInvoice={orderStatusToEnablePharmacyEditInvoice}
      objectMapper={ItemobjectMapper}
      hideButtonBasedOnState={hideButtonBasedOnState}
      mappedOrderStatus={mappedOrderStatus}
      entityTypeEnum={entityTypeEnum}
      patientGenderEnum={patientGenderEnum}
      selectItemsSearchApi={getProducts}
      buttonDisabledOptions={buttonDisabledOptions}
      dynamicPermissions={dynamicPermissions}
      initialNextOrderState={initialNextOrderState}
      permissionsCategories={permissionsCategories}
      promoCodeStatusPending={promoCodeStatusPending}
      getStocks={getItemsStocks}
      submitItemsAlternatives={submitItemsAlternatives}
      getItemAlternatives={getItemAlternatives}
      getOrderRecommendedAlternatives={getOrderRecommendedAlternatives}
      orderAlternatives={orderAlternatives}
      showOrderNoteSection
      showOrderInfoSticker
      showInsuranceDetailsSection={orderDetails.details?.patientInsuranceKey}
      showOrderInfoBadges
      showPaymentMethodSticker
      showRejectButton
      showAddressDetailsEditBtn
      isEligableForAlternativeRecommendation={
        orderDetails.details?.isEligableForAlternativeRecommendation
      }
      {...props}
    />
  );
};

OrderDetailsView.propTypes = {
  orderId: PropTypes.string,
};

export default OrderDetailsView;
