import styled from 'styled-components';

export const CounterContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 100px;
`;

export const CounterWrapper = styled.div`
  display: flex;
  padding: 2px;
  background-color: #fff;
  border: 1px solid #d9dbdf;
  border-radius: 8px;
  width: 100%;
  justify-content: space-between;
  ${props => props.extendCounterWrapper || ''}
`;

export const CountLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  padding: 0 5px;
  min-width: 22px;
  background-color: ${({ theme }) => theme.colors.white};
`;
