import { get } from 'utils/functions/request';
import urls from 'utils/urls';

export const getInvoices = payload =>
  get(
    null,
    false,
    `${urls.pharmacyModuleUrls.pharmacyInvoices.getInvoices}?pagesize=10&pagenumber=${
      payload?.pageNumber || 1
    }&invoiceType=${payload?.invoiceType}&from=${payload?.dateFrom}&to=${
      payload?.dateTo
    }&invoiceNumber=${payload?.searchTerm}`,
  );
