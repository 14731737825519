import styled from 'styled-components';
import VezeetaLogo from 'assets/images/vezeeta.svg';
import InfiniteLoadingBar from '../infiniteLoadingBar/InfiniteLoadingBar';

const SplashContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
`;

const Logo = styled.img`
  height: auto;
  width: 300px;
  margin-bottom: 40px;
  ${props => props.theme.media.tablet`
    width: 150px;
    margin-bottom: 20px;
  `};
  ${props => props.theme.media.phone`
    width: 150px;
    margin-bottom: 20px;
  `};
`;

const InfiniteLoadingBarExtended = styled(InfiniteLoadingBar)`
  width: 300px;
  ${props => props.theme.media.tablet`
    width: 150px;
  `};
  ${props => props.theme.media.phone`
    width: 150px;
  `};
`;

const Splash = () => (
  <SplashContainer>
    <Logo src={VezeetaLogo} alt="logo" />
    <InfiniteLoadingBarExtended />
  </SplashContainer>
);

export default Splash;
