import { takeLatest, call, put } from 'redux-saga/effects';
import { getInsuranceProviderByKey, getPharmacies, getServiceableAreas } from './api';
import {
  fetchAllPharmacySuccess,
  fetchAllPharmacyFail,
  fetchServiceableAreasSuccess,
  fetchServiceableAreasFail,
  fetchInsuranceProvidersByKeySuccess,
  fetchInsuranceProvidersByKeyFail,
} from './actions';
import commonActionTypes from './action-types';

function* getPharmaciesSaga({ payload }) {
  try {
    const response = yield call(getPharmacies, payload);
    yield put(fetchAllPharmacySuccess(response.data));
  } catch (error) {
    yield put(fetchAllPharmacyFail());
  }
}

function* getServiceableAreasSaga() {
  try {
    const response = yield call(getServiceableAreas);
    const serviceableAreas = response.data.map(area => {
      const areaName = area?.languageItemModels.find(langModel => langModel.languageId === 1).name;
      return {
        key: area?.areaKey,
        name: areaName,
      };
    });
    yield put(fetchServiceableAreasSuccess(serviceableAreas));
  } catch (error) {
    yield put(fetchServiceableAreasFail());
  }
}

function* getInsuranceProvidersByKeySaga({ payload }) {
  try {
    const response = yield call(getInsuranceProviderByKey, payload);
    const insuranceProviders = response?.data?.Data.map(insurance => ({
      englishName: insurance.LanguageItemModels.find(i => i.LanguageId === 1)?.Name,
      key: insurance.InsuranceKey,
      imageUrl: insurance.ImageUrl,
    }));
    yield put(fetchInsuranceProvidersByKeySuccess(insuranceProviders));
  } catch (error) {
    yield put(fetchInsuranceProvidersByKeyFail());
  }
}

function* commonSagas() {
  yield takeLatest(commonActionTypes.FETCH_ALL_PHARMACIES, getPharmaciesSaga);
  yield takeLatest(commonActionTypes.FETCH_SERVICEABLE_AREAS, getServiceableAreasSaga);
  yield takeLatest(
    commonActionTypes.FETCH_INSURANCE_PROVIDERS_BY_KEY,
    getInsuranceProvidersByKeySaga,
  );
}

export default commonSagas;
