/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { getOrderDetails } from 'modules/orders/api';
import { setOrderStatusByOrderID } from 'modules/orders/actions';
import { orderStatusEnum } from 'modules/orders/constants';

const OrderPaymentUpdates = ({ orderId, currentState }) => {
  const maxNumberOfTrials = currentState === orderStatusEnum.New ? 20 : 2;
  const intervalDuration = currentState === orderStatusEnum.New ? 1000 : 12000;

  const [trials, setTrials] = useState(0);
  const [trigger, setTrigger] = useState(false);
  const dispatch = useDispatch();
  const intervalRef = useRef();

  useEffect(() => {
    if (trigger) {
      setTrigger(false);
      setTrials(trials + 1);
      if (trials > maxNumberOfTrials) clearInterval(intervalRef.current);
      getOrderDetails({ orderId }).then(response => {
        dispatch(
          setOrderStatusByOrderID({
            key: response?.data?.key,
            orderStateTypeId: response?.data?.orderStateTypeId,
            orderStates: response?.data?.orderStates,
            orderReceipt: response?.data?.orderReceipt,
          }),
        );
        setTrigger(false);
      });
    }
  }, [trigger]);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setTrigger(true);
    }, intervalDuration);
  }, []);

  return <div />;
};

OrderPaymentUpdates.propTypes = {
  orderId: PropTypes.string,
  currentState: PropTypes.number,
};

export default OrderPaymentUpdates;
