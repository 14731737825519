import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { addHoursToDateTime } from 'utils/functions/dateTime';
import {
  fetchAllOrders,
  fetchOrderStatusTypes,
  fetchScheduleOrderReasons,
  fetchCancelOrderReasons,
  fetchRejectOrderReasons,
} from 'modules/orders/actions';
import ordersStrings from 'modules/orders/strings';
import OrdersContainer from 'modules/orders/OrdersView/OrdersContainer';
import {
  entityTypeId,
  filters,
  orderStatusColorEnum,
  orderStatusEnum,
} from 'modules/orders/constants';
import { getProducts } from 'modules/common/api';
import { status } from 'constants/general';
import { usePrevious } from 'utils/functions/hooks';
import NotificationBar from 'modules/common/components/NotificationBar';
import PushNotificationComponent from 'modules/PushNotification';
import { getEndDate } from './helper';

const OrdersView = () => {
  const dispatch = useDispatch();
  const { orderid } = useParams();
  const [selectedData, setSelectedData] = useState({}); // Filters
  const [showOrderNotificationBar, toggleOrderNotificationBar] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(undefined);
  const [searchQuery, setSearchQuery] = useState('');
  const [ordersApiCallParams, setOrdersApiCallParams] = useState({
    beginDate: new Date(new Date().setDate(new Date().getDate() - 1)),
    endDate: new Date(`${getEndDate()}`),
    pageNumber: 1,
  });

  const selectedDateFilterObj = {
    startDate: ordersApiCallParams.beginDate,
    endDate: ordersApiCallParams.endDate,
  };

  const ordersList = useSelector(state => state.pharmacyOrders.ordersList);
  const scheduleOrderReasons = useSelector(state => state.pharmacyOrders.scheduleOrderReasons);

  const getAllOrders = () => {
    dispatch(fetchAllOrders(ordersApiCallParams));
  };

  useEffect(() => {
    getAllOrders();
  }, [ordersApiCallParams]);

  const preScheduleOrderReasons = usePrevious(scheduleOrderReasons.status);
  useEffect(() => {
    if (
      preScheduleOrderReasons !== scheduleOrderReasons.status &&
      scheduleOrderReasons.status === status.SHOULD_CALL_API
    ) {
      dispatch(fetchScheduleOrderReasons());
      dispatch(fetchCancelOrderReasons());
      dispatch(fetchRejectOrderReasons());
      getAllOrders();
      dispatch(fetchOrderStatusTypes());
    }
  }, []);

  const handleOrderSearchBarInputChange = (searchTerm, reset) => {
    const body = {
      ...ordersApiCallParams,
      pageNumber: 1,
      searchTerm: reset ? '' : searchTerm,
    };
    setOrdersApiCallParams(body);
  };

  const handleOrderDateFilterChange = selectedDateObj => {
    const body = {
      ...ordersApiCallParams,
      pageNumber: 1,
      beginDate: addHoursToDateTime(selectedDateObj?.startDate, 0),
      endDate: addHoursToDateTime(selectedDateObj?.endDate, 24),
    };
    setOrdersApiCallParams(body);
  };

  const handleGenericFilterClick = statesObject => {
    const body = {
      ...ordersApiCallParams,
      stateId: [],
    };
    if (Object.keys(statesObject).length > 0) {
      body.stateId = Object.keys(statesObject);
    }
    setOrdersApiCallParams(body);
    setSelectedData(statesObject);
  };

  const handleOrderPaginationClick = selectedPageNumber => {
    const body = {
      ...ordersApiCallParams,
      pageNumber: selectedPageNumber,
    };
    setOrdersApiCallParams(body);
  };

  const handleOrderSelection = key => {
    setSelectedOrderId(key);
    const orderUrl = `${window.location.origin}/orders/${key}`;
    window.history.pushState({ path: orderUrl }, '', orderUrl);
  };

  const onResetAll = () => {
    const body = {
      ...ordersApiCallParams,
      beginDate: new Date(new Date().setDate(new Date().getDate() - 1)),
      endDate: new Date(),
      stateId: [],
      pageNumber: 1,
      searchTerm: '',
    };
    setSearchQuery('');
    setSelectedData({});
    setOrdersApiCallParams(body);
  };

  const pauseNotificationSound = () => {
    const audioElement = document.getElementById('notification-audio');
    if (audioElement) {
      audioElement.loop = false;
      audioElement.pause();
    }
  };

  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <NotificationBar
        key="notification-bar_new-orders"
        message="New Orders Recieved !"
        onClick={() => {
          onResetAll();
          toggleOrderNotificationBar(false);
          pauseNotificationSound();
        }}
        onClose={() => {
          toggleOrderNotificationBar(false);
          pauseNotificationSound();
        }}
        showNotification={showOrderNotificationBar}
      />
      <PushNotificationComponent
        onChange={() => {
          toggleOrderNotificationBar(true);
        }}
        callBack={handleOrderSelection}
      />
      <OrdersContainer
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        totalCount={ordersList.details?.totalCount}
        stringsData={ordersStrings}
        selectedDateFilterObj={selectedDateFilterObj}
        selectedPageFilter={ordersApiCallParams.pageNumber}
        ordersList={ordersList}
        mappedOrdersList={ordersList.details?.mappedOrdersList || []}
        orderStatusColorEnum={orderStatusColorEnum}
        handleOrderSearchBarInputChange={handleOrderSearchBarInputChange}
        handleOrderDateFilterChange={handleOrderDateFilterChange}
        handleOrderPaginationClick={handleOrderPaginationClick}
        handleGenericFilterClick={handleGenericFilterClick}
        ordersApiCallParams={ordersApiCallParams}
        filters={filters}
        selectItemsSearchApi={getProducts}
        entityTypeId={entityTypeId}
        orderStatusEnum={orderStatusEnum}
        setSelectedOrderId={handleOrderSelection}
        selectedOrderId={selectedOrderId || orderid}
      />
    </div>
  );
};

OrdersView.propTypes = {
  allOrdersApiCallBody: PropTypes.shape({}),
  selectedDateRangeFilterObj: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    selectedDateLabel: PropTypes.string,
  }),
};

export default OrdersView;
