import CheckboxGroup from 'modules/common/components/CheckboxGroup';
import SearchBar from 'modules/common/components/SearchBar';
import { reasonsToEnableSelectItemsSection } from 'modules/orders/OrdersView/OrdersContainer/enums';
import {
  OrderState,
  SelectedItem,
  Label,
  DeleteIcon,
} from '../OrderActionBtnsSection/OrderDetailsActionBtns.style';

export const renderSelectItemsSection = (
  orderData,
  selectedChildReasonId,
  selectedItems,
  setSelectedItems,
  selectItemsSearchApi,
) => {
  const { mappedItemsList, orderAttachments } = orderData;
  return (
    reasonsToEnableSelectItemsSection[selectedChildReasonId] && (
      <>
        <OrderState>Select Items</OrderState>
        {mappedItemsList.length > 0 && (
          <CheckboxGroup
            options={mappedItemsList}
            selectedOptions={selectedItems}
            setSelectedOptions={setSelectedItems}
          />
        )}
        {orderAttachments.length > 0 && (
          <>
            <SearchBar
              selectHandler={item => {
                const itemIndex = selectedItems.findIndex(
                  selectedItem => selectedItem.serviceKey === item.key,
                );
                let tempItem = { ...item };
                if (itemIndex === -1) {
                  tempItem = {
                    searchResultItem: true,
                    serviceName: item?.productNameEn,
                    serviceKey: item?.productKey,
                  };
                  setSelectedItems([...selectedItems, tempItem]);
                }
              }}
              objectMapper={item => ({
                name: item?.productNameEn,
                info: item?.newPrice && `${item?.newPrice} EGP`,
              })}
              getData={selectItemsSearchApi}
            />
            {selectedItems
              .filter(({ searchResultItem }) => searchResultItem)
              .map(selectedItem => (
                <SelectedItem>
                  <Label>{selectedItem?.serviceName}</Label>
                  <DeleteIcon
                    onClick={() => {
                      let tempSelected = [...selectedItems];
                      tempSelected = tempSelected.filter(
                        item => item.serviceKey !== selectedItem?.serviceKey,
                      );
                      setSelectedItems(tempSelected);
                    }}
                  />
                </SelectedItem>
              ))}
          </>
        )}
      </>
    )
  );
};
