import PropTypes from 'prop-types';
import Loader from 'modules/common/components/Loader';
import { status } from 'constants/general';
import ErrorImg from 'assets/images/error.png';
import IntroStatus from './IntroStatus';
import {
  MainContainer,
  MainContainerWrapper,
  ErrorImage,
  ErrorMessage,
  ErrorMessageContainer,
  LoaderContainer,
} from './IntroStatus.style';

const IntroStatusContainer = ({ loadStatus }) => {
  const renderState = () => {
    if (loadStatus === status.SHOULD_CALL_API || loadStatus === status.FETCHING) {
      return (
        <MainContainer>
          <MainContainerWrapper>
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          </MainContainerWrapper>
        </MainContainer>
      );
    }
    if (loadStatus === status.SUCCESS) {
      return (
        <MainContainer>
          <MainContainerWrapper>
            <IntroStatus />
          </MainContainerWrapper>
        </MainContainer>
      );
    }
    return (
      <MainContainer>
        <MainContainerWrapper>
          <ErrorImage src={ErrorImg} alt="error-image" />
          <ErrorMessageContainer>
            <ErrorMessage>Something went wrong!</ErrorMessage>
          </ErrorMessageContainer>
        </MainContainerWrapper>
      </MainContainer>
    );
  };

  return <div>{renderState()}</div>;
};

IntroStatusContainer.propTypes = {
  loadStatus: PropTypes.string,
};

export default IntroStatusContainer;
