import PropTypes from 'prop-types';
import DropdownMenu from 'modules/common/components/DropdownMenu';
import RangeDatePicker from 'modules/common/components/RangeDatePicker';
import {
  ContainerHeader,
  FilterSearchBarWrapper,
  FilterSearchBarIcon,
  FilterSearchBar,
  FilterClearIcon,
  extendPickerStyle,
  extendPickerBtnStyle,
} from './SearchAndFilterContainer.style';

const list = [
  {
    key: '',
    name: 'All Types',
  },
  {
    key: 1,
    name: 'Sales',
  },
  {
    key: 2,
    name: 'Return',
  },
];

const SearchAndFilterContainer = ({
  setSelectedDate,
  queryString,
  onSearch,
  onChangeTypes,
  invoiceTypeObj,
  selectedDate,
}) => (
  <ContainerHeader>
    {/** Search Bar */}
    <FilterSearchBarWrapper>
      <FilterSearchBarIcon />
      <FilterSearchBar
        placeholder="Invoice Number"
        value={queryString}
        onChange={event => onSearch(event.target.value)}
      />
      {queryString && (
        <FilterClearIcon
          onClick={() => {
            onSearch('');
          }}
        />
      )}
    </FilterSearchBarWrapper>
    <div
      style={{
        width: '140px',
      }}
    >
      <DropdownMenu
        dropdownMenuItemsList={list}
        dropdownMenuSelectedObj={invoiceTypeObj}
        handleDropdownMenuChange={onChangeTypes}
      />
    </div>
    <div>
      {/** Date Filter */}
      <RangeDatePicker
        extendPickerBtnStyle={extendPickerBtnStyle}
        extendPickerStyle={extendPickerStyle}
        selectedDateFilterObj={selectedDate}
        handleRangeDatePickerChange={setSelectedDate}
      />
    </div>
  </ContainerHeader>
);

SearchAndFilterContainer.propTypes = {
  queryString: PropTypes.string,
  onSearch: PropTypes.func,
  onChangeTypes: PropTypes.func,
  setSelectedDate: PropTypes.func,
  invoiceTypeObj: PropTypes.shape({}),
  selectedDate: PropTypes.shape({}),
};

export default SearchAndFilterContainer;
