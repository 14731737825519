import PropTypes from 'prop-types';
import Popup from 'modules/common/components/Popup';
import SectionDivider from 'modules/common/components/SectionDivider';
import RadioButtonGroup from 'modules/common/components/RadioButtonGroup';
import Loader from 'modules/common/components/Loader';
import { RejectionReasonsToBeHidden } from 'modules/orders/constants';
import { renderSelectItemsSection } from './SelectItemsSection';

import {
  OrderDetailsScheduleOrderPopupWrapper,
  OrderDetailsScheduleOrderPopupTitleWrapper,
  OrderDetailsScheduleOrderPopupTitle,
  OrderDetailsScheduleOrderPopupTitleSpan,
  OrderDetailsScheduleOrderSectionTitle,
  OrderDetailsScheduleOrderReasonsWrapper,
  OrderDetailsScheduleOrderNoteWrapper,
  OrderDetailsScheduleOrderNoteTextArea,
  OrderDetailsScheduleOrderBtnWrapper,
  OrderDetailsScheduleOrderCancelBtn,
  OrderDetailsScheduleOrderError,
  OrderDetailsCancelSectionSubTitle,
  OrderRejectionBtn,
} from '../OrderActionBtnsSection/OrderDetailsActionBtns.style';

const RejectOrderModal = ({
  themeContext,
  stringsData,
  isRejectOrderPopupLoading,
  rejectOrderReason,
  handleRejectOrderReasonRadioButtonClick,
  rejectOrderReasons,
  handleRejectOrderPopupConfirmBtnClick,
  handleRejectOrderPopupDismissBtnClick,
  rejectOrderReasonIsRequiredError,
  rejectOrderNote,
  handleRejectOrderNoteChange,
  orderData,
  selectedItems,
  setSelectedItems,
  selectItemsSearchApi,
}) => (
  <Popup showCloseBtn={false}>
    <OrderDetailsScheduleOrderPopupWrapper>
      <OrderDetailsScheduleOrderPopupTitleWrapper>
        <OrderDetailsScheduleOrderPopupTitle as="p">
          {stringsData.orderDetailsHeader.rejectOrderTitle}
        </OrderDetailsScheduleOrderPopupTitle>
        <OrderDetailsScheduleOrderPopupTitleSpan
          as="span"
          onClick={() => handleRejectOrderPopupDismissBtnClick()}
        />
      </OrderDetailsScheduleOrderPopupTitleWrapper>
      <SectionDivider
        gap={16}
        withSeparator
        separatorGap={16}
        borderColor={themeContext.colors.grey004}
      />
      <OrderDetailsScheduleOrderReasonsWrapper>
        {rejectOrderReasons?.map(reasonCategory => {
          const scheduleOrderReasonsArr = [];
          reasonCategory.orderStateChildReasons.forEach(reason => {
            if (!RejectionReasonsToBeHidden.includes(reason.id)) {
              scheduleOrderReasonsArr.push({
                name: reason.name,
                key: reason.id,
              });
            }
          });
          if (scheduleOrderReasonsArr.length > 0) {
            return (
              <>
                <OrderDetailsCancelSectionSubTitle as="p">
                  {reasonCategory.name}
                </OrderDetailsCancelSectionSubTitle>
                <RadioButtonGroup
                  RadioButtonGroupArr={scheduleOrderReasonsArr}
                  selectedRadioButton={rejectOrderReason}
                  handleRadioButtonClick={handleRejectOrderReasonRadioButtonClick}
                />
                {reasonCategory.id === 1 &&
                  renderSelectItemsSection(
                    orderData,
                    rejectOrderReason,
                    selectedItems,
                    setSelectedItems,
                    selectItemsSearchApi,
                  )}
                <SectionDivider
                  gap={16}
                  withSeparator
                  separatorGap={16}
                  borderColor={themeContext.colors.grey004}
                />
              </>
            );
          }
          return '';
        })}
      </OrderDetailsScheduleOrderReasonsWrapper>
      <OrderDetailsScheduleOrderNoteWrapper>
        <OrderDetailsScheduleOrderSectionTitle as="p">
          {stringsData.orderDetailsHeader.scheduleOrderNoteTitle}
        </OrderDetailsScheduleOrderSectionTitle>
        <SectionDivider gap={8} />
        <OrderDetailsScheduleOrderNoteTextArea
          placeholder={stringsData.orderDetailsHeader.scheduleOrderNotePlaceholder}
          value={rejectOrderNote}
          onChange={event => handleRejectOrderNoteChange(event)}
        />
      </OrderDetailsScheduleOrderNoteWrapper>
      {rejectOrderReasonIsRequiredError && (
        <OrderDetailsScheduleOrderError>
          {stringsData.orderDetailsHeader.cancelOrderReasonIsRequired}
        </OrderDetailsScheduleOrderError>
      )}
      <SectionDivider
        gap={22}
        withSeparator
        separatorGap={16}
        borderColor={themeContext.colors.grey004}
      />
      <OrderDetailsScheduleOrderBtnWrapper>
        <OrderDetailsScheduleOrderCancelBtn
          as="button"
          onClick={() => handleRejectOrderPopupDismissBtnClick()}
        >
          {stringsData.orderDetailsHeader.scheduleOrderCancelBtn}
        </OrderDetailsScheduleOrderCancelBtn>
        <OrderRejectionBtn as="button" onClick={() => handleRejectOrderPopupConfirmBtnClick()}>
          {(isRejectOrderPopupLoading && <Loader width={14} height={14} thickness={4} />) ||
            'Reject'}
        </OrderRejectionBtn>
      </OrderDetailsScheduleOrderBtnWrapper>
    </OrderDetailsScheduleOrderPopupWrapper>
  </Popup>
);

RejectOrderModal.propTypes = {
  themeContext: PropTypes.shape({
    colors: PropTypes.shape({
      grey004: PropTypes.string,
    }),
  }).isRequired,
  stringsData: PropTypes.shape({
    orderDetailsHeader: PropTypes.shape({
      rejectOrderTitle: PropTypes.string,
      scheduleOrderSaveBtn: PropTypes.string,
      scheduleOrderCancelBtn: PropTypes.string,
      cancelOrderReasonIsRequired: PropTypes.string,
      rejectOrderReasonTitle: PropTypes.string,
      scheduleOrderNoteTitle: PropTypes.string,
      scheduleOrderNotePlaceholder: PropTypes.string,
    }),
  }).isRequired,
  isRejectOrderPopupLoading: PropTypes.bool.isRequired,
  rejectOrderReason: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  handleRejectOrderReasonRadioButtonClick: PropTypes.func.isRequired,
  handleRejectOrderPopupConfirmBtnClick: PropTypes.func.isRequired,
  handleRejectOrderPopupDismissBtnClick: PropTypes.func.isRequired,
  rejectOrderReasons: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  rejectOrderReasonIsRequiredError: PropTypes.bool,
  rejectOrderNote: PropTypes.string.isRequired,
  handleRejectOrderNoteChange: PropTypes.func.isRequired,
  orderData: PropTypes.shape({}),
  selectedItems: PropTypes.shape([]),
  setSelectedItems: PropTypes.func,
  selectItemsSearchApi: PropTypes.func,
};

RejectOrderModal.defaultProps = {
  rejectOrderReasonIsRequiredError: false,
  orderData: {},
  selectedItems: [],
  setSelectedItems: () => {},
  selectItemsSearchApi: () => {},
};

export default RejectOrderModal;
