import { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useOnClickOutside } from 'utils/functions/hooks';
import Loader from 'modules/common/components/Loader';
import {
  Item,
  ItemInfo,
  ItemName,
  LoadingWrapper,
  SearchBarClearIcon,
  SearchBarContainer,
  SearchBarSearchIcon,
  SearchContent,
  SearchInput,
  SearchInputContainer,
  WarningMessage,
} from './SearchBar.style';

const SearchBar = ({ selectHandler, getData, objectMapper }) => {
  const [isExpanded, setExpanded] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setLoading] = useState(null);
  const [results, setResults] = useState([]);
  const inputRef = useRef();
  const containerRef = useRef();

  const isEmpty = !results || results.length === 0;

  const changeHandler = e => {
    e.preventDefault();
    setSearchQuery(e.target.value);
  };

  const expandContainer = () => {
    setExpanded(true);
  };

  const collapseContainer = () => {
    setExpanded(false);
    setSearchQuery('');
    setLoading(null);
    setResults([]);
    if (inputRef.current) inputRef.current.value = '';
  };

  const searchItems = useCallback(async () => {
    if (!searchQuery || searchQuery.trim() === '') return;

    setLoading(true);
    try {
      const res = await getData(searchQuery);
      setResults(res.data.productShapes ? res.data.productShapes : res.data);
    } catch (error) {
      console.error('Error: ', error);
    }
    setLoading(false);
  }, [searchQuery, getData]);

  useEffect(() => {
    const timeoutId = setTimeout(() => searchItems(), 500);
    return () => clearTimeout(timeoutId);
  }, [searchQuery, searchItems]);

  useOnClickOutside(containerRef, collapseContainer);

  return (
    <SearchBarContainer ref={containerRef}>
      <SearchInputContainer>
        <SearchBarSearchIcon />
        <SearchInput
          placeholder="Search by Items"
          onFocus={expandContainer}
          ref={inputRef}
          value={searchQuery}
          onChange={changeHandler}
        />
        {isExpanded && <SearchBarClearIcon onClick={collapseContainer} />}
      </SearchInputContainer>
      {isExpanded && (
        <SearchContent>
          {isLoading && (
            <LoadingWrapper>
              <Loader width={40} height={40} thickness={6} />
            </LoadingWrapper>
          )}
          {isLoading === false && results.length === 0 && (
            <LoadingWrapper>
              <WarningMessage>No results found!</WarningMessage>
            </LoadingWrapper>
          )}
          {!isLoading &&
            !isEmpty &&
            results.map(item => (
              <Item
                onClick={() => {
                  selectHandler(item);
                  collapseContainer();
                }}
              >
                <ItemName>{objectMapper(item).name}</ItemName>
                <ItemInfo>{objectMapper(item).info}</ItemInfo>
              </Item>
            ))}
        </SearchContent>
      )}
    </SearchBarContainer>
  );
};

SearchBar.propTypes = {
  selectHandler: PropTypes.func,
  getData: PropTypes.func,
  objectMapper: PropTypes.func.isRequired,
};

SearchBar.defaultProps = {
  selectHandler: () => {},
  getData: () => {},
};

export default SearchBar;
