import styled from 'styled-components';
import themeData from 'utils/theme/themeData';

const { typography } = themeData;

export const DatePickerWrapper = styled.div`
  position: relative;
`;

export const DatePickerSelectedDateWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  color: ${({ theme }) => theme.colors.black};
  border: 1px solid ${({ theme }) => theme.colors.grey000};
  border-radius: 4px;
  cursor: pointer;
`;

export const DatePickerSelectedDateText = styled(typography.BodyTextOneRegularTypography)`
  flex-grow: 1;
  margin: 0;
`;

export const DatePickerSelectedDateIcon = styled.span`
  &:before {
    content: '\\e913';
    font-family: 'Icomoon';
    font-size: 12px;
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const DatePickerSelectorWrapper = styled.div`
  position: absolute;
  top: 30px;

  & .rdrCalendarWrapper {
    background-color: ${({ theme }) => theme.colors.grey100};
    border: 1px solid ${({ theme }) => theme.colors.grey002};
  }
`;
