import { status } from 'constants/general';
import { initialPharmacyInvoiceState } from 'store/initialStates';
import stockActionTypes from './action-types';

const stockReducer = (state = initialPharmacyInvoiceState, { type, payload }) => {
  switch (type) {
    case stockActionTypes.FETCH_INVOICES:
      return {
        ...state,
        pharmacyInvoicesLoadStatus: status.FETCHING,
        pharmacyInvoices: {
          items: payload.pageNumber === 1 ? [] : [...state.pharmacyInvoices.items],
          showMore: payload.pageNumber === 1 ? false : state.pharmacyInvoices.showMore,
        },
      };
    case stockActionTypes.FETCH_INVOICES_SUCCESS:
      return {
        ...state,
        pharmacyInvoices: {
          items: [...state.pharmacyInvoices.items, ...payload],
          showMore: !(payload?.length < 10),
        },
        pharmacyInvoicesLoadStatus: status.SUCCESS,
      };
    case stockActionTypes.FETCH_INVOICES_FAIL:
      return {
        ...state,
        pharmacyInvoicesLoadStatus: status.FAIL,
      };
    case stockActionTypes.TOGGLE_INVOICE_MODAL:
      return {
        ...state,
        isInvoiceModalOpened: payload,
      };
    case stockActionTypes.SET_INVOICE:
      return {
        ...state,
        invoiceDetails: payload,
      };

    default:
      return state;
  }
};

export default stockReducer;
