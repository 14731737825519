const ordersActionTypes = {
  FETCH_ALL_PHARMACY_ORDERS: 'FETCH_ALL_PHARMACY_ORDERS',
  FETCH_ALL_PHARMACY_ORDERS_SUCCESS: 'FETCH_ALL_PHARMACY_ORDERS_SUCCESS',
  FETCH_ALL_PHARMACY_ORDERS_FAIL: 'FETCH_ALL_PHARMACY_ORDERS_FAIL',

  SET_ALL_PHARMACY_ORDERS_ASSIGNED_ENTITY_DETAILS:
    'SET_ALL_PHARMACY_ORDERS_ASSIGNED_ENTITY_DETAILS',

  FETCH_PARTNERSHIP_TYPES: 'FETCH_PARTNERSHIP_TYPES',
  FETCH_PARTNERSHIP_TYPES_SUCCESS: 'FETCH_PARTNERSHIP_TYPES_SUCCESS',
  FETCH_PARTNERSHIP_TYPES_FAIL: 'FETCH_PARTNERSHIP_TYPES_FAIL',

  FETCH_DETAILED_REASONS: 'FETCH_DETAILED_REASONS',
  FETCH_DETAILED_REASONS_SUCCESS: 'FETCH_DETAILED_REASONS_SUCCESS',
  FETCH_DETAILED_REASONS_FAIL: 'FETCH_DETAILED_REASONS_FAIL',

  FETCH_ORDER_STATUS_TYPES: 'FETCH_ORDER_STATUS_TYPES',
  FETCH_ORDER_STATUS_TYPES_SUCCESS: 'FETCH_ORDER_STATUS_TYPES_SUCCESS',
  FETCH_ORDER_STATUS_TYPES_FAIL: 'FETCH_ORDER_STATUS_TYPES_FAIL',

  FETCH_PHARMACY_ORDER_DETAILS: 'FETCH_PHARMACY_ORDER_DETAILS',
  FETCH_PHARMACY_ORDER_DETAILS_SUCCESS: 'FETCH_PHARMACY_ORDER_DETAILS_SUCCESS',
  FETCH_PHARMACY_ORDER_DETAILS_FAIL: 'FETCH_PHARMACY_ORDER_DETAILS_FAIL',

  SET_PHARMACY_ORDER_PATIENT_USER_DETAILS: 'SET_PHARMACY_ORDER_PATIENT_USER_DETAILS',

  SET_PHARMACY_ORDER_ASSIGNED_ENTITY_DETAILS: 'SET_PHARMACY_ORDER_ASSIGNED_ENTITY_DETAILS',

  SET_PHARMACY_ORDER_LOGS_DETAILS: 'SET_PHARMACY_ORDER_LOGS_DETAILS',

  FETCH_PHARMACY_ORDER_STATUS_CHANGE_SUBMISSION: 'FETCH_PHARMACY_ORDER_STATUS_CHANGE_SUBMISSION',
  FETCH_PHARMACY_ORDER_STATUS_CHANGE_SUBMISSION_SUCCESS:
    'FETCH_PHARMACY_ORDER_STATUS_CHANGE_SUBMISSION_SUCCESS',
  FETCH_PHARMACY_ORDER_STATUS_CHANGE_SUBMISSION_FAIL: 'FETCH_ORDERS_STATUS_CHANGE_SUBMISSION_FAIL',

  FETCH_PHARMACY_ORDER_NOTE_CHANGE_SUBMISSION: 'FETCH_PHARMACY_ORDER_NOTE_CHANGE_SUBMISSION',
  FETCH_PHARMACY_ORDER_NOTE_CHANGE_SUBMISSION_SUCCESS:
    'FETCH_PHARMACY_ORDER_NOTE_CHANGE_SUBMISSION_SUCCESS',
  FETCH_PHARMACY_ORDER_NOTE_CHANGE_SUBMISSION_FAIL:
    'FETCH_PHARMACY_ORDERS_NOTE_CHANGE_SUBMISSION_FAIL',

  ASSIGN_PHARMACY_ORDER_TO_ENTITY: 'ASSIGN_PHARMACY_ORDER_TO_ENTITY',
  ASSIGN_PHARMACY_ORDER_TO_ENTITY_SUCCESS: 'ASSIGN_PHARMACY_ORDER_TO_ENTITY_SUCCESS',
  ASSIGN_PHARMACY_ORDER_TO_ENTITY_FAIL: 'ASSIGN_PHARMACY_ORDER_TO_ENTITY_FAIL',

  SCHEDULE_ORDER_SUBMISSION: 'SCHEDULE_ORDER_SUBMISSION',
  SCHEDULE_ORDER_SUBMISSION_SUCCESS: 'SCHEDULE_ORDER_SUBMISSION_SUCCESS',
  SCHEDULE_ORDER_SUBMISSION_FAIL: 'SCHEDULE_ORDERS_SUBMISSION_FAIL',

  FETCH_PHARMACY_SCHEDULE_ORDER_REASONS: 'FETCH_PHARMACY_SCHEDULE_ORDER_REASONS',
  FETCH_PHARMACY_SCHEDULE_ORDER_REASONS_SUCCESS: 'FETCH_PHARMACY_SCHEDULE_ORDER_REASONS_SUCCESS',
  FETCH_PHARMACY_SCHEDULE_ORDER_REASONS_FAIL: 'FETCH_PHARMACY_SCHEDULE_ORDER_REASONS_FAIL',

  FETCH_PHARMACY_CANCEL_ORDER_REASONS: 'FETCH_PHARMACY_CANCEL_ORDER_REASONS',
  FETCH_PHARMACY_CANCEL_ORDER_REASONS_SUCCESS: 'FETCH_PHARMACY_CANCEL_ORDER_REASONS_SUCCESS',
  FETCH_PHARMACY_CANCEL_ORDER_REASONS_FAIL: 'FETCH_PHARMACY_CANCEL_ORDER_REASONS_FAIL',

  FETCH_PHARMACY_REJECT_ORDER_REASONS: 'FETCH_PHARMACY_REJECT_ORDER_REASONS',
  FETCH_PHARMACY_REJECT_ORDER_REASONS_SUCCESS: 'FETCH_PHARMACY_REJECT_ORDER_REASONS_SUCCESS',
  FETCH_PHARMACY_REJECT_ORDER_REASONS_FAIL: 'FETCH_PHARMACY_REJECT_ORDER_REASONS_FAIL',

  SET_ORDER_ITEMS_DETAILS: 'SET_ORDER_ITEMS_DETAILS',

  FETCH_ORDER_ITEMS_SUGGESTIONS: 'FETCH_ORDER_ITEMS_SUGGESTIONS',
  FETCH_ORDER_ITEMS_SUGGESTIONS_SUCCESS: 'FETCH_ORDER_ITEMS_SUGGESTIONS_SUCCESS',
  FETCH_ORDER_ITEMS_SUGGESTIONS_FAIL: 'FETCH_ORDER_ITEMS_SUGGESTIONS_FAIL',
  RESET_ORDER_ITEMS_SUGGESTIONS: 'RESET_ORDER_ITEMS_SUGGESTIONS',

  FETCH_ORDER_ITEMS_SUBMISSION: 'FETCH_ORDER_ITEMS_SUBMISSION',
  FETCH_ORDER_ITEMS_SUBMISSION_SUCCESS: 'FETCH_ORDER_ITEMS_SUBMISSION_SUCCESS',
  FETCH_ORDER_ITEMS_SUBMISSION_FAIL: 'FETCH_ORDER_ITEMS_SUBMISSION_FAIL',

  UPDATE_SHIPPING_ADDRESS: 'UPDATE_SHIPPING_ADDRESS',
  UPDATE_SHIPPING_ADDRESS_SUCCESS: 'UPDATE_SHIPPING_ADDRESS_SUCCESS',
  UPDATE_SHIPPING_ADDRESS_FAIL: 'UPDATE_SHIPPING_ADDRESS_FAIL',

  GET_STOCKS: 'GET_STOCKS',
  GET_STOCKS_SUCCESS: 'GET_STOCKS_SUCCESS',
  GET_STOCKS_FAIL: 'GET_STOCKS_FAIL',

  FETCH_ITEM_ALTERNATIVES: 'FETCH_ITEM_ALTERNATIVES',
  FETCH_ITEM_ALTERNATIVES_SUCCESS: 'FETCH_ITEM_ALTERNATIVES_SUCCESS',
  FETCH_ITEM_ALTERNATIVES_FAIL: 'FETCH_ITEM_ALTERNATIVES_FAIL',

  SUBMIT_ORDER_ITEMS_ALTERNATIVES: 'SUBMIT_ORDER_ITEMS_ALTERNATIVES',
  SUBMIT_ORDER_ITEMS_ALTERNATIVES_SUCCESS: 'SUBMIT_ORDER_ITEMS_ALTERNATIVES_SUCCESS',
  SUBMIT_ORDER_ITEMS_ALTERNATIVES_FAIL: 'SUBMIT_ORDER_ITEMS_ALTERNATIVES_FAIL',

  FETCH_ORDER_RECOMMENDED_ALTERNATIVES: 'FETCH_ORDER_RECOMMENDED_ALTERNATIVES',
  FETCH_ORDER_RECOMMENDED_ALTERNATIVES_SUCCESS: 'FETCH_ORDER_RECOMMENDED_ALTERNATIVES_SUCCESS',
  FETCH_ORDER_RECOMMENDED_ALTERNATIVES_FAIL: 'FETCH_ORDER_RECOMMENDED_ALTERNATIVES_FAIL',

  CONFIRM_ORDER: 'CONFIRM_ORDER',
  CONFIRM_ORDER_SUCCESS: 'CONFIRM_ORDER_SUCCESS',
  CONFIRM_ORDER_FAIL: 'CONFIRM_ORDER_FAIL',

  RETURN_ORDER: 'RETURN_ORDER',
  RETURN_ORDER_SUCCESS: 'RETURN_ORDER_SUCCESS',
  RETURN_ORDER_FAIL: 'RETURN_ORDER_FAIL',

  SET_ORDER_STATUS_BY_ORDER_ID: 'SET_ORDER_STATUS_BY_ORDER_ID',
};

export default ordersActionTypes;
