import PropTypes from 'prop-types';
import DropdownMenu from 'modules/common/components/DropdownMenu';

const TimeSlotsDropdown = ({
  timeSlotsArr,
  selectedTimeSlot,
  handleTimeSlotDropdownMenuChange,
}) => (
  <DropdownMenu
    dropdownMenuItemsList={timeSlotsArr}
    dropdownMenuSelectedObj={selectedTimeSlot}
    handleDropdownMenuChange={handleTimeSlotDropdownMenuChange}
  />
);

TimeSlotsDropdown.propTypes = {
  timeSlotsArr: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedTimeSlot: PropTypes.shape({}).isRequired,
  handleTimeSlotDropdownMenuChange: PropTypes.func.isRequired,
};

TimeSlotsDropdown.defaultProps = {};

export default TimeSlotsDropdown;
