export const getDateTimeObjFromDateTimeStr = (
  dateOrDateTimeStr,
  isDateTime = false,
  convertToLocalTimeZone = false,
  dateSeparator = '-',
  dateStringAlreadyInCorrectOrder = false,
) => {
  if (!dateOrDateTimeStr) return {};
  // get date from dateTime by splitting the string and getting the first array value
  let date = '';
  let time = '';

  // this means that if 'isDateTime' we can provide a DateTime else we will provide a Date
  if (isDateTime) [date, time] = dateOrDateTimeStr.split('T');
  else [date] = dateOrDateTimeStr.split(' ');

  // convert the date into ISO format 'YYYY-MM-DD HH:ss AM/PM' so we can parse it
  let day = '';
  let month = '';
  let year = '';
  if (dateStringAlreadyInCorrectOrder) [day, month, year] = date.split(dateSeparator);
  else [year, month, day] = date.split(dateSeparator);
  const dateInISOFormat = `${month}/${day}/${year}`;
  const dateTimeInISOFormat = `${dateInISOFormat} ${time}`.trim();

  // parse ISO string to date object and get localizedTime
  let dateObj = new Date(dateTimeInISOFormat);
  if (convertToLocalTimeZone) dateObj = new Date(dateObj.setHours(dateObj.getHours() + 2));

  return { dateObj, dateInISOFormat, dateTimeInISOFormat };
};

export const getDateDetailsFromDateTimeObj = dateTimeObj => {
  let day = '';
  let month = '';
  let dayAndMonth = '';
  let year = '';

  const formatOption = { weekday: 'long', day: 'numeric', month: 'short', year: 'numeric' };
  const dateStr = dateTimeObj.toLocaleString('en-US', formatOption);
  [, dayAndMonth, year] = dateStr.split(', ');
  [month, day] = dayAndMonth.split(' ');

  return {
    day,
    month,
    year,
  };
};

export const getTimeDetailsFromDateTimeObj = dateTimeObj => {
  let hours = '';
  let minutes = '';
  let period = '';

  hours = dateTimeObj.getHours();
  period = (hours >= 12 && 'pm') || 'am';
  hours = hours % 12 || 12;
  minutes = dateTimeObj.getMinutes();

  return {
    hours,
    minutes,
    period,
  };
};

export const getDateObjWithoutTime = dateTimeObj => new Date(`${dateTimeObj}Z`);

export const getMonthDifferenceBetweenTwoDates = (fromDateObj, toDateObj) =>
  Math.abs(
    toDateObj.getMonth() -
      fromDateObj.getMonth() +
      12 * (toDateObj.getFullYear() - fromDateObj.getFullYear()),
  );

export const addHoursToDateTime = (dateTimeObj, hoursToAdd) => {
  const dateCopy = new Date(`${dateTimeObj}Z`);
  return new Date(dateCopy.setHours(dateCopy.getHours() + hoursToAdd));
};

export const addMonthsToDateTime = (dateTimeObj, hoursToAdd) => {
  const dateCopy = new Date(dateTimeObj);
  return new Date(dateCopy.setMonth(dateCopy.getMonth() + hoursToAdd));
};

export const addDayToDateTime = (dateTimeObj, daysToAdd) => {
  const dateCopy = new Date(dateTimeObj);
  return new Date(dateCopy.setDate(dateCopy.getDate() + daysToAdd));
};

export const checkIfDateIsToday = dateTimeObj => {
  const dateCopy = new Date(dateTimeObj);
  const dateWithoutTime = dateCopy.setHours(0, 0, 0, 0);
  return dateWithoutTime === new Date().setHours(0, 0, 0, 0);
};

export const checkIfDateHasPassed = dateTimeObj => {
  const dateCopy = new Date(dateTimeObj);
  const dateWithoutTime = dateCopy.setHours(0, 0, 0, 0);
  return dateWithoutTime < new Date().setHours(0, 0, 0, 0);
};

export const checkIfTwoDatesAreEqual = (dateTimeObjOne, dateTimeObjTwo) =>
  dateTimeObjOne.getTime() === dateTimeObjTwo.getTime();

export const getElapsedTimeFromDateTimeObj = dateTimeObj => {
  const currentDateTime = new Date();
  const timeDifferenceInMilliseconds = currentDateTime - dateTimeObj;
  const timeDifferenceInMinutes = timeDifferenceInMilliseconds / 1000 / 60;
  const elapsedMinutes = Math.floor(timeDifferenceInMinutes % 60);
  const elapsedHours = Math.floor(timeDifferenceInMinutes / 60);

  return {
    elapsedMinutes,
    elapsedHours,
  };
};

export const getAgeFromBirthDateObj = birthDateObj => {
  const today = new Date();
  let age = today.getFullYear() - birthDateObj.getFullYear();
  const monthDiff = today.getMonth() - birthDateObj.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
    age -= 1;
  }
  return age;
};

export const getHoursAndMinutes = time => {
  const arrayOfTime = time.split(':');
  let hours = Number(arrayOfTime[0]);
  const minutes = arrayOfTime[1].substring(0, 2);
  if (arrayOfTime[1].substring(3, 5) === 'pm') hours += 12;
  return {
    hours,
    minutes,
  };
};

export const getDateTime = () => {
  // return current date time in format hh:mm:ss-yyyy-mm-dd
  const currentDate = new Date();
  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');
  const seconds = String(currentDate.getSeconds()).padStart(2, '0');
  const year = String(currentDate.getFullYear());
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is zero-based
  const day = String(currentDate.getDate()).padStart(2, '0');

  const formattedDate = `${hours}:${minutes}:${seconds}-${year}/${month}/${day}`;
  return formattedDate;
};

export const formatDateTime = (dateString, langCode) => {
  const day = new Date(`${dateString}Z`).toLocaleString(langCode, { day: '2-digit' });
  const month = new Date(`${dateString}Z`).toLocaleString(langCode, { month: 'long' });
  const year = new Date(`${dateString}Z`).toLocaleString(langCode, { year: 'numeric' });
  const time = new Date(`${dateString}Z`)?.toLocaleTimeString('en-EG', {
    hour: '2-digit',
    hour12: true,
    minute: '2-digit',
  });
  return `${day} ${month} ${year}, ${time}` || '';
};
