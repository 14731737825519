const commonActionTypes = {
  SET_CONTEXT: 'SET_CONTEXT',

  FETCH_ALL_PHARMACIES: 'FETCH_ALL_PHARMACIES',
  FETCH_ALL_PHARMACIES_SUCCESS: 'FETCH_ALL_PHARMACIES_SUCCESS',
  FETCH_ALL_PHARMACIES_FAIL: 'FETCH_ALL_PHARMACIES_FAIL',

  FETCH_ALL_COURIERS: 'FETCH_ALL_COURIERS',
  FETCH_ALL_COURIERS_SUCCESS: 'FETCH_ALL_COURIERS_SUCCESS',
  FETCH_ALL_COURIERS_FAIL: 'FETCH_ALL_COURIERS_FAIL',

  FETCH_ALL_USERS: 'FETCH_ALL_USERS',
  FETCH_ALL_USERS_SUCCESS: 'FETCH_ALL_USERS_SUCCESS',
  FETCH_ALL_USERS_FAIL: 'FETCH_ALL_USERS_FAIL',

  FETCH_SERVICEABLE_AREAS: 'FETCH_SERVICEABLE_AREAS',
  FETCH_SERVICEABLE_AREAS_SUCCESS: 'FETCH_SERVICEABLE_AREAS_SUCCESS',
  FETCH_SERVICEABLE_AREAS_FAIL: 'FETCH_SERVICEABLE_AREAS_FAIL',

  FETCH_INSURANCE_PROVIDERS_BY_KEY: 'FETCH_INSURANCE_PROVIDERS_BY_KEY',
  FETCH_INSURANCE_PROVIDERS_BY_KEY_SUCCESS: 'FETCH_INSURANCE_PROVIDERS_BY_KEY_SUCCESS',
  FETCH_INSURANCE_PROVIDERS_BY_KEY_FAIL: 'FETCH_INSURANCE_PROVIDERS_BY_KEY_FAIL',
};

export default commonActionTypes;
