import styled, { css } from 'styled-components';

export const PopupTitleContainer = styled.div`
  padding: 16px 0;
  box-shadow: 0px -1px 0px 0px #e3e6ea inset;
`;

export const PopupWrapper = styled.div`
  width: fit-content;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
`;

export const PopupTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
`;

export const PopupTitle = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.colors.black};
`;

export const PopupTitleSpan = styled.span`
  margin: 0;

  &:before {
    content: '\\e93d';
    font-family: 'Icomoon';
    cursor: pointer;
  }
`;

export const Container = styled.div`
  height: 520px;
`;

export const extendSearchDropDownStyle = css`
  background-color: #ffff;
  min-height: 26px;
  width: 850px;
`;

export const extendDropDownListItem = css`
  padding: 12px 16px;
`;

export const extendLoaderStyle = css`
  height: 16px;
  width: 16px;
  border: 1px solid #f3f3f3;
  border-top: 3px solid #3498db;
  margin: 0;
`;

export const CartContainer = styled.div``;

export const ItemsCountConatiner = styled.div`
  padding: 12px 16px;
  background: #fff;
  box-shadow: 0px -1px 0px 0px #e3e6ea inset;
  color: #484848;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
`;

export const ItemContainer = styled.div`
  display: grid;
  grid-template-columns: 52% 10% 22% 16%;
  box-shadow: 0px -1px 0px 0px #e3e6ea inset;
`;

export const ItemContainerHeader = styled.div`
  display: grid;
  grid-template-columns: 52% 10% 22% 16%;
  box-shadow: 0px -1px 0px 0px #e3e6ea inset;
`;

export const ItemWrapper = styled.div`
  display: flex;
  gap: 12px;
  padding: 12px 16px;
  border-right: 1px solid #e3e6ea;
  &:last-child {
    border-right: unset;
  }
`;

export const RemoveItemIcon = styled.span`
  margin: auto 0;
  &:before {
    content: '\\e93d';
    font-family: 'Icomoon';
    cursor: pointer;
    font-size: 13px;
  }
`;
export const ItemImage = styled.img`
  width: 40px;
  height: 40px;
`;

export const ItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ItemName = styled.p`
  color: #484848;
  font-size: 14px;
  margin: 0;
`;

export const ItemDescription = styled.p`
  margin: 0;
  font-size: 13px;
  line-height: 14px;
  color: #9c9c9c;
`;

export const ModalActionBtns = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #d9dbdf;
  gap: 8px;
  padding: 16px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const extendGrayButtonStyle = css`
  width: 18px;
  border-radius: 8px;
  border: 1px solid #d9dbdf;
  background: #f1f4f6;
  color: #484848;
  padding: 7px 10px;
`;

export const extendCancelButtonStyle = css`
  width: 100px;
  border-radius: 8px;
  border: 1px solid #d9dbdf;
  background: #f1f4f6;
  color: #484848;
  padding: 7px 10px;
`;

export const extendGrayBackButtonStyle = css`
  width: 140px;
  border-radius: 8px;
  border: 1px solid #d9dbdf;
  background: #f1f4f6;
  color: #484848;
  padding: 7px 10px;
  margin-right: auto;
`;

export const extendBlueButtonStyle = css`
  width: 96px;
  border-radius: 8px;
  border: 1px solid #e3e6ea;
  background: #0070cd;
  color: #fff;
  padding: 7px 10px;
`;

export const ItemsContainer = styled.div`
  height: 331px;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background: #9c9c9c;
    border-radius: 15px;
  }
`;

export const TableHeader = styled.p`
  color: #484848;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  padding: 12px 16px;
  margin: 0;
  border-right: 1px solid #e3e6ea;
  &:last-child {
    border-right: unset;
  }
`;

export const ErrorMessage = styled.p`
  font-size: 13px;
  line-height: 20px;
  margin: 0;
  color: #db3226;
  padding: 5px 18px;
  height: 15px;
`;

export const InputField = styled.input`
  width: 100%;
  margin-top: 4px;
  padding: 8px 12px;
  font-size: 13px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black};
  box-sizing: border-box;

  border-radius: 8px;
  border: ${props => (props.isDuplicateError ? '2px solid #DB3226' : '1px solid #c9cbce')};

  ::-webkit-input-placeholder {
    font-size: 13px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.grey002};
  }
  ::-moz-placeholder {
    font-size: 13px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.grey002};
  }
  :-ms-input-placeholder {
    font-size: 13px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.grey002};
  }
  :-moz-placeholder {
    font-size: 13px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.grey002};
  }

  &:focus {
    border: 2px solid ${({ theme }) => theme.colors.blueMain};
    outline: none;
  }
`;

export const extendCounterWrapper = css`
  padding: 0;
  height: 35px;
`;

export const ItemContent = styled.p`
  margin: 0;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  color: #484848;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const NoAlternativesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 30px 0px;
`;

export const NoAlternativesImg = styled.img`
  width: 220px;
  height: 220px;
  margin: auto;
  display: flex;
`;

export const NoAlternativesMsgA = styled.p`
  margin: 0;
  color: #484848;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
`;

export const NoAlternativesMsgB = styled(NoAlternativesMsgA)`
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
  height: 100%;
`;
