import PropTypes from 'prop-types';
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { Cookie } from 'constants/appCookies';
import Header from 'modules/common/components/Header';
import Sidebar from 'modules/common/components/Sidebar';
import { status } from 'constants/general';
import Splash from 'modules/common/components/splash/Splash';
import initApp from 'utils/functions/init';
import { sidebarItems } from 'modules/common/enums';
import commonStrings from 'modules/common/strings';
import { MainWrapper, SidebarWrapper, ContentWrapper, PageWrapper } from './MainContainer.style';

const MainContainer = ({ children, context }) => {
  const loginInfo = useSelector(state => state.pharmacyAccountManagement.loginInfo);
  const dispatch = useDispatch();
  useEffect(() => {
    if (loginInfo.status === status.SHOULD_CALL_API) initApp(dispatch);
  });

  setInterval(() => {
    if (!Cookies.get(Cookie.AUTH_TOKEN)) {
      document.location.href = '/login';
    }
  }, 60000);

  return (
    <div>
      {loginInfo.status === status.SHOULD_CALL_API || loginInfo.status === status.FETCHING ? (
        <Splash />
      ) : (
        <MainWrapper>
          <SidebarWrapper>
            <Sidebar pageName={context} sidebarItems={sidebarItems} strings={commonStrings} />
          </SidebarWrapper>
          <ContentWrapper>
            <Header pageName={context} loginInfo={loginInfo} strings={commonStrings} />
            <PageWrapper>{children}</PageWrapper>
          </ContentWrapper>
        </MainWrapper>
      )}
    </div>
  );
};

MainContainer.propTypes = {
  children: PropTypes.node.isRequired,
  context: PropTypes.string,
};

MainContainer.defaultProps = {
  context: '',
};

export default MainContainer;
