import PropTypes from 'prop-types';
import { SidebarItemRouterLink, SidebarItemHyperLink } from './Sidebar.style';

const SidebarLink = props => {
  const { sidebarItem, children } = props;
  return sidebarItem.path ? (
    <SidebarItemHyperLink href={sidebarItem.path}>{children}</SidebarItemHyperLink>
  ) : (
    <SidebarItemRouterLink to={`/${sidebarItem.context}`}>{children}</SidebarItemRouterLink>
  );
};

SidebarLink.propTypes = {
  sidebarItem: PropTypes.shape({
    context: PropTypes.string,
    path: PropTypes.string,
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export default SidebarLink;
