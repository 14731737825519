import { useState, useEffect, useDeferredValue } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { status } from 'constants/general';
import NoItemsImg from 'assets/images/noItems.svg';
import Button from 'modules/common/components/Button';
import Popup from 'modules/common/components/Popup';
import ItemWithImageSearchField from 'modules/common/components/ItemWithImageSearchField';
import { fetchOrderItemsSuggestions } from 'modules/orders/actions';
import { orderAttachmentTypeEnum } from 'modules/orders/OrdersView/OrdersContainer/enums';

import { getProductsListForDropDown } from './helper';
import Item from './Item';
import AttachementImageModal from './AttachementImageModal';
import {
  PopupTitleSpan,
  PopupWrapper,
  PopupTitleWrapper,
  Container,
  PopupTitleContainer,
  extendSearchDropDownStyle,
  extendDropDownListItem,
  extendLoaderStyle,
  CartContainer,
  ButtonsContainer,
  extendCancelButtonStyle,
  extendBlueButtonStyle,
  ItemsContainer,
  TableHeader,
  ItemContainerHeader,
  ModalActionBtns,
  NoAlternativesImg,
  NoAlternativesContainer,
  NoAlternativesMsgA,
  NoAlternativesMsgB,
  extendSavingLoaderStyle,
  OrderDetailsAttachmentWrapper,
  OrderDetailsAttachmentImgWrapper,
  OrderDetailsAttachmentImgRatio,
  OrderDetailsAttachmentImg,
  OrderDetailsAttachmentInfoWrapper,
  OrderDetailsTitle,
  OrderDetailsText,
} from './ItemizationItemsModal.style';

const ItemizationItemsModal = ({
  onClose,
  orderDetails,
  showModal,
  submitOrderInvoiceDispensedItems,
  updateOrderItemsCallback,
  attachementToBeItemized,
  ...props
}) => {
  const [addedItemizedItems, appendItemizedItems] = useState([]);
  const [isAttachementOpened, toggleAttachementModal] = useState(false);

  const showImg = attachementToBeItemized.attachmentType === orderAttachmentTypeEnum.image;

  const onCloseModal = () => {
    onClose();
    appendItemizedItems([]);
  };

  const pevorderItemsSubmissionStatus = useDeferredValue(props.orderItemsSubmissionLoadState);
  useEffect(() => {
    if (
      pevorderItemsSubmissionStatus &&
      props.orderItemsSubmissionLoadState === status.SUCCESS &&
      pevorderItemsSubmissionStatus !== props.orderItemsSubmissionLoadState
    ) {
      onCloseModal();
      updateOrderItemsCallback();
    }
  }, [props.orderItemsSubmissionLoadState]);

  useEffect(() => {
    const orderItems = orderDetails?.details?.mappedItemsList;
    if (showModal && orderItems?.length) {
      const tempAddedItemizedItems = [];
      orderItems.forEach(items => {
        tempAddedItemizedItems.push({
          itemName: items.serviceName,
          itemShapeName: items.productShapeName,
          itemPrice: items.price,
          itemShapeId: items.productShapeId,
          itemProductKey: items.serviceKey,
          itemImage: items.mainImageUrl,
          itemQuantity: items.quantity,
        });
      });
      appendItemizedItems(tempAddedItemizedItems);
    }
  }, [showModal]);

  const onSave = () => {
    const payload = [];
    if (addedItemizedItems.length) {
      addedItemizedItems.forEach(item => {
        payload.push({
          key: '',
          quantity: item.itemQuantity,
          productKey: item.itemProductKey,
          productShapeId: item.itemShapeId,
          note: '',
        });
      });
    }
    submitOrderInvoiceDispensedItems({ orderItems: payload });
  };

  const handleChangeItemQuantity = (itemKey, index, operationType) => {
    const tempaddedItemizedItems = _.cloneDeep(addedItemizedItems);
    if (operationType) {
      const tempItemIndex = tempaddedItemizedItems.findIndex(
        (item, itemIndex) => item.itemProductKey === itemKey && itemIndex === index,
      );
      const tempItem = tempaddedItemizedItems.find(
        (item, itemIndex) => item.itemProductKey === itemKey && itemIndex === index,
      );
      if (operationType === 'plus') tempItem.itemQuantity += 1;
      else if (operationType === 'minus' && tempItem.itemQuantity > 1) tempItem.itemQuantity -= 1;
      tempaddedItemizedItems[tempItemIndex] = { ...tempItem };
      appendItemizedItems([...tempaddedItemizedItems]);
    } else {
      const temp = [];
      tempaddedItemizedItems.forEach((item, itemIndex) => {
        if (!(item.itemProductKey === itemKey && itemIndex === index)) {
          temp.push(item);
        }
      });
      appendItemizedItems([...temp]);
    }
  };

  return (
    <Popup showCloseBtn={false}>
      <PopupWrapper>
        <PopupTitleContainer>
          <PopupTitleWrapper>
            <OrderDetailsAttachmentWrapper>
              {showImg && (
                <OrderDetailsAttachmentImgWrapper onClick={() => toggleAttachementModal(true)}>
                  <OrderDetailsAttachmentImgRatio />
                  <OrderDetailsAttachmentImg
                    src={attachementToBeItemized.url}
                    alt={attachementToBeItemized.key}
                  />
                </OrderDetailsAttachmentImgWrapper>
              )}
              <OrderDetailsAttachmentInfoWrapper>
                <OrderDetailsTitle>Requested item</OrderDetailsTitle>
                <OrderDetailsText>{attachementToBeItemized.note}</OrderDetailsText>
              </OrderDetailsAttachmentInfoWrapper>
            </OrderDetailsAttachmentWrapper>
            <PopupTitleSpan
              onClick={() => {
                onCloseModal();
              }}
            />
          </PopupTitleWrapper>
        </PopupTitleContainer>

        <Container>
          <div
            style={{
              height: '520px',
            }}
          >
            {/** Search */}
            <div
              style={{
                padding: '16px',
                background: '#F1F4F6',
              }}
            >
              <ItemWithImageSearchField
                isRequired
                isDynamic
                isDropDown
                searchable
                key="medication-search-list"
                componentName="medication-search-list"
                onTypingDelay={500}
                placeHolder="Medicine Name"
                fieldValue=""
                options={getProductsListForDropDown(
                  props.orderItemsSuggestions.details,
                  addedItemizedItems,
                  'en',
                )}
                onChanges={payload => {
                  appendItemizedItems([
                    ...addedItemizedItems,
                    {
                      itemProductKey: payload?.key,
                      itemImage: payload?.itemImage,
                      itemName: payload?.fieldValue,
                      itemShapeName: payload?.description,
                      itemPrice: payload?.extras?.newPrice,
                      itemQuantity: 1,
                      itemShapeId: payload?.extras?.id,
                    },
                  ]);
                }}
                onSearch={keyWord => {
                  props.fetchOrderItemsSuggestions({
                    searchTerm: keyWord,
                  });
                }}
                language="en"
                isLoading={props.orderItemsSuggestions.status === status.FETCHING}
                extendLoaderStyle={extendLoaderStyle}
                isValid
                extendDropDownStyle={extendSearchDropDownStyle}
                extendDropDownListItem={extendDropDownListItem}
                icon="e927"
              />
            </div>
            {/** Added Items */}
            <div
              style={{
                height: '375px',
              }}
            >
              <div>
                {addedItemizedItems?.length > 0 ? (
                  <CartContainer>
                    <ItemContainerHeader key="header-table">
                      <TableHeader>
                        <div
                          style={{
                            display: 'flex',
                            gap: '12px',
                          }}
                        >
                          {`Order Items (${addedItemizedItems?.length})`}
                        </div>
                      </TableHeader>
                      <TableHeader>Price</TableHeader>
                      <TableHeader>Quantity</TableHeader>
                      <TableHeader>Subtotal</TableHeader>
                    </ItemContainerHeader>
                    <ItemsContainer
                      style={{
                        height: '382px',
                      }}
                    >
                      {addedItemizedItems.map((item, index) => (
                        <Item
                          index={index}
                          key={item?.itemProductKey}
                          itemName={item?.itemName}
                          itemShapeName={item?.itemShapeName}
                          itemPrice={item?.itemPrice}
                          itemShapeId={item?.itemShapeId}
                          itemProductKey={item?.itemProductKey}
                          itemImage={item?.itemImage}
                          itemQuantity={item?.itemQuantity}
                          onChangeQuantity={handleChangeItemQuantity}
                        />
                      ))}
                    </ItemsContainer>
                  </CartContainer>
                ) : (
                  <NoAlternativesContainer>
                    <NoAlternativesImg src={NoItemsImg} alt="NoItemsImg" />
                    <NoAlternativesMsgA>Add items from photo</NoAlternativesMsgA>
                    <NoAlternativesMsgB>Search and add all items from photo </NoAlternativesMsgB>
                  </NoAlternativesContainer>
                )}
              </div>
            </div>
            <ModalActionBtns>
              <ButtonsContainer>
                <Button
                  onClick={() => {
                    onCloseModal();
                  }}
                  btnText="Cancel"
                  extendButtonStyle={extendCancelButtonStyle}
                  key="dismiss-popup"
                />
                <Button
                  isLoading={props.orderItemsSubmissionLoadState === status.FETCHING}
                  onClick={() => onSave()}
                  btnText="Add to Order"
                  extendButtonStyle={extendBlueButtonStyle}
                  extendLoaderStyle={extendSavingLoaderStyle}
                  key="proceed-save-view"
                  disabled={
                    !addedItemizedItems.length ||
                    props.orderItemsSubmissionLoadState === status.FETCHING
                  }
                />
              </ButtonsContainer>
            </ModalActionBtns>
          </div>
        </Container>
      </PopupWrapper>
      {isAttachementOpened && (
        <AttachementImageModal
          attachementToBeItemized={attachementToBeItemized}
          onClose={() => {
            toggleAttachementModal(false);
          }}
        />
      )}
    </Popup>
  );
};

ItemizationItemsModal.propTypes = {
  attachementToBeItemized: PropTypes.shape({
    key: PropTypes.string,
    url: PropTypes.string,
    note: PropTypes.string,
    attachmentType: PropTypes.string,
  }).isRequired,
  updateOrderItemsCallback: PropTypes.func,
  submitOrderInvoiceDispensedItems: PropTypes.func,
  fetchOrderItemsSuggestions: PropTypes.func,
  orderDetails: PropTypes.shape({
    details: PropTypes.shape({
      mappedItemsList: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
  onClose: PropTypes.func,
  orderItemsSubmissionLoadState: PropTypes.string,
  orderItemsSuggestions: PropTypes.shape({
    status: PropTypes.string,
    details: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  showModal: PropTypes.bool,
};

const mapStateToProps = state => ({
  orderItemsSubmissionLoadState: state.pharmacyOrders.orderItemsSubmissionLoadState,
  orderItemsSuggestions: state.pharmacyOrders.orderItemsSuggestions,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchOrderItemsSuggestions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ItemizationItemsModal);
