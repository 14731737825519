import styled from 'styled-components';
import themeData from 'utils/theme/themeData';

const { typography } = themeData;

export const RangeDatePickerWrapper = styled.div`
  position: relative;
  width: 100%;
  ${props => (props.extendPickerBtnStyle ? props.extendPickerBtnStyle : '')};
`;

export const RangeDatePickerSelectorWrapper = styled.div`
  position: absolute;
  z-index: 10;
  & .rdrCalendarWrapper {
    background-color: ${({ theme }) => theme.colors.grey100};
    border: 1px solid ${({ theme }) => theme.colors.grey002};
  }

  ${props => (props.extendPickerStyle ? props.extendPickerStyle : '')};
`;

export const RangeDatePickerLabel = styled(typography.BodyTextOneRegularTypography)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0 12px;
  color: ${({ theme }) => theme.colors.black};
  border: 1px solid #d7dce3;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  white-space: nowrap;
`;
