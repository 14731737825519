const ordersStrings = {
  ordersHeader: {
    allOrdersFilterItem: 'All Orders',
    createOrdersButton: 'Create Order',
    ordersFilterSearchBarPlaceholder: 'ID, Mobile number, Promocode',
    MoreFiltersButton: 'More Filters',
    MoreFiltersConfirmButtonLabel: 'Apply',
    MoreFiltersCancelButtonLabel: 'Clear',
  },

  ordersList: {
    orderGridNoResultsFound: 'No results matching your search criteria',
    orderGridHeader: {
      orderNumberGridHeader: 'Order #',
      orderStatusGridHeader: 'Status',
      orderDateGridHeader: 'Date',
      orderPatientGridHeader: 'Patient',
      orderChannelGridHeader: 'Channel',
      orderPartnersGridHeader: 'Partners',
      orderAppliedGridHeader: 'Applied',
      ordertotalGridHeader: 'Total',
      orderPaymentGridHeader: 'Payment',
      orderLabGridHeader: 'Pharmacy and Courier',
    },
    orderGridItemElapsed(hours, minutes) {
      return `${hours}h:${minutes} min`;
    },
    orderGridItemCount: 'Items',
    loyaltyPointsStickerText: 'Vezeeta Points',
    cashStickerText: 'Cash',
    creditCardStickerText: 'Online Payment',
  },

  orderDetailsHeader: {
    orderId: 'Order #',
    viewBtnText: 'Start Preparation',
    processedBtnText: 'Preparation Completed',
    resultsDoneBtnText: 'All Results Uploaded',
    scheduleBtnText: 'Schedule',
    cancelBtnText: 'Cancel Order',
    rejectBtnText: 'Reject Order',
    actionPopupTitle(status) {
      return `Are you sure you want to mark this order as ${status} ?`;
    },
    actionPopupYesBtn: 'Yes',
    actionPopupNoBtn: 'No',
    scheduleOrderTitle: 'Schedule Order',
    scheduleOrderDateLabel: 'Date',
    scheduleOrderTimeLabel: 'Time',
    scheduleOrderReasonTitle: 'Schedule order reason',
    scheduleOrderReasons: {
      patientRequest: 'Patient Request',
      fulfillmentIssue: 'Fulfillment Issue',
      patientUnreachable: 'Patient Unreachable',
      businessDevelopmentOrder: 'Business Development Order',
    },
    scheduleOrderInsuranceTitle: 'Health Insurance Company',
    scheduleOrderInsuranceSystemDown: 'Online System Down',
    scheduleOrderNoteTitle: 'Notes',
    scheduleOrderNotePlaceholder: 'Write your comments',
    scheduleOrderSaveBtn: 'Save',
    scheduleOrderCancelBtn: 'Cancel',
    scheduleOrderReasonIsRequired: 'Reason Is Required',
    cancelOrderTitle: 'Cancel Order',
    cancelOrderReasonTitle: 'Cancel Order reason',
    cancelOrderReasonIsRequired: 'Reason Is Required',
    rejectOrderTitle: 'Reject Order',
  },

  orderDetailsContent: {
    noDataPlaceholder: 'No Data',
    orderScheduledDate: 'Scheduled to:',
    orderSource: 'from',
    externalKey: 'External Key',
    deliveryDelay: 'Order Delivery Delay',
    processingDelay: 'Order Processing Delay',
    insuranceSticker: 'Insured Order',
    bdCentralPartnershipSticker: 'BD-Central',
    bdIndividualPartnershipSticker: 'BD-Individual',
    loyaltySticker: 'Payment via loyalty points',
    copiedToClipboardText: 'Copied to clipboard 🚀',
    patientDetailsTitle: 'Patient Details',
    patientDetailsName: 'Patient Name',
    patientUserDetailsTitle: 'User Details',
    patientUserDetailsName: 'User Name',
    patientUserDetailsPhoneNumber: 'Phone Number',
    patientUserDetailsAge: 'Age',
    patientUserDetailsGender: 'Gender',
    patientUserDetailsGenderMale: 'Male',
    patientUserDetailsGenderFemale: 'Female',
    addressDetailsTitle: 'Shipping Address',
    addressDetailsLabel: 'Address Label',
    addressDetailsPatientName: 'Recipient Name',
    addressDetailsPhoneNumber: 'Phone Number',
    addressDetailsName: 'Address',
    addressDetailsCompleteAddress: 'Complete Address',
    addressDetailsBuilding: 'Building #',
    addressDetailsFlat: 'Flat #',
    addressDetailsStreet: 'Street Name',
    addressDetailsArea: 'Area',
    addressDetailsLandmark: 'Landmark',
    entityDetailsTitle: 'Entity Details',
    entityDetailsLabName: 'Pharmacy Name',
    entityDetailsChemistName: 'Courier Name',
    insuranceDetailsTitle: 'Insurance Details',
    insuranceDetailsCompanyName: 'Company name',
    insuranceDetailsCardHolder: 'Card holder',
    insuranceDetailsPatientInsId: 'Patient Ins. ID',
    insuranceDetailsBirthDate: 'Birth Date',
    insuranceDetailsIdExpiryDate: 'ID Expiry Date',
    orderLogsTitle: 'Order Logs',
    orderLogsEntry(orderNumber, logStatus, assignedToText) {
      return `Order #${orderNumber} changed to ${logStatus} ${
        assignedToText && `to ${assignedToText}`
      }`;
    },
    orderLogsUser(actionUser, logDate, logTime) {
      return `${actionUser && `By ${actionUser}`} ${logDate} at ${logTime}`;
    },
    orderScheduled(logDate, logTime) {
      return `${'To'} ${logDate} at ${logTime}`;
    },
    orderLabDropdownTitle: 'Pharmacy',
    orderPharmacyDropdownPlaceholder: 'Please select a Pharmacy',
    orderCourierDropdownPlaceholder: 'Please select a Courier',
    orderChemistDropdownTitle: 'Courier',
    orderAttachmentInfoTitle: 'Requsted item',
    orderAttachmentInfoFrom: 'from',
    orderInvoiceTitle: 'Products',
    orderInvoiceFilterOriginal: 'Original',
    orderInvoiceFilterDispensed: 'Dispensed',
    orderInvoiceFilterReturned: 'Returned',
    orderInvoiceFilterComparison: 'Comparison',
    orderInvoiceFilterAlternatives: 'Alternatives',
    orderInvoiceGridHeader: {
      invoiceItemGridHeader: 'Item',
      invoicePriceGridHeader: 'Price',
      invoiceQuantityGridHeader: 'Quantity',
      invoiceSubTotalGridHeader: 'Sub Total',
    },
    orderInvoiceDetailsLabTitle: 'Pharmacy',
    orderInvoiceDetailsChemistTitle: 'Courier',
    orderInvoiceDetailsSubtotalLabel(subTotalAmount) {
      return `Subtotal: ${subTotalAmount} EGP`;
    },
    orderInvoiceDetailsDeliveryFeeLabel(deliveryFeeAmount) {
      return `Delivery Fee: ${deliveryFeeAmount} EGP`;
    },
    orderInvoiceDetailsDiscountLabel(discountAmount) {
      return `Discount: ${discountAmount} EGP`;
    },
    orderInvoiceDetailsTotalLabel(totalAmount) {
      return `Total: ${totalAmount} EGP`;
    },
    orderInvoiceDetailsInsuranceLabel: 'Insuranced Order',
    orderInvoiceDetailsPromoCodeLabel(promocodeId, discountAmount) {
      return `Promo code (${promocodeId}): ${discountAmount}`;
    },
    orderInvoiceDetailsVezeetaPointsLabel(pointsCount, discountAmount) {
      return `Vezeeta Points (${pointsCount} points): ${discountAmount}`;
    },
    orderInvoiceSearchBarPlaceholder: 'Search and add an item',
    orderInvoiceServicesNoResultText: 'No results found',
    orderInvoiceServicesCurrency: 'EGP',
    orderInvoiceBtn: 'Save Invoice',
    orderSuggestAlternativesBtn: 'Suggest Alternatives',
    orderAttachResultsTitle: 'Results',
    orderAttachResultsCopyToClipboardText: 'Click to copy link',
    orderAttachResultsBtnText: 'Upload and Send',
    orderNoteTitle: 'Note',
    orderNoteTextAreaPlaceholder: 'Write your comments',
    orderNoteBtnText: 'Send',
    orderPromoCodeIsInvalid: 'Promo code is invalid',
    visitNote: 'Visit note',
    userDetialsEditModalTitle: 'Edit User Details',
    shippingAddressEditModalTitle: 'Edit Shipping Address',
    EditModaConfirmBtnText: 'Save',
    orderItemRemovedText: 'This Item has been removed',
  },
};

export default ordersStrings;
