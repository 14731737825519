import styled from 'styled-components';
import themeData from 'utils/theme/themeData';

const { typography } = themeData;

export const CheckboxWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  width: fit-content;
  gap: 16px;
`;

export const CheckboxCheck = styled.input`
  margin-right: 10px;
  cursor: inherit;

  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  border: 1px solid #474747;
  background: #fff;
  display: grid;
  place-content: center;

  &:checked {
    background: #0070cd;
    border: 1px solid #0070cd;
  }

  &:checked::before {
    transform: scale(1);
    background: #fff;
  }

  &::before {
    content: '';
    width: 10px;
    height: 10px;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    /* Windows High Contrast Mode */
    background-color: CanvasText;
  }
`;

export const CheckboxLabel = styled(typography.BodyTextOneRegularTypography)`
  margin: 0;
  color: ${({ theme }) => theme.colors.black};
`;
