export const status = {
  SHOULD_CALL_API: 'SHOULD_CALL_API',
  FETCHING: 'FETCHING',
  SUBMITING: 'SUBMITING',
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
};

export const paymentMethodsKeys = {
  vezeetaCash: 'pm2yeai18xos21z101',
};
