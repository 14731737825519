import { takeLatest, call, put } from 'redux-saga/effects';
import { getInvoices } from './api';
import { fetchInvoicesFail, fetchInvoicesSuccess } from './actions';
import invociesActionTypes from './action-types';

function* fetchInvoicesSaga({ payload }) {
  try {
    const response = yield call(getInvoices, payload);
    yield put(fetchInvoicesSuccess(response.data));
  } catch (error) {
    yield put(fetchInvoicesFail(error));
  }
}

function* stockSagas() {
  yield takeLatest(invociesActionTypes.FETCH_INVOICES, fetchInvoicesSaga);
}

export default stockSagas;
