import styled from 'styled-components';

export const ButtonContainer = styled.div`
  background: ${props => (props.disabled ? '#f1f4f6 !important' : '#f1f4f6')};
  ${props =>
    props.disabled &&
    `
    border: 1px solid #f1f4f6 !important;
  `}
  color: ${props => (props.disabled ? '#484848 !important' : ' #484848')};
  opacity: ${props => (props.disabled ? '0.6 !important' : '1')};
  border-radius: 8px;
  width: fit-content;
  cursor: ${props => (props.disabled ? 'unset' : 'pointer')};
  padding: 10px 10px;
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 13px;
  ${props => (props.extendButtonStyle ? props.extendButtonStyle : '')};
`;

export const ButtonText = styled.p`
  text-align: center;
  margin: 0px 0px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const LoaderWrapper = styled.div`
  width: fit-content;
  margin: 0px 10px;
`;
