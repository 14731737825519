import { useRef } from 'react';
import PropTypes from 'prop-types';
import RangeDatePicker from 'modules/common/components/RangeDatePicker';
import Filter from 'modules/common/components/Filter';
import {
  OrdersFilterWrapper,
  OrdersFilterSearchBarWrapper,
  OrdersFilterSearchBar,
  OrdersFilterSearchBarIcon,
  OrdersFilterClearIcon,
} from './OrdersFilter.style';

const OrdersFilter = ({
  stringsData,
  selectedDateFilterObj,
  handleOrderSearchBarInputChange,
  handleOrderDateFilterChange,
  handleGenericFilterClick,
  ordersApiCallParams,
  filters,
  selectedData,
  setSelectedData,
  searchQuery,
  setSearchQuery,
}) => {
  const searchBarInputTimeout = useRef(null);
  const handleSearchBarInputChange = event => {
    const { value } = event.target;
    const searchQueryMinLength = 3;

    if (value.length > 30) return;
    setSearchQuery(value);

    if (searchBarInputTimeout.current) clearTimeout(searchBarInputTimeout.current);

    if (value && value.length >= searchQueryMinLength) {
      searchBarInputTimeout.current = setTimeout(() => {
        clearTimeout(searchBarInputTimeout.current);

        handleOrderSearchBarInputChange(value);
      }, 300);
    }
    // this condition checks if we are deleteing letters from search query and we reached
    // 2 characters, then we need to clear search query filter
    else if (searchQuery.length >= searchQueryMinLength && value.length < searchQueryMinLength)
      handleOrderSearchBarInputChange(value, true);
  };

  return (
    <OrdersFilterWrapper>
      {/** Search Bar */}
      <OrdersFilterSearchBarWrapper>
        <OrdersFilterSearchBarIcon />
        <OrdersFilterSearchBar
          placeholder={stringsData.ordersHeader.ordersFilterSearchBarPlaceholder}
          value={searchQuery}
          onChange={event => handleSearchBarInputChange(event)}
        />
        {searchQuery && (
          <OrdersFilterClearIcon
            onClick={() => {
              setSearchQuery('');
              handleOrderSearchBarInputChange('', true);
            }}
          />
        )}
      </OrdersFilterSearchBarWrapper>
      <div
        style={{
          display: 'flex',
          gap: '5px',
          justifyContent: 'space-between',
          padding: '0 12px',
        }}
      >
        {/** Date Filter */}
        <RangeDatePicker
          selectedDateFilterObj={selectedDateFilterObj}
          handleRangeDatePickerChange={handleOrderDateFilterChange}
        />
        {/** Status Filter */}
        {filters?.dropDownFilters?.map(filterProps => (
          <Filter
            key={filterProps.filterKey}
            handleGenericFilterClick={handleGenericFilterClick}
            filterProps={filterProps}
            ordersApiCallParams={ordersApiCallParams}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
          />
        ))}
      </div>
    </OrdersFilterWrapper>
  );
};

OrdersFilter.propTypes = {
  searchQuery: PropTypes.string,
  setSearchQuery: PropTypes.func,
  selectedData: PropTypes.shape({}),
  setSelectedData: PropTypes.func,
  stringsData: PropTypes.shape({
    ordersHeader: PropTypes.shape({
      ordersFilterSearchBarPlaceholder: PropTypes.string,
      MoreFiltersButton: PropTypes.string,
      MoreFiltersConfirmButtonLabel: PropTypes.string,
      MoreFiltersCancelButtonLabel: PropTypes.string,
    }),
  }).isRequired,
  selectedDateFilterObj: PropTypes.shape({}).isRequired,
  handleOrderSearchBarInputChange: PropTypes.func.isRequired,
  handleOrderDateFilterChange: PropTypes.func.isRequired,
  handleGenericFilterClick: PropTypes.func.isRequired,
  ordersApiCallParams: PropTypes.shape({}).isRequired,
  filters: PropTypes.shape({
    dropDownFilters: PropTypes.arrayOf(PropTypes.shape({})),
    moreFilters: PropTypes.arrayOf(PropTypes.shape({})),
    searchByItemsFilter: PropTypes.bool,
  }),
};

OrdersFilter.defaultProps = {
  filters: {},
};

export default OrdersFilter;
