import styled, { css } from 'styled-components';
import themeData from 'utils/theme/themeData';

const { typography } = themeData;

export const extendLoaderStyle = css`
  height: 16px;
  width: 16px;
  border: 1px solid #ffff;
  border-top: 1px solid #3498db;
  margin: 0;
`;

// ###############################################################
// <------------------- Order Details Header -------------------->
// ###############################################################
export const OrderDetailsHeaderWrapper = styled.div`
  display: flex;
  padding: 16px;

  background-color: #ffffff;
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0px 2px 4px rgb(44 53 68 / 8%);
  margin-bottom: 10px;
  border-top: 1px solid #e3e6ea;
`;

export const OrderDetailsHeaderOrderInfoWrapper = styled.div`
  display: flex;
  align-items: center;

  & > * + * {
    margin-inline-start: 16px !important;
  }
`;

export const OrderDetailsHeaderOrderBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-inline-start: auto;

  & > * + * {
    margin-inline-start: 4px !important;
  }
`;

export const OrderDetailsHeaderIconBtnWrapper = styled.div`
  position: relative;
`;

export const OrderDetailsHeaderIconBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border: 1px solid ${({ theme }) => theme.colors.grey000};
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;

  &:before {
    content: ${({ icon }) => icon};
    font-family: 'Icomoon';
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const OrderDetailsHeaderIconBtnMenu = styled.div`
  position: absolute;
  right: 0;
  bottom: 50px;
  padding: 4px 0;
  width: 160px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  width: 177px;
  border-radius: 8px;
`;

export const OrderDetailsHeaderIconBtnMenuItem = styled(typography.BodyTextOneRegularTypography)`
  margin: 0;
  padding: 6px 16px;
  color: #474747;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};
  }
`;

export const OrderDetailsHeaderOrderId = styled(typography.LargeTitleTypography)`
  margin: 0;
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
`;

export const OrderDetailsHeaderViewedBtn = styled(typography.BodyTextOneRegularTypography)`
  padding: 8px 16px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.grey005 : theme.colors.greenMain};
  border: 0;
  border-radius: 4px;
  cursor: ${({ disabled }) => !disabled && 'pointer'};

  &:before {
    content: '\\e908';
    font-family: 'Icomoon';
    font-size: 12px;
    margin-inline-end: 8px;
  }
`;

export const OrderDetailsHeaderProcessedBtn = styled(OrderDetailsHeaderViewedBtn)``;

export const OrderDetailsHeaderResultsDoneBtn = styled(OrderDetailsHeaderViewedBtn)``;

export const OrderDetailsHeaderScheduleBtn = styled(OrderDetailsHeaderViewedBtn)`
  background-color: ${({ theme }) => theme.colors.blueMain};

  &:before {
    content: '\\e90c';
  }
`;
// ###############################################################
// <----------------- End Order Details Header ------------------>
// ###############################################################

// ###############################################################
// <-------------------- Order Action Popup --------------------->
// ###############################################################
export const OrderDetailsHeaderActionsPopupWrapper = styled.div`
  padding: 30px 0;
  min-width: 550px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
`;

export const OrderDetailsHeaderActionsPopupTitleWrapper = styled.div`
  text-align: center;
`;

export const OrderDetailsHeaderActionsPopupTitle = styled(typography.TitleTwoTypography)`
  margin: 0;
`;

export const OrderDetailsHeaderActionsPopupBtnWrapper = styled.div`
  display: flex;
  padding: 0 20px;

  & * + * {
    margin-inline-start: 15px !important;
  }
`;

export const OrderDetailsHeaderActionsPopupYesBtn = styled(typography.BodyTextOneRegularTypography)`
  margin: 0;
  padding: 8px 0;
  width: 60px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.greenMain};
  border: 0;
  border-radius: 4px;
  cursor: pointer;
`;

export const OrderDetailsHeaderActionsPopupNoBtn = styled(OrderDetailsHeaderActionsPopupYesBtn)`
  margin: 0;
  color: ${({ theme }) => theme.colors.redMain};
  background-color: ${({ theme }) => theme.colors.redLight};
`;
// ###############################################################
// <------------------ End Order Action Popup ------------------->
// ###############################################################

// ###############################################################
// <------------------- Schedule Order Popup -------------------->
// ###############################################################
export const OrderDetailsScheduleOrderPopupWrapper = styled.div`
  width: 680px;
  padding: 16px 0;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
`;

export const OrderDetailsReturnOrderPopupWrapper = styled.div`
  width: 996px;
  padding: 16px 0;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
`;

export const OrderDetailsScheduleOrderPopupTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
`;

export const OrderDetailsScheduleOrderPopupTitle = styled(typography.TitleOneRegularTypography)`
  margin: 0;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 700;
`;

export const OrderDetailsScheduleOrderPopupTitleSpan = styled(typography.TitleOneRegularTypography)`
  margin: 0;

  &:before {
    content: '\\e93d';
    font-family: 'Icomoon';
    cursor: pointer;
  }
`;

export const OrderDetailsScheduleOrderSectionTitle = styled(typography.TitleTwoTypography)`
  margin: 0;
  color: ${({ theme }) => theme.colors.black};
`;

export const OrderDetailsCancelSectionSubTitle = styled(typography.TitleTwoTypography)`
  margin: 0;
  color: ${({ theme }) => theme.colors.black};
  padding-bottom: 8px;
`;

export const OrderDetailsScheduleOrderDateTimeSectionWrapper = styled.div`
  display: flex;
  padding: 0 24px;

  & > * + * {
    margin-inline-start: 16px !important;
  }
`;

export const OrderDetailsScheduleOrderDateTimeWrapper = styled.div`
  flex: 1;
`;

export const OrderDetailsScheduleOrderReasonsWrapper = styled.div`
  padding: 0 24px;
  overflow-y: auto;
  max-height: 60vh;
`;

export const OrderDetailsScheduleOrderInsuranceWrapper = styled.div`
  padding: 0 24px;
`;

export const OrderDetailsScheduleOrderNoteWrapper = styled.div`
  padding: 0 24px;
`;

export const OrderDetailsScheduleOrderNoteTextArea = styled.textarea`
  width: 100%;
  height: 56px;
  padding: 8px 12px;
  border: 1px solid ${({ theme }) => theme.colors.grey000};
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
  vertical-align: top;
  resize: none;

  &::-webkit-input-placeholder {
    font-family: 'Lato';
    font-weight: 400;
    color: ${({ theme }) => theme.colors.grey005};
  }
  &:-moz-placeholder {
    font-family: 'Lato';
    font-weight: 400;
    color: ${({ theme }) => theme.colors.grey005};
  }
  &::-moz-placeholder {
    font-family: 'Lato';
    font-weight: 400;
    color: ${({ theme }) => theme.colors.grey005};
  }
  &:-ms-input-placeholder {
    font-family: 'Lato';
    font-weight: 400;
    color: ${({ theme }) => theme.colors.grey005};
  }
  &::placeholder {
    font-family: 'Lato';
    font-weight: 400;
    color: ${({ theme }) => theme.colors.grey005};
  }
`;

export const OrderDetailsScheduleOrderBtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 24px;

  & > * + * {
    margin-inline-start: 8px !important;
  }
`;

export const OrderDetailsScheduleOrderSaveBtn = styled(typography.BodyTextOneRegularTypography)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96px;
  height: 36px;
  margin: 0;
  margin-inline-start: auto;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.grey000 : theme.colors.blueMain};
  border: 1px solid ${({ theme, disabled }) => !disabled && theme.colors.blueMain};
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
`;

export const OrderRejectionBtn = styled(typography.BodyTextOneRegularTypography)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96px;
  height: 36px;
  margin: 0;
  margin-inline-start: auto;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme, disabled }) => (disabled ? theme.colors.grey000 : '#0070CD')};
  border: 1px solid ${({ disabled }) => !disabled && '#0070CD'};
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
`;

export const OrderDetailsScheduleOrderCancelBtn = styled(OrderDetailsScheduleOrderSaveBtn)`
  ${({ theme }) => `
    color: ${theme.colors.black};
    background-color: ${theme.colors.grey100};
    border: 1px solid ${theme.colors.grey000};
  `}
`;

export const OrderDetailsScheduleOrderError = styled.div`
  display: block;
  font-size: 12px;
  padding: 0 24px;
  color: ${({ theme }) => theme.colors.redMain};
`;

// ###############################################################
// <----------------- End Schedule Order Popup ------------------>
// ###############################################################

// ###############################################################
// <------------------- Select Items Section -------------------->
// ###############################################################

export const OrderState = styled.div`
  display: block;
  font-weight: 700;
  ${({ first }) => (first ? 'border-radius: 5px 5px 0px 0px;' : 'border-radius: 0px')}
  color: #646464;
  font-size: 15px;
  font-weight: 700;
  padding-bottom: 5px;
  position: relative;
`;

export const Label = styled.label`
  color: #646464;
  font-size: 14px;
  font-weight: 400;
  ${({ clickable }) => clickable && 'cursor: pointer;'}
`;

export const CheckboxGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const SelectedItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 16px;
  font-size: 13px;
  line-height: 20px;
  margin-top: 5px;
  border-radius: 4px;
  border: 3px solid #f1f4f6;
  color: #646464;

  &:hover {
    background-color: ${({ disableHoverEffect }) => !disableHoverEffect && '#F1F4F6'};
  }
`;

export const DeleteIcon = styled.div`
  margin-left: auto;

  &:before {
    content: '\\e93d';
    font-family: 'Icomoon';
    color: ${({ theme }) => theme.colors.redMain};
  }
`;

export const extendButtonStyle = css`
  border-radius: 8px;
  border: 1px solid #d9dbdf;
  background: #f1f4f6;
  min-width: 128px;
`;

export const extendNextStateButtonStyle = css`
  border-radius: 8px;
  border: 1px solid #0070cd;
  background: #0070cd;
  color: #fff;
  min-width: 150px;
`;

export const TabsContainer = styled.div`
  display: flex;
  width: 248px;
  height: 36px;
  margin: 12px 16px;
`;

export const TabContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  :first-child {
    border-radius: 8px 0px 0px 8px;
  }

  :last-child {
    border-radius: 0px 8px 8px 0px;
  }

  ${props =>
    !props.selected
      ? `
    color: #484848;
    cursor: pointer;
    border: 1px solid #D9DBDF;

  `
      : `
    color: #0070cd;
    background: #E5F1FF;
    border: 1px solid #0070CD;
  `}
`;

export const TabLabel = styled.p`
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  text-align: center;
`;

export const extendTableGrid4Col = css`
  grid-template-columns: 50% 18% 18% 14%;
  padding: 0px;
`;

export const extendTableGrid5Col = css`
  grid-template-columns: 44% 14% 14% 13% 15%;
  padding: 0px;
`;

export const extendTableHeaderRow = css`
  background-color: unset;
  color: #484848;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  min-height: 44px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const extendTableRow = css`
  margin: 1px 0px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 44px;
  height: unset;
`;

export const extendMainItem = css``;

export const extendAdditionalItem = css`
  color: #7e7e7e;
  text-align: center;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  margin: 5px 0 auto;
  width: 100%;
`;

export const extendHeaderRowItems = css`
  color: #484848;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
`;

export const ProductName = styled.p`
  margin: 0;
  font-weight: 700;
  line-height: 20px;
  color: #484848;
  font-size: 13px;
`;

export const ProductShapeName = styled.p`
  margin: 0;
  font-weight: 400;
  line-height: 18px;
  font-size: 12px;
  color: #7e7e7e;
`;

export const ItemImage = styled.img`
  border-radius: 8px;
  width: 40px;
  height: 40px;
  margin: auto 0;
`;

export const extendGrayButtonStyle = css`
  width: 14px;
  border-radius: 8px;
  border: 1px solid #d9dbdf;
  background: #f1f4f6;
  color: #484848;
  padding: 7px 10px;
`;

export const extendCounterWrapper = css`
  padding: 0;
  height: 35px;
`;

export const SummaryContainer = styled.div`
  display: flex;
  padding: 16px 24px;
`;

export const SummaryWrapper = styled.div`
  margin-inline-start: auto;
  text-align: right;
`;

export const LightText = styled.p`
  margin: 0;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  color: #474747;
`;

export const BoldText = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`;

export const ReturnTableContainer = styled.div``;

export const ReturnTableWrapper = styled.div`
  height: unset;
  padding: 0;
  overflow: auto;
  max-height: 45vh;
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background: #9c9c9c;
    border-radius: 15px;
  }
`;
