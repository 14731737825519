import { useRef } from 'react';
import PropTypes from 'prop-types';
import Button from 'modules/common/components/Button';
import { status } from 'constants/general';
import { VIEW } from '../helper';

import {
  ContainerHeader,
  SearchWithMenuContainer,
  FilterSearchBarWrapper,
  FilterSearchBarIcon,
  FilterSearchBar,
  FilterClearIcon,
  extendButtonStyle,
  extendLoaderStyle,
  extendButtonStyleLoading,
  extendExportButtonStyle,
  ButtonsContainer,
} from './SearchAndFilterContainer.style';

const SearchAndFilterContainer = ({
  queryString,
  onSearchStock,
  uploadPharmacyStockLoadStatus,
  pharmacyExportStockLoadStatus,
  onUploadStockFile,
  onExportStock,
  toggleViews,
  toggleAddStockItemsModal,
}) => {
  let fileInput = useRef();
  const isUploadingCSVFile = uploadPharmacyStockLoadStatus === status.SUBMITING;
  const isExportingStockFile = pharmacyExportStockLoadStatus === status.FETCHING;

  return (
    <ContainerHeader>
      <ButtonsContainer>
        <Button
          onClick={() => {
            toggleViews(VIEW.STOCK_HISTORY);
          }}
          btnText="Upload History"
          extendButtonStyle={extendExportButtonStyle}
          key="upload-history"
          iconName="e92e"
          iconSize={13}
        />
        <Button
          onClick={() => {
            onExportStock();
          }}
          isLoading={isExportingStockFile}
          btnText="Export Stock"
          extendLoaderStyle={extendLoaderStyle}
          extendButtonStyle={extendExportButtonStyle}
          key="export-stock"
          iconName="e908"
          iconSize={13}
        />
        <div>
          <Button
            onClick={() => {
              fileInput.click();
            }}
            isLoading={isUploadingCSVFile}
            btnText="Upload Stock"
            extendLoaderStyle={extendLoaderStyle}
            extendButtonStyle={isUploadingCSVFile ? extendButtonStyleLoading : extendButtonStyle}
            iconName="e90a"
            iconSize={13}
            key="upload-stock"
          />
          <input
            type="file"
            id="stock_csv"
            accept=".csv"
            style={{ display: 'none' }}
            ref={input => {
              fileInput = input;
            }}
            onChange={e => {
              onUploadStockFile(e.currentTarget.files[0]);
              e.target.value = '';
            }}
          />
        </div>
        <Button
          onClick={() => {
            toggleAddStockItemsModal(true);
          }}
          btnText="Add Stock"
          extendButtonStyle={extendButtonStyle}
          iconName="e932"
          iconSize={13}
          key="add-stock"
        />
      </ButtonsContainer>
      <SearchWithMenuContainer>
        {/** Search Bar */}
        <FilterSearchBarWrapper>
          <FilterSearchBarIcon />
          <FilterSearchBar
            placeholder="Medicine name"
            value={queryString}
            onChange={event => onSearchStock(event.target.value)}
          />
          {queryString && (
            <FilterClearIcon
              onClick={() => {
                onSearchStock('');
              }}
            />
          )}
        </FilterSearchBarWrapper>
      </SearchWithMenuContainer>
    </ContainerHeader>
  );
};

SearchAndFilterContainer.propTypes = {
  toggleViews: PropTypes.func,
  queryString: PropTypes.string,
  onSearchStock: PropTypes.func,
  uploadPharmacyStockLoadStatus: PropTypes.string,
  onUploadStockFile: PropTypes.func,
  pharmacyExportStockLoadStatus: PropTypes.string,
  onExportStock: PropTypes.func,
  toggleAddStockItemsModal: PropTypes.func,
};

SearchAndFilterContainer.defaultProps = {
  queryString: '',
};

export default SearchAndFilterContainer;
