export const VIEWS = {
  ADD_ITEMS: 0,
  SAVE_ITEMS: 1,
};

export const getProductsListForDropDown = (productsList, searchList, language) => {
  const list = [];
  if (productsList?.productShapes?.length) {
    const { productShapes } = productsList;
    productShapes.forEach(item => {
      const {
        mainImageUrl,
        productShapeTypeName,
        productShapeTypeNameAr,
        productNameAr,
        productNameEn,
        productKey,
        id,
      } = item;

      const searchedIndex = searchList?.findIndex(itemObj => itemObj.ProductKey === productKey);

      list.push({
        isSelected: searchedIndex !== -1,
        fieldValue: language === 'en' ? productNameEn : productNameAr,
        itemImage: mainImageUrl,
        value: id,
        description: language === 'en' ? productShapeTypeName : productShapeTypeNameAr,
        key: productKey,
        extras: {
          mainImageUrl,
          productShapeTypeName,
          productShapeTypeNameAr,
          productNameAr,
          productNameEn,
          productKey,
          id,
        },
      });
    });
  }
  return list;
};
