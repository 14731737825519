/* eslint-disable react/prop-types */
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import Loader from '../LoaderV2';

import {
  FieldContainer,
  FieldLabel,
  ListContainer,
  SearchInput,
  LabelValueContainer,
  IsRequiredNote,
  DisableOverLay,
} from './DropDown.style';

import { dropDownListToggler, renderDropDownList } from './DropDown.helper';

const DropDown = ({ ...props }) => {
  const {
    placeHolder,
    fieldLabel,
    fieldValue,
    searchable,
    multipleSelection,
    options,
    extendDropDownStyle,
    icon,
    onChanges,
    onSearch,
    isRequired,
    extendDropDownList,
    extendDropDownListItem,
    onClickIconAction,
    componentName,
    isDisabled,
    language,
    isLoading,
    isValid,
    onTypingDelay,
    searchCharacterLimit,
    extendLoaderStyle,
  } = props;
  const [selectedOptions, setSelectOption] = useState([]); // for selected items
  const [typedValue, setTypedValue] = useState('');
  const [dropDownToTop, setDropDownToTop] = useState(false);

  const timer = useRef(null);

  const calculateListPosition = () => {
    const listComponent = document.getElementsByClassName(`dropdown-${componentName}`);
    // Get Parent Hight
    const parentHeight = listComponent[0].offsetParent.clientHeight;
    const parentRect = listComponent[0].getBoundingClientRect();
    // DropDown height
    const listComponentHeight = listComponent[0].clientHeight;
    // List Height
    const listItems = document.getElementById(`dropdown-list-items-${componentName}`);
    const itemListHight = listItems.clientHeight;

    if (parentRect.top + 6 + itemListHight > parentHeight) {
      // list open to top
      setDropDownToTop(-(6 + itemListHight + listComponentHeight));
    } else {
      // list open to down
      setDropDownToTop(6);
    }
  };

  const toggleDropDowns = () => {
    const dropDowns = document.querySelectorAll('div[class^="dropdown"]');
    const anotherDropDownsLabels = [];

    // Get all onther dropdown other than the current clicked one
    dropDowns.forEach(dropDown => {
      const dropDownClassName = Array.from(dropDown.classList).filter(className =>
        className.includes('dropdown-'),
      );
      if (dropDownClassName[0] !== `dropdown-${componentName}`) {
        anotherDropDownsLabels.push(dropDownClassName[0]);
      }
    });

    // Close opened lists of all onther dropdowns
    anotherDropDownsLabels.forEach(dropDown => {
      const label = dropDown.split('-')[1];
      const list = document.getElementById(`dropdown-list-${label}`);
      if (list) list.style.display = 'none';
    });
  };

  const onItemSelect = option => {
    setSelectOption([option]);
    if (onChanges) {
      onChanges(option);
    }
    dropDownListToggler(options, componentName);
  };

  useEffect(() => {
    if (fieldValue !== undefined && options && options.length) {
      const filteredOption = options.filter(option => option.value === fieldValue);
      if (filteredOption && filteredOption[0]) {
        setTypedValue(filteredOption[0].fieldValue);
        setSelectOption(filteredOption);
      }
      calculateListPosition();
    }
  }, [fieldValue, options]);

  const onSearchInList = value => {
    setTypedValue(value);
    const list = document.getElementById(`dropdown-list-${componentName}`);
    if (list && list.style.display === 'none') {
      list.style.display = 'block';
    }
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      if (onSearch && value && value.length >= searchCharacterLimit) {
        onSearch(value);
      }
    }, onTypingDelay);
  };

  const isInputFieldRendered = searchable;

  const onFieldClick = () => {
    if (searchable) {
      if (document.getElementById(`input-container-${componentName}`)) {
        document.getElementById(`input-container-${componentName}`).focus();
      }
    }
    toggleDropDowns();
    calculateListPosition();
    dropDownListToggler(options, componentName);
  };

  const listListenerHandler = event => {
    const myElementToCheckIfClicksAreInsideOf = document.querySelector(
      `#dropdown-${componentName}`,
    );
    if (
      myElementToCheckIfClicksAreInsideOf &&
      !myElementToCheckIfClicksAreInsideOf.contains(event.target)
    ) {
      const list = document.getElementById(`dropdown-list-${componentName}`);
      list.style.display = 'none';
    }
  };

  /* useEffect(() => {
    setIsEnvelopOpen(true);
  }, [orderServicesSuggestions]); */

  const addListener = () => {
    document.body.addEventListener('click', listListenerHandler, true);
  };

  const removeListener = () => {
    document.body.removeEventListener('click', listListenerHandler, true);
  };

  useEffect(() => {
    addListener();
    return () => {
      removeListener();
    };
  }, []);

  return (
    <div
      style={{ position: 'relative' }}
      className={`dropdown dropdown-${componentName}`}
      id={`dropdown-${componentName}`}
    >
      <DisableOverLay isDisabled={isDisabled} />
      <FieldContainer isValid={isValid} isDimmed={false} extendDropDownStyle={extendDropDownStyle}>
        {icon && (
          <div style={{ display: 'inline-flex', alignItems: 'center' }}>
            {isLoading ? (
              <Loader extendStyle={extendLoaderStyle} />
            ) : (
              <Icon
                onClick={() => {
                  if (onClickIconAction) {
                    onClickIconAction();
                  } else {
                    onFieldClick();
                  }
                }}
                icon={icon}
                size={14}
              />
            )}
          </div>
        )}
        <LabelValueContainer
          onClick={() => {
            if ((options && options.length) || searchable) onFieldClick();
          }}
        >
          {fieldLabel && (
            <FieldLabel isValueSelected={searchable || selectedOptions.length}>
              {fieldLabel}
              {isRequired && <IsRequiredNote>*</IsRequiredNote>}
            </FieldLabel>
          )}
          {isInputFieldRendered && (
            <SearchInput
              autoComplete="off"
              placeholder={placeHolder}
              id={`input-container-${componentName}`}
              type="text"
              value={typedValue}
              onChange={e => {
                onSearchInList(e.target.value);
              }}
              extendDropDownStyle={extendDropDownStyle}
              style={{
                width: '100%',
              }}
            />
          )}
        </LabelValueContainer>
      </FieldContainer>
      <div id={`dropdown-list-${componentName}`} style={{ display: 'none', position: 'relative' }}>
        <ListContainer
          dropDownToTop={dropDownToTop}
          extendDropDownList={extendDropDownList}
          id={`dropdown-list-items-${componentName}`}
        >
          {renderDropDownList(
            options,
            multipleSelection,
            onItemSelect,
            {
              extendDropDownListItem,
            },
            componentName,
            language,
          )}
        </ListContainer>
      </div>
    </div>
  );
};

DropDown.propTypes = {
  fieldValue: PropTypes.string,
  fieldLabel: PropTypes.string,
  icon: PropTypes.string.isRequired,
  searchable: PropTypes.bool,
  selectFirst: PropTypes.bool,
  multipleSelection: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  onChanges: PropTypes.func.isRequired,
  iconSize: PropTypes.number,
  extendDropDownStyle: PropTypes.arrayOf(PropTypes.string),
  onSearch: PropTypes.func,
  isHorizontallySorted: PropTypes.bool,
  isRequired: PropTypes.bool,
  extendDropDownListItem: PropTypes.arrayOf(PropTypes.string),
  onClickIconAction: PropTypes.func,
  componentName: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  language: PropTypes.string,
  isValid: PropTypes.bool,
  onTypingDelay: PropTypes.number,
  placeHolder: PropTypes.string,
  searchCharacterLimit: PropTypes.number,
};

DropDown.defaultProps = {
  searchCharacterLimit: 3,
};

export default DropDown;
