import styled from 'styled-components';
import themeData from 'utils/theme/themeData';

const { typography } = themeData;

export const FilterWrapper = styled.div`
  position: relative;
`;

export const FilterLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 10px 12px;
  color: ${({ theme }) => theme.colors.black};
  border: 1px solid ${({ theme }) => theme.colors.grey003};
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  font-family: 'Lato';
  font-weight: 400;
  font-size: 13px;
  white-space: nowrap;
  ${({ selected, theme }) =>
    selected &&
    `
    border-color: ${theme.colors.blueMain};
    background-color: ${theme.colors.blueLight};
    color: ${theme.colors.blueMain};
  `}

  ${({ toggled, theme }) =>
    toggled &&
    `
    border-color: ${theme.colors.grey002};
  `}
`;

export const FilterDropDownWrapper = styled.div`
  position: absolute;
  margin-top: 5px;
  box-shadow: 0px 4px 16px 0px #00000029;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  z-index: 20;
`;

export const FilterDropDown = styled.div`
  border-radius: 4px;
  list-style: none;
  overflow-y: auto;
  max-height: 265px;

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #fff;
  }
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.grey003};
    border-radius: 6px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.grey002};
  }
`;

export const FilterDropDownItem = styled(typography.BodyTextOneRegularTypography)`
  height: 32px;
  width: 200px;
  border-radius: 0px;
  padding: 6px 16px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};
  }

  ${({ selected, theme }) =>
    selected &&
    `
    background-color: ${theme.colors.grey100};

    &:after {
      content: '\\e908';
      font-family: 'Icomoon';
      color: ${theme.colors.black};
    }
  `}
`;

export const FilterDropDownCheckboxWrapper = styled(typography.BodyTextOneRegularTypography)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
  width: 200px;
  border-radius: 0px;
  padding: 6px 16px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  gap: 8px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};
  }

  ${({ selected, theme }) =>
    selected &&
    `
    background-color: ${theme.colors.grey100};
  `}
`;

export const FilterDropDownCheckbox = styled.input`
  margin-right: 10px;
  cursor: inherit;

  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  border: 1px solid #d9dbdf;
  background: #fff;
  display: grid;
  place-content: center;

  &:checked {
    background: #0070cd;
    border: 1px solid #0070cd;
  }

  &:checked::before {
    transform: scale(1);
    background: #fff;
  }

  &::before {
    content: '';
    width: 10px;
    height: 10px;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    /* Windows High Contrast Mode */
    background-color: CanvasText;
  }
`;
export const FilterDropDownCheckboxLabel = styled.label`
  cursor: inherit;
`;

export const FilterClearIcon = styled.div`
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.blueMain};

  &:before {
    content: '\\e91f';
    font-family: 'Icomoon';
  }
  &:hover {
    color: ${({ theme }) => theme.colors.redMain || ''};
    transition: all 150ms ease-in-out;
  }
`;

export const FilterDropDownActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 10px;
  border-top: 1px solid lightgrey;
`;

export const FilterDropDownClearButton = styled.button`
  text-align: center;
  cursor: pointer;
  min-width: 96px;
  height: 30px;
  background: ${({ theme }) => theme.colors.grey100};
  color: ${({ theme }) => theme.colors.black};
  border: 1px solid ${({ theme }) => theme.colors.grey000};
  box-sizing: border-box;
  border-radius: 8px;
`;

export const FilterDropDownApplyButton = styled(FilterDropDownClearButton)`
  background: ${({ theme }) => theme.colors.blueMain};
  color: ${({ theme }) => theme.colors.white};
`;

export const FilterSearchBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.grey100};
  border-radius: 4px;
  width: 100%;
`;

export const FilterSearchBarIcon = styled.i`
  font-style: normal;
  color: ${({ theme }) => theme.colors.grey002};
  padding: 5px 10px;
  &:before {
    content: '\\e905';
    font-family: 'Icomoon';
  }
`;

export const FilterSearchBar = styled.input`
  height: 36px;
  font-size: 13px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.grey002};
  background-color: ${({ theme }) => theme.colors.grey100};
  border: none;
  box-sizing: border-box;
  width: 100%;

  ::-webkit-input-placeholder {
    font-size: 13px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.grey002};
  }
  ::-moz-placeholder {
    font-size: 13px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.grey002};
  }
  :-ms-input-placeholder {
    font-size: 13px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.grey002};
  }

  &:focus {
    outline: none;
    border: none;
    &::placeholder {
      opacity: 0;
    }
  }
  &::placeholder {
    color: ${({ theme }) => theme.colors.grey002};
    transition: all 250ms ease-in-out;
  }
`;

export const FitlerSearchBarClearIcon = styled.i`
  font-style: normal;
  color: ${({ theme }) => theme.colors.grey002};
  padding: 5px 10px;
  cursor: pointer;

  &:before {
    content: '\\e93d';
    font-family: 'Icomoon';
  }

  &:hover {
    color: ${({ theme }) => theme.colors.redMain};
    transition: all 150ms ease-in-out;
  }
`;

export const ExtendedFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey003};
`;

export const ExtendedFilterLabel = styled(typography.TitleOneBoldTypography)`
  line-height: 22px;
`;

export const ExtendedFilterItemsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-basis: 33.333333%;
  max-height: 260px;
  overflow-y: auto;
`;

export const ExtendedFitlerItem = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  width: 175px;
  border-radius: 0px;
  padding: 6px 16px;
  margin: 0;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;
