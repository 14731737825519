import PropTypes from 'prop-types';

import { LoaderWrapper } from './Loader.style';

const Loader = ({ width, height, thickness, outerColor, innerColor, extendStyle, ...props }) => (
  <LoaderWrapper
    width={width}
    height={height}
    thickness={thickness}
    outerColor={outerColor}
    innerColor={innerColor}
    extendStyle={extendStyle}
    {...props}
  />
);

Loader.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  thickness: PropTypes.number,
  outerColor: PropTypes.string,
  innerColor: PropTypes.string,
  extendStyle: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

Loader.defaultProps = {
  width: 60,
  height: 60,
  thickness: 8,
  outerColor: '',
  innerColor: '',
  extendStyle: '',
};

export default Loader;
