import commonActionTypes from './action-types';

export const setContext = payload => ({
  type: commonActionTypes.SET_CONTEXT,
  payload,
});

export const fetchAllPharmacy = payload => ({
  type: commonActionTypes.FETCH_ALL_PHARMACIES,
  payload,
});

export const fetchAllPharmacySuccess = payload => ({
  type: commonActionTypes.FETCH_ALL_PHARMACIES_SUCCESS,
  payload,
});

export const fetchAllPharmacyFail = payload => ({
  type: commonActionTypes.FETCH_ALL_PHARMACIES_FAIL,
  payload,
});

export const fetchAllCouriers = payload => ({
  type: commonActionTypes.FETCH_ALL_COURIERS,
  payload,
});

export const fetchAllCouriersSuccess = payload => ({
  type: commonActionTypes.FETCH_ALL_COURIERS_SUCCESS,
  payload,
});

export const fetchAllCouriersFail = payload => ({
  type: commonActionTypes.FETCH_ALL_COURIERS_FAIL,
  payload,
});

export const fetchServiceableAreas = () => ({
  type: commonActionTypes.FETCH_SERVICEABLE_AREAS,
});

export const fetchServiceableAreasSuccess = payload => ({
  type: commonActionTypes.FETCH_SERVICEABLE_AREAS_SUCCESS,
  payload,
});

export const fetchServiceableAreasFail = payload => ({
  type: commonActionTypes.FETCH_SERVICEABLE_AREAS_FAIL,
  payload,
});

export const fetchAllUsers = payload => ({
  type: commonActionTypes.FETCH_ALL_USERS,
  payload,
});

export const fetchAllUsersSuccess = payload => ({
  type: commonActionTypes.FETCH_ALL_USERS_SUCCESS,
  payload,
});

export const fetchAllUsersFail = payload => ({
  type: commonActionTypes.FETCH_ALL_USERS_FAIL,
  payload,
});

export const fetchInsuranceProvidersByKey = payload => ({
  type: commonActionTypes.FETCH_INSURANCE_PROVIDERS_BY_KEY,
  payload,
});

export const fetchInsuranceProvidersByKeySuccess = payload => ({
  type: commonActionTypes.FETCH_INSURANCE_PROVIDERS_BY_KEY_SUCCESS,
  payload,
});

export const fetchInsuranceProvidersByKeyFail = payload => ({
  type: commonActionTypes.FETCH_INSURANCE_PROVIDERS_BY_KEY_FAIL,
  payload,
});
