export const orderAttachmentTypeEnum = {
  text: 'Text',
  image: 'Image',
};

export const orderResultsTypeEnum = {
  pdf: 'pdf',
  image: 'image',
};

export const reasonsToEnableSelectItemsSection = {
  56: 'Shortage',
  40: 'Fulfillment Issue',
};

const promoCodeStatusEnum = {
  valid: 1,
  pending: 20,
};

export const promoCodeStatusPending = [promoCodeStatusEnum.valid, promoCodeStatusEnum.pending];

export const initialNextOrderStateDefault = {
  14: 6,
  6: 7,
  42: 44,
  43: 44,
  7: 44,
  44: 9,
  9: 10,
  10: 4,
};

export const initialNextOrderStateOnlinePayment = {
  14: 6,
  6: 7,
  42: 44,
  43: 44,
  7: 44,
  21: 9,
  22: 9,
  9: 10,
  10: 4,
};

export const initialNextOrderStateLabelDefault = {
  14: 'Viewed',
  6: 'Accept Order',
  7: 'Confirm Order',
  42: 'Confirm Order',
  43: 'Confirm Order',
  44: 'Ready for pickup',
  9: 'Picked up',
  10: 'Delivered',
};

export const initialNextOrderStateLabelPayment = {
  14: 'Viewed',
  6: 'Accept Order',
  7: 'Confirm Order',
  42: 'Confirm Order',
  43: 'Confirm Order',
  44: 'Confirmed',
  21: 'Ready for pickup',
  22: 'Ready for pickup',
  9: 'Picked up',
  10: 'Delivered',
};

export const StateBadgesColor = {
  14: {
    label: 'New',
    background: '#31A636',
    color: '#FFFFFF',
  },
  6: {
    label: 'Viewed',
    background: '#0070CD',
    color: '#FFFFFF',
  },
  7: {
    label: 'Accepted',
    background: '#0070CD',
    color: '#FFFFFF',
  },
  44: {
    label: 'Confirmed',
    background: '#0070CD',
    color: '#FFFFFF',
  },
  19: {
    label: 'Payment pending',
    background: '#0070CD',
    color: '#FFFFFF',
  },
  20: {
    label: 'Payment processing',
    background: '#0070CD',
    color: '#FFFFFF',
  },
  21: {
    label: 'Payment succeeded',
    background: '#0070CD',
    color: '#FFFFFF',
  },
  22: {
    label: 'Payment failed',
    background: '#DB3226',
    color: '#FFFFFF',
  },
  9: {
    label: 'Ready for pickup',
    background: '#0070CD',
    color: '#FFFFFF',
  },
  4: {
    label: 'Delivered',
    background: '#0070CD',
    color: '#FFFFFF',
  },
  5: {
    label: 'Cancelled',
    background: '#DB3226',
    color: '#FFFFFF',
  },
  8: {
    label: 'Rejected',
    background: '#DB3226',
    color: '#FFFFFF',
  },
  10: {
    label: 'Picked up',
    background: '#0070CD',
    color: '#FFFFFF',
  },
  15: {
    label: 'Rejected',
    background: '#DB3226',
    color: '#FFFFFF',
  },
  16: {
    label: 'Rescheduled',
    background: '#0070CD',
    color: '#FFFFFF',
  },
  41: {
    label: 'Alt. Sent',
    background: '#0070CD',
    color: '#FFFFFF',
  },
  42: {
    label: 'Alt. Resolved',
    background: '#31A636',
    color: '#FFFFFF',
  },
  43: {
    label: 'Alt. No Response',
    background: '#31A636',
    color: '#FFFFFF',
  },
};
