import styled from 'styled-components';
import themeData from 'utils/theme/themeData';

const { typography } = themeData;

export const RadioButtonWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;

  & > * + * {
    margin-inline-start: 8px !important;
  }
`;

export const RadioButtonCheck = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  border: 1px solid ${({ theme }) => theme.colors.grey600};
  border-radius: 50%;
  box-sizing: border-box;

  &:after {
    content: '';
    display: ${({ checked }) => (checked && 'block') || 'none'};
    width: 11px;
    height: 11px;
    background-color: ${({ theme }) => theme.colors.blueMain};
    border-radius: 50%;
  }
`;

export const RadioButtonLabel = styled(typography.BodyTextOneRegularTypography)`
  margin: 0;
  color: ${({ theme }) => theme.colors.black};
`;
