import styled from 'styled-components';

export const PopupWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 100;
`;

export const PopupContentWrapper = styled.div``;

export const PopupCloseIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  cursor: pointer;

  font-size: 14px;
  color: ${({ theme }) => theme.colors.white};

  &:before {
    content: '\\e93d';
    font-family: 'Icomoon';
  }
`;
