import styled, { css } from 'styled-components';

export const ContainerHeader = styled.div`
  padding: 16px 0px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-bottom: 1px solid #eceff2;
  ${props => props.theme.media.phone`
    flex-direction: column;
  `};
`;

export const searchWithMenuExtended = css`
  width: 100%;
  & > div {
    height: 40px !important;
    border: 1px solid #e3e6ea;
    box-sizing: border-box;
    border-radius: 8px;
  }
  & > ul {
    width: 100% !important;
  }
`;

export const SearchWithMenuContainer = styled.div`
  width: 100%;
`;

export const DropDownBtnsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px 5px;

  ${props => props.theme.media.phone`
    margin: 0px 0px;
    margin-top: 8px;
  `};
`;

export const FilterBtnsContainer = styled.div`
  width: 100%;
  ${props => props.theme.media.tablet`
    width: 100%;
    margin: 6px 0px;
  `};
  ${props => props.theme.media.phone`
    width: 100%;
    margin: 6px 0px;
  `};
`;

export const BropDownBtnsContainer = styled.div`
  display: flex;
  flex-direction: column;
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.16));
  border-radius: 8px;
  background-color: #fff;
  width: 360px;
  height: 300px;
  padding: 18px;
  ${props => props.theme.media.phone`
    width: 100%;
    height: 100vh;
  `};
  ${props => props.theme.media.tablet`
    width: 100%;
    height: 100vh;
  `};
`;

export const extendFieldText = css`
  font-size: 15px !important;
  line-height: 16px;
`;

export const FilterActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
`;

export const ContainerTitle = styled.div`
  color: #484848;
  font-weight: 600;
  font-size: 15px;
  line-height: 26px;
  margin-bottom: 8px;
`;

export const ButtonConatiner = styled.div`
  background: #0070cd;
  border-radius: 8px;
  font-size: 13px;
  line-height: 20px;
  color: #fff;
  padding: 10px 24px;
  cursor: pointer;
`;

export const ResetLink = styled.div`
  text-decoration-line: underline;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  color: #9c9c9c;
`;

export const extendDropDownStyle = css`
  background-color: #fff;
  border: 1px solid #e3e6ea;
  box-sizing: border-box;
  height: 50px;
`;

export const extendButtonContainer = css`
  display: flex;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 16px;
`;

export const FilterSearchBarWrapper = styled.div`
  position: relative;
`;

export const FilterSearchBarIcon = styled.i`
  position: absolute;
  top: 8px;
  left: 13px;

  color: ${({ theme }) => theme.colors.grey002};
  font-style: normal;

  &:before {
    content: '\\e927';
    font-family: 'Icomoon';
    font-size: 13px;
  }
`;

export const FilterSearchBar = styled.input`
  width: 100%;
  height: 36px;
  padding: 8px 36px;
  font-size: 13px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.grey002};
  background-color: ${({ theme }) => theme.colors.grey100};
  border: none;
  box-sizing: border-box;

  border-radius: 8px;
  border: 1px solid var(--grey-300, #d9dbdf);
  background: var(--white, #fff);

  ::-webkit-input-placeholder {
    font-size: 13px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.grey002};
  }
  ::-moz-placeholder {
    font-size: 13px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.grey002};
  }
  :-ms-input-placeholder {
    font-size: 13px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.grey002};
  }
  :-moz-placeholder {
    font-size: 13px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.grey002};
  }

  &:focus {
    border: 1px solid #197ed2;
    outline: none;
  }
`;

export const FilterClearIcon = styled.i`
  position: absolute;
  top: 9px;
  right: 9px;
  color: ${({ theme }) => theme.colors.grey002};
  font-style: normal;
  cursor: pointer;

  &:before {
    content: '\\e93d';
    font-family: 'Icomoon';
  }

  &:hover {
    color: ${({ theme }) => theme.colors.redMain};
    transition: all 150ms ease-in-out;
  }
`;

export const extendButtonStyle = css`
  width: 145px;
  border-radius: 8px;
  border: 1px solid #e3e6ea;
  background: #0070cd;
  color: #fff;
  padding: 7px 10px;
`;

export const extendButtonStyleLoading = css`
  width: 145px;
  border-radius: 8px;
  border: 1px solid #e3e6ea;
  background: #ffff;
  color: #fff;
  padding: 7px 10px;
`;

export const extendLoaderStyle = css`
  height: 17px;
  width: 17px;
  border: 1px solid #f3f3f3;
  margin: 0;
  border-top: 2px solid #3498db;
`;

export const extendExportButtonStyle = css`
  width: 145px;
  border-radius: 8px;
  border: 1px solid #e3e6ea;
  background: #ffff;
  color: #484848;
  padding: 7px 10px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: end;
`;
