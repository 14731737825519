import { useState } from 'react';
import PropTypes from 'prop-types';
import Vlogo from 'assets/images/vezeeta.svg';
import { status } from 'constants/general';
import Loader from 'modules/common/components/Loader';
import SectionDivider from 'modules/common/components/SectionDivider';

import {
  LoginWrapper,
  LoginLogo,
  LoginInput,
  LoginValidationErrMsg,
  LoginBtn,
  LoginContainer,
  LoginFieldsContainer,
  FieldLabel,
  LoaderWrapper,
} from './LoginContainer.style';

const LoginComponent = ({ stringsData, userAuthenticationInfo, submitUserLoginData }) => {
  const [usernameFieldValue, setUsernameFieldValue] = useState('');
  const [passwordFieldValue, setPasswordFieldValue] = useState('');
  const [showUsernameErrMsg, setShowUsernameErrMsg] = useState(false);
  const [showPasswordErrMsg, setShowPasswordErrMsg] = useState(false);

  const handleInputFieldChange = (event, setStateFn) => {
    const { value } = event.target;
    setStateFn(value);
  };

  const handleSubmitBtnClick = () => {
    let isValidSubmission = true;
    if (usernameFieldValue === '') {
      setShowUsernameErrMsg(true);
      isValidSubmission = false;
    } else setShowUsernameErrMsg(false);

    if (passwordFieldValue === '') {
      setShowPasswordErrMsg(true);
      isValidSubmission = false;
    } else setShowPasswordErrMsg(false);

    if (isValidSubmission === false) return;

    submitUserLoginData(usernameFieldValue, passwordFieldValue);
  };

  return (
    <LoginContainer>
      <LoginWrapper>
        <LoginLogo src={Vlogo} />
        <LoginFieldsContainer>
          <FieldLabel>Email</FieldLabel>
          <LoginInput
            id="username-login-input"
            value={usernameFieldValue}
            placeholder={stringsData.login.emailFieldPlaceholder}
            onChange={event => handleInputFieldChange(event, setUsernameFieldValue)}
          />
          {showUsernameErrMsg && (
            <LoginValidationErrMsg>
              {stringsData.login.emailFieldValidationError}
            </LoginValidationErrMsg>
          )}
          <SectionDivider gap={16} />
          <FieldLabel>Password</FieldLabel>
          <LoginInput
            id="password-login-input"
            value={passwordFieldValue}
            placeholder={stringsData.login.passwordFieldPlaceholder}
            type="password"
            onChange={event => handleInputFieldChange(event, setPasswordFieldValue)}
          />
          {showPasswordErrMsg && (
            <LoginValidationErrMsg>
              {stringsData.login.passwordFieldValidationError}
            </LoginValidationErrMsg>
          )}
          <SectionDivider gap={16} />
          {(userAuthenticationInfo.status === status.FETCHING && (
            <LoaderWrapper>
              <Loader width={30} height={30} thickness={5} />
            </LoaderWrapper>
          )) || (
            <LoginBtn as="button" onClick={() => handleSubmitBtnClick()}>
              {stringsData.login.loginBtn}
            </LoginBtn>
          )}
          <div
            style={{
              marginTop: '5px',
            }}
          >
            {userAuthenticationInfo.status === status.FAIL && (
              <LoginValidationErrMsg>wrong email or password</LoginValidationErrMsg>
            )}
          </div>
        </LoginFieldsContainer>
      </LoginWrapper>
    </LoginContainer>
  );
};

LoginComponent.propTypes = {
  stringsData: PropTypes.shape({
    login: PropTypes.shape({
      emailFieldPlaceholder: PropTypes.string,
      emailFieldValidationError: PropTypes.string,
      passwordFieldPlaceholder: PropTypes.string,
      passwordFieldValidationError: PropTypes.string,
      loginBtn: PropTypes.string,
    }),
  }).isRequired,
  userAuthenticationInfo: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
  submitUserLoginData: PropTypes.func.isRequired,
};

LoginComponent.defaultProps = {};

export default LoginComponent;
