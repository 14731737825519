import { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ThemeContext } from 'styled-components';
import { status } from 'constants/general';
import Loader from 'modules/common/components/Loader';
import {
  invoiceGridItemsToSizeMap,
  orderInvoiceGridHeaderKeys,
  orderInvoiceGridHeaderList,
  TABLE_VIEWS,
} from 'modules/orders/OrdersView/OrdersContainer/OrderDetailsContainer/OrderDetailsComponent/helper';

import { InvoiceTableContainer } from './OrderInvoiceComponents/InvoiceTableContainer/InvoiceTableContainer';
import SearchInStock from './OrderInvoiceComponents/SearchInStock';
import { InvoiceSummary } from './OrderInvoiceComponents/InvoiceSummary';

import {
  OrderDetailsInvoiceSection,
  OrderDetailsInvoiceTitle,
  OrderDetailsInvoiceSearchBarWrapper,
  OrderDetailsInvoiceSearchBarInputWrapper,
  OrderDetailsInvoiceSearchBar,
  OrderDetailsInvoiceSearchBarIcon,
  OrderDetailsInvoiceSearchBarEnvelop,
  extendOrderDetailsInvoiceLoaderStyle,
  TabContainer,
  TabLabel,
  TabsContainer,
} from './OrderInvoice.style';

const OrderInvoice = ({
  stringsData,
  orderDetails,
  orderServicesSuggestions,
  getItemsSuggestionsData,
  resetServicesSuggestionsData,
  orderStatusToEnableEditInvoice,
  orderStatusToEnableAddingPromoCode,
  objectMapper,
  dispensedOrderItems,
  setDispensedOrderItems,
  handleInvoiceItemCheckToBeConfirmed,
  checkedItemToBeConfirmed,
  isEligableForAlternativeRecommendation,
  setSuggestAllternativeModal,
  productsAlternatives,
  orderTableMode,
  setOrderTableMode,
  appendProductAlternatives,
  onRemoveProductAlternatives,
}) => {
  const themeContext = useContext(ThemeContext);
  const orderData = orderDetails.details;
  const { alternativesRecommendations } = orderData;

  const { orderStateTypeId: orderStatus } = orderData;
  const { orderReceipt } = orderData;

  const invoiceHeaderMenuItemsObjIntialValue = {
    original: stringsData.orderDetailsContent.orderInvoiceFilterOriginal,
    dispensed: stringsData.orderDetailsContent.orderInvoiceFilterDispensed,
  };

  const selectedFilter = invoiceHeaderMenuItemsObjIntialValue.original;
  const [searchInputQuery, setSearchInputQuery] = useState('');
  const [isEnvelopOpen, setIsEnvelopOpen] = useState(false);
  const [promoCodeReference, setPromoCode] = useState('');
  const [isPromoCodeInputAvailable, setIsPromoCodeInputAvailable] = useState(false);

  const onSelectOrderPreparation = () => {
    setOrderTableMode(TABLE_VIEWS.ORDER_PREPARATION);
    appendProductAlternatives([]);
  };

  const onSelectSuggestAlternatives = () => {
    setOrderTableMode(TABLE_VIEWS.SUGGEST_ALTERNATIVES);
    appendProductAlternatives([]);
  };

  const listListenerHandler = event => {
    const myElementToCheckIfClicksAreInsideOf = document.querySelector('#search-items');
    if (
      myElementToCheckIfClicksAreInsideOf &&
      !myElementToCheckIfClicksAreInsideOf.contains(event.target)
    ) {
      setIsEnvelopOpen(false);
    }
  };

  useEffect(() => {
    if (orderServicesSuggestions && orderServicesSuggestions?.length) {
      setIsEnvelopOpen(true);
    }
  }, [orderServicesSuggestions]);

  const addListener = () => {
    document.body.addEventListener('click', listListenerHandler, true);
  };

  const removeListener = () => {
    document.body.removeEventListener('click', listListenerHandler, true);
  };

  useEffect(() => {
    addListener();
    return () => {
      removeListener();
    };
  }, []);

  useEffect(() => {
    const mappedServicesListUpdated = orderDetails?.details?.mappedItemsList;
    setDispensedOrderItems(mappedServicesListUpdated);
    setPromoCode(orderDetails?.details?.promoCode);
    if (orderDetails?.details?.promoCode) setIsPromoCodeInputAvailable(false);
  }, [orderDetails]);

  useEffect(() => {
    if (promoCodeReference === '') setIsPromoCodeInputAvailable(true);
  }, [promoCodeReference]);

  const servicesSearchBarTimeout = useRef(null);

  const getServicesSuggestions = query => {
    setSearchInputQuery(query);

    if (servicesSearchBarTimeout.current) {
      clearTimeout(servicesSearchBarTimeout.current);
    }

    if (query && query.length >= 2) {
      servicesSearchBarTimeout.current = setTimeout(() => {
        clearTimeout(servicesSearchBarTimeout.current);

        getItemsSuggestionsData(query);
      }, 300);
    }
  };

  const handleServicesSuggestionsInputChange = event => {
    const { value } = event.target;

    if (value.length > 30) return;
    setSearchInputQuery(value);

    if (value && value.length >= 2) {
      setIsEnvelopOpen(true);
      getServicesSuggestions(value);
    } else {
      setIsEnvelopOpen(false);
      resetServicesSuggestionsData();
    }
  };

  const handleServicesSuggestionsItemClick = item => {
    if (dispensedOrderItems.some(service => service.serviceKey === item.productKey)) return;
    setIsEnvelopOpen(false);
    setSearchInputQuery('');
    resetServicesSuggestionsData();
    setDispensedOrderItems(prev => [
      ...prev,
      {
        mainImageUrl: item.mainImageUrl,
        serviceKey: item.productKey,
        serviceName: item.productNameEn,
        servicePrice: item.newPrice,
        price: item.newPrice,
        quantity: 1,
        productShapeId: item.id,
        productShapeName: item.productShapeTypeName,
      },
    ]);
  };

  const handleServiceItemDeleteBtnClick = index => {
    const tempDispensedOrderItems = _.cloneDeep(dispensedOrderItems);
    const temp = tempDispensedOrderItems.filter((_item, itemIndex) => itemIndex !== index);
    setDispensedOrderItems(temp);
  };

  const handleChangeItemQuantity = (itemKey, index, operationType) => {
    const tempDispensedOrderItems = _.cloneDeep(dispensedOrderItems);

    const tempItemIndex = tempDispensedOrderItems.findIndex(
      (service, itemIndex) => service.serviceKey === itemKey && itemIndex === index,
    );
    const tempItem = tempDispensedOrderItems.find(
      (service, itemIndex) => service.serviceKey === itemKey && itemIndex === index,
    );

    if (operationType === 'plus') tempItem.quantity += 1;
    else if (operationType === 'minus' && tempItem.quantity > 1) tempItem.quantity -= 1;

    tempDispensedOrderItems[tempItemIndex] = { ...tempItem };
    setDispensedOrderItems([...tempDispensedOrderItems]);
  };

  if (dispensedOrderItems === status.FETCHING)
    return (
      <OrderDetailsInvoiceSection padding>
        <Loader
          width={50}
          height={50}
          thickness={6}
          extendStyle={extendOrderDetailsInvoiceLoaderStyle}
        />
      </OrderDetailsInvoiceSection>
    );

  if (dispensedOrderItems === status.FAIL)
    return (
      <OrderDetailsInvoiceSection padding>
        <OrderDetailsInvoiceTitle>
          {stringsData.orderDetailsContent.noDataPlaceholder}
        </OrderDetailsInvoiceTitle>
      </OrderDetailsInvoiceSection>
    );

  const enableEditInvoice = orderStatusToEnableEditInvoice.includes(orderStatus);
  const enableSearchItemsInOrderPreparation =
    TABLE_VIEWS.ORDER_PREPARATION === orderTableMode && enableEditInvoice;

  const enableSuggestAlternativesTab =
    alternativesRecommendations?.length || isEligableForAlternativeRecommendation;

  return (
    <OrderDetailsInvoiceSection>
      {enableSuggestAlternativesTab && (
        <TabsContainer>
          <TabContainer
            onClick={() => onSelectOrderPreparation()}
            selected={orderTableMode === TABLE_VIEWS.ORDER_PREPARATION}
          >
            <TabLabel>Order Preparation</TabLabel>
          </TabContainer>
          <TabContainer
            onClick={() => onSelectSuggestAlternatives()}
            selected={orderTableMode === TABLE_VIEWS.SUGGEST_ALTERNATIVES}
          >
            <TabLabel>Alternatives</TabLabel>
          </TabContainer>
        </TabsContainer>
      )}
      {/** Items */}
      {(dispensedOrderItems?.length > 0 && (
        <InvoiceTableContainer
          orderInvoiceGridHeaderList={orderInvoiceGridHeaderList}
          orderInvoiceGridHeaderKeys={orderInvoiceGridHeaderKeys}
          orderDetails={orderDetails}
          dispensedOrderItems={dispensedOrderItems}
          invoiceHeaderMenuItemsObj={invoiceHeaderMenuItemsObjIntialValue}
          invoiceGridItemsToSizeMap={invoiceGridItemsToSizeMap}
          handleServiceItemDeleteBtnClick={handleServiceItemDeleteBtnClick}
          handleChangeItemQuantity={handleChangeItemQuantity}
          orderStatusToEnableEditInvoice={orderStatusToEnableEditInvoice}
          objectMapper={objectMapper}
          enableEditInvoice={enableEditInvoice}
          handleInvoiceItemCheckToBeConfirmed={handleInvoiceItemCheckToBeConfirmed}
          checkedItemToBeConfirmed={checkedItemToBeConfirmed}
          orderTableMode={orderTableMode}
          setSuggestAllternativeModal={setSuggestAllternativeModal}
          productsAlternatives={productsAlternatives}
          alternativesRecommendations={alternativesRecommendations}
          onRemoveProductAlternatives={onRemoveProductAlternatives}
        />
      )) ||
        ''}

      {/** Seach Items */}
      {enableSearchItemsInOrderPreparation && (
        <OrderDetailsInvoiceSearchBarWrapper id="search-items">
          <OrderDetailsInvoiceSearchBarInputWrapper>
            <OrderDetailsInvoiceSearchBarIcon />
            <OrderDetailsInvoiceSearchBar
              placeholder="Search and add an item"
              value={searchInputQuery}
              onChange={event => handleServicesSuggestionsInputChange(event)}
            />
          </OrderDetailsInvoiceSearchBarInputWrapper>
          <OrderDetailsInvoiceSearchBarEnvelop id="dropdown-list-search-items" open={isEnvelopOpen}>
            <SearchInStock
              stringsData={stringsData}
              orderServicesSuggestions={orderServicesSuggestions}
              handleServicesSuggestionsItemClick={handleServicesSuggestionsItemClick}
              objectMapper={objectMapper}
            />
          </OrderDetailsInvoiceSearchBarEnvelop>
        </OrderDetailsInvoiceSearchBarWrapper>
      )}

      {/** Invoice Summary Info */}
      {TABLE_VIEWS.ORDER_PREPARATION === orderTableMode && (
        <InvoiceSummary
          stringsData={stringsData}
          themeContext={themeContext}
          orderDetails={orderDetails}
          dispensedOrderItems={dispensedOrderItems}
          showPromoCodeInvoiceText={
            orderDetails.details.promoCode === promoCodeReference &&
            !!orderDetails.details.promoCode
          }
          showPromocodeInvoiceTextOperationSide={
            isPromoCodeInputAvailable &&
            orderStatusToEnableAddingPromoCode?.includes(orderStatus) &&
            selectedFilter === invoiceHeaderMenuItemsObjIntialValue.dispensed
          }
          promoCodeReference={promoCodeReference}
          setPromoCode={setPromoCode}
          selectedFilter={selectedFilter}
          invoiceHeaderMenuItemsObj={invoiceHeaderMenuItemsObjIntialValue}
          orderStatus={orderStatus}
          enableEditInvoice={enableEditInvoice}
          showVezeetaPointsInvoiceText={!!orderReceipt?.vezeetaCash}
        />
      )}
    </OrderDetailsInvoiceSection>
  );
};

OrderInvoice.propTypes = {
  appendProductAlternatives: PropTypes.func,
  setSuggestAllternativeModal: PropTypes.func,
  isEligableForAlternativeRecommendation: PropTypes.bool,
  handleInvoiceItemCheckToBeConfirmed: PropTypes.func,
  checkedItemToBeConfirmed: PropTypes.arrayOf(PropTypes.string),
  orderServicesSuggestions: PropTypes.arrayOf(PropTypes.shape({})),
  dispensedOrderItems: PropTypes.arrayOf(PropTypes.shape({})),
  setDispensedOrderItems: PropTypes.func,
  stringsData: PropTypes.shape({
    orderDetailsContent: PropTypes.shape({
      noDataPlaceholder: PropTypes.string,
      orderInvoiceFilterOriginal: PropTypes.string,
      orderInvoiceFilterDispensed: PropTypes.string,
      orderInvoiceFilterComparison: PropTypes.string,
      orderInvoiceGridHeader: PropTypes.shape({}),
      orderInvoiceSearchBarPlaceholder: PropTypes.string,
      orderInvoiceBtn: PropTypes.string,
      orderInvoiceFilterAlternatives: PropTypes.string,
    }),
  }).isRequired,
  orderDetails: PropTypes.shape({
    details: PropTypes.shape({
      alternativesRecommendations: PropTypes.arrayOf(PropTypes.shape({})),
      orderStateTypeId: PropTypes.number,
      mappedItemsList: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.shape({})),
      ]),

      promoCode: PropTypes.string,
      hasPromoCodeAddedByPatient: PropTypes.bool.isRequired,
      orderReceipt: PropTypes.shape({
        vezeetaCash: PropTypes.number,
      }),
    }),
  }).isRequired,
  getItemsSuggestionsData: PropTypes.func.isRequired,
  resetServicesSuggestionsData: PropTypes.func.isRequired,
  orderStatusToEnableEditInvoice: PropTypes.arrayOf(PropTypes.number).isRequired,
  objectMapper: PropTypes.func.isRequired,
  orderStatusToEnableAddingPromoCode: PropTypes.arrayOf(PropTypes.number),
  productsAlternatives: PropTypes.arrayOf(PropTypes.shape({})),
  orderTableMode: PropTypes.number,
  setOrderTableMode: PropTypes.func,
  onRemoveProductAlternatives: PropTypes.func,
};

OrderInvoice.defaultProps = {
  orderStatusToEnableAddingPromoCode: [],
};

export default OrderInvoice;
