import MainContainer from 'modules/common/MainContainer';
import InvoicesView from 'modules/invoices/views';

const PharmacyInvoicePage = () => (
  <MainContainer context="Invoices">
    <InvoicesView />
  </MainContainer>
);

PharmacyInvoicePage.propTypes = {};

PharmacyInvoicePage.defaultProps = {};

export default PharmacyInvoicePage;
