import { useSelector, useDispatch } from 'react-redux';
import accountManagementStrings from 'modules/accountManagement/strings';
import { fetchUserAuthenticationInfo } from 'modules/accountManagement/actions';
import LoginContainer from './LoginContainer';

const LoginView = () => {
  const userAuthenticationInfo = useSelector(
    state => state.pharmacyAccountManagement.userAuthenticationInfo,
  );

  const dispatch = useDispatch();

  const submitUserLoginData = (username, password) => {
    const body = {
      data: {
        username,
        password,
      },
    };
    dispatch(fetchUserAuthenticationInfo(body));
  };

  return (
    <LoginContainer
      stringsData={accountManagementStrings}
      userAuthenticationInfo={userAuthenticationInfo}
      submitUserLoginData={submitUserLoginData}
    />
  );
};

LoginView.propTypes = {};

LoginView.defaultProps = {};

export default LoginView;
