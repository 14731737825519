import PropTypes from 'prop-types';
import VLogo from 'assets/images/logo-blue.svg';
import SectionDivider from '../SectionDivider';
import {
  SidebarWrapper,
  SidebarLogoWrapper,
  SidebarLogo,
  SidebarItemContainer,
  SidebarItemWrapper,
  SidebarItem,
} from './Sidebar.style';
import SidebarLink from './SidebarItemLink';

const Sidebar = props => {
  const { sidebarItems, strings } = props;

  return (
    <SidebarWrapper>
      <SidebarLogoWrapper>
        <SidebarLogo src={VLogo} alt={strings.sidebar.logoImageAltText} />
      </SidebarLogoWrapper>
      <div
        style={{
          marginTop: '48px',
        }}
      >
        {sidebarItems?.map(sidebarItem => {
          const isItemSelected = String(window.location.pathname).includes(sidebarItem.context);
          return (
            <SidebarItemContainer key={sidebarItem.name} pushToEnd={sidebarItem.pushToEnd}>
              <SidebarLink sidebarItem={sidebarItem}>
                <SidebarItemWrapper icon={sidebarItem.icon} selected={isItemSelected}>
                  {sidebarItem.name && (
                    <div>
                      <SectionDivider gap={6} />
                      <SidebarItem selected={isItemSelected}>{sidebarItem.name}</SidebarItem>
                    </div>
                  )}
                </SidebarItemWrapper>
              </SidebarLink>
            </SidebarItemContainer>
          );
        })}
      </div>
    </SidebarWrapper>
  );
};

Sidebar.propTypes = {
  sidebarItems: PropTypes.arrayOf(
    PropTypes.shape({
      context: PropTypes.string,
      icon: PropTypes.string,
      name: PropTypes.string,
      pushToEnd: PropTypes.bool,
    }),
  ),
  strings: PropTypes.shape({
    sidebar: PropTypes.shape({
      logoImageAltText: PropTypes.string,
    }),
  }).isRequired,
};

Sidebar.defaultProps = {
  sidebarItems: [],
};

export default Sidebar;
