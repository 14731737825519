import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'modules/common/components/Checkbox';
import Counter from 'modules/common/components/Counter';
import {
  invoiceGridItemsToSizeMap,
  orderInvoiceGridHeaderKeys,
  orderInvoiceGridHeaderList,
} from 'modules/orders/OrdersView/OrdersContainer/OrderDetailsContainer/OrderDetailsComponent/helper';
import {
  OrderDetailsInvoiceGridWrapper,
  OrderDetailsInvoiceGridHeader,
  OrderDetailsInvoiceGridItem,
  ItemImage,
  OrderDetailsInvoiceGridHeaderTitle,
  OrderDetailsInvoiceGridItemText,
  extendGrayButtonStyle,
} from '../../OrderInvoice.style';

const InvoiceTable = ({
  servicesList,
  bigGridItemPadding,
  handleServiceItemDeleteBtnClick,
  handleChangeItemQuantity,
  disableFlexGrow,
  enableEditInvoice,
  handleInvoiceItemCheckToBeConfirmed,
  checkedItemToBeConfirmed,
  disableTableBorders,
}) => {
  const invoiceGridSize = Object.keys(invoiceGridItemsToSizeMap)
    .map(itemSizeKey => invoiceGridItemsToSizeMap[itemSizeKey])
    .join(' ');

  return (
    <OrderDetailsInvoiceGridWrapper
      bigGridItemPadding={bigGridItemPadding}
      invoiceGridSize={invoiceGridSize}
      disableFlexGrow={disableFlexGrow}
      disableTableBorders={disableTableBorders}
    >
      {orderInvoiceGridHeaderKeys.map((orderInvoiceGridHeaderKey, index) => (
        <OrderDetailsInvoiceGridHeader
          key={orderInvoiceGridHeaderKey}
          pushToEnd={orderInvoiceGridHeaderKey === 'invoiceTotalGridHeader'}
        >
          {enableEditInvoice && index === 0 ? (
            <Checkbox
              checked={checkedItemToBeConfirmed?.length === servicesList.length}
              key={orderInvoiceGridHeaderKey}
              handleCheckboxClick={() => {
                handleInvoiceItemCheckToBeConfirmed();
              }}
              checkboxObj={{
                key: orderInvoiceGridHeaderKey,
                name: (
                  <div
                    style={{
                      display: 'flex',
                      gap: '12px',
                    }}
                  >
                    <OrderDetailsInvoiceGridHeaderTitle>
                      {orderInvoiceGridHeaderList[orderInvoiceGridHeaderKey]}
                    </OrderDetailsInvoiceGridHeaderTitle>
                  </div>
                ),
              }}
            />
          ) : (
            <OrderDetailsInvoiceGridHeaderTitle>
              {orderInvoiceGridHeaderList[orderInvoiceGridHeaderKey]}
            </OrderDetailsInvoiceGridHeaderTitle>
          )}
        </OrderDetailsInvoiceGridHeader>
      ))}

      {servicesList.map((item, index) => (
        <React.Fragment key={`${item.serviceKey}-${item.productShapeId}`}>
          {enableEditInvoice ? (
            <OrderDetailsInvoiceGridItem displayFlex startPadding endPadding>
              <Checkbox
                checked={checkedItemToBeConfirmed?.includes(
                  `${item.serviceKey}-${item.productShapeId}`,
                )}
                key={item.serviceKey}
                handleCheckboxClick={() =>
                  handleInvoiceItemCheckToBeConfirmed(`${item.serviceKey}-${item.productShapeId}`)
                }
                checkboxObj={{
                  key: item.serviceKey,
                  name: (
                    <div
                      style={{
                        display: 'flex',
                        gap: '12px',
                      }}
                    >
                      <ItemImage src={item.mainImageUrl} alt={item.serviceName} />
                      <OrderDetailsInvoiceGridItemText>
                        {item.serviceName}
                      </OrderDetailsInvoiceGridItemText>
                    </div>
                  ),
                }}
              />
            </OrderDetailsInvoiceGridItem>
          ) : (
            <OrderDetailsInvoiceGridItem displayFlex startPadding endPadding>
              <ItemImage src={item.mainImageUrl} alt={item.serviceName} />
              <OrderDetailsInvoiceGridItemText>{item.serviceName}</OrderDetailsInvoiceGridItemText>
            </OrderDetailsInvoiceGridItem>
          )}
          <OrderDetailsInvoiceGridItem>
            <OrderDetailsInvoiceGridItemText>
              {item?.price?.toFixed(2)}
            </OrderDetailsInvoiceGridItemText>
          </OrderDetailsInvoiceGridItem>
          <OrderDetailsInvoiceGridItem>
            <OrderDetailsInvoiceGridItemText>
              {enableEditInvoice ? (
                <Counter
                  count={item.quantity}
                  extendButtonStyle={extendGrayButtonStyle}
                  key={item.serviceKey}
                  label={item.productShapeName}
                  onClickDecrease={() => handleChangeItemQuantity(item.serviceKey, index, 'minus')}
                  onClickIncrease={() => handleChangeItemQuantity(item.serviceKey, index, 'plus')}
                  onDelete={() => handleServiceItemDeleteBtnClick(index)}
                />
              ) : (
                `${item.quantity} ${item.productShapeName}`
              )}
            </OrderDetailsInvoiceGridItemText>
          </OrderDetailsInvoiceGridItem>

          <OrderDetailsInvoiceGridItem>
            <OrderDetailsInvoiceGridItemText>
              {(item.price * (item.quantity || 1)).toFixed(2)}
            </OrderDetailsInvoiceGridItemText>
          </OrderDetailsInvoiceGridItem>
        </React.Fragment>
      ))}
    </OrderDetailsInvoiceGridWrapper>
  );
};

InvoiceTable.propTypes = {
  disableTableBorders: PropTypes.bool,
  handleInvoiceItemCheckToBeConfirmed: PropTypes.func,
  checkedItemToBeConfirmed: PropTypes.arrayOf(PropTypes.string),
  enableEditInvoice: PropTypes.bool,
  disableFlexGrow: PropTypes.bool,
  orderDetails: PropTypes.shape({
    details: PropTypes.shape({
      orderItems: PropTypes.arrayOf({
        price: PropTypes.number,
      }),
    }),
  }),
  orderInvoiceGridHeaderList: PropTypes.shape({}).isRequired,
  servicesList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  invoiceGridItemsToSizeMap: PropTypes.shape({}).isRequired,
  bigGridItemPadding: PropTypes.bool,
  handleServiceItemDeleteBtnClick: PropTypes.func.isRequired,
  handleChangeItemQuantity: PropTypes.func.isRequired,
};

InvoiceTable.defaultProps = {
  bigGridItemPadding: false,
};

export default InvoiceTable;
