import PropTypes from 'prop-types';
import Loader from 'modules/common/components/Loader';
import { status } from 'constants/general';
import ErrorImg from 'assets/images/error.png';
import IntroStatus from './IntroStatus';
import {
  MainContainer,
  MainContainerWrapper,
  ErrorImage,
  ErrorMessage,
  ErrorMessageContainer,
  LoaderContainer,
} from './IntroStatus.style';

const IntroStatusContainer = ({
  pharmacyStockLoadStatus,
  uploadPharmacyStockLoadStatus,
  onUploadStockFile,
  toggleAddStockItemsModal,
  toggleViews,
}) => {
  const renderState = () => {
    if (
      pharmacyStockLoadStatus === status.SHOULD_CALL_API ||
      pharmacyStockLoadStatus === status.FETCHING
    ) {
      return (
        <MainContainer>
          <MainContainerWrapper>
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          </MainContainerWrapper>
        </MainContainer>
      );
    }
    if (pharmacyStockLoadStatus === status.SUCCESS) {
      return (
        <MainContainer>
          <MainContainerWrapper>
            <IntroStatus
              onUploadStockFile={onUploadStockFile}
              uploadPharmacyStockLoadStatus={uploadPharmacyStockLoadStatus}
              toggleAddStockItemsModal={toggleAddStockItemsModal}
              toggleViews={toggleViews}
            />
          </MainContainerWrapper>
        </MainContainer>
      );
    }
    return (
      <MainContainer>
        <MainContainerWrapper>
          <ErrorImage src={ErrorImg} alt="error-image" />
          <ErrorMessageContainer>
            <ErrorMessage>Something went wrong!</ErrorMessage>
          </ErrorMessageContainer>
        </MainContainerWrapper>
      </MainContainer>
    );
  };

  return <div>{renderState()}</div>;
};

IntroStatusContainer.propTypes = {
  pharmacyStockLoadStatus: PropTypes.string,
  uploadPharmacyStockLoadStatus: PropTypes.string,
  onUploadStockFile: PropTypes.func,
  toggleAddStockItemsModal: PropTypes.func,
  toggleViews: PropTypes.func,
};

export default IntroStatusContainer;
