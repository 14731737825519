import PropTypes from 'prop-types';
import Popup from 'modules/common/components/Popup';
import SectionDivider from 'modules/common/components/SectionDivider';
import Loader from 'modules/common/components/Loader';
import {
  OrderDetailsHeaderActionsPopupWrapper,
  OrderDetailsHeaderActionsPopupTitleWrapper,
  OrderDetailsHeaderActionsPopupTitle,
  OrderDetailsHeaderActionsPopupBtnWrapper,
  OrderDetailsHeaderActionsPopupYesBtn,
  OrderDetailsHeaderActionsPopupNoBtn,
} from '../OrderActionBtnsSection/OrderDetailsActionBtns.style';

const OrderActionsModal = ({
  themeContext,
  stringsData,
  isActionPopupLoading,
  actionStatus,
  handleActionPopupConfirmBtnClick,
  handleActionPopupDismissBtnClick,
  orderStatusEnum,
}) => (
  <Popup handleCloseBtnClick={() => handleActionPopupDismissBtnClick()}>
    <OrderDetailsHeaderActionsPopupWrapper>
      <OrderDetailsHeaderActionsPopupTitleWrapper>
        <OrderDetailsHeaderActionsPopupTitle>
          {stringsData.orderDetailsHeader.actionPopupTitle(
            orderStatusEnum.getKeyByValue(actionStatus),
          )}
        </OrderDetailsHeaderActionsPopupTitle>
      </OrderDetailsHeaderActionsPopupTitleWrapper>
      <SectionDivider
        gap={16}
        withSeparator
        separatorGap={16}
        borderColor={themeContext.colors.grey004}
      />
      <OrderDetailsHeaderActionsPopupBtnWrapper>
        {(isActionPopupLoading && <Loader width={28} height={28} thickness={4} />) || (
          <>
            <OrderDetailsHeaderActionsPopupYesBtn
              as="button"
              onClick={() => handleActionPopupConfirmBtnClick()}
            >
              {stringsData.orderDetailsHeader.actionPopupYesBtn}
            </OrderDetailsHeaderActionsPopupYesBtn>
            <OrderDetailsHeaderActionsPopupNoBtn
              as="button"
              onClick={() => handleActionPopupDismissBtnClick()}
            >
              {stringsData.orderDetailsHeader.actionPopupNoBtn}
            </OrderDetailsHeaderActionsPopupNoBtn>
          </>
        )}
      </OrderDetailsHeaderActionsPopupBtnWrapper>
    </OrderDetailsHeaderActionsPopupWrapper>
  </Popup>
);

OrderActionsModal.propTypes = {
  themeContext: PropTypes.shape({
    colors: PropTypes.shape({
      grey004: PropTypes.string,
    }),
  }).isRequired,
  stringsData: PropTypes.shape({
    orderDetailsHeader: PropTypes.shape({
      actionPopupTitle: PropTypes.func,
      actionPopupYesBtn: PropTypes.string,
      actionPopupNoBtn: PropTypes.string,
    }),
  }).isRequired,
  isActionPopupLoading: PropTypes.bool.isRequired,
  actionStatus: PropTypes.number.isRequired,
  handleActionPopupConfirmBtnClick: PropTypes.func.isRequired,
  handleActionPopupDismissBtnClick: PropTypes.func.isRequired,
  orderStatusEnum: PropTypes.shape({
    getKeyByValue: PropTypes.func,
  }).isRequired,
};

export default OrderActionsModal;
