import { Routes, Route, Navigate } from 'react-router-dom';
import PharmacyOrdersPage from 'pages/PharmacyOrdersPage';
import PharmacyStockPage from 'pages/PharmacyStockPage';
import PharmacyInvoicesPage from 'pages/PharmacyInvoicesPage';
import LoginRoute from './LoginRoute';

const Router = () => (
  <Routes>
    <Route exact path="/stock" element={<PharmacyStockPage />} />
    <Route path="/orders" element={<PharmacyOrdersPage />} />
    <Route path="/orders/:orderid" element={<PharmacyOrdersPage />} />
    <Route path="/invoices" element={<PharmacyInvoicesPage />} />
    <Route exact path="/login" element={<LoginRoute />} />
    <Route path="/" element={<Navigate replace to="/orders" />} />
  </Routes>
);

export default Router;
