import Cookies from 'js-cookie';
import { Cookie } from 'constants/appCookies';

export const parseJwt = token => {
  try {
    let base64String = token.split('.')[1];
    base64String = base64String.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64String)
        .split('')
        .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join(''),
    );
    return JSON.parse(jsonPayload);
  } catch (err) {
    return '';
  }
};

export const validateAuthCookie = shouldSaveRedirectUrl => {
  const cookie = Cookies.get(Cookie.AUTH_TOKEN);
  let validity = false;
  let token = '';
  if (cookie) {
    token = parseJwt(cookie);
    if (token && token.nameid) {
      validity = true;
    }
  } else if (shouldSaveRedirectUrl) {
    Cookies.set(Cookie.RETURN_URL, window.location.href);
  }
  return validity;
};

export const getAuthCookieInfo = () => {
  const authCookie = Cookies.get(Cookie.AUTH_TOKEN);
  const authToken = parseJwt(authCookie);
  const { nameid: userKey, ownerkey: pharmacyKey } = authToken;

  return {
    userKey,
    authCookie,
    pharmacyKey,
  };
};

export const getUpperLevelDomain = () => {
  let domain = '';
  if (process.env.NODE_ENV === 'production') {
    const url = window.location.href;
    const domainArr = new URL(url).hostname.split('.');
    domain = domainArr.slice(-2).join('.');
  }
  return domain;
};
