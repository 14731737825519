import { status } from 'constants/general';
import { initialAccountManagementState } from 'store/initialStates';
import accountManagementActionTypes from './action-types';

const ordersReducer = (state = initialAccountManagementState, { type, payload }) => {
  switch (type) {
    case accountManagementActionTypes.FETCH_PHARMACY_USER_AUTHENTICATION_INFO:
      return {
        ...state,
        userAuthenticationInfo: {
          status: status.FETCHING,
        },
      };

    case accountManagementActionTypes.FETCH_PHARMACY_USER_AUTHENTICATION_INFO_SUCCESS:
      return {
        ...state,
        userAuthenticationInfo: {
          status: status.SUCCESS,
          details: payload,
        },
      };

    case accountManagementActionTypes.FETCH_PHARMACY_USER_AUTHENTICATION_INFO_FAIL:
      return {
        ...state,
        userAuthenticationInfo: {
          status: status.FAIL,
        },
      };

    case accountManagementActionTypes.FETCH_PHARMACY_LOGIN_INFO:
      return {
        ...state,
        loginInfo: {
          status: status.FETCHING,
        },
      };

    case accountManagementActionTypes.FETCH_PHARMACY_LOGIN_INFO_SUCCESS:
      return {
        ...state,
        loginInfo: {
          status: status.SUCCESS,
          details: payload,
        },
      };

    case accountManagementActionTypes.FETCH_PHARMACY_LOGIN_INFO_FAIL:
      return {
        ...state,
        loginInfo: {
          status: status.FAIL,
        },
      };

    case accountManagementActionTypes.FETCH_USER_PERMISSIONS:
      return {
        ...state,
        permissions: {
          status: status.FETCHING,
        },
      };

    case accountManagementActionTypes.FETCH_USER_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: {
          status: status.SUCCESS,
          details: payload,
        },
      };

    case accountManagementActionTypes.FETCH_USER_PERMISSIONS_FAIL:
      return {
        ...state,
        permissions: {
          status: status.FAIL,
        },
      };

    default:
      return state;
  }
};

export default ordersReducer;
