import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { status } from 'constants/general';
import SnackBar from 'modules/common/components/SnackBar';
import {
  fetchStock,
  uploadStock,
  exportStock,
  fetchStocksHistory,
  toggleAddStockItemsModal,
  toggleEditStockItemModal,
  resetToStockItemsCart,
  addToStockItemsCart,
} from 'modules/stock/actions';
import { usePrevious } from 'utils/functions/hooks';
import { getAuthCookieInfo } from 'utils/functions/authentication';
import NotificationBar from 'modules/common/components/NotificationBar';
import PushNotificationComponent from 'modules/PushNotification';
import AddStockItemsModal from './Modals/AddStockItemsModal';
import EditStockItemModal from './Modals/EditStockItemModal';
import { VIEW } from './helper';
import IntroStatus from './IntroStatus';
import StockList from './StockList';
import StockHistoryList from './StockHistoryList';
import { ServiceListWrapper } from './Stock.style';

const StockListContainer = ({ ...props }) => {
  const { isAddStockItemsModalOpened, isEditStockItemModalOpened } = props;
  const [currentView, setCurrentView] = useState(VIEW.CURRENT_STOCK);
  const [hasData, setHasData] = useState(false);
  const [searchedStockList, setSearchedStockList] = useState([]);
  const [currentPage, setPage] = useState(1);
  const [queryString, setQuerySting] = useState('');
  const [showWidget, toggleWidget] = useState(false);
  const [widgetMsg, setWidgetMsg] = useState('');
  const [showOrderNotificationBar, toggleOrderNotificationBar] = useState(false);

  // StockHistory
  const [currentStockHistoryPage, setStockHistoryPage] = useState(1);

  useEffect(() => {
    if (widgetMsg) {
      toggleWidget(true);
      setTimeout(() => {
        toggleWidget(false);
      }, 3000);
    }
  }, [widgetMsg]);

  useEffect(() => {
    if (!showWidget) {
      setTimeout(() => {
        setWidgetMsg('');
      }, 2000);
    }
  }, [showWidget]);

  const getStockListPage = (page, query) => {
    setPage(page);
    setQuerySting(query);
    props.fetchStock({
      searchTerm: page > 1 ? queryString : query,
      pageNumber: page,
      PageSize: 10,
    });
  };

  const getStockHistoryPage = page => {
    setStockHistoryPage(page);
    props.fetchStocksHistory({
      pageNumber: page,
      PageSize: 10,
    });
  };

  useEffect(() => {
    if (currentView === VIEW.CURRENT_STOCK) {
      getStockListPage(1, '');
    }
    if (currentView === VIEW.STOCK_HISTORY) {
      getStockHistoryPage(1);
    }
  }, [currentView]);

  // Executed after stock upload success
  const prevUploadPharmacyStockLoadStatus = usePrevious(props.uploadPharmacyStockLoadStatus);
  const prevSavepharmacyStockItemsLoadStatus = usePrevious(props.savepharmacyStockItemsLoadStatus);

  useEffect(() => {
    if (
      prevUploadPharmacyStockLoadStatus &&
      props.uploadPharmacyStockLoadStatus !== prevUploadPharmacyStockLoadStatus &&
      props.uploadPharmacyStockLoadStatus === status.SUCCESS
    ) {
      getStockListPage(1, '');
      setWidgetMsg('Stock File Uploaded Successfully!');
    }
    if (
      prevUploadPharmacyStockLoadStatus &&
      props.uploadPharmacyStockLoadStatus !== prevUploadPharmacyStockLoadStatus &&
      props.uploadPharmacyStockLoadStatus === status.FAIL
    ) {
      setWidgetMsg('Something went wrong!');
    }

    if (
      prevSavepharmacyStockItemsLoadStatus &&
      props.savepharmacyStockItemsLoadStatus !== prevSavepharmacyStockItemsLoadStatus &&
      props.savepharmacyStockItemsLoadStatus === status.SUCCESS
    ) {
      getStockListPage(1, '');
      setWidgetMsg('Stock Items Added Successfully!');
    }

    if (
      prevSavepharmacyStockItemsLoadStatus &&
      props.savepharmacyStockItemsLoadStatus !== prevSavepharmacyStockItemsLoadStatus &&
      props.savepharmacyStockItemsLoadStatus === status.FAIL
    ) {
      setWidgetMsg('Something went wrong!');
    }
  }, [props.uploadPharmacyStockLoadStatus, props.savepharmacyStockItemsLoadStatus]);

  useEffect(() => {
    if (props.pharmacyStock?.items?.length) {
      setHasData(props.pharmacyStock?.items?.length);
    }
    setSearchedStockList(props.pharmacyStock?.items);
  }, [props.pharmacyStock]);

  const onSearchStock = query => {
    setPage(1);
    setQuerySting(query);
    if (!query || query?.length > 3) {
      props.fetchStock({
        searchTerm: query,
        pageNumber: 1,
        PageSize: 10,
      });
    }
  };

  const showMoreInStock = () => {
    getStockListPage(currentPage + 1);
  };

  const showMoreInHistoryStock = () => {
    getStockHistoryPage(currentStockHistoryPage + 1);
  };

  const onExportStock = () => {
    props.exportStock();
  };

  const validateStockCSVfile = file =>
    new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = e => {
        const csvdata = e.target.result;
        const validColumHeadersTitle = ['Pharmacy Code', 'Price', 'Quantity'];
        let rowData = csvdata.split('\n');
        rowData = rowData[0].replace(/(\r\n|\n|\r)/gm, ''); // Remove line breakers
        const uploadedCSVfileHeaders = rowData?.split(',');

        validColumHeadersTitle.forEach(columnName => {
          if (!uploadedCSVfileHeaders.includes(columnName)) {
            resolve(false);
          }
        });

        resolve(true);
      };
      reader.onerror = () => {
        resolve(false);
      };
    });

  const onUploadStockFile = async file => {
    const isValid = await validateStockCSVfile(file);
    if (isValid) {
      const { pharmacyKey } = getAuthCookieInfo();
      const formData = new FormData();
      formData.append('files', file);
      formData.append('OperationKey', pharmacyKey);
      props.uploadStock({ formData });
    } else {
      setWidgetMsg('Not Valid CSV Stock File!');
    }
  };

  const toggleViews = view => {
    setCurrentView(view);
  };

  const pauseNotificationSound = () => {
    const audioElement = document.getElementById('notification-audio');
    if (audioElement) {
      audioElement.loop = false;
      audioElement.pause();
    }
  };

  return (
    <div
      style={{
        height: '100%',
        position: 'relative',
      }}
    >
      <NotificationBar
        key="notification-bar_new-orders"
        message="New Orders Recieved !"
        onClick={() => {
          window.location.href = `${window.location.origin}/orders`;
          toggleOrderNotificationBar(false);
          pauseNotificationSound();
        }}
        onClose={() => {
          toggleOrderNotificationBar(false);
          pauseNotificationSound();
        }}
        showNotification={showOrderNotificationBar}
      />
      <PushNotificationComponent
        onChange={() => {
          toggleOrderNotificationBar(true);
        }}
        callBack={key => {
          window.location.href = `${window.location.origin}/orders/${key}`;
        }}
      />
      {currentView === VIEW.CURRENT_STOCK && (
        <div
          style={{
            height: '100%',
          }}
        >
          {!hasData ? (
            <IntroStatus
              {...props}
              hasData={hasData}
              pharmacyStockLoadStatus={props.pharmacyStockLoadStatus}
              onUploadStockFile={onUploadStockFile}
              uploadPharmacyStockLoadStatus={props.uploadPharmacyStockLoadStatus}
              toggleAddStockItemsModal={props.toggleAddStockItemsModal}
              toggleViews={toggleViews}
            />
          ) : (
            <ServiceListWrapper>
              <StockList
                addToStockItemsCart={props.addToStockItemsCart}
                toggleAddStockItemsModal={props.toggleAddStockItemsModal}
                toggleEditStockItemModal={props.toggleEditStockItemModal}
                onUploadStockFile={onUploadStockFile}
                onSearchStock={onSearchStock}
                onExportStock={onExportStock}
                currentPage={currentPage}
                searchedStockList={searchedStockList}
                pharmacyStock={props.pharmacyStock?.items || []}
                showMore={props.pharmacyStock.showMore}
                savepharmacyStockItemsLoadStatus={props.savepharmacyStockItemsLoadStatus}
                pharmacyStockLoadStatus={props.pharmacyStockLoadStatus}
                uploadPharmacyStockLoadStatus={props.uploadPharmacyStockLoadStatus}
                pharmacyExportStockLoadStatus={props.pharmacyExportStockLoadStatus}
                getStockListPage={value => getStockListPage(value, queryString)}
                queryString={queryString}
                showMoreInStock={showMoreInStock}
                toggleViews={toggleViews}
              />
            </ServiceListWrapper>
          )}
        </div>
      )}
      {currentView === VIEW.STOCK_HISTORY && (
        <div
          style={{
            height: '100%',
          }}
        >
          <ServiceListWrapper>
            <StockHistoryList
              getStockHistoryPage={value => getStockHistoryPage(value)}
              pharmacyStocksHistoryLoadStatus={props.pharmacyStocksHistoryLoadStatus}
              showMore={props.pharmacyStocksHistory.showMore}
              pharmacyStocksHistory={props.pharmacyStocksHistory?.items || []}
              currentPage={currentStockHistoryPage}
              showMoreInHistoryStock={showMoreInHistoryStock}
              toggleViews={toggleViews}
            />
          </ServiceListWrapper>
        </div>
      )}
      {isAddStockItemsModalOpened && (
        <AddStockItemsModal
          onClose={() => {
            props.toggleAddStockItemsModal(false);
            props.resetToStockItemsCart();
          }}
        />
      )}
      {isEditStockItemModalOpened && (
        <EditStockItemModal
          onClose={() => {
            props.toggleEditStockItemModal(false);
            props.resetToStockItemsCart();
          }}
        />
      )}
      <SnackBar show={showWidget} message={widgetMsg} />
    </div>
  );
};

StockListContainer.propTypes = {
  addToStockItemsCart: PropTypes.func,
  toggleEditStockItemModal: PropTypes.func,
  resetToStockItemsCart: PropTypes.func,
  toggleAddStockItemsModal: PropTypes.func,
  fetchStock: PropTypes.func,
  uploadStock: PropTypes.func,
  exportStock: PropTypes.func,
  pharmacyStock: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
    showMore: PropTypes.bool,
  }),
  pharmacyStocksHistory: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
    showMore: PropTypes.bool,
  }),
  pharmacyStockLoadStatus: PropTypes.string,
  uploadPharmacyStockLoadStatus: PropTypes.string,
  pharmacyExportStockLoadStatus: PropTypes.string,
  fetchStocksHistory: PropTypes.func,
  pharmacyStocksHistoryLoadStatus: PropTypes.string,
  isAddStockItemsModalOpened: PropTypes.bool,
  isEditStockItemModalOpened: PropTypes.bool,
  savepharmacyStockItemsLoadStatus: PropTypes.string,
};

const mapStateToProps = state => ({
  pharmacyStocksHistory: state.pharmacyStock.pharmacyStocksHistory,
  pharmacyStocksHistoryLoadStatus: state.pharmacyStock.pharmacyStocksHistoryLoadStatus,
  pharmacyStock: state.pharmacyStock.pharmacyStock,
  pharmacyStockLoadStatus: state.pharmacyStock.pharmacyStockLoadStatus,
  uploadPharmacyStock: state.pharmacyStock.uploadPharmacyStock,
  uploadPharmacyStockLoadStatus: state.pharmacyStock.uploadPharmacyStockLoadStatus,
  pharmacyExportStockLoadStatus: state.pharmacyStock.pharmacyExportStockLoadStatus,
  isAddStockItemsModalOpened: state.pharmacyStock.isAddStockItemsModalOpened,
  isEditStockItemModalOpened: state.pharmacyStock.isEditStockItemModalOpened,
  savepharmacyStockItemsLoadStatus: state.pharmacyStock.savepharmacyStockItemsLoadStatus,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchStock,
      uploadStock,
      exportStock,
      fetchStocksHistory,
      toggleAddStockItemsModal,
      resetToStockItemsCart,
      toggleEditStockItemModal,
      addToStockItemsCart,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(StockListContainer);
