import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from 'modules/common/components/Loader';
import Popup from 'modules/common/components/Popup';
import { paymentMethodKeys } from 'modules/orders/constants';
import TableWithPagination from 'modules/common/components/TableWithPagination';
import { getProductItems } from 'modules/common/api';
import { getMainRowItems, getTableHeadersLocalized } from './helper';
import {
  PopupTitleSpan,
  PopupWrapper,
  PopupTitle,
  PopupTitleWrapper,
  Container,
  PopupTitleContainer,
  SummarySection,
  SummaryBox,
  SummaryBoxTitle,
  SummaryBoxValue,
  InvoiceContainer,
  InvoiceWrapper,
  SummaryContainer,
  SummaryWrapper,
  LightText,
  BoldText,
  extendTableGrid,
  extendTableRow,
  extendTableHeaderRow,
  extendMainItem,
  extendAdditionalItem,
  LoaderContainer,
  OrderNumberOverlay,
} from './InvoiceModalModal.style';

const formatDateTime = (dateString, langCode) => {
  const day = new Date(`${dateString}Z`).toLocaleString(langCode, { day: '2-digit' });
  const month = new Date(`${dateString}Z`).toLocaleString(langCode, { month: 'short' });
  const year = new Date(`${dateString}Z`).toLocaleString(langCode, { year: 'numeric' });
  const time = new Date(`${dateString}Z`)?.toLocaleTimeString('en-EG', {
    hour: '2-digit',
    hour12: true,
    minute: '2-digit',
  });
  return `${day} ${month} ${year}, ${time}`;
};

const InvoiceModal = ({ onClose, ...props }) => {
  const [invoiceItemsList, setInvoiceItemsList] = useState([]);
  const [isLoadingInvoice, toggleInvoiceLoadingState] = useState(true);
  const [showCopiedTextOverlay, setShowCopiedTextOverlay] = useState(false);

  const handleTextToCopyToClipboardClick = textToCopy => {
    navigator.clipboard.writeText(textToCopy);
    setShowCopiedTextOverlay(true);

    setTimeout(() => {
      setShowCopiedTextOverlay(false);
    }, 2000);
  };

  const { invoiceDetails } = props;
  const {
    createdOn,
    invoiceNumber,
    type,
    total,
    paymentMethod,
    deliveryFees,
    subTotal,
    discountAmmount,
  } = invoiceDetails;

  useEffect(() => {
    if (invoiceDetails && invoiceDetails.invoiceItems) {
      const { invoiceItems } = invoiceDetails;
      const itemsProductKeys = [];
      invoiceItems.forEach(item => itemsProductKeys.push(item.productKey));
      const productKeysQuery = itemsProductKeys.join('&productKeys=');
      const pharmacyCatalogBody = {
        productKeys: productKeysQuery,
      };
      getProductItems({ ...pharmacyCatalogBody }).then(response => {
        if (response?.data?.length) {
          const temp = [];
          invoiceDetails.invoiceItems.forEach(itemObj => {
            const productDetailsObj = response.data.find(
              product => product.key === itemObj.productKey,
            );
            temp.push({
              ...itemObj,
              ...productDetailsObj,
            });
          });
          toggleInvoiceLoadingState(false);
          setInvoiceItemsList(temp);
        }
      });
    }
  }, [invoiceDetails]);

  const invoiceType = `${type === 2 ? 'Return' : 'Sales'}`;
  let paymentMethodName = '';
  if (paymentMethod === paymentMethodKeys.cash) paymentMethodName = 'Cash';
  if (paymentMethod === paymentMethodKeys.cardOnDeliver) paymentMethodName = 'Card On Delivery';
  if (paymentMethod === paymentMethodKeys.creditCard) paymentMethodName = 'Online Payment';
  const totalAmount = `Total: ${total} EGP`;
  const deliveryAmount = `Delivery fee: ${deliveryFees} EGP`;
  const subTotalAmount = `Subtotal: ${subTotal} EGP`;
  const discountedAmount = `Discount: -${discountAmmount} EGP`;

  return (
    <Popup showCloseBtn={false}>
      <PopupWrapper>
        <PopupTitleContainer>
          <PopupTitleWrapper>
            <PopupTitle
              onClick={() => handleTextToCopyToClipboardClick(invoiceNumber)}
            >{`Invoice #${invoiceNumber}`}</PopupTitle>
            <PopupTitleSpan
              onClick={() => {
                onClose();
              }}
            />
          </PopupTitleWrapper>
        </PopupTitleContainer>

        <Container>
          <SummarySection>
            <SummaryBox>
              <SummaryBoxTitle>Time</SummaryBoxTitle>
              <SummaryBoxValue>{`${formatDateTime(createdOn, 'en-EG')}`}</SummaryBoxValue>
            </SummaryBox>
            <SummaryBox>
              <SummaryBoxTitle>Payment</SummaryBoxTitle>
              <SummaryBoxValue>{paymentMethodName}</SummaryBoxValue>
            </SummaryBox>
            <SummaryBox>
              <SummaryBoxTitle>Type</SummaryBoxTitle>
              <SummaryBoxValue>{invoiceType}</SummaryBoxValue>
            </SummaryBox>
          </SummarySection>
          {!isLoadingInvoice ? (
            <InvoiceContainer>
              <InvoiceWrapper>
                <div>
                  <TableWithPagination
                    tableColumnHeaders={getTableHeadersLocalized()}
                    data={getMainRowItems(invoiceItemsList)}
                    language="en"
                    extendTableGrid={extendTableGrid}
                    extendTableRow={extendTableRow}
                    extendTableHeaderRow={extendTableHeaderRow}
                    extendMainItem={extendMainItem}
                    extendAdditionalItem={extendAdditionalItem}
                  />
                </div>
                <SummaryContainer>
                  <SummaryWrapper>
                    <LightText>{subTotalAmount}</LightText>
                    {discountAmmount > 0 && (
                      <LightText
                        style={{
                          color: '#31A636',
                        }}
                      >
                        {discountedAmount}
                      </LightText>
                    )}
                    <LightText>{deliveryAmount}</LightText>
                    <BoldText>{totalAmount}</BoldText>
                  </SummaryWrapper>
                </SummaryContainer>
              </InvoiceWrapper>
            </InvoiceContainer>
          ) : (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          )}
        </Container>
      </PopupWrapper>
      {showCopiedTextOverlay && <OrderNumberOverlay>Copied to clipboard 🚀</OrderNumberOverlay>}
    </Popup>
  );
};

InvoiceModal.propTypes = {
  invoiceDetails: PropTypes.shape({
    key: PropTypes.string,
    createdOn: PropTypes.string,
    invoiceNumber: PropTypes.number,
    type: PropTypes.number,
    total: PropTypes.number,
    paymentMethod: PropTypes.string,
    deliveryFees: PropTypes.number,
    subTotal: PropTypes.number,
    invoiceItems: PropTypes.arrayOf({}),
    discountAmmount: PropTypes.number,
  }),
  onClose: PropTypes.func,
};

const mapStateToProps = state => ({
  invoiceDetails: state.pharmacyInvoices.invoiceDetails,
});

export default connect(mapStateToProps)(InvoiceModal);
