import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { resolvePathArray } from 'utils/functions/general';
import { useOnClickOutside } from 'utils/functions/hooks';
import {
  FilterDropDown,
  FilterDropDownCheckbox,
  FilterDropDownCheckboxLabel,
  FilterDropDownCheckboxWrapper,
  FilterDropDownItem,
  FilterDropDownWrapper,
  FilterLabel,
  FilterWrapper,
  FilterDropDownActionWrapper,
  FilterDropDownClearButton,
  FilterDropDownApplyButton,
} from './Filter.style';

const Filter = ({
  handleGenericFilterClick,
  filterProps,
  ordersApiCallParams,
  selectedData,
  setSelectedData,
}) => {
  const { filterDataPath, filterObjMapper } = filterProps;

  const filterData = useSelector(state => {
    if (filterDataPath) return resolvePathArray(filterDataPath, state);
    return '';
  });

  const [isDropdownOpen, setIsDropdownOpen] = useState('');

  const filterRef = useRef();
  useOnClickOutside(filterRef, () => setIsDropdownOpen(false));

  const handleToggle = () => {
    setIsDropdownOpen(prevState => !prevState);
  };

  const handleMultiDropdownFilterDataSelect = (key, value) => {
    let selectedDataTemp = { ...selectedData };
    if (selectedDataTemp[key] !== undefined) delete selectedDataTemp[key];
    else selectedDataTemp = { ...selectedDataTemp, [key]: value };
    setSelectedData(selectedDataTemp);
  };

  const handleClearFilter = e => {
    e.stopPropagation();
    handleGenericFilterClick({});
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    if (Object.keys(ordersApiCallParams).length > 0) {
      const tempSelectedValue = ordersApiCallParams.stateId;
      if (tempSelectedValue) {
        const filterDataLength = filterData?.length;
        if (filterDataLength) {
          const filterObjMapperKey = filterObjMapper.key;
          const tempSelectedName = filterData.find(
            val => val[filterObjMapperKey] === tempSelectedValue,
          )?.name;
          setSelectedData({ [tempSelectedValue]: tempSelectedName });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  const FilterOptions = [
    {
      id: 14,
      name: 'New',
    },
    {
      id: 6,
      name: 'Viewed',
    },
    {
      id: 7,
      name: 'Accepted',
    },
    {
      id: 41,
      name: 'Alt. Sent',
    },
    {
      id: 42,
      name: 'Alt. Resolved',
    },
    {
      id: 43,
      name: 'Alt. No Response',
    },
    {
      id: 44,
      name: 'Confirmed',
    },
    {
      id: 9,
      name: 'Ready for Pickup',
    },
    {
      id: 10,
      name: 'Picked up',
    },
    {
      id: 4,
      name: 'Delivered',
    },
    {
      id: 19,
      name: 'Payment pending',
    },
    {
      id: 20,
      name: 'Payment processing',
    },
    {
      id: 21,
      name: 'Payment succeeded',
    },
    {
      id: 22,
      name: 'Payment failed',
    },
    {
      id: 5,
      name: 'Canceled',
    },
    {
      id: 8,
      name: 'Rejected',
    },
  ];

  const renderDropdownFilter = () => (
    <FilterWrapper ref={filterRef}>
      <FilterLabel
        onClick={() => handleToggle()}
        toggled={isDropdownOpen}
        selected={Object.values(selectedData).length > 0}
      >
        Status
      </FilterLabel>
      {isDropdownOpen && (
        <FilterDropDownWrapper>
          <FilterDropDown>
            <FilterDropDownItem key={0} onClick={() => handleGenericFilterClick({})}>
              All Status
            </FilterDropDownItem>
            {FilterOptions?.map(item => (
              <FilterDropDownCheckboxWrapper
                onClick={() =>
                  handleMultiDropdownFilterDataSelect(
                    item[filterObjMapper.key],
                    item[filterObjMapper.name],
                  )
                }
              >
                <FilterDropDownCheckbox
                  key={item[filterObjMapper.key]}
                  type="checkbox"
                  readOnly
                  value={item[filterObjMapper.key]}
                  checked={selectedData[item[filterObjMapper.key]] !== undefined}
                />
                <FilterDropDownCheckboxLabel>
                  {item[filterObjMapper.name]}
                </FilterDropDownCheckboxLabel>
              </FilterDropDownCheckboxWrapper>
            ))}
          </FilterDropDown>
          <FilterDropDownActionWrapper>
            <FilterDropDownClearButton onClick={e => handleClearFilter(e)}>
              Clear
            </FilterDropDownClearButton>
            <FilterDropDownApplyButton
              onClick={() => {
                handleGenericFilterClick(selectedData);
                handleToggle();
              }}
            >
              Apply
            </FilterDropDownApplyButton>
          </FilterDropDownActionWrapper>
        </FilterDropDownWrapper>
      )}
    </FilterWrapper>
  );

  return <div>{renderDropdownFilter()}</div>;
};

Filter.propTypes = {
  selectedData: PropTypes.shape({}),
  setSelectedData: PropTypes.func,
  handleGenericFilterClick: PropTypes.func.isRequired,
  filterProps: PropTypes.shape({
    filterLabel: PropTypes.string,
    filterDataPath: PropTypes.arrayOf(PropTypes.string),
    filterObjMapper: PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
    }),
    filterSearchBarPlaceholder: PropTypes.string,
    filterDataValueCondition: PropTypes.shape({}),
    filtersToClear: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  ordersApiCallParams: PropTypes.shape({
    stateId: PropTypes.arrayOf(PropTypes.string),
  }),
  selectedMoreFiltersData: PropTypes.shape({}),
};

Filter.defaultProps = {
  selectedMoreFiltersData: {},
  ordersApiCallParams: {},
};

export default Filter;
