const accountManagementStrings = {
  login: {
    emailFieldPlaceholder: 'Username',
    passwordFieldPlaceholder: 'Password',
    emailFieldValidationError: 'Please enter your username',
    passwordFieldValidationError: 'Please enter your password',
    loginBtn: 'Login',
  },
  unauthorized: {
    title: 'Authorization required',
    subTitle: 'Looks like you are not authorized to view this page',
  },
};

export default accountManagementStrings;
