import PropTypes from 'prop-types';
import Counter from 'modules/common/components/Counter';
import {
  ItemContainer,
  ItemDetails,
  ItemImage,
  ItemName,
  ItemDescription,
  extendGrayButtonStyle,
  extendCounterWrapper,
  ItemWrapper,
  ItemContent,
} from './ItemizationItemsModal.style';

const Item = ({
  index,
  itemName,
  itemShapeName,
  itemPrice,
  itemShapeId,
  itemProductKey,
  itemImage,
  itemQuantity,
  onChangeQuantity,
}) => (
  <ItemContainer key={`${itemProductKey}-${itemShapeId}`}>
    <ItemWrapper>
      <div
        style={{
          display: 'flex',
          gap: '12px',
        }}
      >
        <ItemImage src={itemImage} alt="item-image" />
        <ItemDetails>
          <ItemName>{itemName}</ItemName>
          <ItemDescription>{itemShapeName}</ItemDescription>
        </ItemDetails>
      </div>
    </ItemWrapper>
    <ItemWrapper>
      {' '}
      <ItemContent>{itemPrice}</ItemContent>{' '}
    </ItemWrapper>
    <ItemWrapper>
      <Counter
        count={itemQuantity}
        extendButtonStyle={extendGrayButtonStyle}
        extendCounterWrapper={extendCounterWrapper}
        key={itemProductKey}
        onClickDecrease={() => onChangeQuantity(itemProductKey, index, 'minus')}
        onClickIncrease={() => onChangeQuantity(itemProductKey, index, 'plus')}
        onDelete={() => onChangeQuantity(itemProductKey, index)}
        // minLimit={0}
        // maxLimit={stockQuantity}
      />
    </ItemWrapper>
    <ItemWrapper>
      {' '}
      <ItemContent>{(itemQuantity * itemPrice).toFixed(2)}</ItemContent>{' '}
    </ItemWrapper>
  </ItemContainer>
);

Item.propTypes = {
  itemProductKey: PropTypes.string,
  itemImage: PropTypes.string,
  itemName: PropTypes.string,
  itemShapeName: PropTypes.string,
  index: PropTypes.number,
  itemShapeId: PropTypes.number,
  onChangeQuantity: PropTypes.func,
  itemPrice: PropTypes.number,
  itemQuantity: PropTypes.number,
};

export default Item;
