export const VIEWS = {
  ADD_ITEMS: 0,
  SAVE_ITEMS: 1,
};

export const getProductsListForDropDown = (
  productsList,
  searchList,
  language,
  orderItemToBeReplaced,
) => {
  const list = [];
  if (productsList?.length) {
    productsList.forEach(item => {
      const {
        mainImageUrl,
        productShapeTypeName,
        productShapeTypeNameAr,
        productNameAr,
        productNameEn,
        productKey,
        id,
        newPrice,
      } = item;
      const alreadyAddedAlternative = searchList?.findIndex(
        itemObj => itemObj.itemProductKey === productKey && itemObj.itemShapeId === id,
      );

      const alreadyAnOrderItemToBeReplaced =
        orderItemToBeReplaced?.productKey === productKey &&
        orderItemToBeReplaced?.productShapeId === id;

      list.push({
        isSelected: alreadyAddedAlternative !== -1 || alreadyAnOrderItemToBeReplaced,
        fieldValue: language === 'en' ? productNameEn : productNameAr,
        itemImage: mainImageUrl,
        value: id,
        description: language === 'en' ? productShapeTypeName : productShapeTypeNameAr,
        key: productKey,
        extras: {
          mainImageUrl,
          productShapeTypeName,
          productShapeTypeNameAr,
          productNameAr,
          productNameEn,
          productKey,
          id,
          newPrice,
        },
      });
    });
  }
  return list;
};
