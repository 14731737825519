import styled, { css } from 'styled-components';

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
`;

export const MainContainer = styled.div`
  border-radius: 8px;
  background-color: #fff;
  height: 85vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const MainContainerWrapper = styled.div`
  border-radius: 8px;
  background-color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const FileIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 17px;
`;

export const SectionContainer = styled(FileIconContainer)`
  margin-bottom: 0px;
  flex-direction: column;
`;

export const MainMessage = styled.p`
  color: #484848;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  margin: 0;
`;

export const SubMessage = styled.p`
  color: #484848;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
`;

export const StatusContainer = styled.div`
  padding: 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const StatusContainerWrapper = styled.div`
  width: 100%;
  height: fit-content;
`;

export const ErrorImage = styled.img`
  width: 128px;
  margin: 0 auto;
`;

export const ErrorMessageContainer = styled.div`
  margin-top: 11px;
`;

export const ErrorMessage = styled.p`
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  color: #484848;
`;

export const extendButtonStyle = css`
  width: 100%;
  border-radius: 8px;
  border: 1px solid #e3e6ea;
  background: #0070cd;
  color: #fff;
  padding: 7px 10px;
`;

export const extendButtonStyleLoading = css`
  width: 100%;
  border-radius: 8px;
  border: 1px solid #e3e6ea;
  background: #ffff;
  color: #fff;
  padding: 7px 10px;
`;

export const extendLoaderStyle = css`
  height: 17px;
  width: 17px;
  border: 1px solid #f3f3f3;
  margin: 0;
  border-top: 2px solid #3498db;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  flex-direction: column;
`;

export const extendExportButtonStyle = css`
  width: 100%;
  border-radius: 8px;
  border: 1px solid #e3e6ea;
  background: #ffff;
  color: #484848;
  padding: 7px 10px;
`;
