import { takeLatest, call, put } from 'redux-saga/effects';
import { getDateTime } from 'utils/functions/dateTime';
import {
  getStocks,
  uploadStock,
  exportStock,
  getStocksHistory,
  addStockItems,
  searchItems,
} from './api';
import {
  fetchStockFail,
  fetchStockSuccess,
  uploadStockFail,
  uploadStockSuccess,
  exportStockSuccess,
  exportStockFail,
  fetchStocksHistoryFail,
  fetchStocksHistorySuccess,
  saveStockItemsSuccess,
  saveStockItemsFail,
  searchStockItemsSuccess,
  searchStockItemsFail,
} from './actions';
import stockActionTypes from './action-types';

function* fetchStockSaga({ payload }) {
  try {
    const response = yield call(getStocks, payload);
    yield put(fetchStockSuccess(response.data));
  } catch (error) {
    yield put(fetchStockFail(error));
  }
}

function* searchItemsSaga({ payload }) {
  try {
    const response = yield call(searchItems, payload);
    yield put(searchStockItemsSuccess(response.data));
  } catch (error) {
    yield put(searchStockItemsFail(error));
  }
}

function* addStockItemsSaga({ payload }) {
  try {
    const response = yield call(addStockItems, payload);
    yield put(saveStockItemsSuccess(response.data));
  } catch (error) {
    yield put(saveStockItemsFail(error));
  }
}

function* fetchStockHistorySaga({ payload }) {
  try {
    const response = yield call(getStocksHistory, payload);
    yield put(fetchStocksHistorySuccess(response.data));
  } catch (error) {
    yield put(fetchStocksHistoryFail(error));
  }
}

function* exportStockSaga() {
  try {
    const response = yield call(exportStock);
    const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `stock-${getDateTime()}.csv`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    yield put(exportStockSuccess(response));
  } catch (error) {
    yield put(exportStockFail(error));
  }
}

function* uploadStockSaga({ payload }) {
  try {
    const response = yield call(uploadStock, payload);
    yield put(uploadStockSuccess(response));
  } catch (error) {
    yield put(uploadStockFail(error));
  }
}

function* stockSagas() {
  yield takeLatest(stockActionTypes.SEARCH_STOCK_ITEMS, searchItemsSaga);
  yield takeLatest(stockActionTypes.SAVE_STOCK_ITEMS, addStockItemsSaga);
  yield takeLatest(stockActionTypes.FETCH_STOCK_HISTORY, fetchStockHistorySaga);
  yield takeLatest(stockActionTypes.FETCH_STOCK, fetchStockSaga);
  yield takeLatest(stockActionTypes.UPLOAD_STOCK, uploadStockSaga);
  yield takeLatest(stockActionTypes.EXPORT_STOCK, exportStockSaga);
}

export default stockSagas;
