import { combineReducers } from 'redux';
import pharmacyCommonReducer from 'modules/common/reducer';
import pharmacyOrdersReducer from 'modules/orders/reducer';
import pharmacyAccountManagementReducer from 'modules/accountManagement/reducer';
import pharmacyStockReducer from 'modules/stock/reducer';
import pharmacyInvoicesReducer from 'modules/invoices/reducer';

export default combineReducers({
  pharmacyOrders: pharmacyOrdersReducer,
  pharmacyCommon: pharmacyCommonReducer,
  pharmacyAccountManagement: pharmacyAccountManagementReducer,
  pharmacyStock: pharmacyStockReducer,
  pharmacyInvoices: pharmacyInvoicesReducer,
});
