import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { status } from 'constants/general';
import { Cookie } from 'constants/appCookies';
import Loader from 'modules/common/components/Loader';
import { getUpperLevelDomain } from 'utils/functions/authentication';
import {
  HeaderWrapper,
  HeaderPageName,
  HeaderUserNameWrapper,
  HeaderUserName,
  HeaderLogoutPopup,
  HeaderLogout,
  HeaderUserNameLoaderWrapper,
} from './Header.style';

const Header = ({ pageName, loginInfo, strings }) => {
  const userNameWrapperRef = useRef(null);

  const [isLogoutPopupOpen, setIsLogoutPopupOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = event => {
      if (userNameWrapperRef.current && userNameWrapperRef.current.contains(event.target) === false)
        setIsLogoutPopupOpen(false);
    };
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [userNameWrapperRef]);

  const handleUsernameClick = () => {
    setIsLogoutPopupOpen(prevState => !prevState);
  };

  const handleLogoutClick = () => {
    setIsLogoutPopupOpen(false);
    const domain = getUpperLevelDomain();
    Cookies.remove(Cookie.AUTH_TOKEN, { domain });
    Cookies.remove(Cookie.RETURN_URL);
    document.location.href = '/login';
  };

  return (
    <HeaderWrapper>
      <HeaderPageName>{pageName}</HeaderPageName>
      {((loginInfo.status === status.SHOULD_CALL_API || loginInfo.status === status.FETCHING) && (
        <HeaderUserNameLoaderWrapper>
          <Loader width={16} height={16} thickness={4} />
        </HeaderUserNameLoaderWrapper>
      )) || (
        <HeaderUserNameWrapper ref={userNameWrapperRef}>
          <HeaderUserName
            onClick={() => handleUsernameClick()}
          >{`${strings.header.userNameText} ${loginInfo.details.data.username}`}</HeaderUserName>
          {isLogoutPopupOpen && (
            <HeaderLogoutPopup>
              <HeaderLogout onClick={() => handleLogoutClick()}>
                {strings.header.logoutText}
              </HeaderLogout>
            </HeaderLogoutPopup>
          )}
        </HeaderUserNameWrapper>
      )}
    </HeaderWrapper>
  );
};

Header.propTypes = {
  pageName: PropTypes.string.isRequired,
  loginInfo: PropTypes.shape({
    status: PropTypes.string,
    details: PropTypes.shape({
      data: PropTypes.shape({
        username: PropTypes.string,
      }),
    }),
  }).isRequired,
  strings: PropTypes.shape({
    header: PropTypes.shape({
      userNameText: PropTypes.string,
      logoutText: PropTypes.string,
    }),
  }).isRequired,
};

Header.defaultProps = {};

export default Header;
