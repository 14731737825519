import styled from 'styled-components';

export const OrdersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 181px);
  min-height: calc(100vh - 181px);
  padding: 24px 24px 51px;
  background-color: ${({ theme }) => theme.colors.grey100};
`;

export const LoaderWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`;

export const PaginationWrapper = styled.div`
  padding: 24px 0;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
`;

export const OrderDetailsContainer = styled.div`
  background: #eceef0;
  width: 100%;
  display: flex;
  justify-content: center;
  height: calc(100vh - 56px);
  overflow: auto;
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background: #9c9c9c;
    border-radius: 15px;
  }
`;

export const EmptyStateMsgContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
`;

export const EmptyStateMsg = styled.p`
  color: #484848;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  margin-bottom: 8px;
  margin-top: 8px;
`;

export const EmptyStateSecondaryMsg = styled.p`
  color: #484848;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  margin: 0;
`;

export const EmptyStateImg = styled.img`
  width: 220px;
  margin: 0 auto;
`;

export const EmptyStateContainer = styled.div`
  display: flex;
  width: 100%;
  background: #fff;
  margin: 0 1px;
`;
