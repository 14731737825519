import {
  SectionContainer,
  MainMessage,
  StatusContainer,
  StatusContainerWrapper,
} from './IntroStatus.style';

const IntroStatus = () => (
  <StatusContainer>
    <StatusContainerWrapper>
      {/* <FileIconContainer>
          <img src={FileIcon} alt="file" />
        </FileIconContainer> */}
      <SectionContainer>
        <MainMessage>No Invoices Added</MainMessage>
        {/* <SubMessage>
          There will be a list of products for online sale through the Vezeeta e-pharmacy
        </SubMessage> */}
      </SectionContainer>
    </StatusContainerWrapper>
  </StatusContainer>
);

IntroStatus.propTypes = {};

export default IntroStatus;
