import axios from 'axios';
import { getAuthCookieInfo } from 'utils/functions/authentication';
import { serializeParams } from 'utils/functions/general';

const request = {
  get(headers, url, params) {
    const configOptions = {
      params,
      paramsSerializer: () => serializeParams(params),
      headers,
    };

    const apiCallPromis = axios.get(url, configOptions);
    return apiCallPromis;
  },

  post(headers, url, params) {
    const configOptions = {
      headers,
    };

    const apiCallPromis = axios.post(url, params, configOptions);
    return apiCallPromis;
  },

  put(headers, url, params) {
    const configOptions = {
      headers,
    };

    const apiCallPromis = axios.put(url, params, configOptions);
    return apiCallPromis;
  },

  patch(headers, url, params) {
    const configOptions = {
      headers,
    };

    const apiCallPromis = axios.patch(url, params, configOptions);
    return apiCallPromis;
  },
};

const wrap =
  method =>
  (customHeaders = {}, replaceHeaders = false, ...props) => {
    const { authCookie } = getAuthCookieInfo();
    // todo: Remove bearer
    let headers = {
      authorization: `${authCookie}`,
      ...customHeaders,
    };
    if (replaceHeaders) headers = customHeaders;

    return request[method](headers, ...props);
  };

export const get = wrap('get');
export const post = wrap('post');
export const put = wrap('put');
export const patch = wrap('patch');
