import MainContainer from 'modules/common/MainContainer';
import StockView from 'modules/stock/views';

const PharmacyStockPage = () => (
  <MainContainer context="Stock">
    <StockView />
  </MainContainer>
);

PharmacyStockPage.propTypes = {};

PharmacyStockPage.defaultProps = {};

export default PharmacyStockPage;
