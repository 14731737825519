import PropTypes from 'prop-types';

import { RadioButtonWrapper, RadioButtonCheck, RadioButtonLabel } from './RadioButton.style';

const RadioButton = ({ RadioButtonObj, checked, handleRadioButtonClick }) => (
  <RadioButtonWrapper onClick={() => handleRadioButtonClick(RadioButtonObj.key)}>
    <RadioButtonCheck checked={checked} />
    <RadioButtonLabel>{RadioButtonObj.name}</RadioButtonLabel>
  </RadioButtonWrapper>
);

RadioButton.propTypes = {
  RadioButtonObj: PropTypes.shape({
    key: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  checked: PropTypes.bool,
  handleRadioButtonClick: PropTypes.func.isRequired,
};

RadioButton.defaultProps = {
  checked: false,
};

export default RadioButton;
