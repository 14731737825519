import styled, { css } from 'styled-components';
import themeData from 'utils/theme/themeData';

const { typography } = themeData;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
`;

export const OrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e3e6ea;
  padding: 12px 16px;
  cursor: pointer;
  ${props =>
    props.isSelected &&
    `
    background: #E5F1FF;
    border-left: 4px solid #0070CD;
  `};
`;

export const OrderListNoResultsWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
`;

export const OrderListNoResultsText = styled(typography.TitleOneRegularTypography)``;

export const OrdersListWrapper = styled.div`
  width: 280px;
  padding-top: 10px;
`;

export const OrdersListGridWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 280px;
  margin-top: 5px;
  height: calc(100vh - 79px - 56px - 10px);
  overflow: auto;
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background: #9c9c9c;
    border-radius: 15px;
  }
`;

export const OrdersListDivider = styled.hr`
  width: calc(100% + 48px);
  grid-column: ${({ columnsCount }) => `1 / ${columnsCount + 2}`};
  margin: 0 -24px;
  border: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey003};
`;

export const OrdersListHeader = styled(typography.BodyTextOneRegularTypography)`
  margin: 0;
  padding: 10px 0;
  color: ${({ theme }) => theme.colors.grey002};
`;

export const OrdersListItem = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const OrdersListItemLink = styled.div`
  text-decoration: none;
`;

export const OrdersListItemText = styled(typography.BodyTextOneRegularTypography)`
  margin: 0;
  font-weight: 700;
  color: #484848;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  ${props =>
    props.isSelected &&
    `
    color: #0070CD !important;
  `};
`;

export const OrderID = styled(OrdersListItemText)`
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  ${props =>
    props.isSelected &&
    `
    color: #0070CD !important;
  `};
`;

export const OrdersListItemSubText = styled.div`
  margin: 0;
  color: #484848;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  display: flex;
  gap: 2px;
  ${props =>
    props.isSelected &&
    `
    color: #0070CD !important;
    & > * {
      color: #0070CD !important;
    }
  `};
`;

export const OrderListItemStatus = styled(typography.BodyTextTwoRegularTypography)`
  width: 150px;
  margin: 0;
  padding: 5px 0;
  text-align: center;
  border-radius: 4px;

  ${({ color, backgroundColor }) => `
    color: ${color};
    background-color: ${backgroundColor};
  `};
`;

export const OrderListItemStatusReason = styled(typography.BodyTextTwoRegularTypography)`
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  margin: 0;
  color: ${({ theme }) => theme.colors.redMain};
`;

export const DelayedOrder = styled.div`
  height: 100%;
  border-left: 3px #db3226 solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
  margin: -24px;
`;

export const DelayedOrderTextAlert = styled(typography.BodyTextTwoRegularTypography)`
  margin: 0;
  color: ${({ theme }) => theme.colors.redMain};
`;

export const DelayedOrderIcon = styled.i`
  color: ${({ theme }) => theme.colors.redMain};
  font-style: normal;
  margin-left: 4px;

  &:before {
    content: '\\e90c';
    font-family: 'Icomoon';
  }
`;

export const DelayedOrderWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const OrderInfoSticker = styled(typography.BodyTextTwoRegularTypography)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  width: fit-content;
  color: ${({ color }) => color};

  &:before {
    content: ${({ icon }) => icon};
    font-family: 'Icomoon';
    font-size: 18px;
  }
`;

export const StatusBadges = styled.p`
  color: ${props => props.statusColor || '#fff'};
  background: ${props => props.statusBGColor || '#fff'};
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  border-radius: 4px;
  margin: 0;
`;

export const extendButtonStyle = css`
  width: 230px;
  margin: 10px auto;
  border-radius: 8px;
  border: 1px solid #e3e6ea;
`;

export const extendLoaderStyle = css`
  height: 20px;
  width: 20px;
  border: 1px solid #f3f3f3;
  border-top: 3px solid #3498db;
  margin: 0;
`;

export const EmptyStateMsg = styled.p`
  color: #484848;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  margin: 252px 0;
`;
