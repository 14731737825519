/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { status } from 'constants/general';
import TableWithPagination from 'modules/common/components/TableWithPagination';
import Popup from 'modules/common/components/Popup';
import SectionDivider from 'modules/common/components/SectionDivider';
import Button from 'modules/common/components/Button';
import {
  OrderDetailsReturnOrderPopupWrapper,
  OrderDetailsScheduleOrderPopupTitleWrapper,
  OrderDetailsScheduleOrderPopupTitle,
  OrderDetailsScheduleOrderPopupTitleSpan,
  OrderDetailsScheduleOrderBtnWrapper,
  extendNextStateButtonStyle,
  extendLoaderStyle,
  TabsContainer,
  TabContainer,
  TabLabel,
  extendTableGrid4Col,
  extendTableGrid5Col,
  extendTableHeaderRow,
  extendTableRow,
  extendMainItem,
  extendAdditionalItem,
  extendHeaderRowItems,
  SummaryWrapper,
  SummaryContainer,
  LightText,
  BoldText,
  ReturnTableContainer,
  ReturnTableWrapper,
} from '../OrderActionBtnsSection/OrderDetailsActionBtns.style';
import { VIEWS, getTableHeadersLocalized, getMainRowItems } from './ReturnOrderModal.helper';

const ReturnOrderModal = ({
  themeContext,
  onClose,
  dispensedOrderItems,
  isRetrunOrderPopupOpen,
  handleReturnOrder,
  returnOrderLoadStatus,
}) => {
  const [originalInvoiceSubTotal, setOriginalInvoiceSubTotal] = useState(0);
  const [selectedView, setSelectedView] = useState(VIEWS.PARTIALY_RETURN);
  const [dispensedOrderItemsReplica, setDispensedOriginalOrderItemsReplica] = useState([]);

  useEffect(() => {
    if (isRetrunOrderPopupOpen) {
      const itemsList = _.cloneDeep(dispensedOrderItems);
      const subTotalValue = dispensedOrderItems?.reduce((prevValue, currentValue) => {
        let { price } = currentValue;
        price = currentValue.price * currentValue.quantity;
        return prevValue + price;
      }, 0);
      setOriginalInvoiceSubTotal(subTotalValue);
      setDispensedOriginalOrderItemsReplica([...itemsList]);
    } else {
      setDispensedOriginalOrderItemsReplica([]);
    }
  }, [isRetrunOrderPopupOpen, selectedView]);

  const handleChangeItemQuantity = (itemKey, index, operationType) => {
    const tempDispensedOrderItems = _.cloneDeep(dispensedOrderItemsReplica);
    const tempItemIndex = tempDispensedOrderItems.findIndex(
      (service, itemIndex) => service.serviceKey === itemKey && itemIndex === index,
    );
    const tempItem = tempDispensedOrderItems.find(
      (service, itemIndex) => service.serviceKey === itemKey && itemIndex === index,
    );
    let newQuantity;
    if (operationType === 'plus') newQuantity = tempItem.quantity - 1;
    else if (operationType === 'minus') newQuantity = tempItem.quantity + 1;

    tempDispensedOrderItems[tempItemIndex] = { ...tempItem, quantity: newQuantity };
    setDispensedOriginalOrderItemsReplica(tempDispensedOrderItems);
  };

  const handleUpdateOrder = () => {
    // Return Order
    const checkedItemsList = [];
    dispensedOrderItemsReplica.forEach(item => {
      const originalItem = dispensedOrderItems.find(
        service =>
          service.serviceKey === item.serviceKey && service.productShapeId && item.productShapeId,
      );
      if (selectedView === VIEWS.TOTALLY_RETURN) {
        checkedItemsList.push({ ...item });
      } else if (selectedView === VIEWS.PARTIALY_RETURN) {
        const quantityDifference = originalItem.quantity - item.quantity;
        if (quantityDifference) {
          checkedItemsList.push({ ...item, quantity: quantityDifference });
        }
      }
    });
    handleReturnOrder(checkedItemsList);
  };

  const onClickReturnAll = () => {
    setSelectedView(VIEWS.TOTALLY_RETURN);
  };

  const onClickPartialyReturn = () => {
    setSelectedView(VIEWS.PARTIALY_RETURN);
  };

  const tableHeadersLocalized = getTableHeadersLocalized(selectedView);
  const extendTableColumnsStyle =
    selectedView === VIEWS.PARTIALY_RETURN ? extendTableGrid5Col : extendTableGrid4Col;

  const getTotalReturn = () => {
    const subTotalValue = dispensedOrderItemsReplica?.reduce((prevValue, currentValue) => {
      let { price } = currentValue;
      price = currentValue.price * currentValue.quantity;
      return prevValue + price;
    }, 0);

    if (selectedView === VIEWS.TOTALLY_RETURN)
      return (
        <SummaryContainer>
          <SummaryWrapper>
            <BoldText>{`Total return: ${originalInvoiceSubTotal} EGP`}</BoldText>
          </SummaryWrapper>
        </SummaryContainer>
      );
    return (
      <SummaryContainer>
        <SummaryWrapper>
          <LightText>{`Sold items: ${subTotalValue} EGP`}</LightText>
          <BoldText>{`Total return: ${originalInvoiceSubTotal - subTotalValue} EGP`}</BoldText>
        </SummaryWrapper>
      </SummaryContainer>
    );
  };

  const disableConfirmReturnBtn = () => {
    if (selectedView === VIEWS.PARTIALY_RETURN) {
      const subTotalValue = dispensedOrderItemsReplica?.reduce((prevValue, currentValue) => {
        let { price } = currentValue;
        price = currentValue.price * currentValue.quantity;
        return prevValue + price;
      }, 0);
      return !(originalInvoiceSubTotal - subTotalValue > 0);
    }
    return false;
  };

  return (
    <Popup showCloseBtn={false}>
      <OrderDetailsReturnOrderPopupWrapper>
        <OrderDetailsScheduleOrderPopupTitleWrapper>
          <OrderDetailsScheduleOrderPopupTitle as="p">
            Returning
          </OrderDetailsScheduleOrderPopupTitle>
          <OrderDetailsScheduleOrderPopupTitleSpan as="span" onClick={() => onClose()} />
        </OrderDetailsScheduleOrderPopupTitleWrapper>
        <SectionDivider gap={16} withSeparator borderColor={themeContext.colors.grey004} />

        {/**
         * Tabs
         */}
        <TabsContainer>
          <TabContainer
            onClick={() => onClickPartialyReturn()}
            selected={selectedView === VIEWS.PARTIALY_RETURN}
          >
            <TabLabel>Partly Return</TabLabel>
          </TabContainer>
          <TabContainer
            onClick={() => onClickReturnAll()}
            selected={selectedView === VIEWS.TOTALLY_RETURN}
          >
            <TabLabel>Return All</TabLabel>
          </TabContainer>
        </TabsContainer>

        {/**
         * Table
         */}
        <ReturnTableContainer>
          <ReturnTableWrapper>
            <div
              style={{
                borderTop: '1px solid #e3e6ea',
              }}
            >
              <TableWithPagination
                tableColumnHeaders={tableHeadersLocalized}
                data={getMainRowItems(
                  dispensedOrderItemsReplica,
                  selectedView,
                  handleChangeItemQuantity,
                  dispensedOrderItems,
                )}
                language="en"
                extendTableGrid={extendTableColumnsStyle}
                extendTableRow={extendTableRow}
                extendTableHeaderRow={extendTableHeaderRow}
                extendMainItem={extendMainItem}
                extendAdditionalItem={extendAdditionalItem}
                extendHeaderRowItems={extendHeaderRowItems}
              />
            </div>
          </ReturnTableWrapper>
        </ReturnTableContainer>

        {/** Order Summary */}
        {getTotalReturn()}

        <SectionDivider withSeparator separatorGap={16} borderColor={themeContext.colors.grey004} />
        <OrderDetailsScheduleOrderBtnWrapper>
          <Button
            onClick={() => handleUpdateOrder()}
            key="order-update"
            btnText="Confirm Returning"
            extendButtonStyle={extendNextStateButtonStyle}
            isLoading={returnOrderLoadStatus === status.SUBMITING}
            extendLoaderStyle={extendLoaderStyle}
            disabled={disableConfirmReturnBtn()}
          />
        </OrderDetailsScheduleOrderBtnWrapper>
      </OrderDetailsReturnOrderPopupWrapper>
    </Popup>
  );
};

ReturnOrderModal.propTypes = {
  handleReturnOrder: PropTypes.func,
  returnOrderLoadStatus: PropTypes.string,
  isRetrunOrderPopupOpen: PropTypes.bool,
  themeContext: PropTypes.shape({
    colors: PropTypes.shape({
      grey004: PropTypes.string,
    }),
  }).isRequired,
  stringsData: PropTypes.shape({
    orderDetailsContent: PropTypes.shape({
      noDataPlaceholder: PropTypes.string,
      orderInvoiceFilterOriginal: PropTypes.string,
      orderInvoiceFilterDispensed: PropTypes.string,
      orderInvoiceFilterComparison: PropTypes.string,
      orderInvoiceGridHeader: PropTypes.shape({}),
      orderInvoiceSearchBarPlaceholder: PropTypes.string,
      orderInvoiceBtn: PropTypes.string,
      orderInvoiceFilterAlternatives: PropTypes.string,
    }),
    orderDetailsHeader: PropTypes.shape({
      rejectOrderTitle: PropTypes.string,
      scheduleOrderSaveBtn: PropTypes.string,
      scheduleOrderCancelBtn: PropTypes.string,
      cancelOrderReasonIsRequired: PropTypes.string,
      rejectOrderReasonTitle: PropTypes.string,
      scheduleOrderNoteTitle: PropTypes.string,
      scheduleOrderNotePlaceholder: PropTypes.string,
    }),
  }).isRequired,
  dispensedOrderItems: PropTypes.arrayOf(PropTypes.shape({})),
  onClose: PropTypes.func.isRequired,
  orderData: PropTypes.shape({
    orderStateTypeId: PropTypes.number,
    orderItems: PropTypes.arrayOf(PropTypes.shape({})),
    deliveryFee: PropTypes.number,
    orderDate: PropTypes.shape({
      orderElapsedTimeStr: PropTypes.string,
    }),
    promoCode: PropTypes.string,
    orderReceipt: PropTypes.shape({
      discountAmount: PropTypes.number,
      vezeetaCash: PropTypes.number,
    }),
    promoCodeStatus: PropTypes.number,
    hasPromoCodeAddedByPatient: PropTypes.bool,
  }).isRequired,
};

export default ReturnOrderModal;
