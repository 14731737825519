import styled from 'styled-components';

export const CheckboxGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 16px;
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 5px;
  border-radius: 4px;
  border: 3px solid #f1f4f6;
  color: #646464;
`;

export const Checkbox = styled.input`
  height: 15px;
  margin-right: 8px;
`;

export const Label = styled.label`
  font-size: 14px;
`;
