import stockActionTypes from './action-types';

export const fetchInvoices = payload => ({
  type: stockActionTypes.FETCH_INVOICES,
  payload,
});

export const fetchInvoicesSuccess = payload => ({
  type: stockActionTypes.FETCH_INVOICES_SUCCESS,
  payload,
});

export const fetchInvoicesFail = payload => ({
  type: stockActionTypes.FETCH_INVOICES_FAIL,
  payload,
});

export const toggleInvoiceModal = payload => ({
  type: stockActionTypes.TOGGLE_INVOICE_MODAL,
  payload,
});

export const setInvoice = payload => ({
  type: stockActionTypes.SET_INVOICE,
  payload,
});
