import { status } from 'constants/general';
import { initialPharmacyCommonState } from 'store/initialStates';
import commonActionTypes from './action-types';

const commonReducer = (state = initialPharmacyCommonState, { type, payload }) => {
  switch (type) {
    case commonActionTypes.SET_CONTEXT:
      return {
        ...state,
        context: payload,
      };

    case commonActionTypes.FETCH_ALL_PHARMACIES:
      return {
        ...state,
        pharmaciesList: {
          status: status.FETCHING,
        },
      };

    case commonActionTypes.FETCH_ALL_PHARMACIES_SUCCESS:
      return {
        ...state,
        pharmaciesList: {
          status: status.SUCCESS,
          details: payload,
        },
      };

    case commonActionTypes.FETCH_ALL_PHARMACIES_FAIL:
      return {
        ...state,
        pharmaciesList: {
          status: status.FAIL,
        },
      };

    case commonActionTypes.FETCH_ALL_COURIERS:
      return {
        ...state,
        couriersList: {
          status: status.FETCHING,
        },
      };

    case commonActionTypes.FETCH_ALL_COURIERS_SUCCESS:
      return {
        ...state,
        couriersList: {
          status: status.SUCCESS,
          details: payload,
        },
      };

    case commonActionTypes.FETCH_ALL_COURIERS_FAIL:
      return {
        ...state,
        couriersList: {
          status: status.FAIL,
        },
      };

    case commonActionTypes.FETCH_SERVICEABLE_AREAS:
      return {
        ...state,
        serviceableAreas: {
          status: status.FETCHING,
        },
      };

    case commonActionTypes.FETCH_SERVICEABLE_AREAS_SUCCESS:
      return {
        ...state,
        serviceableAreas: {
          status: status.SUCCESS,
          details: payload,
        },
      };

    case commonActionTypes.FETCH_SERVICEABLE_AREAS_FAIL:
      return {
        ...state,
        serviceableAreas: {
          status: status.FAIL,
        },
      };
    case commonActionTypes.FETCH_ALL_USERS:
      return {
        ...state,
        usersList: {
          status: status.FETCHING,
        },
      };

    case commonActionTypes.FETCH_ALL_USERS_SUCCESS:
      return {
        ...state,
        usersList: {
          status: status.SUCCESS,
          details: payload,
        },
      };

    case commonActionTypes.FETCH_ALL_USERS_FAIL:
      return {
        ...state,
        usersList: {
          status: status.FAIL,
        },
      };

    case commonActionTypes.FETCH_INSURANCE_PROVIDERS_BY_KEY:
      return {
        ...state,
        insuranceProviders: {
          status: status.FETCHING,
        },
      };

    case commonActionTypes.FETCH_INSURANCE_PROVIDERS_BY_KEY_SUCCESS:
      return {
        ...state,
        insuranceProviders: {
          status: status.SUCCESS,
          details: payload,
        },
      };

    case commonActionTypes.FETCH_INSURANCE_PROVIDERS_BY_KEY_FAIL:
      return {
        ...state,
        insuranceProviders: {
          status: status.FAIL,
        },
      };

    default:
      return state;
  }
};

export default commonReducer;
