import PropTypes from 'prop-types';
import Checkbox from 'modules/common/components/Checkbox';
import {
  invoiceGridItemsToSizeMap,
  orderInvoiceGridHeaderKeys,
  orderSuggestAlternatovesGridHeaderList,
} from 'modules/orders/OrdersView/OrdersContainer/OrderDetailsContainer/OrderDetailsComponent/helper';
import AlternativesSection from './AlternativesSection';
import {
  OrderDetailsInvoiceGridWrapper,
  OrderDetailsInvoiceGridHeader,
  OrderDetailsInvoiceGridItem,
  ItemImage,
  OrderDetailsInvoiceGridHeaderTitle,
  OrderDetailsInvoiceGridItemText,
} from '../../OrderInvoice.style';

const SuggestAlternativesTable = ({
  orderItems,
  enableEditInvoice,
  onClickItem,
  productsAlternatives,
  enableAddAlternatives,
  orderDetails,
}) => {
  // to exclude items added from search component in order preparation tab
  const orderItemsAlreadySetInInvoice = [];
  orderItems?.forEach(item => {
    const isItemFoundInOriginalInvoice =
      orderDetails?.details?.orderItems?.findIndex(
        originalInvoiceItem =>
          originalInvoiceItem.productKey === item.serviceKey &&
          originalInvoiceItem.productShapeId === item.productShapeId,
      ) !== -1;

    if (isItemFoundInOriginalInvoice) {
      orderItemsAlreadySetInInvoice.push(item);
    }
  });

  const invoiceGridSize = Object.keys(invoiceGridItemsToSizeMap)
    .map(itemSizeKey => invoiceGridItemsToSizeMap[itemSizeKey])
    .join(' ');
  return (
    <div>
      <OrderDetailsInvoiceGridWrapper invoiceGridSize={invoiceGridSize}>
        {orderInvoiceGridHeaderKeys.map(orderInvoiceGridHeaderKey => (
          <OrderDetailsInvoiceGridHeader
            key={orderInvoiceGridHeaderKey}
            pushToEnd={orderInvoiceGridHeaderKey === 'invoiceTotalGridHeader'}
          >
            <OrderDetailsInvoiceGridHeaderTitle>
              {orderSuggestAlternatovesGridHeaderList[orderInvoiceGridHeaderKey]}
            </OrderDetailsInvoiceGridHeaderTitle>
          </OrderDetailsInvoiceGridHeader>
        ))}
      </OrderDetailsInvoiceGridWrapper>

      {orderItemsAlreadySetInInvoice.map(item => {
        const productAlternativesObj = productsAlternatives.find(
          itemObj =>
            (itemObj.itemProductKey === item.orderItemKey ||
              itemObj.itemProductKey === item.serviceKey) &&
            itemObj.itemShapeId === item.productShapeId,
        );

        const onClickAppendingAlternativesToProduct = isChecked => {
          onClickItem(
            {
              productKey: item.serviceKey,
              orderItemKey: item.orderItemKey,
              productName: item.serviceName,
              productShapeId: item.productShapeId,
            },
            isChecked,
          );
        };

        const productAlternatives = productAlternativesObj?.alternatives;

        return (
          <div>
            <OrderDetailsInvoiceGridWrapper
              invoiceGridSize={invoiceGridSize}
              key={`${item.serviceKey}-${item.productShapeId}`}
            >
              {enableEditInvoice ? (
                <OrderDetailsInvoiceGridItem displayFlex startPadding endPadding>
                  {enableAddAlternatives ? (
                    <Checkbox
                      checked={!!productAlternativesObj}
                      key={item.serviceKey}
                      handleCheckboxClick={() =>
                        onClickAppendingAlternativesToProduct(!!productAlternativesObj)
                      }
                      checkboxObj={{
                        key: item.serviceKey,
                        name: (
                          <div
                            style={{
                              display: 'flex',
                              gap: '12px',
                            }}
                          >
                            <ItemImage src={item.mainImageUrl} alt={item.serviceName} />
                            <OrderDetailsInvoiceGridItemText>
                              {item.serviceName}
                            </OrderDetailsInvoiceGridItemText>
                          </div>
                        ),
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        gap: '12px',
                      }}
                    >
                      <ItemImage src={item.mainImageUrl} alt={item.serviceName} />
                      <OrderDetailsInvoiceGridItemText>
                        {item.serviceName}
                      </OrderDetailsInvoiceGridItemText>
                    </div>
                  )}
                </OrderDetailsInvoiceGridItem>
              ) : (
                <OrderDetailsInvoiceGridItem displayFlex startPadding endPadding>
                  <ItemImage src={item.mainImageUrl} alt={item.serviceName} />
                  <OrderDetailsInvoiceGridItemText>
                    {item.serviceName}
                  </OrderDetailsInvoiceGridItemText>
                </OrderDetailsInvoiceGridItem>
              )}
              <OrderDetailsInvoiceGridItem>
                <OrderDetailsInvoiceGridItemText>
                  {item?.price?.toFixed(2)}
                </OrderDetailsInvoiceGridItemText>
              </OrderDetailsInvoiceGridItem>
              <OrderDetailsInvoiceGridItem>
                <OrderDetailsInvoiceGridItemText>
                  {`${item.quantity} ${item.productShapeName}`}
                </OrderDetailsInvoiceGridItemText>
              </OrderDetailsInvoiceGridItem>

              <OrderDetailsInvoiceGridItem>
                <OrderDetailsInvoiceGridItemText>
                  {(item.price * (item.quantity || 1)).toFixed(2)}
                </OrderDetailsInvoiceGridItemText>
              </OrderDetailsInvoiceGridItem>
            </OrderDetailsInvoiceGridWrapper>
            {productAlternativesObj && (
              <AlternativesSection
                mainProduct={item.serviceName}
                alternativesItemsList={productAlternatives}
                appendAlternatives={() => onClickAppendingAlternativesToProduct()}
                enableAddAlternatives={enableAddAlternatives}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

SuggestAlternativesTable.propTypes = {
  enableAddAlternatives: PropTypes.bool,
  productsAlternatives: PropTypes.arrayOf(PropTypes.shape({})),
  onClickItem: PropTypes.func,
  enableEditInvoice: PropTypes.bool,
  orderDetails: PropTypes.shape({
    details: PropTypes.shape({
      orderItems: PropTypes.arrayOf({
        price: PropTypes.number,
      }),
    }),
  }),
  orderInvoiceGridHeaderList: PropTypes.shape({}).isRequired,
  orderItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  invoiceGridItemsToSizeMap: PropTypes.shape({}).isRequired,
};

export default SuggestAlternativesTable;
