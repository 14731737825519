/* eslint-disable no-use-before-define */
import styled, { css } from 'styled-components';
import themeData from 'utils/theme/themeData';

const { typography } = themeData;

export const StatusBadges = styled.p`
  color: #fff;
  background: #db3226;
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  border-radius: 4px;
  margin: 0;
`;

export const extendGrayButtonStyle = css`
  width: 14px;
  border-radius: 8px;
  border: 1px solid #d9dbdf;
  background: #f1f4f6;
  color: #484848;
  padding: 7px 10px;
`;

export const OrderDetailsInvoiceSection = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0px 2px 4px rgb(44 53 68 / 8%);

  ${({ padding }) =>
    padding &&
    `
    padding: 24px;
  `}
`;

export const OrderDetailsInvoiceTitle = styled(typography.TitleTwoTypography)`
  margin: 0;
  color: ${({ theme }) => theme.colors.black};
`;

export const OrderDetailsInvoiceHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey004};
`;

export const OrderDetailsInvoiceHeaderFilterWrapper = styled.div`
  display: flex;
  margin-inline-start: auto;

  & > * + * {
    margin-inline-start: 16px !important;
  }
`;

export const OrderDetailsInvoiceHeaderFilterItem = styled(typography.BodyTextOneRegularTypography)`
  margin: 0;
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;

  ${({ selected, theme }) =>
    selected &&
    `
    color: ${theme.colors.blueMain};
    text-decoration: underline;
  `}
`;

export const OrderDetailsInvoiceMultiGridWrapper = styled.div`
  display: flex;
  & > * + * {
    border-left: 1px solid ${({ theme }) => theme.colors.grey004};
  }
  flex-direction: ${({ direction }) => direction || 'row'};
`;

export const OrderDetailsInvoiceGridWrapper = styled.div`
  display: grid;
  grid-template: 1fr / ${({ invoiceGridSize }) => invoiceGridSize};
  flex-grow: ${({ disableFlexGrow }) => (disableFlexGrow ? '0' : '1')};
  height: fit-content;

  ${({ bigGridItemPadding }) =>
    bigGridItemPadding &&
    `
      ${OrderDetailsInvoiceGridItem} {
        padding-top: 16px;
        padding-bottom: 16px;
      }
  `}
`;

export const OrderDetailsInvoiceGridHeader = styled.div`
  margin: 0;
  padding: 12px 16px;
  color: ${({ theme }) => theme.colors.grey002};
  border: 1px solid #e3e6ea;
`;

export const OrderDetailsInvoiceGridHeaderTitle = styled.p`
  color: #484848;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
`;

export const OrderDetailsInvoiceGridItem = styled.div`
  padding: 12px 16px;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  border: 1px solid #e3e6ea;
  gap: 12px;
`;

export const OrderDetailsInvoiceGridItemText = styled.p`
  margin: auto 0;
  color: #484848;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  height: fit-content;
`;

export const OrderDetailsInvoiceGridItemIcon = styled.span`
  width: 20px;
  height: 20px;
  margin-inline-start: auto;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.redMain};
  border-radius: 50%;
  cursor: pointer;
`;

export const OrderDetailsInvoiceGridQuantityWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const OrderDetailsInvoiceGridChangeQuantityWrapper = styled.div`
  display: flex;
  padding: 2px;
  background-color: #fff;
  border: 1px solid #d9dbdf;
  border-radius: 8px;
`;

export const OrderDetailsInvoiceGridChangeQuantityValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  min-width: 50px;
  padding: 0 5px;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const OrderDetailsInvoiceGridChangeQuantityMinus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: ${({ theme }) => theme.colors.grey100};
  cursor: pointer;

  &:before {
    content: '\\e90e';
    font-family: 'Icomoon';
    font-size: 10px;
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const OrderDetailsInvoiceGridChangeQuantityPlus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: ${({ theme }) => theme.colors.grey100};
  cursor: pointer;

  &:before {
    content: '\\e910';
    font-family: 'Icomoon';
    font-size: 10px;
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const OrderDetailsInvoiceSearchBarWrapper = styled.div`
  position: relative;
`;

export const OrderDetailsInvoiceSearchBarInputWrapper = styled.div`
  flex-basis: 50%;
  position: relative;
`;

export const OrderDetailsInvoiceSearchBar = styled.input`
  width: 100%;
  height: 48px;
  padding: 14px 45px;
  font-size: 13px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey004};
  border-left-width: 0;
  border-right-width: 0;
  box-sizing: border-box;

  ::-webkit-input-placeholder {
    font-size: 13px;
    line-height: 20px;
    color: #7e7e7e;
  }
  ::-moz-placeholder {
    font-size: 13px;
    line-height: 20px;
    color: #7e7e7e;
  }
  :-ms-input-placeholder {
    font-size: 13px;
    line-height: 20px;
    color: #7e7e7e;
  }
  :-moz-placeholder {
    font-size: 13px;
    line-height: 20px;
    color: #7e7e7e;
  }

  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.grey004};
    border-left-width: 0;
    border-right-width: 0;
    outline: none;
  }
`;

export const OrderDetailsInvoiceSearchBarIcon = styled.i`
  position: absolute;
  top: 16px;
  left: 13px;
  font-style: normal;
  color: ${({ theme }) => theme.colors.grey002};

  &:before {
    content: '\\e932';
    font-family: 'Icomoon';
  }
`;

export const OrderDetailsInvoiceSearchBarEnvelop = styled.div`
  display: ${({ open }) => (open && 'block') || 'none'};
  position: absolute;
  width: 100%;
  height: 300px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  overflow: scroll;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none;
  z-index: 10;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const OrderDetailsInvoiceSearchBarLoaderWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const OrderDetailsInvoiceSearchBarNoDataText = styled(
  typography.BodyTextOneRegularTypography,
)`
  margin: 0;
  color: ${({ theme }) => theme.colors.black};
`;

export const OrderDetailsInvoiceSearchBarItemWrapper = styled.div`
  padding: 6px 16px;
  background-color: #fff;
  cursor: ${props => (props.isOutOFStock ? 'unset' : 'pointer')};
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};
  }
`;

export const OrderDetailsInvoiceSearchBarItem = styled(typography.BodyTextOneRegularTypography)`
  margin: 0px;
  color: ${({ theme }) => theme.colors.black};
`;

export const OrderDetailsInvoiceSearchBarItemSubText = styled(
  typography.BodyTextTwoRegularTypography,
)`
  margin: 0px;
  color: ${({ theme }) => theme.colors.grey002};
`;

export const OrderDetailsInvoiceStockTag = styled(typography.BodyTextThreeRegularTypography)`
  width: fit-content;
  margin: 0;
  padding: 2px 4px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.greenMain};
  border-radius: 4px;
`;

export const OrderDetailsInvoiceInfoWrapper = styled.div`
  display: flex;
  padding: 16px 24px;
`;

export const OrderDetailsInvoiceInfoFirstColumn = styled.div``;

export const OrderDetailsInvoiceInfoSecondColumn = styled.div`
  margin-inline-start: auto;
  text-align: right;
`;

export const OrderDetailsInvoiceInfoText = styled(typography.BodyTextOneRegularTypography)`
  margin: 0;
  color: ${({ theme }) => theme.colors.grey002};
`;

export const OrderDetailsInvoiceInfoBlackText = styled(typography.BodyTextOneRegularTypography)`
  margin: 0;
  color: ${({ theme }) => theme.colors.black};
`;

export const OrderDetailsInvoiceInfoBigText = styled(typography.TitleOneBoldTypography)`
  margin: 0;
  color: ${({ theme }) => theme.colors.black};
`;

export const OrderInvoiceInfoPromoCode = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const OrderDetailsInvoiceInfoIconText = styled(typography.BodyTextOneRegularTypography)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  color: ${({ theme, textColor }) => textColor || theme.colors.black};

  &:before {
    content: ${({ icon }) => icon};
    font-family: 'Icomoon';
    font-size: 15px;
    color: ${({ iconColor }) => iconColor};
    margin-inline-end: 8px;
  }
`;

export const OrderDetailsInvoiceBtnWrapper = styled.div`
  display: flex;
  border-top: 1px solid ${({ theme }) => theme.colors.grey004};
  justify-content: space-between;
`;

export const OrderDetailsInvoiceBtn = styled(typography.BodyTextOneRegularTypography)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  padding: 10px;
  margin: 16px 24px;
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.grey100};
  border: 1px solid ${({ theme }) => theme.colors.grey000};
  border-radius: 4px;
  cursor: pointer;
`;

export const extendOrderDetailsInvoiceLoaderStyle = css`
  margin: auto;
`;

export const InvalidPromoCodeText = styled(typography.BodyTextOneRegularTypography)`
  margin: 0;
  margin-left: 2px;
  color: ${({ theme }) => theme.colors.redMain};
`;

export const OrderInvoiceRemovePromoCodeItemIcon = styled(typography.TitleOneBoldTypography)`
  color: ${({ theme }) => theme.colors.redMain};
  cursor: pointer;
  padding: 0 5px;
`;

export const InvoicesContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey004};
`;

export const InvioceHeaderTitlesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  & > * + * {
    border-left: 1px solid ${({ theme }) => theme.colors.grey004};
  }
`;

export const InvoiceHeaderTitle = styled(typography.TitleTwoTypography)`
  display: flex;
  align-items: center;
  width: 425px;
  color: ${({ theme }) => theme.colors.black};
  padding: 8px 0 8px 24px;
`;

export const RemovedItemText = styled(typography.BodyTextOneRegularTypography)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: red;
  width: 100%;
`;

export const ItemImage = styled.img`
  border-radius: 8px;
  width: 40px;
  height: 40px;
  margin: auto 0;
`;

export const TabContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  :first-child {
    border-radius: 8px 0px 0px 8px;
  }

  :last-child {
    border-radius: 0px 8px 8px 0px;
  }

  ${props =>
    !props.selected
      ? `
    color: #484848;
    cursor: pointer;
    border: 1px solid #D9DBDF;

  `
      : `
    color: #0070cd;
    background: #E5F1FF;
    border: 1px solid #0070CD;
  `}
`;

export const TabLabel = styled.p`
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  text-align: center;
`;

export const TabsContainer = styled.div`
  display: flex;
  width: 304px;
  height: 36px;
  margin: 12px 16px;
`;
