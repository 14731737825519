import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 16px;
  position: relative;
`;

export const Wrapper = styled.div`
  border-radius: 8px;
  border: ${props =>
    props.isAlternativesNotAvailable ? '1px solid #7E7E7E' : '1px solid #0070cd'};
  height: 100%;
  width: 100%;
`;

export const HeaderContainer = styled.div`
  background: ${props => (props.isAlternativesNotAvailable ? '#7E7E7E' : '#0070cd')};
  padding: 8px 16px;
  border-radius: 8px 8px 0px 0px;
`;

export const HeaderContainerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HeaderContainerTitle = styled.p`
  margin: 0;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
`;

export const EditIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
`;

export const extendIcon = css`
  color: #ffff;
`;

export const PopUpArrow = styled.div`
  position: absolute;
  top: -35px;
  left: 36px;
  width: 30px;
  height: 25px;
  color: #0070cd;
  ::after {
    border-style: solid;
    border-width: 15px;
    content: '';
    position: absolute;
    border-color: ${props =>
      props.isAlternativesNotAvailable
        ? 'transparent transparent #7E7E7E transparent'
        : 'transparent transparent #0070cd transparent'};
    margin-left: -5px;
    top: 0px;
    width: 0px;
    height: 25px;
  }
`;
