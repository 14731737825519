import { get, patch, post, put } from 'utils/functions/request';
import urls from 'utils/urls';

export const getAllOrders = data =>
  get(
    null,
    false,
    `${urls.pharmacyModuleUrls.ordersUrls.getAllOrders}?&Includes=Partnership.PartnershipType`,
    data,
  );

export const getOrderDetails = ({ orderId }) =>
  get(null, false, `${urls.pharmacyModuleUrls.ordersUrls.getOrderDetails}/${orderId}`);

export const getOrderPatientUserDetails = ({ patientUserKey }) =>
  get(
    null,
    false,
    `${urls.pharmacyModuleUrls.ordersUrls.getOrderPatientUserDetails}/${patientUserKey}`,
  );

export const getOrderStatusTypes = () =>
  get(null, false, urls.pharmacyModuleUrls.ordersUrls.getOrderStatusTypes);

export const getPartnershipTypes = data =>
  get(
    null,
    false,
    `${urls.pharmacyModuleUrls.ordersUrls.getPartnershipTypes}?Includes=PartnershipTypeTranslators`,
    data,
  );

export const getDetailedReasons = data =>
  get(null, false, urls.pharmacyModuleUrls.ordersUrls.getOrderStateReasonsTree, data);

export const updateOrderStatus = ({ data }) =>
  post(null, false, urls.pharmacyModuleUrls.ordersUrls.updateOrderStatus, data);

export const updateOrderNote = ({ orderId, data }) =>
  patch(null, false, `${urls.pharmacyModuleUrls.ordersUrls.updateOrderNote}/${orderId}`, data);

export const updateOrderItems = ({ orderId, data }) =>
  put(
    null,
    false,
    `${urls.pharmacyModuleUrls.ordersUrls.updateOrderItems}?orderId=${orderId}`,
    data.orderItems,
  );
export const assignOrderToEntity = ({ data, query }) => {
  const orderKey = `orderId=${query.orderKey}`;
  const assignedEntityType = `&ActionByEntityTypeId=${query.ActionByEntityTypeId}`;
  const assignedEntityKey = `&ActionByEntityKey=${query.ActionByEntityKey}`;
  return post(
    null,
    false,
    `${urls.pharmacyModuleUrls.ordersUrls.assignOrderToEntity}?${orderKey}${assignedEntityType}${assignedEntityKey}`,
    data,
  );
};

export const scheduleOrder = ({ orderId, data }) =>
  post(null, false, `${urls.pharmacyModuleUrls.ordersUrls.getAllOrders}/${orderId}/status`, data);

export const getScheduleOrderReasons = () =>
  get(null, false, `${urls.pharmacyModuleUrls.ordersUrls.getOrderStateReasonsTree}?stateId=16`);

export const getCancelOrderReasons = () =>
  get(null, false, `${urls.pharmacyModuleUrls.ordersUrls.getOrderStateReasonsTree}?stateId=5`);

export const getRejectOrderReasons = () =>
  get(
    null,
    false,
    `${urls.pharmacyModuleUrls.ordersUrls.getOrderStateReasonsTree}?stateId=8&stateId=15`,
  );

export const getProductStocksAllocation = ({ productKeys }) => {
  const query = `productKeys=${productKeys}`;
  return get(
    null,
    false,
    `${urls.pharmacyModuleUrls.ordersUrls.getProductStocksAllocation}?${query}`,
  );
};

export const updateShippingAddress = ({ addressKey, data }) =>
  patch(
    null,
    false,
    `${urls.pharmacyModuleUrls.ordersUrls.updateShippingAddress}/${addressKey}`,
    data,
  );

export const getOrderItemsAlternatives = ({ orderProductKey }) => {
  const queryParams = 'pageSize=4';
  const getProductsUrl = urls.pharmacyModuleUrls.ordersUrls.getProducts;
  return get(
    null,
    false,
    `${getProductsUrl}/${orderProductKey}${urls.pharmacyModuleUrls.ordersUrls.productAlternatives}?${queryParams}`,
  );
};

export const submitOrderItemsAlternatives = ({ orderKey, recommendedAlternatives }) => {
  const updateOrderUrl = urls.pharmacyModuleUrls.ordersUrls.updateOrder;
  return post(
    null,
    false,
    `${updateOrderUrl}/${orderKey}${urls.pharmacyModuleUrls.ordersUrls.productAlternatives}`,
    { recommendedAlternatives },
  );
};

export const getOrderItemsSuggestedAlternatives = ({ orderKey }) => {
  const updateOrderUrl = urls.pharmacyModuleUrls.ordersUrls.updateOrder;
  return get(
    null,
    false,
    `${updateOrderUrl}/${orderKey}${urls.pharmacyModuleUrls.ordersUrls.productAlternatives}`,
  );
};

export const confirmOrder = payload =>
  post(
    null,
    false,
    `${urls.pharmacyModuleUrls.ordersUrls.updateOrder}/${payload.orderKey}/confirm`,
    payload.data,
  );

export const returnOrder = payload =>
  post(
    null,
    false,
    `${urls.pharmacyModuleUrls.ordersUrls.updateOrder}/${payload.orderKey}/return`,
    payload.data,
  );

export const getOrderRecommendedAlternatives = payload => {
  const updateOrderUrl = urls.pharmacyModuleUrls.ordersUrls.updateOrder;
  return get(
    null,
    false,
    `${updateOrderUrl}/${payload}${urls.pharmacyModuleUrls.ordersUrls.productAlternatives}`,
  );
};
