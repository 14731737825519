import styled from 'styled-components';

export const OrdersFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const OrdersFilterSearchBarWrapper = styled.div`
  padding: 0 12px;
  position: relative;
`;

export const OrdersFilterSearchBar = styled.input`
  width: 100%;
  height: 36px;
  padding: 8px 36px;
  font-size: 13px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.grey002};
  background-color: ${({ theme }) => theme.colors.grey100};
  border: none;
  box-sizing: border-box;

  border-radius: 8px;
  border: 1px solid var(--grey-300, #d9dbdf);
  background: var(--white, #fff);

  ::-webkit-input-placeholder {
    font-size: 13px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.grey002};
  }
  ::-moz-placeholder {
    font-size: 13px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.grey002};
  }
  :-ms-input-placeholder {
    font-size: 13px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.grey002};
  }
  :-moz-placeholder {
    font-size: 13px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.grey002};
  }

  &:focus {
    border: 1px solid #197ed2;
    outline: none;
  }
`;

export const OrdersFilterSearchBarIcon = styled.i`
  position: absolute;
  top: 9px;
  left: 20px;
  color: ${({ theme }) => theme.colors.grey002};
  font-style: normal;

  &:before {
    content: '\\e927';
    font-family: 'Icomoon';
  }
`;

export const OrdersFilterClearIcon = styled.i`
  position: absolute;
  top: 9px;
  right: 20px;
  color: ${({ theme }) => theme.colors.grey002};
  font-style: normal;
  cursor: pointer;

  &:before {
    content: '\\e93d';
    font-family: 'Icomoon';
  }
`;

export const MoreFiltersButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin-left: 10px;
  padding: 10px 12px;
  color: ${({ theme }) => theme.colors.black};
  border: 1px solid ${({ theme }) => theme.colors.grey003};
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  font-family: 'Lato';
  font-weight: 400;
  font-size: 13px;
  white-space: nowrap;

  ${({ selected, theme }) =>
    selected &&
    `
    border-color: ${theme.colors.blueMain};
    background-color: ${theme.colors.blueLight};
    color: ${theme.colors.blueMain};
  `}
`;

export const MoreFitlersClearIcon = styled.div`
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.blueMain};

  &:before {
    content: '\\e91f';
    font-family: 'Icomoon';
  }
  &:hover {
    color: ${({ theme }) => theme.colors.redMain || ''};
    transition: all 150ms ease-in-out;
  }
`;
