import styled from 'styled-components';

export const PaginationConatiner = styled.div`
  margin: 30px auto;
  // text-align: center;
  direction: ${props => (props.theme && props.theme.direction ? 'rtl' : props.theme.direction)};
  ${props => (props.extendContainerStyle ? props.extendContainerStyle : '')};
  ${props => props.theme.media.phone`
        display: ${props.desktop ? 'none' : 'inherit'}
    `}
  ${props => props.theme.media.tablet`
        display: ${props.desktop ? 'inherit' : 'none'}
    `}
    ${props => props.theme.media.desktop`
        display: ${props.desktop ? 'inherit' : 'none'}
    `}
`;

export const PaginationItem = styled.div`
  width: 32px;
  height: 32px;
  text-align: center;
  font-size: 14px;
  line-height: 33px;
  display: inline-grid;
  vertical-align: top;
  margin: 0px 2px;
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  cursor: ${props => (props.selected || props.disabled ? 'default' : 'pointer')};
  ${props => props.theme.media.tablet`
        width: 42px;
        height: 42px;
        font-size: 18px;
        line-height: 42px;
        margin: 0px 4px;
    
    `};
  ${props => props.theme.media.desktop`
        width: 32px;
        height: 32px;
        font-size: 22px;
        line-height: 33px;
        margin: 0px 7px;
    `};
`;
export const PaginationBox = styled(PaginationItem)`
  color: ${props => (props.selected ? props.background : props.color)};
  background: ${props => (props.selected ? props.color : props.background)};
  border-radius: 4px;
  // border: 1px solid #d1d1d1;
  font-size: 14px;
  line-height: 33px;
  color: #484848;
  &:hover {
    ${props => (props.selected || props.disabled ? '' : 'background: #f2f6f9a6;')};
  }
  ${props => (props.extendPageTag ? props.extendPageTag : '')};
`;

export const PaginationDots = styled(PaginationItem)`
  color: #6f7072;
`;

export const PaginationItemArrow = styled(PaginationBox)`
  opacity: ${props => (props.disabled ? '0.5' : '1')};
  &:after {
    color: white;
    font-weight: ${props => (props.openSymbol ? 'normal' : 'bold')};
    float: left;
    margin-left: 5px;
    margin-right: 5px;
    color: ${props => (props.theme.primary ? props.theme.primary : '#0070cd')};
    font-family: 'icomoon' !important;
    transform: ${props => (props.theme.direction === 'ltr' ? 'rotate(180deg)' : 'unset')};
    font-size: 18px;
    ${props => props.theme.media.tablet`
                font-size: 15px;
            `};
    ${props => props.theme.media.phone`
                font-size: 12px;
            `};
  }
  ${props => (props.extendPageNumberBoxStyle ? props.extendPageNumberBoxStyle : '')};
`;

export const PaginationItemNext = styled(PaginationItemArrow)`
  ${props => (props.extendArrowsBoxStyle ? props.extendArrowsBoxStyle : '')};
  ${props => (props.extendPageTag ? props.extendPageTag : '')};
`;

export const PaginationItemPrevious = styled(PaginationItemArrow)`
  ${props => (props.extendArrowsBoxStyle ? props.extendArrowsBoxStyle : '')};
  ${props => (props.extendPageTag ? props.extendPageTag : '')};
`;

export const TransparentA = styled.a`
  color: transparent;

  ${props =>
    !props.disabled
      ? ''
      : `
        pointer-events: none;
        cursor: default;`};
`;
