import OrdersView from 'modules/orders/OrdersView';
import MainContainer from 'modules/common/MainContainer';

const OrdersPage = () => (
  <MainContainer context="Orders">
    <OrdersView />
  </MainContainer>
);

OrdersPage.propTypes = {};

OrdersPage.defaultProps = {};

export default OrdersPage;
