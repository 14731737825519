import PropTypes from 'prop-types';

import { PopupWrapper, PopupContentWrapper, PopupCloseIcon } from './Popup.style';

const Popup = ({ children, showCloseBtn, handleCloseBtnClick }) => (
  <PopupWrapper>
    <PopupContentWrapper data-testid="popup-wrapper">
      {showCloseBtn && (
        <PopupCloseIcon data-testid="close-popup" onClick={() => handleCloseBtnClick()} />
      )}
      {children}
    </PopupContentWrapper>
  </PopupWrapper>
);

Popup.propTypes = {
  children: PropTypes.node.isRequired,
  showCloseBtn: PropTypes.bool,
  handleCloseBtnClick: PropTypes.func,
};

Popup.defaultProps = {
  showCloseBtn: true,
  handleCloseBtnClick: () => {},
};

export default Popup;
