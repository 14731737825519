/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { TABLE_VIEWS } from 'modules/orders/OrdersView/OrdersContainer/OrderDetailsContainer/OrderDetailsComponent/helper';
import InvoiceTable from './InvoiceTable';
import SuggestAlternativesTable from './SuggestAlternativesTable';

export const InvoiceTableContainer = props => {
  const {
    dispensedOrderItems,
    enableEditInvoice,
    handleInvoiceItemCheckToBeConfirmed,
    checkedItemToBeConfirmed,
    orderTableMode,
    setSuggestAllternativeModal,
    productsAlternatives,
    alternativesRecommendations,
    onRemoveProductAlternatives,
    orderDetails,
  } = props;

  const enableAddAlternatives = !(alternativesRecommendations?.length > 0);

  const adaptAlternativesView = () => {
    let adaptedAlternaivesList = [];
    if (enableAddAlternatives) {
      // Consume Added Alternatives from Component State
      adaptedAlternaivesList = productsAlternatives;
    } else {
      // Consume ALREADY Add Alternatives (Sent to Patient) from Order Details
      const tempAdaptedAlternaivesList = [];
      alternativesRecommendations?.forEach(item => {
        const { recommendedAlternatives } = item;
        const tempRecommendedAlternatives = [];
        recommendedAlternatives?.forEach(alternative => {
          const { alternativeProductShapeTypeTranslators, alternativeProductTranslators } =
            alternative;

          const productName = alternativeProductTranslators.find(
            translator => translator.lang === 'en',
          )?.name;

          const productShapeName = alternativeProductShapeTypeTranslators.find(
            translator => translator.lang === 'en',
          )?.name;

          tempRecommendedAlternatives.push({
            itemImage: alternative.alternativeProductImageUrl,
            itemName: productName,
            itemPrice: alternative.alternativePrice,
            itemProductKey: alternative.alternativeProductKey,
            itemQuantity: alternative.alternativeQuantity,
            itemShapeId: alternative.alternativeShapeId,
            itemShapeName: productShapeName,
          });
        });

        tempAdaptedAlternaivesList.push({
          itemShapeId: item.currentShapeId,
          itemProductKey: item.currentProductKey,
          alternatives: tempRecommendedAlternatives,
        });
        adaptedAlternaivesList = tempAdaptedAlternaivesList;
      });
    }
    return adaptedAlternaivesList;
  };

  return (
    <div>
      {orderTableMode === TABLE_VIEWS.SUGGEST_ALTERNATIVES ? (
        <SuggestAlternativesTable
          {...props}
          enableAddAlternatives={enableAddAlternatives}
          orderItems={dispensedOrderItems}
          orderDetails={orderDetails}
          productsAlternatives={adaptAlternativesView()}
          enableEditInvoice={enableEditInvoice}
          onClickItem={(payload, isChecked) => {
            if (isChecked) {
              onRemoveProductAlternatives(payload);
            } else {
              setSuggestAllternativeModal({
                isOpened: true,
                productKey: payload.productKey,
                orderItemKey: payload.orderItemKey,
                productName: payload.productName,
                productShapeId: payload.productShapeId,
              });
            }
          }}
        />
      ) : (
        <InvoiceTable
          {...props}
          servicesList={dispensedOrderItems}
          enableEditInvoice={enableEditInvoice}
          handleInvoiceItemCheckToBeConfirmed={handleInvoiceItemCheckToBeConfirmed}
          checkedItemToBeConfirmed={checkedItemToBeConfirmed}
        />
      )}
    </div>
  );
};
InvoiceTableContainer.propTypes = {
  handleInvoiceItemCheckToBeConfirmed: PropTypes.func,
  checkedItemToBeConfirmed: PropTypes.arrayOf(PropTypes.string),
  enableEditInvoice: PropTypes.bool,
  dispensedOrderItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  orderTableMode: PropTypes.bool,
  setSuggestAllternativeModal: PropTypes.func,
  productsAlternatives: PropTypes.arrayOf(
    PropTypes.shape({
      itemShapeId: PropTypes.number,
      itemProductKey: PropTypes.string,
      alternatives: PropTypes.arrayOf(
        PropTypes.shape({
          itemImage: PropTypes.string,
          itemName: PropTypes.string,
          itemPrice: PropTypes.number,
          itemProductKey: PropTypes.string,
          itemQuantity: PropTypes.number,
          itemShapeId: PropTypes.number,
          itemShapeName: PropTypes.string,
        }),
      ),
    }),
  ),
  orderDetails: PropTypes.shape({}),
  alternativesRecommendations: PropTypes.arrayOf(PropTypes.shape({})),
  onRemoveProductAlternatives: PropTypes.func,
};

export default InvoiceTableContainer;
