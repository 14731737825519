import styled from 'styled-components';

export const SearchBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 38px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grey100};
  border-radius: 4px;
  position: relative;
`;

export const SearchInputContainer = styled.div`
  min-height: 36px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 2px 15px;
  width: 95%;
`;

export const SearchInput = styled.input`
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.grey002};
  font-weight: 500;
  border-radius: 6px;
  background-color: transparent;
  margin: 0px 5px;
  &:focus {
    outline: none;
    &::placeholder {
      opacity: 0;
    }
  }
  &::placeholder {
    color: ${({ theme }) => theme.colors.grey002};
    transition: all 250ms ease-in-out;
  }
`;

export const SearchBarSearchIcon = styled.i`
  font-style: normal;
  color: ${({ theme }) => theme.colors.grey002};
  &:before {
    content: '\\e905';
    font-family: 'Icomoon';
  }
`;

export const SearchBarClearIcon = styled.i`
  font-style: normal;
  color: ${({ theme }) => theme.colors.grey002};
  cursor: pointer;

  &:before {
    content: '\\e93d';
    font-family: 'Icomoon';
  }

  &:hover {
    color: ${({ theme }) => theme.colors.redMain};
    transition: all 150ms ease-in-out;
  }
`;

export const SearchContent = styled.div`
  position: absolute;
  top: 42px;
  width: 100%;
  max-height: 155px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 1;
  border-radius: 4px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);

  &::-webkit-scrollbar-thumb {
    background: #e2dede;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.white};
    border-radius: 6px;
  }
`;

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
`;

export const WarningMessage = styled.span`
  color: #a1a1a1;
  font-size: 14px;
  display: flex;
  align-self: center;
  justify-self: center;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px 16px;
  font-size: 13px;
  line-height: 20px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};
  }
`;

export const ItemName = styled.span`
  color: ${({ theme }) => theme.colors.black};
`;

export const ItemInfo = styled.span`
  color: ${({ theme }) => theme.colors.grey002};
`;
