/* eslint-disable class-methods-use-this */
/* eslint-disable lines-between-class-members */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import {
  PaginationConatiner,
  PaginationBox,
  PaginationItemNext,
  PaginationItemPrevious,
  PaginationDots,
  TransparentA,
} from './Pagination.style';

class Pagination extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      colorFallback: '#EEF0F2',
      backgroundFallback: '#fff',
    };
  }

  setPage = no => {
    const newPageNo = no;
    if (!newPageNo || newPageNo > this.props.totalNumberOfPages || newPageNo < 1) return;
    this.props.setPage(newPageNo);
  };

  toIndiaDigits = number => {
    const id = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
    return number.replace(/[0-9]/g, w => id[+w]);
  };

  renderMobilePagination() {
    return this.renderPagination(4, 5, 1, 1);
  }
  renderDesktopPagination() {
    return this.renderPagination(6, 8, 2, 3, true);
  }

  renderPagination(
    startDotsAt,
    maxTotalPagesNumberWithoutDots,
    pagesAfterSelectedPage,
    pagesBeforeSelectedPage,
    desktop,
  ) {
    const { extendContainerStyle, extendArrowsBoxStyle, getPaginationHref, extendPageTag } =
      this.props;
    let { IDForAutomatedTesting } = this.props;
    if (!desktop) IDForAutomatedTesting += '-mobile';
    const { totalNumberOfPages, selectedPage } = this.props;
    const color = this.props.color ? this.props.color : this.state.colorFallback;
    const background = this.props.background
      ? this.props.background
      : this.state.backgroundFallback;
    const items = [];
    const dotsAtTheBegining =
      totalNumberOfPages > maxTotalPagesNumberWithoutDots && selectedPage >= startDotsAt;
    const dotsAtTheEnd =
      totalNumberOfPages > maxTotalPagesNumberWithoutDots &&
      selectedPage < totalNumberOfPages - pagesAfterSelectedPage - 1;
    const startingIndex = dotsAtTheBegining
      ? dotsAtTheEnd
        ? selectedPage - pagesBeforeSelectedPage
        : totalNumberOfPages - startDotsAt + 1
      : 2;
    const endingIndex = dotsAtTheEnd
      ? dotsAtTheBegining
        ? selectedPage + pagesAfterSelectedPage
        : startDotsAt
      : totalNumberOfPages - 1;

    for (let i = startingIndex; i <= endingIndex; i += 1) {
      items.push(this.renderPaginationBox(i, null, IDForAutomatedTesting));
    }

    return (
      <PaginationConatiner desktop={desktop} extendContainerStyle={extendContainerStyle}>
        <TransparentA
          as="a"
          aria-label="navigate to page previous number"
          disabled={selectedPage === 1}
          href={getPaginationHref && getPaginationHref(selectedPage - 1)}
        >
          <PaginationItemPrevious
            extendPageTag={extendPageTag}
            id={`${IDForAutomatedTesting}__Pagination-page--Previous`}
            data-testid="page-previous"
            key="Pagination_Prev"
            color={color}
            extendArrowsBoxStyle={extendArrowsBoxStyle}
            background={background}
            disabled={selectedPage === 1}
            onClick={() => {
              if (!getPaginationHref && !(selectedPage === 1)) this.setPage(selectedPage - 1);
            }}
          >
            {' '}
            {'<'}{' '}
          </PaginationItemPrevious>
        </TransparentA>
        {this.renderPaginationBox(1, null, IDForAutomatedTesting)}
        {!dotsAtTheBegining
          ? ''
          : this.renderPaginationBox(startingIndex - 1, true, IDForAutomatedTesting)}
        {items}
        {!dotsAtTheEnd
          ? ''
          : this.renderPaginationBox(endingIndex + 1, true, IDForAutomatedTesting)}
        {!totalNumberOfPages > 2
          ? ''
          : this.renderPaginationBox(totalNumberOfPages, null, IDForAutomatedTesting)}

        <TransparentA
          as="a"
          aria-label="navigate to page next number"
          disabled={selectedPage === totalNumberOfPages}
          href={getPaginationHref && getPaginationHref(selectedPage + 1)}
        >
          <PaginationItemNext
            extendPageTag={extendPageTag}
            id={`${IDForAutomatedTesting}__Pagination-page--next`}
            data-testid="page-next"
            key="Pagination_next"
            color={color}
            extendArrowsBoxStyle={extendArrowsBoxStyle}
            background={background}
            disabled={selectedPage === totalNumberOfPages}
            onClick={() => {
              if (!getPaginationHref && !(selectedPage === totalNumberOfPages))
                this.setPage(selectedPage + 1);
            }}
          >
            {' '}
            {'>'}{' '}
          </PaginationItemNext>
        </TransparentA>
      </PaginationConatiner>
    );
  }

  renderPaginationBox(pageNo, dots, IDForAutomatedTesting) {
    const { extendPageNumberBoxStyle, getPaginationHref, extendPageTag, language } = this.props;
    const color = this.props.color ? this.props.color : this.state.colorFallback;
    const background = this.props.background
      ? this.props.background
      : this.state.backgroundFallback;
    const { selectedPage } = this.props;
    const selected = selectedPage === pageNo;
    return (
      <TransparentA
        as={selected ? 'span' : 'a'}
        aria-label={`navigate to page number ${pageNo}`}
        href={getPaginationHref && !selected && getPaginationHref(pageNo)}
        key={`Pagination_Page_${pageNo}`}
      >
        {dots ? (
          <PaginationDots
            id={`${IDForAutomatedTesting}__Pagination--dots-page-no--${pageNo}`}
            data-testid={`page-${pageNo}`}
            color={color}
            extendPageNumberBoxStyle={extendPageNumberBoxStyle}
            background={background}
            selected={selected}
            // onClick={()=>{!getPaginationHref ? this.setPage(pageNo) : null}}
          >
            ...
          </PaginationDots>
        ) : (
          <PaginationBox
            id={`${IDForAutomatedTesting}__Pagination-page-no--${pageNo}`}
            data-testid={`page-${pageNo}`}
            color={color}
            extendPageNumberBoxStyle={extendPageNumberBoxStyle}
            background={background}
            selected={selected}
            extendPageTag={extendPageTag}
            onClick={() => (!getPaginationHref && !selected ? this.setPage(pageNo) : null)}
          >
            {language === 'ar' ? this.toIndiaDigits(pageNo.toString()) : pageNo.toString()}
          </PaginationBox>
        )}
      </TransparentA>
    );
  }

  render() {
    return (
      <div style={{ borderTop: '1px solid #EEF0F2' }}>
        {this.renderMobilePagination()}
        {this.renderDesktopPagination()}
      </div>
    );
  }
}

Pagination.propTypes = {
  totalNumberOfPages: PropTypes.number,
  IDForAutomatedTesting: PropTypes.string,
  setPage: PropTypes.func,
  getPaginationHref: PropTypes.func,
  selectedPage: PropTypes.number,
  color: PropTypes.string,
  background: PropTypes.string,
  extendContainerStyle: PropTypes.string,
  extendPageNumberBoxStyle: PropTypes.string,
  extendArrowsBoxStyle: PropTypes.string,
  language: PropTypes.string,
  extendPageTag: PropTypes.object,
};

Pagination.defaultProps = {
  IDForAutomatedTesting: '',
  totalNumberOfPages: 1,
  selectedPage: 1,
};

export default Pagination;
