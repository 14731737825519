import PropTypes from 'prop-types';

import RadioButton from './RadioButton';

import { RadioButtonGroupWrapper } from './RadioButtonGroup.style';

const RadioButtonGroup = ({ RadioButtonGroupArr, selectedRadioButton, handleRadioButtonClick }) => (
  <RadioButtonGroupWrapper>
    {RadioButtonGroupArr.map(radioButtonObj => (
      <RadioButton
        key={radioButtonObj.key}
        RadioButtonObj={radioButtonObj}
        checked={radioButtonObj.key === selectedRadioButton}
        handleRadioButtonClick={handleRadioButtonClick}
      />
    ))}
  </RadioButtonGroupWrapper>
);

RadioButtonGroup.propTypes = {
  RadioButtonGroupArr: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  selectedRadioButton: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  handleRadioButtonClick: PropTypes.func.isRequired,
};

export default RadioButtonGroup;
