import styled from 'styled-components';

export const SnackBarContainer = styled.div`
  position: fixed;
  bottom: 40px;
  left: 50%;
  margin-left: -10px;
  margin-bottom: ${props => (props.show ? '40px' : '-90px')};
  transition: margin 200ms ease-in-out;
  padding: 15px 30px;
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  z-index: 100;
`;
