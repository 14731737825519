const invoicesActionTypes = {
  FETCH_INVOICES: 'FETCH_INVOICES',
  FETCH_INVOICES_SUCCESS: 'FETCH_INVOICES_SUCCESS',
  FETCH_INVOICES_FAIL: 'FETCH_INVOICES_FAIL',

  TOGGLE_INVOICE_MODAL: 'TOGGLE_INVOICE_MODAL',

  SET_INVOICE: 'SET_INVOICE',
};

export default invoicesActionTypes;
