import Proptypes from 'prop-types';
import { LoaderContainer } from './Loader.style';

const Loader = ({ extendStyle, type, ...props }) => (
  <LoaderContainer extendStyle={extendStyle} type={type || 'spinner'} {...props}>
    <div className="first" />
    <div className="second" />
  </LoaderContainer>
);

Loader.propTypes = {
  extendStyle: Proptypes.oneOfType([Proptypes.string, Proptypes.array]),
  type: Proptypes.string,
};

Loader.defaultProps = {
  extendStyle: '',
  type: 'spinner',
};

export default Loader;
