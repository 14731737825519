import { takeLatest, call, put, select } from 'redux-saga/effects';
import { addPaddingZerosToNum } from 'utils/functions/general';
import {
  getDateTimeObjFromDateTimeStr,
  getDateDetailsFromDateTimeObj,
  getTimeDetailsFromDateTimeObj,
  getElapsedTimeFromDateTimeObj,
  checkIfTwoDatesAreEqual,
} from 'utils/functions/dateTime';
import { status } from 'constants/general';
import {
  fetchAllCouriersFail,
  fetchAllCouriersSuccess,
  fetchAllPharmacyFail,
  fetchAllUsersFail,
  fetchAllUsersSuccess,
} from 'modules/common/actions';
import { userRoleEnum } from 'modules/common/enums';
import { getProductItems, getUsers, searchProductItems } from 'modules/common/api';
import {
  assignOrderToEntity,
  getAllOrders,
  getCancelOrderReasons,
  getDetailedReasons,
  getOrderDetails,
  getOrderItemsAlternatives,
  getOrderPatientUserDetails,
  getOrderRecommendedAlternatives,
  getOrderStatusTypes,
  getPartnershipTypes,
  getProductStocksAllocation,
  getRejectOrderReasons,
  getScheduleOrderReasons,
  scheduleOrder,
  submitOrderItemsAlternatives,
  updateOrderItems,
  updateOrderNote,
  updateOrderStatus,
  updateShippingAddress,
  confirmOrder,
  returnOrder,
  getOrderItemsSuggestedAlternatives,
} from './api';
import {
  fetchAllOrdersSuccess,
  fetchAllOrdersFail,
  // setAllOrdersAssignedEntityDetails,
  fetchPartnershipTypesSuccess,
  fetchPartnershipTypesFail,
  fetchDetailedReasonsSuccess,
  fetchDetailedReasonsFail,
  fetchOrderStatusTypesSuccess,
  fetchOrderStatusTypesFail,
  fetchOrderDetailsSuccess,
  setOrderPatientUserDetails,
  setOrderAssignedEntityDetails,
  fetchOrderDetailsFail,
  fetchOrderNoteChangeSubmissionSuccess,
  fetchOrderNoteChangeSubmissionFail,
  assignOrderToEntitySuccess,
  assignOrderToEntityFail,
  scheduleOrderSubmissionSuccess,
  scheduleOrderSubmissionFail,
  fetchScheduleOrderReasonsSuccess,
  fetchScheduleOrderReasonsFail,
  fetchCancelOrderReasonsSuccess,
  fetchCancelOrderReasonsFail,
  fetchOrderStatusChangeSubmissionSuccess,
  fetchOrderStatusChangeSubmissionFail,
  setOrderItemsDetails,
  fetchOrderItemsSuggestionsSuccess,
  fetchOrderItemsSuggestionsFail,
  fetchOrderItemsSubmissionSuccess,
  fetchOrderItemsSubmissionFail,
  fetchRejectOrderReasonsSuccess,
  fetchRejectOrderReasonsFail,
  updateShippingAddressSuccess,
  updateShippingAddressFail,
  fetchItemAlternativesSuccess,
  fetchItemAlternativesFail,
  fetchOrderRecommendedAlternativesSuccess,
  fetchOrderRecommendedAlternativesFail,
  confirmOrderFail,
  confirmOrderSuccess,
  returnOrderFail,
  returnOrderSuccess,
} from './actions';
import ordersActionTypes from './action-types';
import ordersStrings from './strings';
import { filterStatusesToShow, orderStatusEnum } from './constants';

export const getCreationDateDetails = orderDate => {
  const { dateObj } = getDateTimeObjFromDateTimeStr(orderDate, true, true);
  const { day, month, year } = getDateDetailsFromDateTimeObj(dateObj);
  const { hours, minutes, period } = getTimeDetailsFromDateTimeObj(dateObj);
  const { elapsedMinutes, elapsedHours } = getElapsedTimeFromDateTimeObj(dateObj);

  const orderDateTimeStr = `${day} ${month} ${year}, ${hours}:${addPaddingZerosToNum(
    minutes,
    1,
    2,
  )} ${period}`;
  const orderElapsedTimeStr = ordersStrings.ordersList.orderGridItemElapsed(
    elapsedHours,
    addPaddingZerosToNum(elapsedMinutes, 1, 2),
  );

  return {
    orderDateTimeStr,
    orderElapsedTimeStr,
  };
};

const mapOrderItemObjForView = order => {
  const { orderDateTimeStr, orderElapsedTimeStr } = getCreationDateDetails(order.createdOn);
  const orderStatusStr = orderStatusEnum.getKeyByValue(order.orderStateTypeId);

  return {
    ...order,
    orderDateTimeStr,
    orderElapsedTimeStr,
    orderStatusStr,
  };
};

export const checkIfOrderCreationDateAndDeliveryDateIsTheSame = (creationDate, deliveryDate) => {
  const { dateObj: creationDateObj } = getDateTimeObjFromDateTimeStr(creationDate, true, true);
  const { dateObj: deliveryDateObj } = getDateTimeObjFromDateTimeStr(deliveryDate, true, true);
  return checkIfTwoDatesAreEqual(creationDateObj, deliveryDateObj);
};

function* getAllOrdersSaga({ payload }) {
  let tempPayload = {};
  if (payload?.stateId?.length) {
    tempPayload = payload;
  } else {
    tempPayload = {
      ...payload,
      stateId: [
        orderStatusEnum.Delivered,
        orderStatusEnum.Cancelled,
        orderStatusEnum.Viewed,
        orderStatusEnum.Accepted,
        orderStatusEnum['Ph. Rejected'],
        orderStatusEnum.Confirmed,
        orderStatusEnum['Payment Pending'],
        orderStatusEnum['Payment Processing'],
        orderStatusEnum['Payment Success'],
        orderStatusEnum['Payment Failed'],
        orderStatusEnum['Picked Up'],
        orderStatusEnum['Ready for Pickup'],
        orderStatusEnum.New,
        orderStatusEnum['Selecting Alternatives'],
        orderStatusEnum['Items Updated'],
        orderStatusEnum['Items Not Updated'],
      ],
    };
  }

  try {
    const response = yield call(getAllOrders, tempPayload);
    const orderListData = response.data.orderDTOs;
    const mappedOrdersList = [];

    if (orderListData) {
      orderListData?.forEach(order => {
        mappedOrdersList.push(mapOrderItemObjForView(order));
      });
    }

    yield put(fetchAllOrdersSuccess({ ...response.data, mappedOrdersList }));
    // yield put(setAllOrdersAssignedEntityDetails(mappedOrdersList));
  } catch (error) {
    yield put(fetchAllOrdersFail());
  }
}

function* setAllOrdersAssignedEntityDetailsSaga({ payload }) {
  try {
    const orderListData = payload;
    const mappedOrdersList = [];
    if (orderListData) {
      orderListData?.forEach(order => {
        mappedOrdersList.push({
          ...order,
        });
      });
    }

    const allOrdersState = state => state.pharmacyOrders.ordersList;
    const allOrders = yield select(allOrdersState);
    yield put(fetchAllOrdersSuccess({ ...allOrders.details, mappedOrdersList }));
  } catch (error) {
    console.log(error);
    yield put(fetchAllPharmacyFail());
    yield put(fetchAllCouriersFail());
  }
}

function* getPartnershipTypesSaga() {
  try {
    const response = yield call(getPartnershipTypes);

    const partnershipTypesList = response.data.map(partnership => ({
      key: partnership.key,
      name: partnership.partnershipTypeTranslators[0]?.name,
      icon: partnership.iconUrl,
    }));
    yield put(fetchPartnershipTypesSuccess(partnershipTypesList));
  } catch (error) {
    yield put(fetchPartnershipTypesFail());
  }
}

function* getDetailedReasonsSaga({ payload }) {
  try {
    const response = yield call(getDetailedReasons, { stateId: payload });
    const reasons = {};
    response.data?.forEach(({ orderStateTypeId, orderStateChildReasons }) => {
      if (orderStateChildReasons.length > 0) {
        if (reasons.hasOwnProperty(orderStateTypeId))
          reasons[orderStateTypeId] = [...reasons[orderStateTypeId], ...orderStateChildReasons];
        else reasons[orderStateTypeId] = [...orderStateChildReasons];
      }
    });
    yield put(fetchDetailedReasonsSuccess(reasons));
  } catch (error) {
    yield put(fetchDetailedReasonsFail());
  }
}

function* getOrderStatusTypesSaga() {
  try {
    const response = yield call(getOrderStatusTypes);
    const orderStatusTypes = response.data
      .sort((a, b) => a.displayOrder - b.displayOrder)
      .filter(orderStatus => filterStatusesToShow[orderStatus.id]);
    yield put(fetchOrderStatusTypesSuccess(orderStatusTypes));
  } catch (error) {
    yield put(fetchOrderStatusTypesFail());
  }
}

function* setOrderPatientUserDetailsSaga({ payload }) {
  try {
    const patientUser = {
      patientUserKey: payload,
    };
    const response = yield call(getOrderPatientUserDetails, patientUser);

    const orderDetailsState = state => state.pharmacyOrders.orderDetails;
    const orderDetails = yield select(orderDetailsState);
    yield put(
      fetchOrderDetailsSuccess({
        ...orderDetails.details,
        patientUserDetails: response.data,
      }),
    );
  } catch (error) {
    const orderDetailsState = state => state.pharmacyOrders.orderDetails;
    const orderDetails = yield select(orderDetailsState);

    yield put(
      fetchOrderDetailsSuccess({
        ...orderDetails.details,
        patientUserDetails: status.FAIL,
      }),
    );
  }
}

function* setOrderAssignedEntityDetailsSaga() {
  try {
    const orderDetailsState = state => state.pharmacyOrders.orderDetails;
    const orderDetails = yield select(orderDetailsState);
    yield put(
      fetchOrderDetailsSuccess({
        ...orderDetails.details,
      }),
    );
  } catch (error) {
    const orderDetailsState = state => state.pharmacyOrders.orderDetails;
    const orderDetails = yield select(orderDetailsState);
    console.log(error);
    yield put(
      fetchOrderDetailsSuccess({
        ...orderDetails.details,
      }),
    );
    yield put(fetchAllPharmacyFail());
  }
}

function* setOrderLogsDetailsSaga() {
  try {
    const allCCouriersBody = {
      data: {
        roleId: userRoleEnum.Courier,
        searchTerm: '',
        deleted: false,
        pageNumber: 1,
        pageSize: 10000,
      },
    };
    const allCouriersResponse = yield call(getUsers, allCCouriersBody);

    const userRoles = Object.keys(userRoleEnum).filter(
      userRoleKey => userRoleKey !== 'Courier' && userRoleKey !== 'PAPIUser',
    );
    let allUsersQuery = userRoles.map(role => `roleId=${userRoleEnum[role]}`).join('&');
    allUsersQuery = `${allUsersQuery}&searchTerm=&pageNumber=1&pageSize=10000`;
    const allUsersBody = {
      query: allUsersQuery,
    };
    const allUsersResponse = yield call(getUsers, allUsersBody);

    yield put(fetchAllCouriersSuccess(allCouriersResponse.data));
    yield put(fetchAllUsersSuccess(allUsersResponse.data));
  } catch (error) {
    yield put(fetchAllCouriersFail());
    yield put(fetchAllUsersFail());
  }
}

function* setOrderItemsDetailsSaga({ payload }) {
  try {
    const { orderItems } = payload;
    const allOrderServices = [...orderItems].map(item => item.productKey);
    const mappedItemsList = [];

    if (allOrderServices.length > 0) {
      const productKeysQuery = allOrderServices.join('&productKeys=');
      const pharmacyCatalogBody = {
        productKeys: productKeysQuery,
      };
      const productItemsResponse = yield call(getProductItems, pharmacyCatalogBody);
      const productItemsList = productItemsResponse.data;

      if (productItemsList.length > 0) {
        orderItems?.forEach(orderItem => {
          const orderServiceObj = productItemsList.find(
            productItem => productItem.key === orderItem.productKey,
          );

          const productShape = orderServiceObj?.productShapes.find(
            shape => shape.id === orderItem?.productShapeId,
          );

          if (orderServiceObj)
            mappedItemsList.push({
              orderItemKey: orderItem.key,
              serviceKey: orderServiceObj.key,
              serviceName: orderServiceObj.nameEn,
              servicePrice: orderItem.actualPrice,
              price: orderItem.price,
              productShapeId: productShape.id,
              productShapeName: productShape.productShapeTypeName,
              quantity: orderItem.quantity,
              mainImageUrl: orderServiceObj.mainImageUrl,
            });
        });
      }
    }
    const orderDetailsState = state => state.pharmacyOrders.orderDetails;
    const orderDetails = yield select(orderDetailsState);
    yield put(
      fetchOrderDetailsSuccess({
        ...orderDetails.details,
        mappedItemsList,
      }),
    );
  } catch (error) {
    const orderDetailsState = state => state.pharmacyOrders.orderDetails;
    const orderDetails = yield select(orderDetailsState);

    yield put(
      fetchOrderDetailsSuccess({
        ...orderDetails.details,
        mappedItemsList: status.FAIL,
      }),
    );
  }
}

function* getOrderDetailsSaga({ payload }) {
  try {
    const response = yield call(getOrderDetails, payload);
    const orderDetails = response.data;
    const { orderDateTimeStr, orderElapsedTimeStr } = getCreationDateDetails(
      orderDetails.createdOn,
    );
    const { orderDateTimeStr: orderScheduledDateTimeStr } = getCreationDateDetails(
      orderDetails.deliveredOn,
    );

    let itemRecommendations = [];
    if (orderDetails?.alternativesRecommendations?.length) {
      const orderSuggestedAlternatives = yield call(getOrderItemsSuggestedAlternatives, {
        orderKey: payload.orderId,
      });
      itemRecommendations = orderSuggestedAlternatives?.data?.itemRecommendations;
    }

    yield put(
      fetchOrderDetailsSuccess({
        ...orderDetails,
        alternativesRecommendations: itemRecommendations,
        orderDate: {
          orderDateTimeStr,
          orderElapsedTimeStr,
          orderScheduledDateTimeStr,
          isOrderCreationDateSameAsDeliveryDate: checkIfOrderCreationDateAndDeliveryDateIsTheSame(
            orderDetails.createdOn,
            orderDetails.deliveredOn,
          ),
        },
        mappedItemsList: status.FETCHING,
        patientUserDetails: status.FETCHING,
      }),
    );

    yield put(setOrderPatientUserDetails(orderDetails.userKey));
    yield put(setOrderAssignedEntityDetails());
    yield put(
      setOrderItemsDetails({
        orderItems: orderDetails.orderItems,
        orderItemsSnapshot: orderDetails.orderItemsSnapshot,
      }),
    );
  } catch (error) {
    yield put(fetchOrderDetailsFail());
  }
}

function* fetchOrderStatusChangeSubmissionSaga({ payload }) {
  const { callbackAction } = payload;

  try {
    const response = yield call(updateOrderStatus, payload);
    if (callbackAction) callbackAction(true);
    yield put(fetchOrderStatusChangeSubmissionSuccess(response.data));
  } catch (error) {
    if (callbackAction) callbackAction();
    yield put(fetchOrderStatusChangeSubmissionFail(error));
  }
}

function* fetchOrderNoteChangeSubmissionSaga({ payload }) {
  try {
    const response = yield call(updateOrderNote, payload);
    yield put(fetchOrderNoteChangeSubmissionSuccess(response.data));
  } catch (error) {
    yield put(fetchOrderNoteChangeSubmissionFail(error));
  }
}

function* assignOrderToEntitySaga({ payload }) {
  try {
    const response = yield call(assignOrderToEntity, payload);
    yield put(assignOrderToEntitySuccess(response));
  } catch (error) {
    yield put(assignOrderToEntityFail());
  }
}

function* fetchScheduleOrderSubmissionSaga({ payload }) {
  const { callbackAction } = payload;

  try {
    const response = yield call(scheduleOrder, payload);
    callbackAction(true);
    yield put(scheduleOrderSubmissionSuccess(response.data));
  } catch (error) {
    callbackAction();
    yield put(scheduleOrderSubmissionFail(error));
  }
}

function* fetchScheduleOrderReasonsSaga() {
  try {
    const response = yield call(getScheduleOrderReasons);
    yield put(fetchScheduleOrderReasonsSuccess(response.data[0].orderStateChildReasons));
  } catch (error) {
    yield put(fetchScheduleOrderReasonsFail(error));
  }
}

function* fetchCancelOrderReasonsSaga() {
  try {
    const response = yield call(getCancelOrderReasons);
    yield put(fetchCancelOrderReasonsSuccess(response.data));
  } catch (error) {
    yield put(fetchCancelOrderReasonsFail(error));
  }
}

function* fetchRejectOrderReasonsSaga() {
  try {
    const response = yield call(getRejectOrderReasons);
    yield put(fetchRejectOrderReasonsSuccess(response.data));
  } catch (error) {
    yield put(fetchRejectOrderReasonsFail(error));
  }
}

function* fetchOrderItemsSuggestionsSaga({ payload }) {
  try {
    const response = yield call(searchProductItems, payload);
    yield put(fetchOrderItemsSuggestionsSuccess(response.data));
  } catch (error) {
    yield put(fetchOrderItemsSuggestionsFail(error));
  }
}

function* fetchOrderItemsSubmissionSaga({ payload }) {
  try {
    const response = yield call(updateOrderItems, payload);
    yield put(fetchOrderItemsSubmissionSuccess(response.data));
  } catch (error) {
    console.log(error);
    yield put(fetchOrderItemsSubmissionFail(error));
  }
}

function* updateShippingAddressSaga({ payload }) {
  try {
    const { callbackAction } = payload;
    const response = yield call(updateShippingAddress, payload);
    callbackAction();
    yield put(updateShippingAddressSuccess(response.data));
  } catch (error) {
    console.log(error);
    yield put(updateShippingAddressFail(error));
  }
}

function* getStocksSaga({ payload }) {
  const tempPayload = { ...payload };
  tempPayload.productKeys = tempPayload.productKeys.join('&productKeys=');
  try {
    const stocksResponse = yield call(getProductStocksAllocation, tempPayload);
    yield put({
      type: ordersActionTypes.GET_STOCKS_SUCCESS,
      payload: stocksResponse.data,
    });
  } catch (err) {
    console.log(err);
    yield put({
      type: ordersActionTypes.GET_STOCKS_FAIL,
    });
  }
}

function* getItemAlternativesSaga({ payload }) {
  try {
    const res = yield call(getOrderItemsAlternatives, payload);
    const recommendedAlternatives = res.data.map(item => {
      const productName = item.nameEn;
      const productShapes = item.productShapes?.map(productShape => {
        const productShapeId = productShape.id;
        const productShapeName = productShape.productShapeType?.productShapeTypeTranslators?.find(
          translator => translator.lang === 'en',
        ).name;
        const productShapePrice = productShape.newPrice;
        const productShapeStock = productShape.stockQuantity;
        return { productShapeId, productShapeName, productShapePrice, productShapeStock };
      });
      return {
        key: item.key,
        name: productName,
        productShapes,
        selectedProductShape: productShapes[0],
        selectedQuantity: 1,
        imageUrl: item.mainImageUrl,
      };
    });

    yield put(fetchItemAlternativesSuccess(recommendedAlternatives));
  } catch (err) {
    console.log(err);
    yield put(fetchItemAlternativesFail());
  }
}

function* submitOrderItemAlternativesSaga({ payload }) {
  const { callbackAction } = payload;
  try {
    yield call(submitOrderItemsAlternatives, payload);
    yield put({
      type: ordersActionTypes.SUBMIT_ORDER_ITEMS_ALTERNATIVES_SUCCESS,
    });
    callbackAction(true);
  } catch (err) {
    console.log(err);
    yield put({
      type: ordersActionTypes.SUBMIT_ORDER_ITEMS_ALTERNATIVES_FAIL,
    });
    callbackAction();
  }
}

function* getOrderRecommendedAlternativesSaga({ payload }) {
  try {
    const res = yield call(getOrderRecommendedAlternatives, payload);
    const mappedOrderAlternatives = res.data.itemRecommendations.map(item => {
      const itemName = item.currentProductTranslators.find(
        translator => translator.lang === 'en',
      )?.name;
      const itemShape = item.currentProductShapeTypeTranslators.find(
        translator => translator.lang === 'en',
      )?.name;
      const itemQuantity = item.currentQuantity;
      let selectedAlternative = [];
      const recommendedAlternatives = item.recommendedAlternatives.map(recommendedItem => {
        const recommendedItemName = recommendedItem.alternativeProductTranslators.find(
          translator => translator.lang === 'en',
        )?.name;
        const recommendedItemShape = recommendedItem.alternativeProductShapeTypeTranslators.find(
          translator => translator.lang === 'en',
        )?.name;
        const recommendedItemQuantity = recommendedItem.alternativeQuantity;
        const recommendedObj = {
          serviceName: recommendedItemName,
          quantity: recommendedItemQuantity,
          productShapeName: recommendedItemShape,
        };
        if (recommendedItem.status === 1) selectedAlternative = [recommendedObj];
        return recommendedObj;
      });

      return {
        serviceName: itemName,
        quantity: itemQuantity,
        productShapeName: itemShape,
        recommendedAlternatives,
        selectedAlternative,
      };
    });
    yield put(fetchOrderRecommendedAlternativesSuccess(mappedOrderAlternatives));
  } catch (err) {
    console.log(err);
    yield put(fetchOrderRecommendedAlternativesFail());
  }
}

function* confirmOrderSaga({ payload }) {
  const { callbackAction } = payload;
  try {
    const response = yield call(confirmOrder, payload);
    callbackAction(true);
    yield put(confirmOrderSuccess(response));
  } catch (error) {
    callbackAction();
    yield put(confirmOrderFail(error));
  }
}

function* returnOrderSaga({ payload }) {
  const { callbackAction } = payload;
  try {
    const response = yield call(returnOrder, payload);
    callbackAction(true);
    yield put(returnOrderSuccess(response));
  } catch (error) {
    callbackAction();
    yield put(returnOrderFail(error));
  }
}

function* ordersSagas() {
  yield takeLatest(ordersActionTypes.RETURN_ORDER, returnOrderSaga);
  yield takeLatest(ordersActionTypes.CONFIRM_ORDER, confirmOrderSaga);
  yield takeLatest(ordersActionTypes.FETCH_ALL_PHARMACY_ORDERS, getAllOrdersSaga);
  yield takeLatest(ordersActionTypes.FETCH_PHARMACY_ORDER_DETAILS, getOrderDetailsSaga);
  yield takeLatest(
    ordersActionTypes.SET_PHARMACY_ORDER_PATIENT_USER_DETAILS,
    setOrderPatientUserDetailsSaga,
  );
  yield takeLatest(
    ordersActionTypes.SET_PHARMACY_ORDER_ASSIGNED_ENTITY_DETAILS,
    setOrderAssignedEntityDetailsSaga,
  );
  yield takeLatest(ordersActionTypes.SET_PHARMACY_ORDER_LOGS_DETAILS, setOrderLogsDetailsSaga);
  yield takeLatest(ordersActionTypes.SET_ORDER_ITEMS_DETAILS, setOrderItemsDetailsSaga);
  yield takeLatest(
    ordersActionTypes.FETCH_PHARMACY_ORDER_STATUS_CHANGE_SUBMISSION,
    fetchOrderStatusChangeSubmissionSaga,
  );
  yield takeLatest(
    ordersActionTypes.FETCH_PHARMACY_ORDER_NOTE_CHANGE_SUBMISSION,
    fetchOrderNoteChangeSubmissionSaga,
  );
  yield takeLatest(ordersActionTypes.FETCH_ORDER_STATUS_TYPES, getOrderStatusTypesSaga);
  yield takeLatest(ordersActionTypes.FETCH_PARTNERSHIP_TYPES, getPartnershipTypesSaga);
  yield takeLatest(ordersActionTypes.FETCH_DETAILED_REASONS, getDetailedReasonsSaga);
  yield takeLatest(
    ordersActionTypes.SET_ALL_PHARMACY_ORDERS_ASSIGNED_ENTITY_DETAILS,
    setAllOrdersAssignedEntityDetailsSaga,
  );
  yield takeLatest(ordersActionTypes.ASSIGN_PHARMACY_ORDER_TO_ENTITY, assignOrderToEntitySaga);
  yield takeLatest(ordersActionTypes.SCHEDULE_ORDER_SUBMISSION, fetchScheduleOrderSubmissionSaga);
  yield takeLatest(
    ordersActionTypes.FETCH_PHARMACY_SCHEDULE_ORDER_REASONS,
    fetchScheduleOrderReasonsSaga,
  );
  yield takeLatest(
    ordersActionTypes.FETCH_PHARMACY_CANCEL_ORDER_REASONS,
    fetchCancelOrderReasonsSaga,
  );
  yield takeLatest(
    ordersActionTypes.FETCH_PHARMACY_REJECT_ORDER_REASONS,
    fetchRejectOrderReasonsSaga,
  );
  yield takeLatest(ordersActionTypes.FETCH_ORDER_ITEMS_SUGGESTIONS, fetchOrderItemsSuggestionsSaga);
  yield takeLatest(ordersActionTypes.FETCH_ORDER_ITEMS_SUBMISSION, fetchOrderItemsSubmissionSaga);
  yield takeLatest(ordersActionTypes.UPDATE_SHIPPING_ADDRESS, updateShippingAddressSaga);
  yield takeLatest(ordersActionTypes.GET_STOCKS, getStocksSaga);
  yield takeLatest(ordersActionTypes.FETCH_ITEM_ALTERNATIVES, getItemAlternativesSaga);
  yield takeLatest(
    ordersActionTypes.SUBMIT_ORDER_ITEMS_ALTERNATIVES,
    submitOrderItemAlternativesSaga,
  );
  yield takeLatest(
    ordersActionTypes.FETCH_ORDER_RECOMMENDED_ALTERNATIVES,
    getOrderRecommendedAlternativesSaga,
  );
}

export default ordersSagas;
