import { fork } from 'redux-saga/effects';
import pharmacyOrdersSagas from 'modules/orders/sagas';
import pharmacyCommonSagas from 'modules/common/sagas';
import pharmacyAccountManagementSagas from 'modules/accountManagement/sagas';
import pharmacyStockSagas from 'modules/stock/sagas';
import pharmacyInvoicesSagas from 'modules/invoices/sagas';

export default function* rootSaga() {
  yield fork(pharmacyOrdersSagas);
  yield fork(pharmacyCommonSagas);
  yield fork(pharmacyAccountManagementSagas);
  yield fork(pharmacyStockSagas);
  yield fork(pharmacyInvoicesSagas);
}
