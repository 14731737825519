import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Loader from 'modules/common/components/Loader';
import Button from 'modules/common/components/Button';
import { status } from 'constants/general';
import ErrorImg from 'assets/images/error.png';
import SearchImg from 'assets/images/search.png';
import TableViewContainer from 'modules/common/components/TableViewContainer';
import SearchAndFilterContainer from './SearchAndFilterContainer';
import { getMainRowItems, getTableHeadersLocalized } from './helper';
import {
  extendAdditionalItem,
  extendMainItem,
  extendTableGrid,
  extendTableHeaderRow,
  extendTableRow,
} from './TableViewContainer.style';
import {
  GrayOverLay,
  ViewContainer,
  ExceptionContainer,
  ExceptionImage,
  ExceptionImageContainer,
  ExceptionMessage,
  ExceptionMessageContainer,
  extendButtonStyle,
  extendLoaderStyle,
} from './Stock.style';

const StockList = ({
  queryString,
  currentPage,
  pharmacyStockLoadStatus,
  uploadPharmacyStockLoadStatus,
  pharmacyExportStockLoadStatus,
  pharmacyStock,
  getStockListPage,
  showMore,
  showMoreInStock,
  onSearchStock,
  onUploadStockFile,
  onExportStock,
  toggleViews,
  toggleAddStockItemsModal,
  toggleEditStockItemModal,
  addToStockItemsCart,
  ...props
}) => {
  const [stockRecords, setStockRecords] = useState([]);

  /** *****************************************************************************************
   * Set Records
   ***************************************************************************************** */
  useEffect(() => {
    // || KeyWord === queryString
    if (pharmacyStock?.length) {
      setStockRecords(
        getMainRowItems(pharmacyStock, toggleEditStockItemModal, addToStockItemsCart),
      );
    } else {
      setStockRecords([]);
    }
  }, [pharmacyStockLoadStatus]);

  const getExceptionComponent = (loadingStatus, data) => {
    let exceptionImage;
    let exceptionMessage;
    if (loadingStatus === status.SUCCESS && (!data || !data.length)) {
      exceptionImage = SearchImg;
      exceptionMessage = 'No Stock Found';
    }
    if (loadingStatus === status.FAIL) {
      exceptionImage = ErrorImg;
      exceptionMessage = 'Something went wrong!';
    }

    if (exceptionImage && exceptionMessage) {
      return (
        <ExceptionContainer>
          <ExceptionImageContainer>
            <ExceptionImage src={exceptionImage} alt="exception" />
          </ExceptionImageContainer>
          <ExceptionMessageContainer>
            <ExceptionMessage>{exceptionMessage}</ExceptionMessage>
          </ExceptionMessageContainer>
        </ExceptionContainer>
      );
    }
    return '';
  };

  const renderStockList = () => {
    if (
      !pharmacyStock?.length &&
      (pharmacyStockLoadStatus === status.FETCHING ||
        pharmacyStockLoadStatus === status.SHOULD_CALL_API)
    ) {
      return (
        <GrayOverLay>
          <Loader />
        </GrayOverLay>
      );
    }
    if (stockRecords.length) {
      return (
        <div>
          <div>
            <TableViewContainer
              {...props}
              tableRecords={stockRecords}
              tableColumnHeaders={getTableHeadersLocalized()}
              getListPage={getStockListPage}
              extendAdditionalItem={extendAdditionalItem}
              extendMainItem={extendMainItem}
              extendTableGrid={extendTableGrid}
              extendTableHeaderRow={extendTableHeaderRow}
              extendTableRow={extendTableRow}
            />
          </div>
        </div>
      );
    }
    if (
      pharmacyStockLoadStatus === status.FAIL ||
      (pharmacyStockLoadStatus === status.SUCCESS && pharmacyStock && !pharmacyStock.length)
    ) {
      return <div>{getExceptionComponent(pharmacyStockLoadStatus, pharmacyStock)}</div>;
    }

    return (
      <div>
        <div>
          <TableViewContainer
            {...props}
            currentPage={currentPage}
            tableRecords={stockRecords}
            tableColumnHeaders={getTableHeadersLocalized()}
            getListPage={getStockListPage}
            extendAdditionalItem={extendAdditionalItem}
            extendMainItem={extendMainItem}
            extendTableGrid={extendTableGrid}
            extendTableHeaderRow={extendTableHeaderRow}
            extendTableRow={extendTableRow}
          />
        </div>
      </div>
    );
  };

  const displayShowMoreBtn = pharmacyStock?.length > 0 && showMore;
  return (
    <div
      style={{
        padding: '16px',
      }}
    >
      <SearchAndFilterContainer
        queryString={queryString}
        onSearchStock={onSearchStock}
        uploadPharmacyStockLoadStatus={uploadPharmacyStockLoadStatus}
        pharmacyExportStockLoadStatus={pharmacyExportStockLoadStatus}
        onUploadStockFile={onUploadStockFile}
        onExportStock={onExportStock}
        toggleViews={toggleViews}
        toggleAddStockItemsModal={toggleAddStockItemsModal}
      />
      <ViewContainer>{renderStockList()}</ViewContainer>
      {displayShowMoreBtn && (
        <Button
          onClick={() => showMoreInStock()}
          isLoading={pharmacyStockLoadStatus === status.FETCHING}
          btnText="Show More"
          extendLoaderStyle={extendLoaderStyle}
          extendButtonStyle={extendButtonStyle}
        />
      )}
    </div>
  );
};

StockList.propTypes = {
  pharmacyStock: PropTypes.arrayOf(PropTypes.shape({})),
  pharmacyStockLoadStatus: PropTypes.string,
  queryString: PropTypes.string,
  currentPage: PropTypes.number,
  getStockListPage: PropTypes.func,
  showMore: PropTypes.bool,
  showMoreInStock: PropTypes.func,
  onSearchStock: PropTypes.func,
  uploadPharmacyStockLoadStatus: PropTypes.string,
  onUploadStockFile: PropTypes.func,
  pharmacyExportStockLoadStatus: PropTypes.string,
  onExportStock: PropTypes.func,
  toggleViews: PropTypes.func,
  toggleAddStockItemsModal: PropTypes.func,
  toggleEditStockItemModal: PropTypes.func,
  addToStockItemsCart: PropTypes.func,
};

export default StockList;
