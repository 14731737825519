import PropTypes from 'prop-types';
import { StatusesIdToLabelAborted } from 'modules/orders/constants';
import {
  OrderDetailsStatusIconWrapper,
  OrderDetailsStatusIcon,
  OrderDetailsStatusTextWrapper,
  OrderDetailsStatusText,
  OrderDetailsStatusLine,
  OrderDetailsStatusSection,
} from '../OrderDetailsComponent.style';

const OrderStatus = ({ orderStatusObj, isFirstStatus, isOrderAborted }) => (
  <>
    {!isFirstStatus && <OrderDetailsStatusLine color={orderStatusObj.orderStatusColor} />}
    <OrderDetailsStatusIconWrapper>
      <OrderDetailsStatusIcon
        color={orderStatusObj.orderStatusColor}
        isOrderAborted={isOrderAborted}
      />
      <OrderDetailsStatusTextWrapper>
        <OrderDetailsStatusText color={orderStatusObj.orderStatusColor}>
          {orderStatusObj.orderStatusName}
        </OrderDetailsStatusText>
      </OrderDetailsStatusTextWrapper>
    </OrderDetailsStatusIconWrapper>
  </>
);

OrderStatus.propTypes = {
  orderStatusObj: PropTypes.shape({
    orderStatusName: PropTypes.string,
    orderStatusColor: PropTypes.string,
  }),
  isFirstStatus: PropTypes.bool,
  isOrderAborted: PropTypes.bool,
};

const OrderStatusSection = props => {
  const { mappedOrderStatus } = props;
  return (
    <OrderDetailsStatusSection>
      {mappedOrderStatus.map((orderStatusObj, index) => {
        const isFirstStatus = index === 0;

        return (
          <OrderStatus
            key={`${orderStatusObj.orderStatusName} ${Math.random().toString()}`}
            orderStatusObj={orderStatusObj}
            isFirstStatus={isFirstStatus}
            isOrderAborted={!!StatusesIdToLabelAborted[orderStatusObj.orderStatus]}
          />
        );
      })}
    </OrderDetailsStatusSection>
  );
};

OrderStatusSection.propTypes = {
  mappedOrderStatus: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default OrderStatusSection;
