import { status } from 'constants/general';
import { initialPharmacyStockState } from 'store/initialStates';
import stockActionTypes from './action-types';

const stockReducer = (state = initialPharmacyStockState, { type, payload }) => {
  switch (type) {
    case stockActionTypes.EXPORT_STOCK:
      return {
        ...state,
        pharmacyExportStockLoadStatus: status.FETCHING,
      };
    case stockActionTypes.EXPORT_STOCK_SUCCESS:
      return {
        ...state,
        pharmacyExportStockLoadStatus: status.SUCCESS,
      };
    case stockActionTypes.EXPORT_STOCK_FAIL:
      return {
        ...state,
        pharmacyExportStockLoadStatus: status.FAIL,
      };

    case stockActionTypes.FETCH_STOCK:
      return {
        ...state,
        pharmacyStockLoadStatus: status.FETCHING,
        pharmacyStock: {
          items: payload.pageNumber === 1 ? [] : [...state.pharmacyStock.items],
          showMore: payload.pageNumber === 1 ? false : state.pharmacyStock.showMore,
        },
      };
    case stockActionTypes.FETCH_STOCK_SUCCESS:
      return {
        ...state,
        pharmacyStock: {
          items: [...state.pharmacyStock.items, ...payload],
          showMore: !(payload?.length < 10),
        },
        pharmacyStockLoadStatus: status.SUCCESS,
      };
    case stockActionTypes.FETCH_STOCK_FAIL:
      return {
        ...state,
        pharmacyStockLoadStatus: status.FAIL,
      };
    case stockActionTypes.UPLOAD_STOCK:
      return {
        ...state,
        uploadPharmacyStockLoadStatus: status.SUBMITING,
      };
    case stockActionTypes.UPLOAD_STOCK_SUCCESS:
      return {
        ...state,
        uploadPharmacyStockLoadStatus: status.SUCCESS,
      };
    case stockActionTypes.UPLOAD_STOCK_FAIL:
      return {
        ...state,
        uploadPharmacyStockLoadStatus: status.FAIL,
      };
    case stockActionTypes.FETCH_STOCK_HISTORY:
      return {
        ...state,
        pharmacyStocksHistoryLoadStatus: status.FETCHING,
        pharmacyStocksHistory: {
          items: payload.pageNumber === 1 ? [] : [...state.pharmacyStocksHistory.items],
          showMore: payload.pageNumber === 1 ? false : state.pharmacyStocksHistory.showMore,
        },
      };
    case stockActionTypes.FETCH_STOCK_HISTORY_SUCCESS:
      return {
        ...state,
        pharmacyStocksHistory: {
          items: [...state.pharmacyStocksHistory.items, ...payload],
          showMore: !(payload?.length < 10),
        },
        pharmacyStocksHistoryLoadStatus: status.SUCCESS,
      };
    case stockActionTypes.FETCH_STOCK_HISTORY_FAIL:
      return {
        ...state,
        pharmacyStocksHistoryLoadStatus: status.FAIL,
      };
    case stockActionTypes.TOGGLE_ADD_STOCK_ITEMS_MODAL:
      return {
        ...state,
        isAddStockItemsModalOpened: payload,
      };

    case stockActionTypes.TOGGLE_EDIT_STOCK_ITEM_MODAL:
      return {
        ...state,
        isEditStockItemModalOpened: payload,
      };
    case stockActionTypes.SEARCH_STOCK_ITEMS:
      return {
        ...state,
        searchResultsPharmacyStockLoadStatus: status.FETCHING,
      };
    case stockActionTypes.SEARCH_STOCK_ITEMS_SUCCESS:
      return {
        ...state,
        searchResultsPharmacyStock: payload,
        searchResultsPharmacyStockLoadStatus: status.SUCCESS,
      };
    case stockActionTypes.SEARCH_STOCK_ITEMS_FAIL:
      return {
        ...state,
        searchResultsPharmacyStockLoadStatus: status.FAIL,
      };

    case stockActionTypes.SAVE_STOCK_ITEMS:
      return {
        ...state,
        savepharmacyStockItemsLoadStatus: status.SUBMITING,
      };
    case stockActionTypes.SAVE_STOCK_ITEMS_SUCCESS:
      return {
        ...state,
        savepharmacyStockItems: payload,
        savepharmacyStockItemsLoadStatus: status.SUCCESS,
      };
    case stockActionTypes.SAVE_STOCK_ITEMS_FAIL:
      return {
        ...state,
        savepharmacyStockItems: payload,
        savepharmacyStockItemsLoadStatus: status.FAIL,
      };

    case stockActionTypes.ADD_STOCK_ITEM:
      return {
        ...state,
        stockItemsToBeAddedCart: [...state.stockItemsToBeAddedCart, payload],
      };
    case stockActionTypes.REMOVE_STOCK_ITEM: {
      const cart = [];
      state.stockItemsToBeAddedCart.forEach(item => {
        if (item.ProductKey !== payload.ProductKey) {
          cart.push(item);
        }
      });
      return {
        ...state,
        stockItemsToBeAddedCart: cart,
      };
    }
    case stockActionTypes.EDIT_STOCK_ITEM: {
      const cart = [];
      state.stockItemsToBeAddedCart.forEach(item => {
        if (item.ProductKey === payload.ProductKey) {
          cart.push(payload);
        } else {
          cart.push(item);
        }
      });
      return {
        ...state,
        stockItemsToBeAddedCart: cart,
      };
    }
    case stockActionTypes.RESET_STOCK_ITEM:
      return {
        ...state,
        stockItemsToBeAddedCart: [],
      };
    default:
      return state;
  }
};

export default stockReducer;
