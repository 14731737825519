import PropTypes from 'prop-types';

import { CheckboxWrapper, CheckboxCheck, CheckboxLabel } from './Checkbox.style';

const Checkbox = ({ checkboxObj, checked, handleCheckboxClick }) => (
  <CheckboxWrapper
    data-testid="checkbox-wrapper"
    onClick={() => handleCheckboxClick(checkboxObj.key)}
  >
    <CheckboxCheck type="checkbox" readOnly data-testid="checkbox-check" checked={checked} />
    <CheckboxLabel data-testid="checkbox-label">{checkboxObj.name}</CheckboxLabel>
  </CheckboxWrapper>
);

Checkbox.propTypes = {
  checkboxObj: PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  checked: PropTypes.bool,
  handleCheckboxClick: PropTypes.func.isRequired,
};

Checkbox.defaultProps = {
  checked: false,
};

export default Checkbox;
