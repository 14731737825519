import PropTypes from 'prop-types';
import TableWithPagination from 'modules/common/components/TableWithPagination';

const TableViewContainer = ({
  language,
  numberOfPages,
  getListPage,
  currentPage,
  tableRecords,
  tableColumnHeaders,
  extendTableGrid,
  extendTableRow,
  extendTableHeaderRow,
  extendMainItem,
  extendAdditionalItem,
}) => (
  <div>
    <TableWithPagination
      tableColumnHeaders={tableColumnHeaders}
      data={tableRecords}
      totalNumberOfPages={numberOfPages}
      pagination={numberOfPages > 1}
      language={language}
      extendTableGrid={extendTableGrid}
      extendTableRow={extendTableRow}
      extendTableHeaderRow={extendTableHeaderRow}
      extendMainItem={extendMainItem}
      extendAdditionalItem={extendAdditionalItem}
      selectedPage={currentPage}
      onClickPage={getListPage}
      isLoading
    />
  </div>
);

TableViewContainer.propTypes = {
  language: PropTypes.string,
  numberOfPages: PropTypes.number,
  tableRecords: PropTypes.arrayOf(PropTypes.shape({})),
  getListPage: PropTypes.func,
  currentPage: PropTypes.number,
  tableColumnHeaders: PropTypes.arrayOf(PropTypes.shape({})),
  extendAdditionalItem: PropTypes.arrayOf(PropTypes.shape({})),
  extendMainItem: PropTypes.arrayOf(PropTypes.shape({})),
  extendTableGrid: PropTypes.arrayOf(PropTypes.shape({})),
  extendTableHeaderRow: PropTypes.arrayOf(PropTypes.shape({})),
  extendTableRow: PropTypes.arrayOf(PropTypes.shape({})),
};

export default TableViewContainer;
