import styled, { css, keyframes } from 'styled-components';

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const spinner = css`
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 150px;
  height: 150px;
  animation: ${rotate360} 1s linear infinite;
  -webkit-animation: ${rotate360} 1s linear infinite; /* Safari */
  margin-left: auto;
  margin-right: auto;
  margin-top: 125px;
  margin-bottom: 125px;
`;

const lineSpinner = css`
  position: relative;
  display: inline-block;
  width: 5em;
  height: 5em;
  margin: 0 0.5em;
  font-size: 12px;
  text-indent: 999em;
  overflow: hidden;
  animation: ${rotate360} 1s linear 0s infinite;
  -webkit-animation: ${rotate360} 1s linear 0s infinite; /*Safari*/
  &:before,
  &:after,
  & div:before,
  & div:after {
    content: '';
    position: absolute;
    top: 0;
    left: 2.25em; /* (container width - part width)/2  */
    width: 0.4em;
    height: 1.45em;
    border-radius: 0.2em;
    background: #ffffff11;
    box-shadow: 0 3.6em #ffffff77; /* container height - part height */
    transform-origin: 50% 2.5em; /* container height / 2 */
  }

  &:before {
    background: #fff;
  }

  &:after {
    transform: rotate(-30deg);
    background: #ffffffdd;
  }

  & div.first:before {
    transform: rotate(-60deg);
    background: #ffffffbb;
  }

  & div.first:after {
    transform: rotate(-90deg);
    background: #ffffffaa;
  }

  & div.second:before {
    transform: rotate(-120deg);
    background: #ffffff99;
  }

  & div.second:after {
    transform: rotate(-150deg);
    background: #ffffff77;
  }
`;

export const LoaderContainer = styled.div`
  ${props => (props.type === 'spinner' ? spinner : '')};
  ${props => (props.type === 'lineSpinner' ? lineSpinner : '')};
  ${props => (props.extendStyle ? props.extendStyle : '')};
`;

export const FullHeightCenteredLoaderWrapper = styled.div`
  background-color: white;
  width: 100%;
  height: 100vh;
  ${({ extendStyle }) => extendStyle || ''};
`;
