import { Link } from 'react-router-dom';
import styled from 'styled-components';
import themeData from 'utils/theme/themeData';

const { typography } = themeData;

export const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: max(100vh, 100%);
  background-color: #fff;
  border-right: 1px solid #e3e6ea;
`;

export const SidebarLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 56px;
`;

export const SidebarLogo = styled.img`
  width: 22px;
`;

export const SidebarItemContainer = styled.div`
  ${({ pushToEnd }) => pushToEnd && 'margin-top: auto;'}
`;

export const SidebarItemRouterLink = styled(Link)`
  text-decoration: none;
`;

export const SidebarItemHyperLink = styled.a`
  text-decoration: none;
`;

export const SidebarItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 64px;

  ${({ selected, theme }) =>
    selected &&
    `
    box-shadow: inset 3px 0 0 ${theme.colors.blueMedium};
  `}

  &:hover {
    cursor: pointer;
  }

  &:before {
    content: ${({ icon }) => icon};
    font-family: Icomoon;
    font-size: 22px;
    color: #7e7e7e;
    ${({ selected, theme }) =>
      selected &&
      `
    color: ${theme.colors.blueMedium};
  `}
  }
`;

export const SidebarItem = styled(typography.BodyTextThreeRegularTypography)`
  margin: 0;
  color: #7e7e7e;
  ${({ selected, theme }) =>
    selected &&
    `
    color: ${theme.colors.blueMedium};
  `}
`;
