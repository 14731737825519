import { paymentMethodsKeys } from 'constants/general';
import {
  orderStatusesToShowInProgressBarByOrderType,
  StatusesIdToLabelDefaults,
  StatusesIdToLabelSpecial,
  StatusesIdToLabelAborted,
} from 'modules/orders/constants';

export const getOrderStatusesDefaultTemplate = (onlinePaymentCardKey, optionalPaymentMethodKey) => {
  let orderStatusToShowInProgressBarArr = [];
  if (onlinePaymentCardKey || optionalPaymentMethodKey === paymentMethodsKeys.vezeetaCash) {
    orderStatusToShowInProgressBarArr = [...orderStatusesToShowInProgressBarByOrderType.loyalty];
  } else {
    orderStatusToShowInProgressBarArr = [...orderStatusesToShowInProgressBarByOrderType.default];
  }
  return orderStatusToShowInProgressBarArr;
};

export const getOrderStatusesTobeDisplayedInProgressBar = orderLogs => {
  const orderLogsStatusArr = [];
  orderLogs?.forEach(log => {
    // Following condition to exclude any state before 14: New Order
    if (
      (StatusesIdToLabelDefaults[log.orderStateTypeId] ||
        StatusesIdToLabelSpecial[log.orderStateTypeId] ||
        StatusesIdToLabelAborted[log.orderStateTypeId]) &&
      !orderLogsStatusArr.includes(log.orderStateTypeId)
    ) {
      orderLogsStatusArr.push(log.orderStateTypeId);
    }
  });
  return orderLogsStatusArr;
};

export const getStatusName = statusId => {
  let statusName = '';
  statusName =
    StatusesIdToLabelDefaults[statusId] ||
    StatusesIdToLabelAborted[statusId] ||
    StatusesIdToLabelSpecial[statusId] ||
    '';
  return statusName;
};

export const getStatusColor = (statusId, orderLogsStatusList) => {
  let statusColor = '';
  if (orderLogsStatusList.includes(statusId)) {
    if (StatusesIdToLabelAborted[statusId]) {
      statusColor = '#DB3226';
    } else {
      statusColor = '#31A636';
    }
  }
  return statusColor;
};
