import styled from 'styled-components';

export const TableHeader = styled.div`
  color: #fff;
  background: #0070cd;
  height: 44px;
  border-radius: 3px;
  border-bottom: 1px solid #e3e6ea;
  ${props => props.theme.media.phone`
    display: none;  
  `}
  ${props => props.theme.media.tablet`
    display: none;
  `}
  ${props => (props.extendTableHeaderRow ? props.extendTableHeaderRow : '')};
`;

export const TableContainer = styled.div`
  ${props => (props.extendTableWidth ? props.extendTableWidth : '')};
`;

export const HeaderGrid = styled.div`
  grid-template-columns: 17% 13% 15% 15% 27% 10%;
  display: grid;
  text-align: center;
  padding: 17px 0px;
  font-size: 14px;
  text-align: left;
  width: 100%;
  height: 100%;
  ${props => (props.extendTableGrid ? props.extendTableGrid : '')};
`;

export const Row = styled(TableHeader)`
  display: flex;
  color: #000;
  background-color: unset;
  ${props => (props.extendTableRow ? props.extendTableRow : '')};
  ${props => props.theme.media.phone`
    height: unset;
  `};
  ${props => props.theme.media.tablet`
    height: unset;
  `};
`;

export const RowGrid = styled(HeaderGrid)`
  width: 100%;
  height: 100%;
  cursor: ${props => (props.isClickable ? 'pointer' : 'unset')};
  ${props => (props.extendTableGrid ? props.extendTableGrid : '')};
  ${props => props.theme.media.phone`
    display: flex;
    flex-direction: column;
    grid-template-columns: unset;
    height: fit-content;
  `};
  ${props => props.theme.media.tablet`
    display: flex;
    flex-direction: column;
    grid-template-columns: unset;
    height: fit-content;
    
  `};
`;

export const ExpandedRow = styled(RowGrid)`
  display: flex;
  padding: 0px;
  flex-wrap: wrap;
  max-height: ${props => (props.isExpanded ? `${props.totalHeight}px` : '0px')};
  // background-color: #f6f6f6;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  padding: 0px 0px;
`;

export const RowItem = styled.div`
  margin: 0%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 100%;
  text-align: ${props => (props.language === 'ar' ? 'right' : 'unset')};
  padding: 0 16px;
  border-right: 1px solid #e3e6ea;
  &:last-child {
    border-right: unset;
  }
  ${props => props.theme.media.phone`
    padding: 5px 10px;
    &:nth-child(even) {
    background-color: #9c9c9c1c;
    border-radius: 7px;
  }
  `};
  ${props => props.theme.media.tablet`
    padding: 5px 10px;
    &:nth-child(even) {
    background-color: #9c9c9c1c;
    border-radius: 7px;
  }
  `};
`;

export const ExpandIcon = styled(RowItem)`
  ${props => props.theme.media.phone`
    display: none;
  }
  `};
  ${props => props.theme.media.tablet`
    display: none;
  }
  `};
`;

export const HeaderRowItems = styled(RowItem)`
  white-space: unset;
  overflow: unset;
  text-overflow: unset;
  color: #484848;
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
  ${props => (props.extendHeaderRowItems ? props.extendHeaderRowItems : '')};
`;

export const HeaderRowItemsPhone = styled(HeaderRowItems)`
  ${props => props.theme.media.desktop`
    display: none;
  `};
  ${props => props.theme.media.phone`
    width: 50%;
    line-height: 11px;
  `};
  ${props => props.theme.media.tablet`
    width: 50%;
    line-height: 11px;
  `};
`;

export const PaginationContainer = styled.div``;

export const MainItem = styled.div`
  font-weight: ${props => (props.isBold ? '700 !important' : 'normal !important')};
  font-size: 14px !important;
  color: #484848 !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* max-width: 20ch; */
  overflow: hidden;
  /* position: relative; */
  max-width: 92%;
  direction: ${props => (props.isRtl ? 'rtl' : 'ltr')};
  cursor: ${props => (props.isClickable ? 'pointer' : 'unset')};
  ${props => (props.extendMainItem ? props.extendMainItem : '')};
`;
export const AdditionalItem = styled.p`
  font-weight: normal !important;
  font-size: 14px !important;
  color: #9c9c9c !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 92%;
  display: inline-block;
  direction: ${props => (props.isRtl ? 'rtl' : 'ltr')};
  ${props => (props.extendAdditionalItem ? props.extendAdditionalItem : '')};
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0px;
  transform: rotateZ(0deg);
  transition: transform 0.4s ease-in;
`;

export const RowItemWrapper = styled.div`
  display: block;
  ${props => props.theme.media.phone`
    padding-top: unset;
  `};
  ${props => props.theme.media.tablet`
    padding-top: unset;
  `};
`;

export const RowItemContentWrapper = styled.div`
  // position: absolute;
  height: 100%;
  &:hover > p {
    visibility: visible;
  }
  ${props => props.theme.media.phone`
    width: 50%;
  `};
  ${props => props.theme.media.tablet`
    width: 50%;
  `};
`;

export const TooltipContainer = styled.p`
  visibility: hidden;
  z-index: 1;
  background-color: #ff9999;
  position: absolute;
  /* margin: 0px 5px;
  left: ${props => (props.language === 'en' ? '100%' : 'unset')};
  right: ${props => (props.language === 'ar' ? '100%' : 'unset')}; */
  white-space: break-spaces !important;
  max-width: 730px;
  text-align: center;
  border-radius: 6px;
  font-size: 14px;
  padding: 5px 8px;
  background: #e2e3e4;
  transform: translate(0%, -100%);
  ${props => props.theme.media.phone`
    display: none;
  `};
  ${props => props.theme.media.tablet`
    display: none;
  `};
`;
export const TooltipMessage = styled.p``;

export const InnerWrapper = styled.div`
  ${props => props.theme.media.phone`
    display: flex;
  `};
  ${props => props.theme.media.tablet`
    display: flex;
  `};
`;

export const RowContainer = styled.div`
  &:nth-child(1) {
    border-top: 1px solid #f1f4f6;
  }
  ${props => props.theme.media.phone`
    &:nth-child(1) {
      border-top: 1px solid #7878788a;
    }
    border-bottom: 1px solid #7878788a;
  `};
  ${props => props.theme.media.tablet`
    &:nth-child(1) {
      border-top: 1px solid #7878788a;
    }
    border-bottom: 1px solid #7878788a;
  `};
`;

export const ShowDesktop = styled.div`
  ${props => props.theme.media.phone`
    display: none;
  `};
  ${props => props.theme.media.tablet`
    display: none;
  `};
`;

export const ShowDevice = styled.div`
  ${props => props.theme.media.desktop`
    display: none;
  `};
`;
