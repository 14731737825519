import PropTypes from 'prop-types';
import Popup from 'modules/common/components/Popup';
import {
  PopupTitleSpan,
  PopupWrapper,
  PopupTitleWrapper,
  Container,
  PopupTitleContainer,
  PopupTitle,
  AttachementBigImage,
  AttachementNote,
  AttachementContentContainer,
} from './ItemizationItemsModal.style';

const AttachementImageModal = ({ onClose, attachementToBeItemized }) => {
  const onCloseModal = () => {
    onClose();
  };

  return (
    <Popup showCloseBtn={false}>
      <PopupWrapper
        style={{
          width: '670px',
        }}
      >
        <PopupTitleContainer>
          <PopupTitleWrapper>
            <PopupTitle>Order by Photo</PopupTitle>
            <PopupTitleSpan
              onClick={() => {
                onCloseModal();
              }}
            />
          </PopupTitleWrapper>
        </PopupTitleContainer>

        <Container>
          <AttachementContentContainer>
            <AttachementNote>{attachementToBeItemized.note}</AttachementNote>
            <AttachementBigImage
              src={attachementToBeItemized.url}
              alt={attachementToBeItemized.key}
            />
          </AttachementContentContainer>
        </Container>
      </PopupWrapper>
    </Popup>
  );
};

AttachementImageModal.propTypes = {
  attachementToBeItemized: PropTypes.shape({
    key: PropTypes.string,
    url: PropTypes.string,
    note: PropTypes.string,
    attachmentType: PropTypes.string,
  }).isRequired,
  orderDetails: PropTypes.shape({
    details: PropTypes.shape({
      mappedItemsList: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
  onClose: PropTypes.func,
  orderItemsSuggestions: PropTypes.shape({
    status: PropTypes.string,
    details: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default AttachementImageModal;
