import PropTypes from 'prop-types';
import { CheckboxGroupWrapper, CheckboxWrapper, Checkbox, Label } from './CheckboxGroup.style';

const CheckboxGroup = ({ options, selectedOptions, setSelectedOptions }) => {
  const handleCheckboxButtonChange = value => {
    const optionIndex = selectedOptions.findIndex(option => option.serviceKey === value.serviceKey);
    if (optionIndex > -1) {
      const tempSelected = [...selectedOptions];
      tempSelected.splice(optionIndex, 1);
      setSelectedOptions(tempSelected);
    } else setSelectedOptions([...selectedOptions, value]);
  };

  return (
    <CheckboxGroupWrapper>
      {options.map(option => (
        <CheckboxWrapper>
          <Checkbox
            id={`option-id-${option.serviceKey}`}
            name={option.serviceName}
            type="checkbox"
            value={option}
            checked={
              selectedOptions.findIndex(selected => selected.serviceKey === option.serviceKey) > -1
            }
            onChange={() => handleCheckboxButtonChange(option)}
          />
          <Label for={`option-id-${option.serviceKey}`}>{option.serviceName}</Label>
        </CheckboxWrapper>
      ))}
    </CheckboxGroupWrapper>
  );
};

CheckboxGroup.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setSelectedOptions: PropTypes.func.isRequired,
};

export default CheckboxGroup;
