import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Calendar } from 'react-date-range';
import { getDateDetailsFromDateTimeObj, checkIfDateHasPassed } from 'utils/functions/dateTime';

import {
  DatePickerWrapper,
  DatePickerSelectedDateWrapper,
  DatePickerSelectedDateText,
  DatePickerSelectedDateIcon,
  DatePickerSelectorWrapper,
} from './DatePicker.style';

// ###############################################################
// <------------------------ Constants -------------------------->
// ###############################################################
// ###############################################################
// <---------------------- End Constants ------------------------>
// ###############################################################
const DatePicker = ({ selectedDate, handleDatePickerValueChange }) => {
  // ###############################################################
  // <------------------------- Globals --------------------------->
  // ###############################################################
  const datePickerWrapperRef = useRef(null);
  // ###############################################################
  // <----------------------- End Globals ------------------------->
  // ###############################################################
  // ###############################################################
  // <------------------------ useState --------------------------->
  // ###############################################################
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  // ###############################################################
  // <---------------------- End useState ------------------------->
  // ###############################################################
  // ###############################################################
  // <------------------------ useEffect -------------------------->
  // ###############################################################
  useEffect(() => {
    const handleClickOutside = event => {
      if (
        datePickerWrapperRef.current &&
        datePickerWrapperRef.current.contains(event.target) === false
      )
        setIsDatePickerOpen(false);
    };
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [datePickerWrapperRef]);
  // ###############################################################
  // <---------------------- End useEffect ------------------------>
  // ###############################################################
  // ###############################################################
  // <--------------------- rest of hooks ------------------------->
  // ###############################################################
  // ###############################################################
  // <-------------------- End rest of hooks ---------------------->
  // ###############################################################
  // ###############################################################
  // <-------------------------- Logic ---------------------------->
  // ###############################################################
  // ###############################################################
  // <------------------------ End Logic -------------------------->
  // ###############################################################
  // ###############################################################
  // <------------------------- Actions --------------------------->
  // ###############################################################
  // ###############################################################
  // <----------------------- End Actions ------------------------->
  // ###############################################################
  // ###############################################################
  // <----------------------- UI Handlers ------------------------->
  // ###############################################################
  const handleDatePickerLabelClick = () => {
    setIsDatePickerOpen(prevState => !prevState);
  };

  const handleDatePickerChange = date => {
    handleDatePickerValueChange(date);

    setIsDatePickerOpen(false);
  };
  // ###############################################################
  // <--------------------- End UI Handlers ----------------------->
  // ###############################################################
  // ###############################################################
  // <-------------------------- Render --------------------------->
  // ###############################################################
  // ###############################################################
  // <------------------------ End Render ------------------------->
  // ###############################################################
  // ###############################################################
  // <------------------------- Return UI ------------------------->
  // ###############################################################
  const { day, month, year } = getDateDetailsFromDateTimeObj(selectedDate);
  const selectedDateStr = `${day} ${month} ${year}`;

  return (
    <DatePickerWrapper ref={datePickerWrapperRef}>
      <DatePickerSelectedDateWrapper onClick={() => handleDatePickerLabelClick()}>
        <DatePickerSelectedDateText>{selectedDateStr}</DatePickerSelectedDateText>
        <DatePickerSelectedDateIcon />
      </DatePickerSelectedDateWrapper>
      {isDatePickerOpen && (
        <DatePickerSelectorWrapper data-testid="data-picker-selector">
          <Calendar
            date={selectedDate}
            disabledDay={date => checkIfDateHasPassed(date)}
            onChange={date => handleDatePickerChange(date)}
          />
        </DatePickerSelectorWrapper>
      )}
    </DatePickerWrapper>
  );
  // ###############################################################
  // <----------------------- End Return UI ----------------------->
  // ###############################################################
};

DatePicker.propTypes = {
  selectedDate: PropTypes.shape({}).isRequired,
  handleDatePickerValueChange: PropTypes.func.isRequired,
};

DatePicker.defaultProps = {};

export default DatePicker;
