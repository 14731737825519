export const addPaddingZerosToNum = (number, numberOfZeros, resultLength) => {
  // const arrayOfZeros = Array(numberOfZeros).fill('0');
  // const numberConvertedToArr = number.toString().split('');
  // const concatinatedArr = arrayOfZeros.concat(numberConvertedToArr);
  // const resultArr = concatinatedArr.slice(
  //   concatinatedArr.length - resultLength,
  //   concatinatedArr.length,
  // );
  // return resultArr.join('');

  const zerosStr = Array(numberOfZeros).fill('0').join();
  const concatinatedStr = zerosStr + number;
  const resultStr = concatinatedStr.slice(-resultLength);
  return resultStr;
};

export const convertArabicNumbersToEnglish = number =>
  number.replace(/[٠-٩]/g, d => '٠١٢٣٤٥٦٧٨٩'.indexOf(d));

export const resolvePathArray = (path, obj) =>
  path.reduce((prev, curr) => (prev ? prev[curr] : null), obj);

export const serializeParams = params => {
  const keys = Object.keys(params);
  let options = '';
  keys?.forEach(key => {
    if (Array.isArray(params[key])) {
      params[key]?.forEach(element => {
        options += `${key}=${element}&`;
      });
    } else if (key === 'beginDate' || key === 'endDate') {
      const date = new Date(params[key]).toISOString();
      options += `${key}=${date}&`;
    } else options += `${key}=${params[key]}&`;
  });
  return options ? options.slice(0, -1) : options;
};
