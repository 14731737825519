import PropTypes from 'prop-types';
import { SnackBarContainer } from './SnackBar.style';

const SnackBar = ({ show, message }) => (
  <SnackBarContainer show={show}>{message}</SnackBarContainer>
);

SnackBar.propTypes = {
  message: PropTypes.string,
  show: PropTypes.bool,
};

export default SnackBar;
