/* eslint-disable camelcase */
import Icon from 'modules/common/components/Icon';
import { StockFileAnchor, EditIconContainer, extendStyle } from './Stock.style';

export const SIZE_OF_PAGE = 10;

export const VIEW = {
  CURRENT_STOCK: 0,
  STOCK_HISTORY: 1,
};

export const getTableHeadersLocalized = () => [
  {
    id: 0,
    name: 'Item name',
  },
  {
    id: 1,
    name: 'Available Quantity',
  },
  {
    id: 2,
    name: 'Price',
  },
  {
    id: 3,
    name: 'Edit',
  },
];

export const getTableHeadersStockHistoryLocalized = () => [
  {
    id: 0,
    name: 'Name',
  },
  {
    id: 1,
    name: 'Available Products Count',
  },
  {
    id: 2,
    name: 'Upload Status',
  },
  {
    id: 3,
    name: 'Date',
  },
];

export const getMainRowItems = (pharmacyStock, toggleEditStockItemModal, addToStockItemsCart) => {
  const recordsRows = [];
  pharmacyStock?.forEach(item => {
    const { sellingPrice, product, stockQuantity, pharmacyCode } = item;
    const { mainImageUrl, productTranslators, key } = product;
    const productName = productTranslators.find(prod => prod.lang === 'en')?.name;
    recordsRows.push({
      key,
      mainRow: {
        c0: {
          main: productName,
          additional: '',
          showToolTip: false,
          isBold: true,
        },
        c1: {
          main: stockQuantity > 0 ? 'Available' : 'Unavailable',
          additional: '',
        },
        c2: {
          main: `${sellingPrice} L.E.`,
          additional: '',
        },
        c3: {
          main: (
            <EditIconContainer>
              <Icon
                onClick={() => {
                  addToStockItemsCart({
                    ProductKey: key,
                    PharmacyCode: pharmacyCode,
                    StockQuantity: stockQuantity,
                    SellingPrice: sellingPrice,
                    itemName: productName,
                    productShape: '',
                    itemImage: mainImageUrl,
                  });
                  toggleEditStockItemModal(true);
                }}
                extendStyle={extendStyle}
                icon="e92c"
                size={14}
              />
            </EditIconContainer>
          ),
          additional: '',
        },
      },
    });
  });

  return recordsRows;
};

export const UPLOAD_STATUS = {
  BeingProcessed: 0,
  ProcessedSuccessfully: 1,
  FailedProcessing: 2,
  FailedValidation: 3,
  PartiallyProcessed: 4,
};

export const UPLOAD_STATUS_Label = {
  0: 'Being Processed',
  1: 'Processed Successfully',
  2: 'Failed Processing',
  3: 'Failed Validation',
  4: 'Partially Processed',
};

const formatDateTime = (dateString, langCode) => {
  const day = new Date(`${dateString}Z`).toLocaleString(langCode, { day: '2-digit' });
  const month = new Date(`${dateString}Z`).toLocaleString(langCode, { month: 'long' });
  const year = new Date(`${dateString}Z`).toLocaleString(langCode, { year: 'numeric' });
  const time = new Date(`${dateString}Z`)?.toLocaleTimeString('en-EG', {
    hour: '2-digit',
    hour12: true,
    minute: '2-digit',
  });
  return `${day} ${month} ${year}, ${time}`;
};

export const getMainRowItemsStockHistory = pharmacyStockHistory => {
  const recordsRows = [];
  pharmacyStockHistory?.forEach(item => {
    const { createdOn, filePath, fileName, id, status, avaialablProductsCount } = item;
    recordsRows.push({
      key: id,
      mainRow: {
        c0: {
          main: <StockFileAnchor href={filePath}>{fileName}</StockFileAnchor>,
          additional: '',
          showToolTip: false,
          isBold: true,
        },
        c1: {
          main: avaialablProductsCount,
          additional: '',
        },
        c2: {
          main: UPLOAD_STATUS_Label[status],
          additional: '',
        },
        c3: {
          main: `${formatDateTime(createdOn, 'en-EG')}`,
          additional: '',
        },
      },
    });
  });

  return recordsRows;
};
