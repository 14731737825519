import PropTypes from 'prop-types';
import Popup from 'modules/common/components/Popup';
import SectionDivider from 'modules/common/components/SectionDivider';
import DatePicker from 'modules/common/components/DatePicker';
import TimeSlotsDropdown from 'modules/common/components/TimeSlotsDropdown';
import RadioButtonGroup from 'modules/common/components/RadioButtonGroup';
import Loader from 'modules/common/components/Loader';
import { status } from 'constants/general';
import { reasonsToEnableSelectItemsSection } from 'modules/orders/OrdersView/OrdersContainer/enums';
import { renderSelectItemsSection } from './SelectItemsSection';

import {
  OrderDetailsScheduleOrderPopupWrapper,
  OrderDetailsScheduleOrderPopupTitleWrapper,
  OrderDetailsScheduleOrderPopupTitle,
  OrderDetailsScheduleOrderPopupTitleSpan,
  OrderDetailsScheduleOrderSectionTitle,
  OrderDetailsScheduleOrderDateTimeSectionWrapper,
  OrderDetailsScheduleOrderDateTimeWrapper,
  OrderDetailsScheduleOrderReasonsWrapper,
  OrderDetailsScheduleOrderNoteWrapper,
  OrderDetailsScheduleOrderNoteTextArea,
  OrderDetailsScheduleOrderBtnWrapper,
  OrderDetailsScheduleOrderSaveBtn,
  OrderDetailsScheduleOrderCancelBtn,
  OrderDetailsScheduleOrderError,
} from '../OrderActionBtnsSection/OrderDetailsActionBtns.style';

const RescheduleOrderModal = ({
  themeContext,
  stringsData,
  isScheduleOrderPopupLoading,
  scheduleOrderSelectedDate,
  scheduleOrderTimeSlotsArr,
  scheduleOrderSelectedTime,
  scheduleOrderReason,
  scheduleOrderNote,
  handleScheduleOrderDatePickerChange,
  handleScheduleOrderTimeSlotDropdownMenuChange,
  handleScheduleOrderReasonRadioButtonClick,
  handleScheduleOrderNoteChange,
  handleScheduleOrderPopupConfirmBtnClick,
  handleScheduleOrderPopupDismissBtnClick,
  scheduleOrderReasons,
  scheduleOrderReasonIsRequiredError,
  orderData,
  selectedItems,
  setSelectedItems,
  selectItemsSearchApi,
}) => {
  let scheduleOrderReasonsArr = [];
  if (scheduleOrderReasons.status === status.SUCCESS) {
    scheduleOrderReasonsArr = scheduleOrderReasons.data.map(reason => ({
      name: reason.name,
      key: reason.id,
    }));
  }

  return (
    <Popup showCloseBtn={false}>
      <OrderDetailsScheduleOrderPopupWrapper>
        <OrderDetailsScheduleOrderPopupTitleWrapper>
          <OrderDetailsScheduleOrderPopupTitle as="p">
            {stringsData.orderDetailsHeader.scheduleOrderTitle}
          </OrderDetailsScheduleOrderPopupTitle>
          <OrderDetailsScheduleOrderPopupTitleSpan
            as="span"
            onClick={() => handleScheduleOrderPopupDismissBtnClick()}
          />
        </OrderDetailsScheduleOrderPopupTitleWrapper>
        <SectionDivider
          gap={16}
          withSeparator
          separatorGap={16}
          borderColor={themeContext.colors.grey004}
        />
        <OrderDetailsScheduleOrderDateTimeSectionWrapper>
          <OrderDetailsScheduleOrderDateTimeWrapper>
            <OrderDetailsScheduleOrderSectionTitle as="p">
              {stringsData.orderDetailsHeader.scheduleOrderDateLabel}
            </OrderDetailsScheduleOrderSectionTitle>
            <SectionDivider gap={4} />
            <DatePicker
              selectedDate={scheduleOrderSelectedDate}
              handleDatePickerValueChange={handleScheduleOrderDatePickerChange}
            />
          </OrderDetailsScheduleOrderDateTimeWrapper>
          <OrderDetailsScheduleOrderDateTimeWrapper>
            <OrderDetailsScheduleOrderSectionTitle as="p">
              {stringsData.orderDetailsHeader.scheduleOrderTimeLabel}
            </OrderDetailsScheduleOrderSectionTitle>
            <SectionDivider gap={4} />
            <TimeSlotsDropdown
              timeSlotsArr={scheduleOrderTimeSlotsArr}
              selectedTimeSlot={scheduleOrderSelectedTime}
              handleTimeSlotDropdownMenuChange={handleScheduleOrderTimeSlotDropdownMenuChange}
            />
          </OrderDetailsScheduleOrderDateTimeWrapper>
        </OrderDetailsScheduleOrderDateTimeSectionWrapper>
        <SectionDivider
          gap={16}
          withSeparator
          separatorGap={16}
          borderColor={themeContext.colors.grey004}
        />
        <OrderDetailsScheduleOrderReasonsWrapper>
          <OrderDetailsScheduleOrderSectionTitle as="p">
            {stringsData.orderDetailsHeader.scheduleOrderReasonTitle}
          </OrderDetailsScheduleOrderSectionTitle>
          <SectionDivider gap={14} />
          <RadioButtonGroup
            RadioButtonGroupArr={scheduleOrderReasonsArr}
            selectedRadioButton={scheduleOrderReason}
            handleRadioButtonClick={handleScheduleOrderReasonRadioButtonClick}
          />
          {renderSelectItemsSection(
            orderData,
            scheduleOrderReason,
            selectedItems,
            setSelectedItems,
            selectItemsSearchApi,
          )}
          {scheduleOrderReasonIsRequiredError && (
            <OrderDetailsScheduleOrderError>
              {stringsData.orderDetailsHeader.scheduleOrderReasonIsRequired}
            </OrderDetailsScheduleOrderError>
          )}
        </OrderDetailsScheduleOrderReasonsWrapper>
        <SectionDivider
          gap={22}
          withSeparator
          separatorGap={16}
          borderColor={themeContext.colors.grey004}
        />
        <OrderDetailsScheduleOrderNoteWrapper>
          <OrderDetailsScheduleOrderSectionTitle as="p">
            {stringsData.orderDetailsHeader.scheduleOrderNoteTitle}
          </OrderDetailsScheduleOrderSectionTitle>
          <SectionDivider gap={8} />
          <OrderDetailsScheduleOrderNoteTextArea
            placeholder={stringsData.orderDetailsHeader.scheduleOrderNotePlaceholder}
            value={scheduleOrderNote}
            onChange={event => handleScheduleOrderNoteChange(event)}
          />
        </OrderDetailsScheduleOrderNoteWrapper>
        <SectionDivider
          gap={16}
          withSeparator
          separatorGap={16}
          borderColor={themeContext.colors.grey004}
        />
        <OrderDetailsScheduleOrderBtnWrapper>
          <OrderDetailsScheduleOrderCancelBtn
            as="button"
            onClick={() => handleScheduleOrderPopupDismissBtnClick()}
          >
            {stringsData.orderDetailsHeader.scheduleOrderCancelBtn}
          </OrderDetailsScheduleOrderCancelBtn>
          <OrderDetailsScheduleOrderSaveBtn
            as="button"
            onClick={() => handleScheduleOrderPopupConfirmBtnClick()}
            disabled={
              reasonsToEnableSelectItemsSection[scheduleOrderReason] && selectedItems.length === 0
            }
          >
            {(isScheduleOrderPopupLoading && <Loader width={14} height={14} thickness={4} />) ||
              stringsData.orderDetailsHeader.scheduleOrderSaveBtn}
          </OrderDetailsScheduleOrderSaveBtn>
        </OrderDetailsScheduleOrderBtnWrapper>
      </OrderDetailsScheduleOrderPopupWrapper>
    </Popup>
  );
};

RescheduleOrderModal.propTypes = {
  themeContext: PropTypes.shape({
    colors: PropTypes.shape({
      grey004: PropTypes.string,
    }),
  }).isRequired,
  stringsData: PropTypes.shape({
    orderDetailsHeader: PropTypes.shape({
      scheduleOrderTitle: PropTypes.string,
      scheduleOrderDateLabel: PropTypes.string,
      scheduleOrderTimeLabel: PropTypes.string,
      scheduleOrderReasonTitle: PropTypes.string,
      scheduleOrderReasons: PropTypes.shape({
        patientRequest: PropTypes.string,
        fulfillmentIssue: PropTypes.string,
        patientUnreachable: PropTypes.string,
        businessDevelopmentOrder: PropTypes.string,
      }),
      scheduleOrderInsuranceTitle: PropTypes.string,
      scheduleOrderInsuranceSystemDown: PropTypes.string,
      scheduleOrderNoteTitle: PropTypes.string,
      scheduleOrderNotePlaceholder: PropTypes.string,
      scheduleOrderSaveBtn: PropTypes.string,
      scheduleOrderCancelBtn: PropTypes.string,
      scheduleOrderReasonIsRequired: PropTypes.string,
    }),
  }).isRequired,
  isScheduleOrderPopupLoading: PropTypes.bool.isRequired,
  scheduleOrderSelectedDate: PropTypes.shape({}).isRequired,
  scheduleOrderTimeSlotsArr: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  scheduleOrderSelectedTime: PropTypes.shape({}).isRequired,
  scheduleOrderReason: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  scheduleOrderNote: PropTypes.string.isRequired,
  handleScheduleOrderDatePickerChange: PropTypes.func.isRequired,
  handleScheduleOrderTimeSlotDropdownMenuChange: PropTypes.func.isRequired,
  handleScheduleOrderReasonRadioButtonClick: PropTypes.func.isRequired,
  handleScheduleOrderNoteChange: PropTypes.func.isRequired,
  handleScheduleOrderPopupConfirmBtnClick: PropTypes.func.isRequired,
  handleScheduleOrderPopupDismissBtnClick: PropTypes.func.isRequired,
  scheduleOrderReasons: PropTypes.shape({
    status: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  scheduleOrderReasonIsRequiredError: PropTypes.bool,
  orderData: PropTypes.shape({}),
  selectedItems: PropTypes.shape([]),
  setSelectedItems: PropTypes.func,
  selectItemsSearchApi: PropTypes.func,
};

RescheduleOrderModal.defaultProps = {
  scheduleOrderReasonIsRequiredError: false,
  orderData: {},
  selectedItems: [],
  setSelectedItems: () => {},
  selectItemsSearchApi: () => {},
};

export default RescheduleOrderModal;
