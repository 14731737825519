/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Pagination from './Pagination';
import TableRow from './TableRow';
import {
  TableContainer,
  TableHeader,
  HeaderGrid,
  RowItem,
  PaginationContainer,
  MainItem,
  AdditionalItem,
  HeaderRowItems,
  TooltipContainer,
  RowItemWrapper,
  RowItemContentWrapper,
  InnerWrapper,
  HeaderRowItemsPhone,
} from './Table.styles';

class Table extends React.Component {
  setPage = selectedPage => {
    const { onClickPage } = this.props;
    onClickPage(selectedPage);
  };

  render() {
    const {
      data,
      onClickRecord,
      extendPageTag,
      extendTableWidth,
      tableColumnHeaders,
      extendTableGrid,
      totalNumberOfPages,
      pagination,
      isExpandableRows,
      language,
      extendTableRow,
      extendTableHeaderRow,
      extendMainItem,
      extendAdditionalItem,
      selectedPage,
      source,
      extendHeaderRowItems,
    } = this.props;
    return (
      <TableContainer extendTableWidth={extendTableWidth}>
        <TableHeader extendTableHeaderRow={extendTableHeaderRow}>
          <HeaderGrid extendTableGrid={extendTableGrid}>
            {tableColumnHeaders &&
              tableColumnHeaders.map(header => (
                <HeaderRowItems
                  extendHeaderRowItems={extendHeaderRowItems}
                  key={`rowItem-item-${header.id}`}
                  language={language}
                >
                  {header.name}
                </HeaderRowItems>
              ))}
          </HeaderGrid>
        </TableHeader>
        {data &&
          data.map((record, index) => {
            const recordLenght = Object.keys(record.mainRow).length;
            const items = [];
            for (let i = 0; i < recordLenght; i += 1) {
              const { additional, isIcon, showToolTip, main, isBold, onClickAction } =
                record.mainRow[`c${String(i)}`];
              items.push(
                <RowItem language={language} key={`${record.key}-${i}`}>
                  <RowItemWrapper isOneRow={!additional && !isIcon}>
                    <InnerWrapper>
                      <HeaderRowItemsPhone key={`rowItem-item-${i}`} language={language}>
                        {tableColumnHeaders && tableColumnHeaders[i] && tableColumnHeaders[i].name}
                      </HeaderRowItemsPhone>
                      <RowItemContentWrapper>
                        {showToolTip && main && (
                          <TooltipContainer language={language}>{main}</TooltipContainer>
                        )}
                        <div>
                          <div style={{ display: 'block', height: '100%' }}>
                            <MainItem
                              isRtl={language === 'ar'}
                              onClick={() => {
                                if (onClickAction) onClickAction();
                              }}
                              isClickable={onClickAction}
                              isBold={isBold}
                              extendMainItem={extendMainItem}
                            >
                              {main}
                            </MainItem>
                          </div>
                          {additional ? (
                            <div style={{ display: 'block', height: '100%' }}>
                              <AdditionalItem
                                isRtl={language === 'ar'}
                                extendAdditionalItem={extendAdditionalItem}
                              >
                                {additional}
                              </AdditionalItem>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </RowItemContentWrapper>
                    </InnerWrapper>
                  </RowItemWrapper>
                </RowItem>,
              );
            }
            return (
              <TableRow
                extendTableRow={extendTableRow}
                onClickRecord={onClickRecord}
                extendTableGrid={extendTableGrid}
                record={record}
                items={items}
                isExpandableRows={isExpandableRows}
                language={language}
                rowIndex={index}
                source={source}
                key={record.key}
              />
            );
          })}
        {pagination && (
          <PaginationContainer>
            <Pagination
              extendPageTag={extendPageTag}
              totalNumberOfPages={totalNumberOfPages}
              selectedPage={selectedPage}
              setPage={this.setPage}
              language={language}
            />
          </PaginationContainer>
        )}
      </TableContainer>
    );
  }
}

Table.propTypes = {
  source: PropTypes.string,
  data: PropTypes.array,
  onClickRecord: PropTypes.func,
  extendPageTag: PropTypes.object,
  extendTableWidth: PropTypes.object,
  tableColumnHeaders: PropTypes.array,
  extendTableGrid: PropTypes.array,
  extendHeaderRowItems: PropTypes.array,
  totalNumberOfPages: PropTypes.number,
  pagination: PropTypes.bool,
  language: PropTypes.string,
  extendTableRow: PropTypes.array,
  extendTableHeaderRow: PropTypes.array,
  isExpandableRows: PropTypes.bool,
  extendMainItem: PropTypes.array,
  extendAdditionalItem: PropTypes.array,
  selectedPage: PropTypes.number,
  onClickPage: PropTypes.func,
};

export default Table;
