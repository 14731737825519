import { get } from 'utils/functions/request';
import urls from 'utils/urls';

export const getUsers = ({ query, data }) => {
  let url = urls.pharmacyModuleUrls.commonUrls.getUsers;
  if (query) url = `${urls.pharmacyModuleUrls.commonUrls.getUsers}?${query}`;

  return get(null, false, url, data);
};

export const getPharmacies = () =>
  get(null, false, urls.pharmacyModuleUrls.commonUrls.getPharmacies);

export const getServiceableAreas = () =>
  get(null, false, `${urls.pharmacyModuleUrls.commonUrls.getServiceableAreas}?countryISO=eg`);

export const getProducts = query =>
  get(
    null,
    false,
    `${urls.pharmacyModuleUrls.commonUrls.getProducts}?from=1&size=5&isTrending=false&Version=2&query=${query}`,
  );

export const getProductItems = ({ productKeys, headers }) => {
  const url = `${urls.pharmacyModuleUrls.commonUrls.getProductItems}?productKeys=${productKeys}`;

  return get(headers, false, url, null);
};

export const searchProductItems = payload =>
  get(
    null,
    false,
    `${
      urls.pharmacyModuleUrls.pharmacyStock.searchStockItems
    }?from=1&size=30&isTrending=false&Version=2&query=${payload?.searchTerm || ''}`,
  );
export const getInsuranceProviderByKey = key =>
  get(
    null,
    false,
    `${urls.pharmacyModuleUrls.commonUrls.getInsuranceProviderByKey}?countryId=1&ids=${key}`,
  );
