import styled from 'styled-components';

export const ItemContainer = styled.div`
  box-shadow: 0px -1px 0px 0px #e3e6ea inset;
  padding: 12px 16px;
`;

export const ItemWrapper = styled.div`
  display: flex;
  padding: 0;
  display: grid;
  grid-template-columns: 67% 11% 13% 9%;
`;
export const ItemImage = styled.img`
  width: 40px;
  height: 40px;
`;

export const ItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ItemName = styled.p`
  color: #484848;
  font-size: 14px;
  margin: 0;
`;

export const ItemDescription = styled.p`
  margin: 0;
  font-size: 13px;
  line-height: 14px;
  color: #9c9c9c;
`;
