import { get, post } from 'utils/functions/request';
import urls from 'utils/urls';

export const authenticateUser = ({ data }) =>
  post(null, true, urls.pharmacyModuleUrls.accountManagementUrls.authenticateUser, data);

export const getLoginInfo = ({ userKey }) =>
  get(null, false, `${urls.pharmacyModuleUrls.accountManagementUrls.getLoginInfo}/${userKey}`);

export const getUserPermissions = () =>
  get(null, false, urls.pharmacyModuleUrls.accountManagementUrls.getUserPermissions);
