import styled, { css } from 'styled-components';
import themeData from 'utils/theme/themeData';

const { typography } = themeData;

export const extendAddToOrderButtonStyle = css`
  border-radius: 8px;
  border: 1px solid #0070cd;
  background: #0070cd;
  color: #fff;
  min-width: 120px;
  height: 14px;
`;

// ###############################################################
// <-------------------------- Common --------------------------->
// ###############################################################
export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
`;

export const OrderDetailsTitle = styled.div`
  display: flex;
  gap: 8px;
  ${({ showCursor }) =>
    showCursor &&
    `
    cursor: pointer;
  `}
`;

export const OrderDetailsText = styled(typography.BodyTextOneRegularTypography)`
  margin: 0;
  display: flex;
  gap: 8px;
  white-space: pre-line;
  color: ${({ theme, blueColor }) => (blueColor && theme.colors.blueMain) || theme.colors.black};

  ${({ showCursor }) =>
    showCursor &&
    `
    cursor: pointer;
  `}
`;

export const OrderDetailsSubText = styled(typography.BodyTextOneRegularTypography)`
  margin: 0;
  color: ${({ theme }) => theme.colors.grey002};
`;
// ###############################################################
// <------------------------ End Common ------------------------->
// ###############################################################

// ###############################################################
// <------------------------- Wrappers -------------------------->
// ###############################################################
export const OrderDetailsNoDataText = styled(typography.TitleOneRegularTypography)`
  margin: 0;
`;

export const OrderDetailsWrapper = styled.div`
  min-height: calc(100vh - 82px);
  height: 100%;
  width: 100%;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.grey100};
`;

export const OrderDetailsContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const OrderDetailsContentFirstColumn = styled.div`
  width: 100%;
`;

export const OrderDetailsContentSecondColumn = styled.div`
  width: 100%;
`;

export const OrderDetailsInfoSection = styled.div`
  padding: 24px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgb(44 53 68 / 8%);
`;

export const OrderDetailsLogsSection = styled.div`
  padding: 24px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgb(44 53 68 / 8%);
`;

export const OrderDetailsStatusSection = styled.div`
  display: flex;
  gap: 7px;
  align-items: center;
  justify-content: center;
  padding: 18px 16px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgb(44 53 68 / 8%);
`;

export const OrderDetailsChemistSection = styled.div`
  display: flex;
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgb(44 53 68 / 8%);

  & > * + * {
    margin-inline-start: 16px;
  }
`;

export const OrderDetailsAttachmentSectionContainer = styled.div`
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgb(44 53 68 / 8%);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const OrderDetailsAttachResultsSection = styled.div`
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgb(44 53 68 / 8%);
`;

export const OrderDetailsNoteSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgb(44 53 68 / 8%);
`;
// ###############################################################
// <----------------------- End Wrappers ------------------------>
// ###############################################################

// ###############################################################
// <------------------------ Order Info ------------------------->
// ###############################################################
export const OrderDetailsInfoNumberSectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const OrderDetailsInfoNumberInfoWrapper = styled.div`
  width: 216px;
`;

export const OrderDetailsInfoSticker = styled(typography.BodyTextFourRegularTypography)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  color: ${({ color }) => color};

  &:before {
    content: ${({ icon }) => icon};
    font-family: 'Icomoon';
    font-size: 18px;
  }
`;

export const OrderDetailsInfoBadgesWrapper = styled.div``;

export const OrderDetailsInfoBadges = styled(typography.BodyTextFourRegularTypography)`
  display: inline-block;
  margin: 0;
  margin-right: 4px;
  padding: 2px 6px;
  color: ${({ textColor }) => textColor};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 4px;
`;

export const OrderDetailsInfoPatientUserDetailsTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  align-items: start;
  cursor: pointer;
  color: #0070cd;
`;

export const OrderDetailsInfoPatientUserDetailsTitleIcon = styled.div`
  margin-inline-start: auto;
  cursor: pointer;
  &:before {
    content: '\\e93d';
    font-family: 'Icomoon';
    font-size: 13px;
    color: ${({ theme }) => theme.colors.grey005};
  }
`;

export const OrderDetailsInfoContent = styled.div`
  display: grid;
  grid-template: 1fr / 96px 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 4px;
  align-items: flex-start;
`;

export const OrderDetailsInfoImgWrapper = styled.div`
  position: relative;
  padding-top: 100%;
`;

export const OrderDetailsInfoImg = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

export const OrderNumberOverlay = styled.div`
  position: fixed;
  bottom: 40px;
  left: 50%;
  margin-left: -10px;
  padding: 15px 30px;
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  z-index: 1;
`;

export const extendOrderDetailsPatientUserInfoLoaderStyle = css`
  margin: auto;
`;
// ###############################################################
// <---------------------- End Order Info ----------------------->
// ###############################################################

// ###############################################################
// <----------------------- Order Status ------------------------>
// ###############################################################
export const OrderDetailsStatusIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const OrderDetailsStatusIcon = styled.div`
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.grey000};
  border-radius: 50%;
  box-sizing: border-box;

  ${({ color, theme, isOrderAborted }) =>
    color &&
    `
    background-color: ${color};
    border-color: ${color};
    ${
      isOrderAborted
        ? `
    &:before {
      content: '\\e93d';
      font-family: 'Icomoon';
      color: ${theme.colors.white};
    }`
        : `
    &:before {
      content: '\\e92a';
      font-family: 'Icomoon';
      color: ${theme.colors.white};
    }
    `
    }
  `}
`;

export const OrderDetailsStatusTextWrapper = styled.div`
  width: max-content;
  display: flex;
`;

export const OrderDetailsStatusText = styled(typography.BodyTextOneRegularTypography)`
  width: 75px;
  min-height: 36px;
  text-align: center;

  left: -50%;
  margin: auto 0;
  color: ${({ theme }) => theme.colors.grey005};
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  ${({ color }) =>
    color &&
    `
    color: ${color};
  `}
`;

export const OrderDetailsStatusLine = styled.div`
  display: flex;
  &:before {
    content: '\\e909';
    font-family: 'Icomoon';
    color: ${props => props.color || '#D9DBDF'};
    font-size: 12px;
    margin: 0 auto;
    transform: scaleX(-1);
  }
`;
// ###############################################################
// <--------------------- End Order Status ---------------------->
// ###############################################################

// ###############################################################
// <---------------------- Order Chemist ------------------------>
// ###############################################################
export const OrderDetailsChemistDropdownWrapper = styled.div`
  flex: 1;
`;
// ###############################################################
// <-------------------- End Order Chemist ---------------------->
// ###############################################################

// ###############################################################
// <-------------------- Order Attachment ----------------------->
// ###############################################################
export const OrderDetailsAttachmentWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  & > * + * {
    margin-inline-start: 16px;
  }
`;

export const OrderDetailsAttachmentImgWrapper = styled.div`
  position: relative;
  width: 80px;
`;

export const OrderDetailsAttachmentImgRatio = styled.div`
  padding-top: 100%;
`;

export const OrderDetailsAttachmentImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const OrderDetailsAttachmentImgFullSize = styled.img`
  max-width: 100%;
  max-height: 100vh;
`;

export const OrderDetailsAttachmentInfoWrapper = styled.div``;
// ###############################################################
// <------------------ End Order Attachment --------------------->
// ###############################################################

// ###############################################################
// <------------------- Order Attach Results -------------------->
// ###############################################################
export const OrderDetailsAttachResultsBtnWrapper = styled.div`
  text-align: center;
`;

export const OrderDetailsAttachResultsFilesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 20px;
`;

export const OrderDetailsAttachResultsFileItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const OrderDetailsAttachResultsFileItemCopyText = styled(
  typography.BodyTextOneRegularTypography,
)`
  margin: 0;
  cursor: pointer;
`;

export const OrderDetailsAttachResultsFileItem = styled.div`
  position: relative;
  width: 100%;
  cursor: pointer;
`;

export const OrderDetailsAttachResultsFileItemPadding = styled.div`
  padding-top: 100%;
`;

export const OrderDetailsAttachResultsFileImg = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;

  ${({ opacity }) =>
    opacity &&
    `
    opacity: ${opacity};
  `}
  ${({ blur }) =>
    blur &&
    `
    filter: blur(${blur}px);
  `}
`;

export const OrderDetailsAttachResultsResizedFileImg = styled.img`
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  top: 0;
`;

export const OrderDetailsAttachResultsImgPreview = styled.img`
  max-width: 100%;
  max-height: 100vh;
`;

export const OrderDetailsAttachResultsBtn = styled(typography.BodyTextOneRegularTypography)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 46px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.greenMain};
  border: 1px solid ${({ theme }) => theme.colors.greenMain};
  border-radius: 4px;
  cursor: pointer;
`;

export const extendOrderDetailsAttachResultsLoaderStyle = css`
  margin: auto;
`;

export const extendOrderDetailsAttachResultsBtnLoaderStyle = css`
  border: 4px solid white;
  border-top: 4px solid ${({ theme }) => theme.colors.greenMain};
`;
// ###############################################################
// <----------------- End Order Attach Results ------------------>
// ###############################################################

// ###############################################################
// <------------------------ Order Note ------------------------->
// ###############################################################
export const OrderDetailsNoteTextArea = styled.textarea`
  width: 100%;
  height: 56px;
  padding: 8px 12px;
  border: 1px solid ${({ theme }) => theme.colors.grey000};
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
  vertical-align: top;
  resize: none;

  &::-webkit-input-placeholder {
    font-family: 'Lato';
    font-weight: 400;
    color: ${({ theme }) => theme.colors.grey005};
  }
  &:-moz-placeholder {
    font-family: 'Lato';
    font-weight: 400;
    color: ${({ theme }) => theme.colors.grey005};
  }
  &::-moz-placeholder {
    font-family: 'Lato';
    font-weight: 400;
    color: ${({ theme }) => theme.colors.grey005};
  }
  &:-ms-input-placeholder {
    font-family: 'Lato';
    font-weight: 400;
    color: ${({ theme }) => theme.colors.grey005};
  }
  &::placeholder {
    font-family: 'Lato';
    font-weight: 400;
    color: ${({ theme }) => theme.colors.grey005};
  }
`;

export const OrderDetailsNoteBtn = styled(typography.BodyTextOneRegularTypography)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 121px;
  height: 38px;
  margin-inline-start: auto;
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.grey100};
  border: 1px solid ${({ theme }) => theme.colors.grey000};
  border-radius: 4px;
  cursor: pointer;
`;
// ###############################################################
// <---------------------- End Order Note ----------------------->
// ###############################################################
// ###############################################################
// <-------------------- Order Visit Note -------------------->
// ###############################################################
export const OrderDetailsVisitNoteSection = styled.div`
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgb(44 53 68 / 8%);
`;

export const OrdetVisitNote = styled(typography.BodyTextOneRegularTypography)`
  color: ${({ theme }) => theme.colors.redDark};
`;
// ###############################################################
// <------------------ End Order Visit Note ------------------>
// ###############################################################
// ###############################################################
// <-------------------- Edit Modal -------------------->
// ###############################################################
export const ModalContentWrapper = styled.div`
  padding: 16px 24px 16px 24px;
  width: 500px;
`;

export const FieldWrapper = styled.div`
  padding-bottom: 16px;
`;

export const FieldLabel = styled(typography.TitleTwoTypography)`
  margin: 0;
  color: ${({ theme }) => theme.colors.black};
`;

export const FieldErrorLabel = styled(typography.TitleTwoTypography)`
  margin: 0;
  color: ${({ theme }) => theme.colors.redMain};
`;

export const AddressLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const AddressLabel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  width: 71px;
  height: 24px;
  background: ${({ theme, selected }) =>
    selected ? theme.colors.blueLight : theme.colors.grey100};
  color: ${({ theme, selected }) => (selected ? theme.colors.blueMain : theme.colors.grey600)};
  border: ${({ theme, selected }) => ` 1px solid  ${selected && theme.colors.blueMain}`};
  border-radius: 4px;
  margin-right: 8px;

  &:hover {
    background: ${({ theme }) => theme.colors.blueLight};
    color: ${({ theme }) => theme.colors.blueMain};
    cursor: pointer;
  }
`;

export const CompleteAddressField = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 100%;
  background: ${({ theme }) => theme.colors.grey100};
  border-radius: 4px;
`;

// ###############################################################
// <------------------ Edit Modal ------------------>
// ###############################################################

export const OrderId = styled.p`
  color: #484848;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  margin: 0;
`;

export const CreationDateTime = styled.p`
  color: #484848;
  font-size: 13px;
  font-weight: 400;
  line-height: 28px;
  margin: 0;
`;

export const ElapsedTime = styled(CreationDateTime)`
  margin: 0;
`;

export const SeparationDot = styled.p`
  margin: 0;
`;

export const PaymentIcon = styled.div`
  &:before {
    content: '\\${({ icon }) => icon || ''}';
    font-family: 'Icomoon';
    font-size: 18px;
    line-height: 21px;
    color: ${({ color }) => color || ''};
  }
`;
