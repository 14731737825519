import { takeLatest, call, put } from 'redux-saga/effects';
import Cookies from 'js-cookie';
import { getUpperLevelDomain, parseJwt } from 'utils/functions/authentication';
import { Cookie } from 'constants/appCookies';
import { authenticateUser, getLoginInfo, getUserPermissions } from './api';
import {
  fetchUserAuthenticationInfoSuccess,
  fetchUserAuthenticationInfoFail,
  fetchLoginInfoSuccess,
  fetchLoginInfoFail,
  fetchUserPermissionsSuccess,
  fetchUserPermissionsFail,
} from './actions';
import accountManagementActionTypes from './action-types';

function* authenticateUserSaga({ payload }) {
  try {
    const response = yield call(authenticateUser, payload);

    let returnURL = '/';
    if (Cookies.get(Cookie.RETURN_URL)) returnURL = Cookies.get(Cookie.RETURN_URL);
    let date = new Date(0);
    const expiryDate = parseJwt(response.data.token).exp;
    date = date.setUTCSeconds(expiryDate);
    const domain = getUpperLevelDomain();
    Cookies.set(Cookie.AUTH_TOKEN, response.data.token, {
      domain,
      expires: new Date(date),
    });

    document.location.href = returnURL;

    yield put(fetchUserAuthenticationInfoSuccess(response));
  } catch (error) {
    yield put(fetchUserAuthenticationInfoFail());
  }
}

function* getLoginInfoSaga({ payload }) {
  try {
    const response = yield call(getLoginInfo, payload);
    yield put(fetchLoginInfoSuccess(response));
  } catch (error) {
    const domain = getUpperLevelDomain();
    Cookies.remove(Cookie.AUTH_TOKEN, { domain });
    Cookies.remove(Cookie.RETURN_URL);
    document.location.href = '/login';
    yield put(fetchLoginInfoFail());
  }
}

function* getUserPermissionsSaga() {
  try {
    const response = yield call(getUserPermissions);
    yield put(fetchUserPermissionsSuccess(response.data));
  } catch (error) {
    const domain = getUpperLevelDomain();
    Cookies.remove(Cookie.AUTH_TOKEN, { domain });
    Cookies.remove(Cookie.RETURN_URL);
    document.location.href = '/login';
    yield put(fetchUserPermissionsFail());
  }
}

function* accountManagementSagas() {
  yield takeLatest(
    accountManagementActionTypes.FETCH_PHARMACY_USER_AUTHENTICATION_INFO,
    authenticateUserSaga,
  );
  yield takeLatest(accountManagementActionTypes.FETCH_PHARMACY_LOGIN_INFO, getLoginInfoSaga);
  yield takeLatest(accountManagementActionTypes.FETCH_USER_PERMISSIONS, getUserPermissionsSaga);
}

export default accountManagementSagas;
