import styled from 'styled-components';
import themeData from 'utils/theme/themeData';

const { typography } = themeData;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey000};
`;

export const HeaderPageName = styled(typography.LargeTitleTypography)`
  margin: 0;
  color: ${({ theme }) => theme.colors.black};
`;

export const HeaderUserNameWrapper = styled.div`
  position: relative;
  margin-inline-start: auto;
  cursor: pointer;
`;

export const HeaderUserName = styled(typography.BodyTextOneRegularTypography)`
  display: flex;
  margin: 0;
  color: ${({ theme }) => theme.colors.black};

  &:after {
    content: '\\e913';
    font-family: 'Icomoon';
    margin-inline-start: 6px;
  }
`;

export const HeaderLogoutPopup = styled.div`
  position: absolute;
  top: 25px;
  width: 100%;
  padding: 4px 0;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey000};
  border-radius: 4px;
  box-shadow: 0px 4px 16px rgb(0 0 0 / 16%);
  z-index: 1;
`;

export const HeaderLogout = styled(typography.BodyTextOneRegularTypography)`
  margin: 0;
  padding: 6px 16px;
  color: ${({ theme }) => theme.colors.black};

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};
  }
`;

export const HeaderUserNameLoaderWrapper = styled.div`
  margin-inline-start: auto;
`;
