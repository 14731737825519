import { css } from 'styled-components';

export const extendTableGrid = css`
  grid-template-columns: 55% 20% 20% 5%;
  padding: 0px;
`;

export const extendTableGridHistory = css`
  grid-template-columns: 40% 15% 20% 25%;
  padding: 0px;
`;

export const extendTableHeaderRow = css`
  background-color: unset;
  color: #484848;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  height: 44px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const extendTableRow = css`
  margin: 1px 0px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const extendMainItem = css``;

export const extendAdditionalItem = css`
  color: #484848;
`;
