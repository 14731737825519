/* eslint-disable prettier/prettier */
/* eslint-disable max-len */
import { createGlobalStyle, css } from 'styled-components';
import LatoRegular from 'assets/fonts/lato/Lato-Regular.ttf';
import LatoBold from 'assets/fonts/lato/Lato-Bold.ttf';
import Icomoon from 'assets/fonts/icomoon/icomoon.woff';

const font = (family, weight, ttf, woff) => css`
  @font-face {
    font-family: ${family};
    font-weight: ${weight};
    src: ${`local('${family}')`}, ${ttf ? `url(${ttf}) format('truetype')${woff ? ',' : ''}` : ''} ${woff ? `url(${woff}) format('woff')` : ''};
  }
`;

const GlobalStyle = createGlobalStyle`
  ${font('Lato', '400', LatoRegular)}
  ${font('Lato', '700', LatoBold)}

  ${font('Icomoon', '400', null, Icomoon)}

  body {
    margin: 0;
    font-family: Lato;
    font-weight: 400;
  }
  
  button {
    font-family: Lato;
  }

  input {
    font-family: Lato;
    font-weight: 400;
  }

  textarea {
    font-family: Lato;
    font-weight: 400;
  }

  h1, h2, p, div {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  ::-webkit-scrollbar {
  width: 5px;
  border-radius: 4.5px;

}

  /* Track */
  ::-webkit-scrollbar-track {
    background: #fff;
    
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #e2dede;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #646464;
  }
`;

export default GlobalStyle;
