import styled, { createGlobalStyle } from 'styled-components';
import themeData from 'utils/theme/themeData';

const { typography } = themeData;

export const PreventBodyScrolling = createGlobalStyle`
  body {
    ${({ isOpen }) => isOpen && 'overflow: hidden;'}
  }
`;

export const ModalContainer = styled.div`
  display: ${({ toggle }) => (toggle ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  background: #000000b8;
  position: fixed;
  z-index: 999;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin-inline-start: 0px !important;
  ${({ extendStyle }) => extendStyle || ''};
`;
export const ModalDialog = styled.div`
  ${({ size }) => size === 'md' && 'width:680px'};
  background: #fff;
  border-radius: 6px;
  ${({ theme }) => theme.media.phone`
    width:100%;
    `}
`;
export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  padding: 16px 24px;
  color: ${({ theme }) => theme.colors.black};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey003};
`;
export const ModalBody = styled.div`
  max-height: 75vh;
  overflow-y: auto;
`;
export const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${({ theme }) => theme.colors.grey003};
  padding: 16px 24px;
`;
export const ModalButton = styled.button`
  text-align: center;
  cursor: pointer;
  min-width: 96px;
  height: 36px;
  background: ${({ theme }) => theme.colors.grey001};
  color: ${({ theme }) => theme.colors.black};
  border: 1px solid #d7dce3;
  box-sizing: border-box;
  border-radius: 4px;
  margin-left: 20px;
  ${({ extendStyle }) => extendStyle || ''};
`;
export const ModalConfirmButton = styled(ModalButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0070cd;
  background: ${({ theme, disabled }) => (disabled ? theme.colors.grey001 : theme.colors.blueMain)};
  color: ${({ theme, disabled }) => (disabled ? '#D8D8D8' : theme.colors.white)};
  border: none;
  ${({ extendStyle }) => extendStyle || ''};
`;
export const Title = styled(typography.TitleOneRegularTypography)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 4px 0 0;

  color: #484848;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`;

export const ModalCloseIcon = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;

  &:before {
    content: '\\e93d';
    font-family: 'Icomoon';
  }
`;
