import { get, post, put } from 'utils/functions/request';
import urls from 'utils/urls';

export const getStocks = payload =>
  get(
    null,
    false,
    `${urls.pharmacyModuleUrls.pharmacyStock.getStock}?pagesize=10&pagenumber=${
      payload?.pageNumber || 1
    }&searchterm=${payload?.searchTerm || ''}`,
  );

export const getStocksHistory = payload =>
  get(
    null,
    false,
    `${urls.pharmacyModuleUrls.pharmacyStock.stockHistory}?pagesize=10&pagenumber=${
      payload?.pageNumber || 1
    }`,
  );

export const exportStock = () =>
  get(null, false, `${urls.pharmacyModuleUrls.pharmacyStock.exportStock}`);

export const uploadStock = payload => {
  const headers = {
    Accept: 'application/json',
  };
  return post(
    headers,
    false,
    `${urls.pharmacyModuleUrls.pharmacyStock.uploadStock}`,
    payload?.formData,
  );
};

export const addStockItems = payload =>
  put(null, false, `${urls.pharmacyModuleUrls.pharmacyStock.addStockItems}`, payload);

export const searchItems = payload =>
  get(
    null,
    false,
    `${
      urls.pharmacyModuleUrls.pharmacyStock.searchStockItems
    }?from=1&size=30&isTrending=false&Version=2&query=${payload?.searchTerm || ''}`,
  );
