import ordersActionTypes from './action-types';

export const fetchAllOrders = payload => ({
  type: ordersActionTypes.FETCH_ALL_PHARMACY_ORDERS,
  payload,
});

export const fetchAllOrdersSuccess = payload => ({
  type: ordersActionTypes.FETCH_ALL_PHARMACY_ORDERS_SUCCESS,
  payload,
});

export const fetchAllOrdersFail = payload => ({
  type: ordersActionTypes.FETCH_ALL_PHARMACY_ORDERS_FAIL,
  payload,
});

export const setAllOrdersAssignedEntityDetails = payload => ({
  type: ordersActionTypes.SET_ALL_PHARMACY_ORDERS_ASSIGNED_ENTITY_DETAILS,
  payload,
});

export const fetchPartnershipTypes = () => ({
  type: ordersActionTypes.FETCH_PARTNERSHIP_TYPES,
});

export const fetchPartnershipTypesSuccess = payload => ({
  type: ordersActionTypes.FETCH_PARTNERSHIP_TYPES_SUCCESS,
  payload,
});

export const fetchPartnershipTypesFail = payload => ({
  type: ordersActionTypes.FETCH_PARTNERSHIP_TYPES_FAIL,
  payload,
});

export const fetchDetailedReasons = payload => ({
  type: ordersActionTypes.FETCH_DETAILED_REASONS,
  payload,
});

export const fetchDetailedReasonsSuccess = payload => ({
  type: ordersActionTypes.FETCH_DETAILED_REASONS_SUCCESS,
  payload,
});

export const fetchDetailedReasonsFail = payload => ({
  type: ordersActionTypes.FETCH_DETAILED_REASONS_FAIL,
  payload,
});

export const fetchOrderStatusTypes = () => ({
  type: ordersActionTypes.FETCH_ORDER_STATUS_TYPES,
});

export const fetchOrderStatusTypesSuccess = payload => ({
  type: ordersActionTypes.FETCH_ORDER_STATUS_TYPES_SUCCESS,
  payload,
});

export const fetchOrderStatusTypesFail = payload => ({
  type: ordersActionTypes.FETCH_ORDER_STATUS_TYPES_FAILED,
  payload,
});

export const fetchOrderDetails = payload => ({
  type: ordersActionTypes.FETCH_PHARMACY_ORDER_DETAILS,
  payload,
});

export const fetchOrderDetailsSuccess = payload => ({
  type: ordersActionTypes.FETCH_PHARMACY_ORDER_DETAILS_SUCCESS,
  payload,
});

export const fetchOrderDetailsFail = payload => ({
  type: ordersActionTypes.FETCH_PHARMACY_ORDER_DETAILS_FAIL,
  payload,
});

export const setOrderStatusByOrderID = payload => ({
  type: ordersActionTypes.SET_ORDER_STATUS_BY_ORDER_ID,
  payload,
});

export const setOrderPatientUserDetails = payload => ({
  type: ordersActionTypes.SET_PHARMACY_ORDER_PATIENT_USER_DETAILS,
  payload,
});

export const setOrderAssignedEntityDetails = payload => ({
  type: ordersActionTypes.SET_PHARMACY_ORDER_ASSIGNED_ENTITY_DETAILS,
  payload,
});

export const setOrderLogsDetails = payload => ({
  type: ordersActionTypes.SET_PHARMACY_ORDER_LOGS_DETAILS,
  payload,
});

export const fetchOrderNoteChangeSubmission = payload => ({
  type: ordersActionTypes.FETCH_PHARMACY_ORDER_NOTE_CHANGE_SUBMISSION,
  payload,
});

export const fetchOrderNoteChangeSubmissionSuccess = () => ({
  type: ordersActionTypes.FETCH_PHARMACY_ORDER_NOTE_CHANGE_SUBMISSION_SUCCESS,
});

export const fetchOrderNoteChangeSubmissionFail = () => ({
  type: ordersActionTypes.FETCH_PHARMACY_ORDER_NOTE_CHANGE_SUBMISSION_FAIL,
});

export const assignOrderToEntity = payload => ({
  type: ordersActionTypes.ASSIGN_PHARMACY_ORDER_TO_ENTITY,
  payload,
});

export const assignOrderToEntitySuccess = payload => ({
  type: ordersActionTypes.ASSIGN_PHARMACY_ORDER_TO_ENTITY_SUCCESS,
  payload,
});

export const assignOrderToEntityFail = payload => ({
  type: ordersActionTypes.ASSIGN_PHARMACY_ORDER_TO_ENTITY_FAIL,
  payload,
});

export const scheduleOrderSubmission = payload => ({
  type: ordersActionTypes.SCHEDULE_ORDER_SUBMISSION,
  payload,
});

export const scheduleOrderSubmissionSuccess = () => ({
  type: ordersActionTypes.SCHEDULE_ORDER_SUBMISSION_SUCCESS,
});

export const scheduleOrderSubmissionFail = () => ({
  type: ordersActionTypes.SCHEDULE_ORDER_SUBMISSION_FAIL,
});

export const fetchScheduleOrderReasons = () => ({
  type: ordersActionTypes.FETCH_PHARMACY_SCHEDULE_ORDER_REASONS,
});

export const fetchScheduleOrderReasonsSuccess = payload => ({
  type: ordersActionTypes.FETCH_PHARMACY_SCHEDULE_ORDER_REASONS_SUCCESS,
  payload,
});

export const fetchScheduleOrderReasonsFail = () => ({
  type: ordersActionTypes.FETCH_PHARMACY_SCHEDULE_ORDER_REASONS_FAIL,
});

export const fetchCancelOrderReasons = () => ({
  type: ordersActionTypes.FETCH_PHARMACY_CANCEL_ORDER_REASONS,
});

export const fetchCancelOrderReasonsSuccess = payload => ({
  type: ordersActionTypes.FETCH_PHARMACY_CANCEL_ORDER_REASONS_SUCCESS,
  payload,
});

export const fetchCancelOrderReasonsFail = () => ({
  type: ordersActionTypes.FETCH_PHARMACY_CANCEL_ORDER_REASONS_FAIL,
});

export const fetchRejectOrderReasons = () => ({
  type: ordersActionTypes.FETCH_PHARMACY_REJECT_ORDER_REASONS,
});

export const fetchRejectOrderReasonsSuccess = payload => ({
  type: ordersActionTypes.FETCH_PHARMACY_REJECT_ORDER_REASONS_SUCCESS,
  payload,
});

export const fetchRejectOrderReasonsFail = () => ({
  type: ordersActionTypes.FETCH_PHARMACY_REJECT_ORDER_REASONS_FAIL,
});

export const fetchOrderStatusChangeSubmission = payload => ({
  type: ordersActionTypes.FETCH_PHARMACY_ORDER_STATUS_CHANGE_SUBMISSION,
  payload,
});

export const fetchOrderStatusChangeSubmissionSuccess = () => ({
  type: ordersActionTypes.FETCH_PHARMACY_ORDER_STATUS_CHANGE_SUBMISSION_SUCCESS,
});

export const fetchOrderStatusChangeSubmissionFail = () => ({
  type: ordersActionTypes.FETCH_PHARMACY_ORDER_STATUS_CHANGE_SUBMISSION_FAIL,
});

export const setOrderItemsDetails = payload => ({
  type: ordersActionTypes.SET_ORDER_ITEMS_DETAILS,
  payload,
});

export const fetchOrderItemsSuggestions = payload => ({
  type: ordersActionTypes.FETCH_ORDER_ITEMS_SUGGESTIONS,
  payload,
});

export const fetchOrderItemsSuggestionsSuccess = payload => ({
  type: ordersActionTypes.FETCH_ORDER_ITEMS_SUGGESTIONS_SUCCESS,
  payload,
});

export const fetchOrderItemsSuggestionsFail = () => ({
  type: ordersActionTypes.FETCH_ORDER_ITEMS_SUGGESTIONS_FAIL,
});

export const resetOrderItemsSuggestions = () => ({
  type: ordersActionTypes.RESET_ORDER_ITEMS_SUGGESTIONS,
});

export const fetchOrderItemsSubmission = payload => ({
  type: ordersActionTypes.FETCH_ORDER_ITEMS_SUBMISSION,
  payload,
});

export const fetchOrderItemsSubmissionSuccess = () => ({
  type: ordersActionTypes.FETCH_ORDER_ITEMS_SUBMISSION_SUCCESS,
});

export const fetchOrderItemsSubmissionFail = () => ({
  type: ordersActionTypes.FETCH_ORDER_ITEMS_SUBMISSION_FAIL,
});

export const updateShippingAddress = payload => ({
  type: ordersActionTypes.UPDATE_SHIPPING_ADDRESS,
  payload,
});

export const updateShippingAddressSuccess = () => ({
  type: ordersActionTypes.UPDATE_SHIPPING_ADDRESS_SUCCESS,
});

export const updateShippingAddressFail = () => ({
  type: ordersActionTypes.UPDATE_SHIPPING_ADDRESS_FAIL,
});

export const getStocks = payload => ({
  type: ordersActionTypes.GET_STOCKS,
  payload,
});

export const fetchItemAlternatives = payload => ({
  type: ordersActionTypes.FETCH_ITEM_ALTERNATIVES,
  payload,
});

export const fetchItemAlternativesSuccess = payload => ({
  type: ordersActionTypes.FETCH_ITEM_ALTERNATIVES_SUCCESS,
  payload,
});

export const fetchItemAlternativesFail = () => ({
  type: ordersActionTypes.FETCH_ITEM_ALTERNATIVES_FAIL,
});

export const submitOrderItemsAlternatives = payload => ({
  type: ordersActionTypes.SUBMIT_ORDER_ITEMS_ALTERNATIVES,
  payload,
});

export const fetchOrderRecommendedAlternatives = payload => ({
  type: ordersActionTypes.FETCH_ORDER_RECOMMENDED_ALTERNATIVES,
  payload,
});

export const fetchOrderRecommendedAlternativesSuccess = payload => ({
  type: ordersActionTypes.FETCH_ORDER_RECOMMENDED_ALTERNATIVES_SUCCESS,
  payload,
});

export const fetchOrderRecommendedAlternativesFail = () => ({
  type: ordersActionTypes.FETCH_ORDER_RECOMMENDED_ALTERNATIVES_FAIL,
});

export const confirmOrder = payload => ({
  type: ordersActionTypes.CONFIRM_ORDER,
  payload,
});

export const confirmOrderSuccess = payload => ({
  type: ordersActionTypes.CONFIRM_ORDER_SUCCESS,
  payload,
});

export const confirmOrderFail = () => ({
  type: ordersActionTypes.CONFIRM_ORDER_FAIL,
});

export const returnOrder = payload => ({
  type: ordersActionTypes.RETURN_ORDER,
  payload,
});

export const returnOrderSuccess = payload => ({
  type: ordersActionTypes.RETURN_ORDER_SUCCESS,
  payload,
});

export const returnOrderFail = () => ({
  type: ordersActionTypes.RETURN_ORDER_FAIL,
});
