import PropTypes from 'prop-types';
import Button from 'modules/common/components/Button';
import Loader from 'modules/common/components/Loader';
import OrdersFilter from 'modules/orders/OrdersView/OrdersContainer/OrdersSideList/OrdersFilter';
import { orderStatusEnum } from 'modules/orders/constants';
import { StateBadgesColor } from '../enums';
import {
  OrdersListWrapper,
  OrdersListGridWrapper,
  OrderContainer,
  OrdersListItem,
  OrdersListItemLink,
  OrdersListItemText,
  OrdersListItemSubText,
  StatusBadges,
  extendButtonStyle,
  extendLoaderStyle,
  OrderID,
  LoaderWrapper,
  EmptyStateMsg,
} from './OrdersSideList.style';

const OrdersSideList = ({
  mappedOrdersList,
  stringsData,
  selectedDateFilterObj,
  handleOrderSearchBarInputChange,
  handleOrderDateFilterChange,
  handleGenericFilterClick,
  ordersApiCallParams,
  filters,
  selectItemsSearchApi,
  isLoading,
  handleOrderPaginationClick,
  setSelectedOrderId,
  selectedOrderId,
  totalCount,
  selectedData,
  setSelectedData,
  searchQuery,
  setSearchQuery,
}) => {
  const renderOrderNumber = order => (
    <OrdersListItemLink>
      <OrderID isSelected={order.key === selectedOrderId}>Order #{order.orderNumber}</OrderID>
    </OrdersListItemLink>
  );

  const orderGridHeaderList = stringsData.ordersList.orderGridHeader;
  const displayShowMoreBtn = mappedOrdersList?.length < totalCount;

  const renderOrdersList = () => {
    if (isLoading && !mappedOrdersList?.length) {
      return (
        <OrdersListGridWrapper>
          <LoaderWrapper>
            <Loader width={40} height={40} thickness={4} />
          </LoaderWrapper>
        </OrdersListGridWrapper>
      );
    }
    if (!isLoading && !mappedOrdersList?.length) {
      return <EmptyStateMsg>No Orders</EmptyStateMsg>;
    }
    return (
      <OrdersListGridWrapper columnsCount={Object.keys(orderGridHeaderList).length}>
        {mappedOrdersList.map(order => {
          const orderStates = order?.orderStates;
          const hideMoreDetails = !orderStates?.find(
            state => state.orderStateTypeId === orderStatusEnum.Accepted,
          );

          const orderElapsedTimeInMin = parseInt(
            (new Date() - new Date(`${order?.createdOn}Z`)) / (1000 * 60),
          );
          const hours = Math.floor(orderElapsedTimeInMin / 60);
          const minutes = orderElapsedTimeInMin % 60;

          const getStatusBadge = () => {
            const orderStatusId = order?.orderStateTypeId;

            return (
              <StatusBadges
                statusBGColor={StateBadgesColor[orderStatusId]?.background}
                statusColor={StateBadgesColor[orderStatusId]?.color}
              >
                {StateBadgesColor[orderStatusId]?.label}
              </StatusBadges>
            );
          };

          return (
            <OrderContainer
              isSelected={order.key === selectedOrderId}
              key={order.orderNumber}
              onClick={() => {
                setSelectedOrderId(order.key);
              }}
            >
              <OrdersListItem>
                {renderOrderNumber(order)} {getStatusBadge()}
              </OrdersListItem>
              <OrdersListItemSubText isSelected={order.key === selectedOrderId}>
                {`${order.orderItems.length} ${
                  stringsData.ordersList.orderGridItemCount
                } - ${order.priceDetails.netTotal.toFixed(2)} EGP`}
                <p
                  style={{
                    color: '#7E7E7E',
                    margin: '0px',
                    lineHeight: '12px',
                  }}
                >
                  .
                </p>
                <p
                  style={{
                    color: '#7E7E7E',
                    margin: '0px',
                  }}
                >
                  {`${hours}h:${minutes}min`}
                </p>
              </OrdersListItemSubText>
              <OrdersListItemText isSelected={order.key === selectedOrderId}>
                {hideMoreDetails
                  ? '**********, ************'
                  : `${order.recipientName}, ${order.recipientNumber}`}
              </OrdersListItemText>
            </OrderContainer>
          );
        })}
        {displayShowMoreBtn && (
          <Button
            onClick={() => handleOrderPaginationClick()}
            isLoading={isLoading}
            btnText="Show More"
            extendLoaderStyle={extendLoaderStyle}
            extendButtonStyle={extendButtonStyle}
          />
        )}
      </OrdersListGridWrapper>
    );
  };

  return (
    <OrdersListWrapper>
      <OrdersFilter
        stringsData={stringsData}
        selectedDateFilterObj={selectedDateFilterObj}
        handleOrderSearchBarInputChange={handleOrderSearchBarInputChange}
        handleOrderDateFilterChange={handleOrderDateFilterChange}
        handleGenericFilterClick={handleGenericFilterClick}
        ordersApiCallParams={ordersApiCallParams}
        filters={filters}
        selectItemsSearchApi={selectItemsSearchApi}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
      <div>{renderOrdersList()}</div>
    </OrdersListWrapper>
  );
};

OrdersSideList.propTypes = {
  searchQuery: PropTypes.string,
  setSearchQuery: PropTypes.func,
  selectedData: PropTypes.shape({}),
  setSelectedData: PropTypes.func,
  selectedDateFilterObj: PropTypes.shape({}),
  handleOrderSearchBarInputChange: PropTypes.func,
  handleOrderDateFilterChange: PropTypes.func,
  handleGenericFilterClick: PropTypes.func,
  ordersApiCallParams: PropTypes.shape({}),
  filters: PropTypes.shape({}),
  selectItemsSearchApi: PropTypes.func,
  isLoading: PropTypes.bool,
  handleOrderPaginationClick: PropTypes.func,
  setSelectedOrderId: PropTypes.func,
  selectedOrderId: PropTypes.string,
  totalCount: PropTypes.number,
  mappedOrdersList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  stringsData: PropTypes.shape({
    ordersList: PropTypes.shape({
      orderGridNoResultsFound: PropTypes.string,
      orderGridHeader: PropTypes.shape({}),
      orderGridItemCount: PropTypes.string,
      loyaltyPointsStickerText: PropTypes.string,
      creditCardStickerText: PropTypes.string,
      cashStickerText: PropTypes.string,
    }),
  }).isRequired,
  orderStatusEnum: PropTypes.shape({
    Scheduled: PropTypes.number,
  }).isRequired,
};

export default OrdersSideList;
