/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import Icon from '../Icon';
import Loader from '../LoaderV2';
import { LoaderWrapper, ButtonContainer, ButtonText } from './Button.style';

const NewButton = ({
  btnText,
  onClick,
  iconName,
  /* iconColor,
  iconSize, */
  iconSize,
  extendButtonStyle,
  isLoading,
  isSubmitting,
  disabled,
  extendIcon,
  extendLoaderStyle,
}) => (
  <ButtonContainer
    extendButtonStyle={extendButtonStyle}
    onClick={e => {
      if (onClick && !isSubmitting && !disabled) onClick(e);
    }}
    disabled={disabled}
    role="button"
  >
    {isLoading ? (
      <LoaderWrapper>
        <Loader extendStyle={extendLoaderStyle} />
      </LoaderWrapper>
    ) : (
      <div style={{ display: 'flex', gap: '2px' }}>
        {iconName && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Icon icon={iconName} size={iconSize} extendStyle={extendIcon} />
          </div>
        )}
        {btnText && <ButtonText>{btnText}</ButtonText>}
      </div>
    )}
  </ButtonContainer>
);

NewButton.propTypes = {
  btnText: PropTypes.string,
  iconName: PropTypes.string,
  onClick: PropTypes.func,
  // iconColor: PropTypes.string,
  isLoading: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default NewButton;
