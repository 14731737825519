const baseUrls = {
  PharmacyGatewayOrdersApiBaseUrl: `${process.env.REACT_APP_PHARMACY_API_ORDERS_MICRO_SERVICE}/orders/api`,
  PharmacyGatewayUsersApiBaseUrl: `${process.env.REACT_APP_PHARMACY_API_ORDERS_MICRO_SERVICE}/users/api`,
  PharmacyGatewayPharmaciesApiBaseUrl: `${process.env.REACT_APP_PHARMACY_API_ORDERS_MICRO_SERVICE}/epharmacy/api`,
  PharmacyGatewayInventoryApiBaseUrl: `${process.env.REACT_APP_PHARMACY_API_ORDERS_MICRO_SERVICE}/inventory/api`,
  PharmacyGatewayPapiApiBaseUrl: `${process.env.REACT_APP_PHARMACY_API_ORDERS_MICRO_SERVICE}/papi-web/api`,
  PharmacyPatientsStaticBaseUrl: `${process.env.REACT_APP_STATIC_URL}/api`,
};

const urls = {
  pharmacyModuleUrls: {
    ordersUrls: {
      getAllOrders: `${baseUrls.PharmacyGatewayOrdersApiBaseUrl}/v2/orders`,
      getOrderDetails: `${baseUrls.PharmacyGatewayOrdersApiBaseUrl}/orders`,
      getOrderPatientUserDetails: `${baseUrls.PharmacyGatewayPapiApiBaseUrl}/user/getbyuserkey`,
      getOrderStatusTypes: `${baseUrls.PharmacyGatewayOrdersApiBaseUrl}/orderstatetypes`,
      getPartnershipTypes: `${baseUrls.PharmacyGatewayOrdersApiBaseUrl}/PartnershipTypes`,
      getOrderStateReasonsTree: `${baseUrls.PharmacyGatewayOrdersApiBaseUrl}/orderstatereasonstree`,
      getProductStocksAllocation: `${baseUrls.PharmacyGatewayInventoryApiBaseUrl}/stock/stockallocation`,
      updateOrderNote: `${baseUrls.PharmacyGatewayOrdersApiBaseUrl}/orders`,
      updateOrderStatus: `${baseUrls.PharmacyGatewayOrdersApiBaseUrl}/orderstates`,
      updateOrderItems: `${baseUrls.PharmacyGatewayOrdersApiBaseUrl}/orderitems`,
      updateShippingAddress: `${baseUrls.PharmacyGatewayOrdersApiBaseUrl}/ShippingAddresses`,
      assignOrderToEntity: `${baseUrls.PharmacyGatewayOrdersApiBaseUrl}/AssignedEntities`,
      updateOrder: `${baseUrls.PharmacyGatewayOrdersApiBaseUrl}/orders`,
      getProducts: `${baseUrls.PharmacyGatewayInventoryApiBaseUrl}/products`,
      productAlternatives: '/alternatives',
    },
    commonUrls: {
      getUsers: `${baseUrls.PharmacyGatewayUsersApiBaseUrl}/v2/Users`,
      getPharmacies: `${baseUrls.PharmacyGatewayPharmaciesApiBaseUrl}/Pharmacies`,
      getServiceableAreas: `${baseUrls.PharmacyGatewayPharmaciesApiBaseUrl}/ServiceableAreas`,
      getProducts: `${baseUrls.PharmacyGatewayInventoryApiBaseUrl}/v2/ProductShapes`,
      getProductItems: `${baseUrls.PharmacyGatewayInventoryApiBaseUrl}/products`,
      getInsuranceProviderByKey: `${baseUrls.PharmacyPatientsStaticBaseUrl}/insurance/GetInsuranceProviderById`,
    },
    accountManagementUrls: {
      authenticateUser: `${baseUrls.PharmacyGatewayUsersApiBaseUrl}/login`,
      getLoginInfo: `${baseUrls.PharmacyGatewayUsersApiBaseUrl}/users`,
      getUserPermissions: `${baseUrls.PharmacyGatewayUsersApiBaseUrl}/Permissions`,
    },
    PharmaciesUrls: {
      getPharmacies: `${baseUrls.PharmacyGatewayPharmaciesApiBaseUrl}/v2/pharmacies`,
      registerPharmacy: `${baseUrls.PharmacyGatewayPharmaciesApiBaseUrl}/Pharmacies`,
      getPharmacyTypes: `${baseUrls.PharmacyGatewayPharmaciesApiBaseUrl}/PharmacyTypes`,
    },
    pharmacyStock: {
      getStock: `${baseUrls.PharmacyGatewayInventoryApiBaseUrl}/stock`,
      uploadStock: `${baseUrls.PharmacyGatewayInventoryApiBaseUrl}/stock/upload`,
      exportStock: `${baseUrls.PharmacyGatewayInventoryApiBaseUrl}/stock/export`,
      stockHistory: `${baseUrls.PharmacyGatewayInventoryApiBaseUrl}/stock/files`,
      addStockItems: `${baseUrls.PharmacyGatewayInventoryApiBaseUrl}/stock`,
      searchStockItems: `${baseUrls.PharmacyGatewayInventoryApiBaseUrl}/search`,
    },
    pharmacyInvoices: {
      getInvoices: `${baseUrls.PharmacyGatewayOrdersApiBaseUrl}/invoices`,
    },
  },
};

export default urls;
