import { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'modules/common/components/Modal';
import SectionDivider from 'modules/common/components/SectionDivider';
import { orderStatusEnum, paymentMethodKeys } from 'modules/orders/constants';
import {
  OrderDetailsText,
  OrderDetailsSubText,
  OrderDetailsInfoPatientUserDetailsTitleWrapper,
  OrderDetailsInfoContent,
  ModalContentWrapper,
  PaymentIcon,
} from '../OrderDetailsComponent.style';

const PatientUserDetailsSection = ({ orderDetails }) => {
  const [isAddressModalOpened, toggleAddressModal] = useState(false);
  const insuranceProvider = useSelector(state => state.pharmacyCommon.insuranceProviders);
  const orderData = orderDetails?.details;
  const patientUserData = orderData.patientUserDetails;
  const fullAddress = orderData?.shippingAddressDTO?.fullAddress;
  const latitude = orderData?.shippingAddressDTO?.latitude;
  const longitude = orderData?.shippingAddressDTO?.longitude;
  const paymentMethodKey = orderData?.paymentMethodKey;
  const orderStates = orderData?.orderStates;

  let paymentStickerInfo = '';

  if (paymentMethodKey === paymentMethodKeys.cash) paymentStickerInfo = 'Cash';
  if (paymentMethodKey === paymentMethodKeys.cardOnDeliver) paymentStickerInfo = 'Card On Delivery';
  if (paymentMethodKey === paymentMethodKeys.creditCard) paymentStickerInfo = 'Online Payment';

  const note = orderData?.note;
  const hideMoreDetails = !orderStates?.find(
    state => state.orderStateTypeId === orderStatusEnum.Accepted,
  );

  let appliedInsuranceProviderName = '--';
  let appliedInsuranceProviderLogo = '';
  const isInsured = !!orderData?.patientInsurance?.insuranceProviderKey;
  if (
    isInsured &&
    insuranceProvider &&
    insuranceProvider.details &&
    insuranceProvider.details.length
  ) {
    appliedInsuranceProviderName = insuranceProvider?.details[0]?.englishName;
    appliedInsuranceProviderLogo = insuranceProvider?.details[0]?.imageUrl;
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <OrderDetailsInfoContent>
        <OrderDetailsSubText>Customer</OrderDetailsSubText>
        <OrderDetailsText>
          {hideMoreDetails
            ? '***************'
            : `${patientUserData?.Name}, ${patientUserData?.MobileNumber}`}
          {longitude && latitude && !hideMoreDetails ? (
            <a
              href={`https://www.google.com/maps/search/${latitude},${longitude}`}
              target="_blank"
              rel="noreferrer"
            >
              See on Map
            </a>
          ) : (
            ''
          )}
        </OrderDetailsText>

        <OrderDetailsSubText>Address</OrderDetailsSubText>
        <OrderDetailsText>{hideMoreDetails ? '***************' : fullAddress}</OrderDetailsText>
        <OrderDetailsSubText>Applied</OrderDetailsSubText>
        {hideMoreDetails ? (
          <OrderDetailsText>***************</OrderDetailsText>
        ) : (
          <OrderDetailsText>
            {appliedInsuranceProviderLogo && (
              <img
                style={{
                  width: '20px',
                  height: '20px',
                }}
                src={appliedInsuranceProviderLogo}
                alt=""
              />
            )}
            {`${appliedInsuranceProviderName || '--'}`}
          </OrderDetailsText>
        )}
        <OrderDetailsSubText>Payment</OrderDetailsSubText>
        <div
          style={{
            display: 'flex',
            gap: '16px',
          }}
        >
          <OrderDetailsText>
            {hideMoreDetails ? '***************' : paymentStickerInfo}
          </OrderDetailsText>
          {orderData?.optionalPaymentMethodKey && !hideMoreDetails ? (
            <PaymentIcon icon="e934" color="#ff9900" />
          ) : (
            ''
          )}
          {paymentMethodKey !== paymentMethodKeys.cash && !hideMoreDetails ? (
            <PaymentIcon icon="e93c" color="#904D96" />
          ) : (
            ''
          )}
          {paymentMethodKey === paymentMethodKeys.cash && !hideMoreDetails ? (
            <PaymentIcon icon="e93b" color="#31A636" />
          ) : (
            ''
          )}
        </div>
        <OrderDetailsSubText>Comment</OrderDetailsSubText>
        <OrderDetailsText>{(hideMoreDetails ? '***************' : note) || '--'}</OrderDetailsText>
      </OrderDetailsInfoContent>
      {!hideMoreDetails && (
        <OrderDetailsInfoPatientUserDetailsTitleWrapper onClick={() => toggleAddressModal(true)}>
          More Details
        </OrderDetailsInfoPatientUserDetailsTitleWrapper>
      )}
      <Modal
        title="Delivery Address"
        onClose={() => toggleAddressModal(false)}
        toggle={isAddressModalOpened}
        display
      >
        <ModalContentWrapper>
          <SectionDivider gap={4} />
          <OrderDetailsInfoContent>
            <OrderDetailsSubText>Address Label</OrderDetailsSubText>
            <OrderDetailsText>{orderData?.shippingAddressDTO?.label || '-'}</OrderDetailsText>
            <OrderDetailsSubText>Recipient Name</OrderDetailsSubText>
            <OrderDetailsText>{orderData?.shippingAddressDTO?.recipientName}</OrderDetailsText>
            <OrderDetailsSubText>Phone Number</OrderDetailsSubText>
            <OrderDetailsText>{orderData?.shippingAddressDTO?.recipientNumber}</OrderDetailsText>
            <OrderDetailsSubText>Address</OrderDetailsSubText>
            <OrderDetailsText>{orderData?.shippingAddressDTO?.completeAddress}</OrderDetailsText>
            <OrderDetailsSubText>Building #</OrderDetailsSubText>
            <OrderDetailsText>{orderData?.shippingAddressDTO?.buildingNumber}</OrderDetailsText>
            <OrderDetailsSubText>Flat #</OrderDetailsSubText>
            <OrderDetailsText>{orderData?.shippingAddressDTO?.flatNumber}</OrderDetailsText>
            <OrderDetailsSubText>Street Name</OrderDetailsSubText>
            <OrderDetailsText>{orderData?.shippingAddressDTO?.detail || '-'}</OrderDetailsText>
            <OrderDetailsSubText>Area</OrderDetailsSubText>
            <OrderDetailsText>
              {orderData?.shippingAddressDTO?.area?.areaTranslators[0].name || '-'}
            </OrderDetailsText>
            <OrderDetailsSubText>Landmark</OrderDetailsSubText>
            <OrderDetailsText>{orderData?.shippingAddressDTO?.landmark || '-'}</OrderDetailsText>
          </OrderDetailsInfoContent>
        </ModalContentWrapper>
      </Modal>
    </div>
  );
};

PatientUserDetailsSection.propTypes = {
  stringsData: PropTypes.shape({
    orderDetailsContent: PropTypes.shape({
      noDataPlaceholder: PropTypes.string,
      patientUserDetailsTitle: PropTypes.string,
      patientUserDetailsName: PropTypes.string,
      patientUserDetailsPhoneNumber: PropTypes.string,
      patientUserDetailsAge: PropTypes.string,
      patientUserDetailsGender: PropTypes.string,
      patientUserDetailsGenderMale: PropTypes.string,
      patientUserDetailsGenderFemale: PropTypes.string,
    }),
  }),
  orderDetails: PropTypes.shape({
    details: PropTypes.shape({
      paymentMethodKey: PropTypes.string,
      optionalPaymentMethodKey: PropTypes.string,
      orderStateTypeId: PropTypes.number,
      note: PropTypes.string,
      onlinePaymentCardKey: PropTypes.string,
      shippingAddressDTO: PropTypes.shape({
        fullAddress: PropTypes.string,
        latitude: PropTypes.number,
        longitude: PropTypes.number,
        label: PropTypes.string,
        recipientName: PropTypes.string,
        recipientNumber: PropTypes.string,
        completeAddress: PropTypes.string,
        landmark: PropTypes.string,
        detail: PropTypes.string,
        buildingNumber: PropTypes.string,
        flatNumber: PropTypes.string,
        area: PropTypes.shape({
          areaTranslators: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string,
            }),
          ),
        }),
      }),
      patientUserDetails: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          Name: PropTypes.string,
          MobileNumber: PropTypes.string,
          Birthdate: PropTypes.string,
          Gender: PropTypes.bool,
        }),
      ]),
    }),
  }).isRequired,
};

export default PatientUserDetailsSection;
