import { ProductName, ProductShapeName, ItemImage } from './InvoiceModalModal.style';

export const getTableHeadersLocalized = () => [
  {
    id: 0,
    name: 'Items',
  },
  {
    id: 1,
    name: 'Price',
  },
  {
    id: 2,
    name: 'Quantity',
  },
  {
    id: 3,
    name: 'Subtotal',
  },
];

export const getMainRowItems = itemsList => {
  const recordsRows = [];
  itemsList?.forEach(item => {
    const { price, productKey, nameEn, productShapeId, quantity, productShapes, mainImageUrl } =
      item;
    const shapeDetails = productShapes.find(shape => shape.id === productShapeId);
    recordsRows.push({
      key: productKey,
      mainRow: {
        c0: {
          main: (
            <div
              style={{
                display: 'flex',
                gap: '12px',
                padding: '12px 16px',
              }}
            >
              <ItemImage src={mainImageUrl} alt={`${productKey}-${productShapeId}`} />
              <div>
                <ProductName>{nameEn}</ProductName>
                <ProductShapeName>{shapeDetails?.productShapeTypeName}</ProductShapeName>
              </div>
            </div>
          ),
          additional: '',
        },
        c1: {
          main: `${price} EGP`,
          additional: '',
        },
        c2: {
          main: `${quantity}`,
          additional: '',
        },
        c3: {
          main: `${(quantity * price)?.toFixed(2)}`,
          additional: '',
        },
      },
    });
  });

  return recordsRows;
};
