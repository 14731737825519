import Cookies from 'js-cookie';

const addCookiePrefix = cookieName => `VZT_IMS_${cookieName}`;

export const Cookie = {
  AUTH_TOKEN: addCookiePrefix('TOKEN'), // veezAuth
  CULTURE: addCookiePrefix('CULTURE'),
  RETURN_URL: addCookiePrefix('RETURN_URL'), // opsReturnURL
  LANGUAGE: addCookiePrefix('LANGUAGE'),
};

const getAllCookies = () => [Cookie.AUTH_TOKEN, Cookie.CULTURE, Cookie.RETURN_URL, Cookie.LANGUAGE];

const set = (name, value, options) => {
  Cookies.set(name, value, {
    expires: parseInt(process.env.REACT_APP_DEFAULT_COOKIE_EXPIRY_DATE),
    secure: process.env.REACT_APP_FORCE_HTTPS === 'true',
    domain: process.env.REACT_APP_TOKEN_DOMAIN,
    ...options,
  });
};

const get = name => Cookies.get(name);

const remove = (name, options) =>
  Cookies.remove(name, {
    ...options,
    domain: process.env.REACT_APP_TOKEN_DOMAIN,
  });

export { get, set, remove, getAllCookies };
