const accountManagementActionTypes = {
  FETCH_PHARMACY_USER_AUTHENTICATION_INFO: 'FETCH_PHARMACY_USER_AUTHENTICATION_INFO',
  FETCH_PHARMACY_USER_AUTHENTICATION_INFO_SUCCESS:
    'FETCH_PHARMACY_USER_AUTHENTICATION_INFO_SUCCESS',
  FETCH_PHARMACY_USER_AUTHENTICATION_INFO_FAIL: 'FETCH_PHARMACY_USER_AUTHENTICATION_INFO_FAIL',

  FETCH_PHARMACY_LOGIN_INFO: 'FETCH_PHARMACY_LOGIN_INFO',
  FETCH_PHARMACY_LOGIN_INFO_SUCCESS: 'FETCH_PHARMACY_LOGIN_INFO_SUCCESS',
  FETCH_PHARMACY_LOGIN_INFO_FAIL: 'FETCH_PHARMACY_LOGIN_INFO_FAIL',

  FETCH_USER_PERMISSIONS: 'FETCH_USER_PERMISSIONS',
  FETCH_USER_PERMISSIONS_SUCCESS: 'FETCH_USER_PERMISSIONS_SUCCESS',
  FETCH_USER_PERMISSIONS_FAIL: 'FETCH_USER_PERMISSIONS_FAIL',
};

export default accountManagementActionTypes;
