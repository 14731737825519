import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { pharmacyContexts } from 'constants/contexts';
import { setContext } from 'modules/common/actions';
import { fetchOrderDetails } from 'modules/orders/actions';
import OrderDetails from './OrderDetails';

const OrderDetailsContainer = ({ selectedOrderId }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setContext(pharmacyContexts.orders));
    const body = {
      orderId: selectedOrderId,
    };
    dispatch(fetchOrderDetails(body));
  }, [dispatch, selectedOrderId]);
  return <OrderDetails orderId={selectedOrderId} />;
};

OrderDetailsContainer.propTypes = {
  selectedOrderId: PropTypes.string,
};

OrderDetailsContainer.defaultProps = {
  selectedOrderId: '',
};

export default OrderDetailsContainer;
