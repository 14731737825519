import styled from 'styled-components';

export const NotificationBarContainer = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  width: calc(100% - 46px);
  color: #ffff;
  background: #db3226;
  z-index: 100;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 20px;
  height: 0px;
  transition: height 0.5s ease-in-out;

  ${props =>
    props.showNotification &&
    `
    height: 48px;
  `}
`;

export const NotificationBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const NotificationMsg = styled.p`
  margin: 0;
  line-height: 30px;
`;

export const NotificationBarCloseIcon = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
