/* eslint-disable react/prop-types */
import { useRef } from 'react';
import PropTypes from 'prop-types';
import { useOnClickOutside } from 'utils/functions/hooks';
import Loader from 'modules/common/components/Loader';
import {
  ModalContainer,
  ModalDialog,
  ModalHeader,
  Title,
  ModalBody,
  ModalActions,
  ModalButton,
  ModalConfirmButton,
  PreventBodyScrolling,
  ModalCloseIcon,
} from './Modal.style';

const Modal = props => {
  const {
    toggle,
    size,
    title,
    children,
    onClose,
    onCancelHandler,
    cancelActionLabel,
    onConfirmHandler,
    disableConfirmButton,
    confirmActionLabel,
    confirmActionLoading,
    extendConfirmButtonStyle,
    extendStyle,
    display,
  } = props;
  const containerRef = useRef();

  const handleClose = () => {
    onClose();
  };

  useOnClickOutside(containerRef, handleClose);
  return (
    <ModalContainer data-testid="modal-container" toggle={toggle} extendStyle={extendStyle}>
      {toggle && <PreventBodyScrolling isOpen={toggle} />}
      <ModalDialog data-testid="modal-card" ref={containerRef} size={size}>
        <ModalHeader data-testid="modal-header">
          <Title data-testid="modal-title">{title}</Title>
          <ModalCloseIcon data-testid="modal-close-icon" onClick={handleClose} />
        </ModalHeader>
        <ModalBody data-testid="modal-body">{children !== '' && children}</ModalBody>
        {onConfirmHandler && !display && (
          <ModalActions data-testid="modal-actions">
            <ModalButton
              data-testid="modal-cancel-button"
              onClick={() => {
                if (onCancelHandler !== undefined) onCancelHandler();
              }}
            >
              {cancelActionLabel || 'Cancel'}
            </ModalButton>
            <ModalConfirmButton
              extendStyle={extendConfirmButtonStyle}
              data-testid="modal-confirm-button"
              onClick={() => {
                onConfirmHandler();
              }}
              disabled={disableConfirmButton}
            >
              {(confirmActionLoading && <Loader width={14} height={14} thickness={4} />) ||
                confirmActionLabel ||
                'Save'}
            </ModalConfirmButton>
          </ModalActions>
        )}
      </ModalDialog>
    </ModalContainer>
  );
};

export default Modal;

Modal.propTypes = {
  size: PropTypes.string,
  title: PropTypes.string,
  toggle: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onCancelHandler: PropTypes.func,
  cancelActionLabel: PropTypes.string,
  onConfirmHandler: PropTypes.func,
  confirmActionLabel: PropTypes.string,
  disableConfirmButton: PropTypes.bool,
  confirmActionLoading: PropTypes.bool,
  children: PropTypes.node,
};
