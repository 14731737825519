import PropTypes from 'prop-types';
import SectionDivider from 'modules/common/components/SectionDivider';
import { orderStatusEnum } from 'modules/orders/constants';

import { promoCodeStatusPending } from 'modules/orders/OrdersView/OrdersContainer/enums';
import {
  OrderDetailsInvoiceInfoWrapper,
  OrderDetailsInvoiceInfoSecondColumn,
  OrderInvoiceInfoPromoCode,
  OrderDetailsInvoiceInfoBlackText,
  OrderDetailsInvoiceInfoBigText,
  OrderDetailsInvoiceInfoIconText,
} from '../OrderInvoice.style';

export const InvoiceSummary = ({
  stringsData,
  themeContext,
  orderDetails,
  showInsuranceInvoiceText,
  promoCodeReference,
  dispensedOrderItems,
}) => {
  const orderData = orderDetails.details;
  const { deliveryFee, promoCodeStatus, orderReceipt, orderStateTypeId } = orderData;
  const subTotal = dispensedOrderItems?.reduce((prevValue, currentValue) => {
    let { price } = currentValue;
    price = currentValue.price * currentValue.quantity;
    return prevValue + price;
  }, 0);
  const discountAmount = orderReceipt?.discountAmount ?? 0;
  const vezeetaCashDiscountAmount = orderReceipt?.vezeetaCash ?? 0;
  const insuranceDiscount = orderReceipt?.insurance ?? 0;
  const totalDiscount = -(discountAmount + vezeetaCashDiscountAmount + insuranceDiscount);
  const totalAmount =
    subTotal + deliveryFee - discountAmount - vezeetaCashDiscountAmount - insuranceDiscount;

  const handlePromoCode = () => {
    if (promoCodeStatusPending.includes(promoCodeStatus))
      return discountAmount ? `-${discountAmount?.toFixed(2)} EGP` : '-';
    return '';
  };

  const showInvoiceTotalAmount =
    orderStateTypeId === orderStatusEnum.Confirmed ||
    orderStateTypeId === orderStatusEnum['Payment Success'] ||
    orderStateTypeId === orderStatusEnum['Payment Failed'] ||
    orderStateTypeId === orderStatusEnum['Picked Up'] ||
    orderStateTypeId === orderStatusEnum['Ready for Pickup'] ||
    orderStateTypeId === orderStatusEnum.Delivered;

  return (
    <OrderDetailsInvoiceInfoWrapper>
      <OrderDetailsInvoiceInfoSecondColumn>
        <OrderDetailsInvoiceInfoBlackText>
          {stringsData.orderDetailsContent.orderInvoiceDetailsSubtotalLabel(subTotal?.toFixed(2))}
        </OrderDetailsInvoiceInfoBlackText>
        <SectionDivider gap={4} />
        {totalDiscount < 0 && (
          <OrderDetailsInvoiceInfoBlackText
            style={{
              color: themeContext.colors.greenMain,
            }}
          >
            {stringsData.orderDetailsContent.orderInvoiceDetailsDiscountLabel(
              totalDiscount?.toFixed(2),
            )}
          </OrderDetailsInvoiceInfoBlackText>
        )}
        <SectionDivider gap={4} />
        <OrderDetailsInvoiceInfoBlackText>
          {stringsData.orderDetailsContent.orderInvoiceDetailsDeliveryFeeLabel(
            deliveryFee?.toFixed(2),
          )}
        </OrderDetailsInvoiceInfoBlackText>
        <SectionDivider gap={4} />
        {showInsuranceInvoiceText && (
          <>
            <OrderDetailsInvoiceInfoIconText
              icon={"'\\e90d'"}
              textColor={themeContext.colors.greenMain}
              iconColor={themeContext.colors.greenMain}
            >
              {stringsData.orderDetailsContent.orderInvoiceDetailsInsuranceLabel}
            </OrderDetailsInvoiceInfoIconText>
            <SectionDivider gap={4} />
          </>
        )}
        {promoCodeReference && (
          <OrderInvoiceInfoPromoCode>
            <OrderDetailsInvoiceInfoIconText
              icon={"'\\e933'"}
              iconColor={themeContext.colors.blueMain}
            >
              {stringsData.orderDetailsContent.orderInvoiceDetailsPromoCodeLabel(
                promoCodeReference,
                handlePromoCode(),
              )}
            </OrderDetailsInvoiceInfoIconText>
            <SectionDivider gap={4} />
          </OrderInvoiceInfoPromoCode>
        )}
        {showInvoiceTotalAmount ? (
          <OrderDetailsInvoiceInfoBigText>
            {stringsData.orderDetailsContent.orderInvoiceDetailsTotalLabel(totalAmount?.toFixed(2))}
          </OrderDetailsInvoiceInfoBigText>
        ) : (
          ''
        )}
      </OrderDetailsInvoiceInfoSecondColumn>
    </OrderDetailsInvoiceInfoWrapper>
  );
};
InvoiceSummary.propTypes = {
  dispensedOrderItems: PropTypes.arrayOf(PropTypes.shape({})),
  stringsData: PropTypes.shape({
    orderDetailsContent: PropTypes.shape({
      orderInvoiceDetailsLabTitle: PropTypes.string,
      orderInvoiceDetailsChemistTitle: PropTypes.string,
      orderInvoiceDetailsSubtotalLabel: PropTypes.func,
      orderInvoiceDetailsDeliveryFeeLabel: PropTypes.func,
      orderInvoiceDetailsDiscountLabel: PropTypes.func,
      orderInvoiceDetailsTotalLabel: PropTypes.func,
      orderInvoiceDetailsInsuranceLabel: PropTypes.string,
      orderInvoiceDetailsPromoCodeLabel: PropTypes.func,
      orderInvoiceDetailsVezeetaPointsLabel: PropTypes.func,
      orderInvoiceDetailsVezeetaPointsLabelWithoutPoints: PropTypes.func,
      orderPromoCodeIsInvalid: PropTypes.string,
      orderInvoiceDetailsPromocodePlaceholder: PropTypes.string,
    }),
  }).isRequired,
  themeContext: PropTypes.shape({
    colors: PropTypes.shape({
      greenMain: PropTypes.string,
      blueMain: PropTypes.string,
      orangeDark: PropTypes.string,
    }),
  }).isRequired,
  orderDetails: PropTypes.shape({
    details: PropTypes.shape({
      orderStateTypeId: PropTypes.number,
      orderItems: PropTypes.arrayOf(PropTypes.shape({})),
      deliveryFee: PropTypes.number,
      orderDate: PropTypes.shape({
        orderElapsedTimeStr: PropTypes.string,
      }),
      promoCode: PropTypes.string,
      orderReceipt: PropTypes.shape({
        discountAmount: PropTypes.number,
        vezeetaCash: PropTypes.number,
        insurance: PropTypes.number,
      }),
      promoCodeStatus: PropTypes.number,
      hasPromoCodeAddedByPatient: PropTypes.bool,
    }),
  }).isRequired,
  promoCodeReference: PropTypes.string.isRequired,
  showInsuranceInvoiceText: PropTypes.bool,
  invoiceHeaderMenuItemsObj: PropTypes.shape({
    original: PropTypes.string,
  }).isRequired,
};
