export const contexts = {
  login: 'login',
  orders: 'orders',
  vendors: 'vendors',
  notFound: 'notFound',
  masterList: 'masterlist',
  unauthorized: 'unauthorized',
  stock: 'stock',
};

export const contextToPageNameMap = {
  login: 'Login',
  orders: 'Orders',
  vendors: 'Labs',
  notFound: 'Not Found',
  masterList: 'Master List',
  unauthorized: 'Unauthorized',
  stock: 'Stock',
};

export const pharmacyContexts = {
  orders: 'orders',
  stock: 'stock',
  invoices: 'invoices',
};

export const pharmacyContextToPageNameMap = {
  login: 'Login',
  orders: 'Orders',
  masterList: 'Master List',
  unauthorized: 'Unauthorized',
};
