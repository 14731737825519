import PropTypes from 'prop-types';
import Icon from 'modules/common/components/Icon';
import {
  NotificationBarContainer,
  NotificationBarCloseIcon,
  NotificationBarWrapper,
  NotificationMsg,
} from './NotificationBar.style';

const NotificationBar = ({ onClick, onClose, message, showNotification }) => (
  <NotificationBarContainer
    onClick={() => {
      onClick();
    }}
    showNotification={showNotification}
  >
    <NotificationBarWrapper>
      <NotificationMsg>{message}</NotificationMsg>
      <NotificationBarCloseIcon>
        <Icon
          onClick={e => {
            e.stopPropagation();
            onClose();
          }}
          icon="e93d"
          size={14}
        />
      </NotificationBarCloseIcon>
    </NotificationBarWrapper>
  </NotificationBarContainer>
);

NotificationBar.propTypes = {
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  message: PropTypes.string,
  showNotification: PropTypes.bool,
};

export default NotificationBar;
