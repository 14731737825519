import { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import _ from 'lodash';
import { status } from 'constants/general';
import { formatDateTime } from 'utils/functions/dateTime';
import Loader from 'modules/common/components/Loader';
import SectionDivider from 'modules/common/components/SectionDivider';
import { orderStatusEnum } from 'modules/orders/constants';
import OrderPaymentUpdates from 'modules/orders/OrdersView/OrderPaymentUpdates';
import {
  isSubmitAlternativesEnabled,
  isEnableOrderPaymentUpdates,
  getOrderElapsedTime,
  TABLE_VIEWS,
} from './helper';
import OrderStatusSection from './Sections/OrderStatusSection';
import PatientUserDetailsSection from './Sections/PatientUserDetailsSection';
import OrderDetailsAttachmentSection from './Sections/OrderDetailsAttachmentSection';
import OrderInvoiceSection from './Sections/OrderInvoiceSection';
import OrderActionBtnsSection from './Sections/OrderActionBtnsSection';

import {
  LoaderContainer,
  OrderDetailsTitle,
  OrderDetailsNoDataText,
  OrderDetailsWrapper,
  OrderDetailsInfoSection,
  OrderNumberOverlay,
  OrderId,
  CreationDateTime,
  ElapsedTime,
  SeparationDot,
} from './OrderDetailsComponent.style';

const OrderDetailsComponent = ({
  stringsData,
  orderDetails,
  orderServicesSuggestions,
  loginInfo,
  actionPopupStatus,
  handleOrderRefreshBtnClick,
  getItemsSuggestionsData,
  resetServicesSuggestionsData,
  submitOrderInvoiceDispensedItems,
  actionBtnClick,
  actionPopupDismissBtn,
  actionPopupConfirmBtn,
  actionPopupConfirmBtnCallback,
  scheduleOrderPopupConfirmBtn,
  cancelOrderPopupConfirmBtn,
  rejectOrderPopupConfirmBtn,
  orderStatusToEnableEditInvoice,
  orderStatusToEnableAddingPromoCode,
  scheduleOrderReasons,
  cancelOrderReasons,
  rejectOrderReasons,
  objectMapper,
  hideButtonBasedOnState,
  mappedOrderStatus,
  selectItemsSearchApi,
  buttonDisabledOptions,
  dynamicPermissions,
  initialNextOrderState,
  showRejectButton,
  getStocks,
  submitItemsAlternatives,
  getItemAlternatives,
  reloadOrderDetailsPage,
  fetchOrderStatusChangeSubmission,
  confirmOrderAction,
  returnOrderAction,
  orderId,
  isEligableForAlternativeRecommendation,
  updateOrderItemsCallback,
}) => {
  const themeContext = useContext(ThemeContext);
  const [showCopiedTextOverlay, setShowCopiedTextOverlay] = useState(false);
  const [dispensedOrderItems, setDispensedOrderItems] = useState([]);
  const [checkedItemToBeConfirmed, setCheckedItemsToBeConfirmed] = useState([]);
  const [productsAlternatives, appendProductAlternatives] = useState([]);
  const [orderTableMode, setOrderTableMode] = useState(TABLE_VIEWS.ORDER_PREPARATION);
  const [isAddToOrderModalOpened, toggleAddToOrderModal] = useState(false);
  const [suggestAlternativesModal, setSuggestAllternativeModal] = useState({
    isOpened: false,
    orderItemKey: null,
    productKey: null,
    productName: null,
    productShapeId: null,
  });

  const orderData = orderDetails.details;
  const orderSource = orderData?.source;

  useEffect(() => {
    /**
     * Reset State
     */
    setDispensedOrderItems([]);
    setCheckedItemsToBeConfirmed([]);
    appendProductAlternatives([]);
    setOrderTableMode(TABLE_VIEWS.ORDER_PREPARATION);
  }, [orderId]);

  const onClickSendToCustomer = () => {
    if (productsAlternatives?.length) {
      const payload = [];
      productsAlternatives.forEach(product => {
        const { alternatives } = product;
        const temp = {
          orderItemKey: product.itemProductKey,
          alternatives: [],
        };

        alternatives.forEach(alternativeItem => {
          temp.alternatives.push({
            price: alternativeItem.itemPrice,
            productKey: alternativeItem.itemProductKey,
            productShapeId: alternativeItem.itemShapeId,
            quantity: alternativeItem.itemQuantity,
          });
        });
        payload.push(temp);
      });

      submitItemsAlternatives({
        recommendedAlternatives: payload,
        orderKey: orderId,
        callbackAction: reloadOrderDetailsPage,
      });
    }
  };

  const handleInvoiceItemCheckToBeConfirmed = key => {
    let temp = [];
    if (key) {
      if (checkedItemToBeConfirmed.includes(key)) {
        checkedItemToBeConfirmed.forEach(itemKey => {
          if (itemKey !== key) {
            temp.push(itemKey);
          }
        });
        setCheckedItemsToBeConfirmed(temp);
      } else {
        temp = [...checkedItemToBeConfirmed, key];
        setCheckedItemsToBeConfirmed(temp);
      }
    } else if (!checkedItemToBeConfirmed.length) {
      dispensedOrderItems.forEach(item => temp.push(`${item.serviceKey}-${item.productShapeId}`));
      setCheckedItemsToBeConfirmed(temp);
    } else {
      setCheckedItemsToBeConfirmed(temp);
    }
  };

  const handleTextToCopyToClipboardClick = textToCopy => {
    navigator.clipboard.writeText(textToCopy);
    setShowCopiedTextOverlay(true);

    setTimeout(() => {
      setShowCopiedTextOverlay(false);
    }, 2000);
  };

  useEffect(() => {
    setCheckedItemsToBeConfirmed([]);
  }, [orderDetails.status]);

  if (
    orderDetails.status === status.SHOULD_CALL_API ||
    orderDetails.status === status.FETCHING ||
    orderDetails.details.patientUserDetails === status.FETCHING
  )
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );

  if (orderDetails.status === status.FAIL)
    return (
      <LoaderContainer>
        <OrderDetailsNoDataText>
          {stringsData.orderDetailsContent.noDataPlaceholder}
        </OrderDetailsNoDataText>
      </LoaderContainer>
    );

  const {
    isEligableForItemization,
    paymentMethodKey,
    orderAttachments,
    orderNumber,
    orderStateTypeId,
    createdOn,
  } = orderDetails.details;
  const enableOrderPaymentUpdates = isEnableOrderPaymentUpdates(orderStateTypeId, paymentMethodKey);
  const enableAddItemToOrder = isEligableForItemization;
  const submitAlternativesEnabled =
    isSubmitAlternativesEnabled(orderStateTypeId) &&
    orderTableMode === TABLE_VIEWS.SUGGEST_ALTERNATIVES;
  const orderElapsedTimeInMin = getOrderElapsedTime(createdOn);

  const onAppendProductAlternatives = payload => {
    const productIndex = productsAlternatives.findIndex(
      item =>
        item.itemProductKey === payload.itemProductKey && item.itemShapeId === payload.itemShapeId,
    );

    if (productIndex === -1) {
      appendProductAlternatives([
        ...productsAlternatives,
        {
          ...payload,
        },
      ]);
    } else {
      const temp = _.cloneDeep(productsAlternatives);
      temp[productIndex] = { ...payload };
      appendProductAlternatives(temp);
    }
  };

  const onRemoveProductAlternatives = payload => {
    let temp = _.cloneDeep(productsAlternatives);
    temp = temp.filter(
      item =>
        !(
          payload.orderItemKey === item.itemProductKey &&
          payload.productShapeId === item.itemShapeId
        ),
    );
    appendProductAlternatives(temp);
  };

  return (
    <OrderDetailsWrapper>
      {/** Check Payment Processing */}
      {enableOrderPaymentUpdates && (
        <OrderPaymentUpdates orderId={orderId} currentState={orderStateTypeId} />
      )}
      {/** Order Id */}
      <OrderDetailsTitle showCursor onClick={() => handleTextToCopyToClipboardClick(orderNumber)}>
        <OrderId>{`Order #${orderNumber}`}</OrderId>
        <SeparationDot>.</SeparationDot>
        <CreationDateTime>{formatDateTime(createdOn)}</CreationDateTime>
        <SeparationDot>.</SeparationDot>
        <ElapsedTime>{orderElapsedTimeInMin}</ElapsedTime>
      </OrderDetailsTitle>
      {/** Status Progress */}
      <SectionDivider gap={10} />
      <OrderStatusSection mappedOrderStatus={mappedOrderStatus} />
      {/** Delivery Details */}
      <SectionDivider gap={10} />
      <OrderDetailsInfoSection>
        <PatientUserDetailsSection orderDetails={orderDetails} />
      </OrderDetailsInfoSection>

      {/** Non Itemized */}
      <SectionDivider gap={10} />
      <OrderDetailsAttachmentSection
        orderAttachments={orderAttachments}
        orderDetails={orderDetails}
        stringsData={stringsData}
        themeContext={themeContext}
        isAddToOrderModalOpened={isAddToOrderModalOpened}
        toggleAddToOrderModal={toggleAddToOrderModal}
        submitOrderInvoiceDispensedItems={submitOrderInvoiceDispensedItems}
        updateOrderItemsCallback={updateOrderItemsCallback}
        orderSource={orderSource}
        enableAddItemToOrder={enableAddItemToOrder}
      />
      {/** Itemized */}
      <SectionDivider gap={10} />
      <OrderInvoiceSection
        productsAlternatives={productsAlternatives}
        isEligableForAlternativeRecommendation={isEligableForAlternativeRecommendation}
        dispensedOrderItems={dispensedOrderItems}
        setDispensedOrderItems={setDispensedOrderItems}
        stringsData={stringsData}
        orderDetails={orderDetails}
        orderServicesSuggestions={orderServicesSuggestions}
        getItemsSuggestionsData={getItemsSuggestionsData}
        resetServicesSuggestionsData={resetServicesSuggestionsData}
        submitOrderInvoiceDispensedItems={submitOrderInvoiceDispensedItems}
        showInvoiceStockColumn
        showInvoiceSubTotalColumn
        showInvoiceQuantityColumn
        orderStatusToEnableEditInvoice={orderStatusToEnableEditInvoice}
        orderStatusToEnableAddingPromoCode={orderStatusToEnableAddingPromoCode}
        objectMapper={objectMapper}
        getStocks={getStocks}
        submitItemsAlternatives={submitItemsAlternatives}
        getItemAlternatives={getItemAlternatives}
        handleInvoiceItemCheckToBeConfirmed={handleInvoiceItemCheckToBeConfirmed}
        checkedItemToBeConfirmed={checkedItemToBeConfirmed}
        setSuggestAllternativeModal={setSuggestAllternativeModal}
        orderTableMode={orderTableMode}
        setOrderTableMode={setOrderTableMode}
        appendProductAlternatives={appendProductAlternatives}
        onRemoveProductAlternatives={onRemoveProductAlternatives}
      />
      {/** Action Btns */}
      <OrderActionBtnsSection
        onClickSendToCustomer={onClickSendToCustomer}
        isSuggestAlternativesMode={submitAlternativesEnabled}
        productsAlternatives={productsAlternatives}
        appendProductAlternatives={onAppendProductAlternatives}
        isAllInvoiceItemsToBeConfirmedChecked={
          checkedItemToBeConfirmed.length > 0 &&
          checkedItemToBeConfirmed.length === dispensedOrderItems?.length
        }
        dispensedOrderItems={dispensedOrderItems}
        confirmOrderAction={confirmOrderAction}
        returnOrderAction={returnOrderAction}
        fetchOrderStatusChangeSubmission={fetchOrderStatusChangeSubmission}
        reloadOrderDetailsPage={reloadOrderDetailsPage}
        orderStateTypeId={orderStateTypeId}
        stringsData={stringsData}
        orderDetails={orderDetails}
        loginInfo={loginInfo}
        actionPopupStatus={actionPopupStatus}
        handleOrderRefreshBtnClick={handleOrderRefreshBtnClick}
        actionBtnClick={actionBtnClick}
        actionPopupDismissBtn={actionPopupDismissBtn}
        actionPopupConfirmBtn={actionPopupConfirmBtn}
        actionPopupConfirmBtnCallback={actionPopupConfirmBtnCallback}
        handleTextToCopyToClipboardClick={handleTextToCopyToClipboardClick}
        scheduleOrderPopupConfirmBtn={scheduleOrderPopupConfirmBtn}
        cancelOrderPopupConfirmBtn={cancelOrderPopupConfirmBtn}
        rejectOrderPopupConfirmBtn={rejectOrderPopupConfirmBtn}
        orderStatusEnum={orderStatusEnum}
        scheduleOrderReasons={scheduleOrderReasons}
        cancelOrderReasons={cancelOrderReasons}
        rejectOrderReasons={rejectOrderReasons}
        hideButtonBasedOnState={hideButtonBasedOnState}
        showRejectButton={showRejectButton}
        selectItemsSearchApi={selectItemsSearchApi}
        buttonDisabledOptions={buttonDisabledOptions}
        dynamicPermissions={dynamicPermissions}
        initialNextOrderState={initialNextOrderState}
        suggestAlternativesModal={suggestAlternativesModal}
        setSuggestAllternativeModal={setSuggestAllternativeModal}
      />

      {/** Widget */}
      {showCopiedTextOverlay && (
        <OrderNumberOverlay>
          {stringsData.orderDetailsContent.copiedToClipboardText}
        </OrderNumberOverlay>
      )}
    </OrderDetailsWrapper>
  );
};

OrderDetailsComponent.propTypes = {
  updateOrderItemsCallback: PropTypes.func,
  isEligableForAlternativeRecommendation: PropTypes.bool,
  orderId: PropTypes.string,
  confirmOrderAction: PropTypes.func,
  returnOrderAction: PropTypes.func,
  fetchOrderStatusChangeSubmission: PropTypes.func,
  reloadOrderDetailsPage: PropTypes.func,
  stringsData: PropTypes.shape({
    orderDetailsContent: PropTypes.shape({
      noDataPlaceholder: PropTypes.string,
      copiedToClipboardText: PropTypes.string,
    }),
  }).isRequired,

  submitItemsAlternatives: PropTypes.func,
  getItemAlternatives: PropTypes.func,
  getStocks: PropTypes.func,
  orderDetails: PropTypes.shape({
    status: PropTypes.string,
    details: PropTypes.shape({
      isEligableForItemization: PropTypes.bool,
      source: PropTypes.string,
      paymentMethodKey: PropTypes.string,
      createdOn: PropTypes.string,
      patientUserDetails: PropTypes.string,
      orderNumber: PropTypes.string,
      orderStateTypeId: PropTypes.number,
      orderAttachments: PropTypes.arrayOf(PropTypes.shape({})),
      orderDate: PropTypes.shape({
        orderDateTimeStr: PropTypes.string,
        orderElapsedTimeStr: PropTypes.string,
      }),
    }),
  }).isRequired,
  orderServicesSuggestions: PropTypes.shape({}).isRequired,
  uploadContentSubmission: PropTypes.shape({}),
  loginInfo: PropTypes.shape({}).isRequired,
  actionPopupStatus: PropTypes.number.isRequired,
  showRejectButton: PropTypes.bool,
  handleOrderRefreshBtnClick: PropTypes.func.isRequired,
  getItemsSuggestionsData: PropTypes.func.isRequired,
  resetServicesSuggestionsData: PropTypes.func.isRequired,
  submitOrderInvoiceDispensedItems: PropTypes.func.isRequired,
  actionBtnClick: PropTypes.func.isRequired,
  actionPopupDismissBtn: PropTypes.func.isRequired,
  actionPopupConfirmBtn: PropTypes.func.isRequired,
  actionPopupConfirmBtnCallback: PropTypes.func.isRequired,
  cancelOrderPopupConfirmBtn: PropTypes.func.isRequired,
  rejectOrderPopupConfirmBtn: PropTypes.func,
  orderStatusEnum: PropTypes.shape({}).isRequired,
  orderStatusToEnableEditInvoice: PropTypes.arrayOf(PropTypes.number).isRequired,
  scheduleOrderPopupConfirmBtn: PropTypes.func.isRequired,
  scheduleOrderReasons: PropTypes.shape({}).isRequired,
  cancelOrderReasons: PropTypes.shape({}).isRequired,
  rejectOrderReasons: PropTypes.shape({}),
  objectMapper: PropTypes.func.isRequired,
  hideButtonBasedOnState: PropTypes.shape({}).isRequired,
  mappedOrderStatus: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectItemsSearchApi: PropTypes.func,
  orderStatusToEnableAddingPromoCode: PropTypes.arrayOf(PropTypes.number),
  buttonDisabledOptions: PropTypes.shape({}),
  dynamicPermissions: PropTypes.shape({}),
  initialNextOrderState: PropTypes.shape({}),
  permissionsCategories: PropTypes.shape({
    order: PropTypes.string,
  }),
};

OrderDetailsComponent.defaultProps = {
  showRejectButton: false,
  rejectOrderReasons: {},
  orderStatusToEnableAddingPromoCode: [],
  buttonDisabledOptions: {},
  dynamicPermissions: {},
  initialNextOrderState: {},
  permissionsCategories: {},
  rejectOrderPopupConfirmBtn: () => {},
  selectItemsSearchApi: () => {},
};

export default OrderDetailsComponent;
