import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { DateRange } from 'react-date-range';
import {
  getDateDetailsFromDateTimeObj,
  getMonthDifferenceBetweenTwoDates,
} from 'utils/functions/dateTime';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import {
  RangeDatePickerWrapper,
  RangeDatePickerSelectorWrapper,
  RangeDatePickerLabel,
} from './RangeDatePicker.style';

const RangeDatePicker = ({
  selectedDateFilterObj,
  handleRangeDatePickerChange,
  extendPickerStyle,
  extendPickerBtnStyle,
}) => {
  const datePickerWrapperRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState();
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [selectedDateLabel, setSelectedDateLabel] = useState();
  const [selectDateStep, setSelectDateStep] = useState({
    startDate: false,
    endDate: false,
  });

  useEffect(() => {
    let dateLabel = 'Please select a date';
    if (selectedDateFilterObj?.startDate && selectedDateFilterObj?.endDate) {
      const {
        day: startDay,
        month: startMonth,
        year: startYear,
      } = getDateDetailsFromDateTimeObj(selectedDateFilterObj?.startDate);
      const {
        day: endDay,
        month: endMonth,
        year: endYear,
      } = getDateDetailsFromDateTimeObj(selectedDateFilterObj?.endDate);
      dateLabel = `${startDay} ${startMonth} ${startYear} - ${endDay} ${endMonth} ${endYear}`;
    }

    setSelectedDateLabel(dateLabel || 'Please select a date');
    setSelectedDate([
      {
        startDate: selectedDateFilterObj?.startDate || new Date(),
        endDate: selectedDateFilterObj?.endDate || new Date(),
        key: 'selection',
      },
    ]);
  }, [selectedDateFilterObj]);

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        datePickerWrapperRef.current &&
        datePickerWrapperRef.current.contains(event.target) === false
      )
        setIsDatePickerOpen(false);
    };
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [datePickerWrapperRef]);

  const handleDatePickerLabelClick = () => {
    setIsDatePickerOpen(prevState => !prevState);
  };

  const handleDatePickerChange = date => {
    const { selection: dateObj } = date;

    if (selectDateStep.startDate === false && selectDateStep.endDate === false) {
      setSelectedDate([dateObj]);
      setSelectDateStep({
        startDate: true,
        endDate: true,
      });
    } else if (selectDateStep.startDate === true) {
      if (getMonthDifferenceBetweenTwoDates(dateObj.startDate, dateObj.endDate) > 1) {
        setSelectDateStep({
          startDate: false,
          endDate: false,
        });

        return;
      }

      setSelectedDate([dateObj]);
      setSelectDateStep({
        startDate: false,
        endDate: false,
      });

      setIsDatePickerOpen(false);

      const {
        day: startDay,
        month: startMonth,
        year: startYear,
      } = getDateDetailsFromDateTimeObj(dateObj.startDate);
      const {
        day: endDay,
        month: endMonth,
        year: endYear,
      } = getDateDetailsFromDateTimeObj(dateObj.endDate);
      setSelectedDateLabel(
        `${startDay} ${startMonth} ${startYear} - ${endDay} ${endMonth} ${endYear}`,
      );

      handleRangeDatePickerChange(date.selection);
    }
  };

  return (
    <RangeDatePickerWrapper extendPickerBtnStyle={extendPickerBtnStyle} ref={datePickerWrapperRef}>
      <RangeDatePickerLabel
        data-testid="data-picker-label"
        onClick={() => handleDatePickerLabelClick()}
      >
        {selectedDateLabel}
      </RangeDatePickerLabel>
      {isDatePickerOpen && (
        <RangeDatePickerSelectorWrapper
          extendPickerStyle={extendPickerStyle}
          data-testid="data-picker-selector"
        >
          <DateRange
            editableDateInputs
            onChange={date => handleDatePickerChange(date)}
            moveRangeOnFirstSelection={false}
            ranges={selectedDate}
          />
        </RangeDatePickerSelectorWrapper>
      )}
    </RangeDatePickerWrapper>
  );
};

RangeDatePicker.propTypes = {
  extendPickerBtnStyle: PropTypes.arrayOf({}),
  extendPickerStyle: PropTypes.arrayOf({}),
  selectedDateFilterObj: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }),
  handleRangeDatePickerChange: PropTypes.func.isRequired,
};

RangeDatePicker.defaultProps = {
  selectedDateFilterObj: {},
};

export default RangeDatePicker;
