import { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'modules/common/components/Popup';
import Button from 'modules/common/components/Button';
import SectionDivider from 'modules/common/components/SectionDivider';
import ItemizationItemsModal from './Modals/ItemizationItemsModal';
import { orderAttachmentTypeEnum } from '../../../enums';
import {
  OrderDetailsTitle,
  OrderDetailsText,
  OrderDetailsSubText,
  OrderDetailsAttachmentSectionContainer,
  OrderDetailsAttachmentWrapper,
  OrderDetailsAttachmentImgWrapper,
  OrderDetailsAttachmentImgRatio,
  OrderDetailsAttachmentImg,
  OrderDetailsAttachmentImgFullSize,
  OrderDetailsAttachmentInfoWrapper,
  extendAddToOrderButtonStyle,
} from '../OrderDetailsComponent.style';

const OrderAttachment = ({
  themeContext,
  orderDetails,
  attachment,
  onClickAddToOrder,
  isLastItem,
  enableAddItemToOrder,
}) => {
  const [isOrderAttachementImgPopupOpen, setIsOrderAttachementImgPopupOpen] = useState(false);
  const orderData = orderDetails.details;
  const orderSource = orderData.source;
  const showImg = attachment.attachmentType === orderAttachmentTypeEnum.image;

  const handleOrderAttachementImgClick = () => {
    setIsOrderAttachementImgPopupOpen(true);
  };

  const handleOrderAttachementImgPopupCloseBtnClick = () => {
    setIsOrderAttachementImgPopupOpen(false);
  };

  return (
    <>
      <OrderDetailsAttachmentWrapper>
        {showImg && (
          <OrderDetailsAttachmentImgWrapper onClick={() => handleOrderAttachementImgClick()}>
            <OrderDetailsAttachmentImgRatio />
            <OrderDetailsAttachmentImg src={attachment.url} alt={attachment.key} />
          </OrderDetailsAttachmentImgWrapper>
        )}
        <OrderDetailsAttachmentInfoWrapper>
          <OrderDetailsTitle>Requested item</OrderDetailsTitle>
          <OrderDetailsSubText>{`from ${orderSource}`}</OrderDetailsSubText>
          <OrderDetailsText>{attachment.note}</OrderDetailsText>
        </OrderDetailsAttachmentInfoWrapper>
        {enableAddItemToOrder && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              flexDirection: 'column',
              marginLeft: 'auto',
            }}
          >
            <Button
              onClick={() => {
                onClickAddToOrder(attachment);
              }}
              key="add-order-items"
              btnText="Add to Order"
              extendButtonStyle={extendAddToOrderButtonStyle}
            />
          </div>
        )}
      </OrderDetailsAttachmentWrapper>
      {!isLastItem && (
        <SectionDivider
          gap={16}
          withSeparator
          separatorGap={16}
          borderColor={themeContext.colors.grey004}
        />
      )}
      {isOrderAttachementImgPopupOpen && (
        <Popup handleCloseBtnClick={() => handleOrderAttachementImgPopupCloseBtnClick()}>
          <OrderDetailsAttachmentImgFullSize src={attachment.url} alt={attachment.key} />
        </Popup>
      )}
    </>
  );
};

OrderAttachment.propTypes = {
  enableAddItemToOrder: PropTypes.bool,
  onClickAddToOrder: PropTypes.func,
  themeContext: PropTypes.shape({
    colors: PropTypes.shape({
      grey004: PropTypes.string,
    }),
  }).isRequired,
  stringsData: PropTypes.shape({
    orderDetailsContent: PropTypes.shape({
      orderAttachmentInfoTitle: PropTypes.string,
      orderAttachmentInfoFrom: PropTypes.string,
    }),
  }).isRequired,
  orderDetails: PropTypes.shape({
    details: PropTypes.shape({
      source: PropTypes.string,
    }),
  }).isRequired,
  attachment: PropTypes.shape({
    key: PropTypes.string,
    url: PropTypes.string,
    note: PropTypes.string,
    attachmentType: PropTypes.string,
  }).isRequired,
  isLastItem: PropTypes.bool.isRequired,
};

const OrderDetailsAttachmentSection = ({
  orderAttachments,
  orderDetails,
  stringsData,
  themeContext,
  toggleAddToOrderModal,
  isAddToOrderModalOpened,
  submitOrderInvoiceDispensedItems,
  updateOrderItemsCallback,
  orderSource,
  enableAddItemToOrder,
}) => {
  const [attachementToBeItemized, setSelectedAttachement] = useState({});
  return (
    <div>
      {orderAttachments?.length > 0 && (
        <OrderDetailsAttachmentSectionContainer>
          {orderAttachments?.map((attachment, index) => {
            const isLastItem = index === orderAttachments.length - 1;
            return (
              <OrderAttachment
                enableAddItemToOrder={enableAddItemToOrder}
                key={attachment.key}
                themeContext={themeContext}
                stringsData={stringsData}
                orderDetails={orderDetails}
                attachment={attachment}
                isLastItem={isLastItem}
                onClickAddToOrder={selectedAttachement => {
                  toggleAddToOrderModal(true);
                  setSelectedAttachement(selectedAttachement);
                }}
              />
            );
          })}
        </OrderDetailsAttachmentSectionContainer>
      )}
      {isAddToOrderModalOpened && (
        <ItemizationItemsModal
          onClose={() => {
            toggleAddToOrderModal(false);
          }}
          orderDetails={orderDetails}
          showModal={isAddToOrderModalOpened}
          submitOrderInvoiceDispensedItems={submitOrderInvoiceDispensedItems}
          updateOrderItemsCallback={updateOrderItemsCallback}
          attachementToBeItemized={attachementToBeItemized}
          orderSource={orderSource}
        />
      )}
    </div>
  );
};

OrderDetailsAttachmentSection.propTypes = {
  orderSource: PropTypes.string,
  updateOrderItemsCallback: PropTypes.func,
  submitOrderInvoiceDispensedItems: PropTypes.func,
  orderAttachments: PropTypes.arrayOf(PropTypes.shape({})),
  stringsData: PropTypes.shape({}),
  orderDetails: PropTypes.shape({}),
  themeContext: PropTypes.shape({}),
  toggleAddToOrderModal: PropTypes.func,
  isAddToOrderModalOpened: PropTypes.bool,
  enableAddItemToOrder: PropTypes.bool,
};

export default OrderDetailsAttachmentSection;
