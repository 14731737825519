import themeData from 'utils/theme/themeData';

export const paymentMethodKeys = {
  cash: 'pm4ade2768d19w87a2',
  cardOnDeliver: 'pm2kjsdflk532jsfls',
  creditCard: 'pm4bcc2653a34f5454',
};

export const orderStatusEnum = {
  'New Order': 1,
  Delivered: 4,
  Cancelled: 5,
  Viewed: 6,
  Accepted: 7,
  'Ph. Rejected': 8,
  'Ready for Pickup': 9,
  'Picked Up': 10,
  'Del. Rejected': 11,
  'Del. Assigned': 12,
  'Del. Ended Shift': 13,
  New: 14,
  Rejected: 15,
  Scheduled: 16,
  'Pending Ins. Approval': 17,
  'Ins. Approved': 18,
  'Payment Pending': 19,
  'Payment Processing': 20,
  'Payment Success': 21,
  'Payment Failed': 22,
  Procurement: 24,
  'Procurement Success': 25,
  'Getting Items': 39,
  'Waiting Ins. Approval': 40,
  'Selecting Alternatives': 41,
  'Items Updated': 42,
  'Items Not Updated': 43,
  Confirmed: 44,

  getKeyByValue(value) {
    return Object.keys(this).find(key => this[key] === parseInt(value));
  },
};

export const orderStatusColorEnum = colors => ({
  [orderStatusEnum['New Order']]: {
    color: colors.white,
    backgroundColor: colors.black,
  },
  [orderStatusEnum.Delivered]: {
    color: colors.white,
    backgroundColor: colors.greenMain,
  },
  [orderStatusEnum.Cancelled]: {
    color: colors.white,
    backgroundColor: colors.redDark,
  },
  [orderStatusEnum.Viewed]: {
    color: colors.orangeDark,
    backgroundColor: colors.orangeLight,
  },
  [orderStatusEnum['Ph. Accepted']]: {
    color: colors.white,
    backgroundColor: colors.purpleMain,
  },
  [orderStatusEnum['Ph. Rejected']]: {
    color: colors.black,
    backgroundColor: colors.grey004,
  },
  [orderStatusEnum['Ready for Pickup']]: {
    color: colors.orangeLight,
    backgroundColor: colors.orangeDark,
  },
  [orderStatusEnum['Picked Up']]: {
    color: colors.white,
    backgroundColor: colors.greenDark,
  },
  [orderStatusEnum['Del. Rejected']]: {
    color: colors.redMain,
    backgroundColor: colors.redLight,
  },
  [orderStatusEnum['Del. Assigned']]: {
    color: colors.white,
    backgroundColor: colors.orangeDark,
  },
  [orderStatusEnum['Del. Ended Shift']]: {
    color: colors.white,
    backgroundColor: colors.purpleMain,
  },
  [orderStatusEnum.New]: {
    color: colors.white,
    backgroundColor: colors.orangeDark,
  },
  [orderStatusEnum.Rejected]: {
    color: colors.white,
    backgroundColor: colors.redMain,
  },
  [orderStatusEnum.Scheduled]: {
    color: colors.white,
    backgroundColor: colors.purpleLight,
  },
  [orderStatusEnum['Waiting Ins. Approval']]: {
    color: colors.white,
    backgroundColor: colors.purpleDark,
  },
  [orderStatusEnum['Pending Ins. Approval']]: {
    color: colors.black,
    backgroundColor: colors.grey004,
  },
  [orderStatusEnum['Ins. Approved']]: {
    color: colors.white,
    backgroundColor: colors.greenDark,
  },
  [orderStatusEnum['Payment Pending']]: {
    color: colors.black,
    backgroundColor: colors.grey004,
  },
  [orderStatusEnum['Payment Processing']]: {
    color: colors.orangeDark,
    backgroundColor: colors.orangeLight,
  },
  [orderStatusEnum['Payment Success']]: {
    color: colors.greenDark,
    backgroundColor: colors.greenLight,
  },
  [orderStatusEnum['Payment Failed']]: {
    color: colors.redMain,
    backgroundColor: colors.redLight,
  },
  [orderStatusEnum.Procurement]: {
    color: colors.white,
    backgroundColor: colors.blueMain,
  },
  [orderStatusEnum['Procurement Success']]: {
    color: colors.black,
    backgroundColor: colors.greenLight,
  },
  [orderStatusEnum['Getting Items']]: {
    color: colors.white,
    backgroundColor: colors.purpleDark,
  },
  [orderStatusEnum['Selecting Alternatives']]: {
    color: colors.white,
    backgroundColor: colors.blueMain,
  },
  [orderStatusEnum['Items Updated']]: {
    color: colors.white,
    backgroundColor: colors.blueMain,
  },
  [orderStatusEnum['Items Not Updated']]: {
    color: colors.white,
    backgroundColor: colors.orangeMain,
  },
});

export const entityTypeId = {
  1: { name: 'Pharmacy', color: themeData.colors.purpleMain },
  3: { name: 'V', color: themeData.colors.blueSkyDark },
  4: { name: 'Patient', color: themeData.colors.orangeMain },
};

export const orderStatusGroupsToShowInFilter = [
  {
    key: 1,
    name: 'Not Assigned',
    orderStatusGroup: [orderStatusEnum['New Order']],
  },
  {
    key: 2,
    name: 'In Progress',
    orderStatusGroup: [
      orderStatusEnum.New,
      orderStatusEnum.Viewed,
      orderStatusEnum['Ph. Accepted'],
      orderStatusEnum['Ins. Approved'],
      orderStatusEnum['Procurement Success'],
      orderStatusEnum['Procurement Success'],
    ],
  },
  {
    key: 3,
    name: 'Delivered',
    orderStatusGroup: [orderStatusEnum.Delivered],
  },
  {
    key: 4,
    name: 'Not Delivered',
    orderStatusGroup: [orderStatusEnum.Rejected, orderStatusEnum.Cancelled],
  },
  {
    key: 5,
    name: 'Pending Insurance',
    orderStatusGroup: [
      orderStatusEnum['Pending Ins. Approval'],
      orderStatusEnum['Waiting Ins. Approval'],
    ],
  },
  {
    key: 6,
    name: 'Pending Procurement',
    orderStatusGroup: [orderStatusEnum.Procurement, orderStatusEnum['Getting Items']],
  },
  {
    key: 7,
    name: 'In Delivery',
    orderStatusGroup: [
      orderStatusEnum['Ready for Pickup'],
      orderStatusEnum['Picked Up'],
      orderStatusEnum['Del Assigned'],
      orderStatusEnum['Del. Ended Shift'],
    ],
  },
  {
    key: 8,
    name: 'Scheduled',
    orderStatusGroup: [orderStatusEnum.Scheduled],
  },
  {
    key: 9,
    name: 'Payment Pending',
    orderStatusGroup: [
      orderStatusEnum['Payment Pending'],
      orderStatusEnum['Payment Processing'],
      orderStatusEnum['Payment Failed'],
    ],
  },
];

export const promoCodeStatusEnum = {
  valid: 1,
  pending: 20,
};

export const promoCodeStatusPending = [promoCodeStatusEnum.valid, promoCodeStatusEnum.pending];

export const filterStatusesToShow = {
  [orderStatusEnum['New Order']]: false,
  [orderStatusEnum.Delivered]: true,
  [orderStatusEnum.Cancelled]: true,
  [orderStatusEnum.Viewed]: true,
  [orderStatusEnum['Ph. Rejected']]: true,
  [orderStatusEnum['Ready for Pickup']]: true,
  [orderStatusEnum['Picked Up']]: false,
  [orderStatusEnum['Del. Rejected']]: false,
  [orderStatusEnum['Del. Assigned']]: false,
  [orderStatusEnum['Del. Ended Shift']]: false,
  [orderStatusEnum.New]: true,
  [orderStatusEnum.Rejected]: false,
  [orderStatusEnum.Scheduled]: true,
  [orderStatusEnum['Pending Ins. Approval']]: false,
  [orderStatusEnum['Ins. Approved']]: false,
  [orderStatusEnum['Payment Pending']]: false,
  [orderStatusEnum['Payment Processing']]: false,
  [orderStatusEnum['Payment Success']]: false,
  [orderStatusEnum['Payment Failed']]: false,
  [orderStatusEnum.Procurement]: false,
  [orderStatusEnum['Procurement Success']]: false,
  [orderStatusEnum['Getting Items']]: false,
  [orderStatusEnum['Waiting Ins. Approval']]: false,
  [orderStatusEnum['Selecting Alternatives']]: false,
  [orderStatusEnum['Items Updated']]: false,
  [orderStatusEnum['Items Not Updated']]: false,
  [orderStatusEnum.Confirmed]: true,
  [orderStatusEnum.Accepted]: true,
};

/* const alertsList = [
  {
    key: 'isProcessingDelay',
    name: 'Order Processing Delay',
  },
  {
    key: 'isDeliveryDelay',
    name: 'Order Delivery Delay',
  },
];

const actionTakersList = [
  { key: 1, name: 'IMS' },
  { key: 2, name: 'Courier' },
  { key: 3, name: 'VOPS User' },
  { key: 4, name: 'Patient User' },
  { key: 5, name: 'System' },
]; */

/**
 * filters Object to hold all filters both dropdown filters and filters in more filters modal
 * filterKey -> unique identifier to differentiate between filters.
 * filterDataPath -> an array holding the path of the filter value in the store and resolved using resolvePathArray
 * utility function passed to useSelector hook.
 * filterDataValue -> an array holding static filter values.
 * filterObjMapper -> mapes the key and value to the filter.
 * filterSearchKey -> a string holding the search key sent in the request payload.
 * filtersToClear -> an array holding the dependent filters that needs to be cleared with the filter.
 * filterDisplayCondition -> an object acting as a condition holding filter search keys and values that the filter is
 * allowed to be displayed if selected.
 * filterDataValueCondition -> an object acting as a condition holding filter search keys and values and populate filter
 * values depending on these values.
 * filterType -> a string holding the filter type (single | multi:default )
 */

export const filters = {
  dropDownFilters: [
    /* {
      filterKey: 1,
      filterLabel: 'Pharmacies',
      filterDataPath: ['pharmacyCommon', 'pharmaciesList', 'details'],
      filterObjMapper: { key: 'key', name: 'name' },
      filterSearchKey: 'pharmacyKey',
      filterSearchBarPlaceholder: 'Search by Pharmacy name',
    }, */
    {
      filterKey: 2,
      filterLabel: 'Status',
      filterDataPath: ['pharmacyOrders', 'orderStatusTypes', 'details'],
      filterObjMapper: { key: 'id', name: 'name' },
      filterSearchKey: 'stateId',
      filterSearchBarPlaceholder: 'Search by Status',
      filtersToClear: ['stateReasonId', 'actionTakerKey'],
    },
    /* {
        filterKey: 3,
        filterLabel: 'Detailed Reasons',
        filterDataPath: ['pharmacyOrders', 'detailedReasons', 'details'],
        filterObjMapper: { key: 'id', name: 'name' },
        filterSearchKey: 'stateReasonId',
        filterSearchBarPlaceholder: 'Search by Reason',
        filterDisplayCondition: {
          stateId: [
            orderStatusEnum.Cancelled,
            orderStatusEnum['Rejected'],
            orderStatusEnum['Ph. Rejected'],
            orderStatusEnum.Scheduled,
          ],
        },
        filterDataValueCondition: {
          stateId: [
            orderStatusEnum.Cancelled,
            orderStatusEnum['Rejected'],
            orderStatusEnum['Ph. Rejected'],
            orderStatusEnum.Scheduled,
          ],
        },
        filterType: 'single',
      },
      {
        filterKey: 4,
        filterLabel: 'Action Takers',
        filterDataValue: actionTakersList,
        filterObjMapper: { key: 'key', name: 'name' },
        filterSearchKey: 'actionTakerKey',
        filterSearchBarPlaceholder: 'Search by Action Taker',
        filterDisplayCondition: {
          stateId: [
            orderStatusEnum.Cancelled,
            orderStatusEnum['Rejected'],
            orderStatusEnum['Ph. Rejected'],
            orderStatusEnum.Scheduled,
          ],
        },
        filterType: 'single',
      },
      {
        filterKey: 5,
        filterLabel: 'Alerts',
        filterDataValue: alertsList,
        filterObjMapper: { key: 'key', name: 'name' },
        filterSearchBarPlaceholder: 'Search by Alert',
      },
      {
        filterKey: 6,
        filterLabel: 'Order Types',
        filterDataPath: ['pharmacyOrders', 'partnershipTypes', 'details'],
        filterObjMapper: { key: 'key', name: 'name' },
        filterSearchKey: 'partnershipTypeKey',
        filterSearchBarPlaceholder: 'Search by Order Type',
        filterType: 'single',
      }, */
  ],
  /* moreFilters: [
    {
      filterKey: 1,
      filterLabel: 'Couriers',
      filterDataPath: ['pharmacyCommon', 'couriersList', 'details', 'results'],
      filterObjMapper: { key: 'key', name: 'fullName' },
      filterSearchKey: 'courierKey',
      filterSearchBarPlaceholder: 'Search by Courier name',
    },
    {
      filterKey: 2,
      filterLabel: 'Areas',
      filterDataPath: ['pharmacyCommon', 'serviceableAreas', 'details'],
      filterObjMapper: { key: 'key', name: 'name' },
      filterSearchKey: 'areaKey',
      filterSearchBarPlaceholder: 'Search by area name',
    },
  ], */
  searchByItemsFilter: true,
};

export const orderStatusToEnablePharmacyAssignMenu = [
  orderStatusEnum.New,
  orderStatusEnum.Viewed,
  orderStatusEnum['Ph. Accepted'],
  orderStatusEnum['Ready for Pickup'],
  orderStatusEnum['Del. Assigned'],
  orderStatusEnum['Picked Up'],
  orderStatusEnum.Delivered,
  orderStatusEnum['Ph. Rejected'],
  orderStatusEnum['Del. Rejected'],
  orderStatusEnum.Rejected,
  orderStatusEnum.Cancelled,
  orderStatusEnum['Pending Ins. Approval'],
  orderStatusEnum['Waiting Ins. Approval'],
  orderStatusEnum.Procurement,
  orderStatusEnum['Getting Items'],
  orderStatusEnum['Items Updated'],
  orderStatusEnum['Items Not Updated'],
];

export const orderStatusToEnablePharmacyEditInvoice = [orderStatusEnum.Accepted];

export const initialButtonOptions = {
  1: 'New',
  14: 'Viewed',
  6: 'Preparing',
  7: 'Ready for pickup',
  9: 'Del Assigned',
  12: 'Picked up',
  10: 'Delivered',
  17: 'Waiting Ins. Approval',
  40: 'Ins. Approved',
  21: 'Ready for pickup',
  22: 'Ready for pickup',
  19: 'Payment Processing',
  20: 'Payment Success',
  39: 'Procurement Success',
  25: 'Viewed',
  24: 'Getting Items',
  42: 'Preparing',
  43: 'Preparing',
};

export const initialNextOrderState = {
  1: '14',
  14: '6',
  6: '7',
  7: '9',
  9: '10',
  10: '4',
  17: '40',
  40: '18',
  21: '9',
  22: '9',
  19: '20',
  20: '22',
  24: '39',
  39: '25',
  25: '6',
  42: '7',
  43: '7',
};

export const buttonDisabledOptions = {
  19: 'Payment Pending',
};

export const hideButtonBasedOnState = {
  schedule: {
    [orderStatusEnum.Delivered]: true,
    [orderStatusEnum['Ins. Approved']]: true,
    [orderStatusEnum['Payment Success']]: true,
    [orderStatusEnum.Procurement]: true,
    [orderStatusEnum['Getting Items']]: true,
    [orderStatusEnum['Waiting Ins. Approval']]: true,
    [orderStatusEnum['Selecting Alternatives']]: true,
  },
  next: {
    [orderStatusEnum.Scheduled]: true,
    [orderStatusEnum.Delivered]: true,
    [orderStatusEnum.Cancelled]: true,
    [orderStatusEnum['Del. Rejected']]: true,
    [orderStatusEnum.Rejected]: true,
    [orderStatusEnum['Ph. Rejected']]: true,
    [orderStatusEnum['Ready for Pickup']]: true,
    [orderStatusEnum['Ins. Approved']]: true,
    [orderStatusEnum['Selecting Alternatives']]: true,
  },
  cancel: {
    [orderStatusEnum.Cancelled]: true,
  },
};

export const specialCaseStatuses = {
  [orderStatusEnum.Scheduled]: themeData.colors.blueSkyDark,
  [orderStatusEnum['Del. Rejected']]: themeData.colors.redMain,
  [orderStatusEnum['Ph. Rejected']]: themeData.colors.redMain,
  [orderStatusEnum.Rejected]: themeData.colors.redMain,
  [orderStatusEnum['Ph. Accepted']]: themeData.colors.greenMain,
  [orderStatusEnum.Cancelled]: themeData.colors.redMain,
  [orderStatusEnum.procurement]: themeData.colors.blueMain,
  [orderStatusEnum.procurementSuccess]: themeData.colors.greenMain,
  [orderStatusEnum['Getting Items']]: themeData.colors.purpleMain,
  [orderStatusEnum['Selecting Alternatives']]: themeData.colors.blueMain,
  [orderStatusEnum['Items Updated']]: themeData.colors.blueMain,
  [orderStatusEnum['Items Not Updated']]: themeData.colors.orangeMain,
  [orderStatusEnum['Payment Success']]: themeData.colors.greenMain,
  [orderStatusEnum['Payment Failed']]: themeData.colors.redMain,
};

export const orderStatusesToShowInProgressBarByOrderType = {
  default: [
    orderStatusEnum.New,
    orderStatusEnum.Viewed,
    orderStatusEnum.Accepted,
    orderStatusEnum.Confirmed,
    orderStatusEnum['Ready for Pickup'],
    orderStatusEnum['Picked Up'],
    orderStatusEnum.Delivered,
  ],
  insurance: [
    orderStatusEnum['New Order'],
    orderStatusEnum['Pending Ins. Approval'],
    orderStatusEnum['Waiting Ins. Approval'],
    orderStatusEnum['Ins. Approved'],
    orderStatusEnum.New,
    orderStatusEnum.Viewed,
    orderStatusEnum['Ready for Pickup'],
    orderStatusEnum['Picked Up'],
    orderStatusEnum.Delivered,
  ],
  loyalty: [
    orderStatusEnum.New,
    orderStatusEnum.Viewed,
    orderStatusEnum.Accepted,
    orderStatusEnum.Confirmed,
    orderStatusEnum['Payment Pending'],
    orderStatusEnum['Payment Processing'],
    orderStatusEnum['Ready for Pickup'],
    orderStatusEnum['Picked Up'],
    orderStatusEnum.Delivered,
  ],
};

export const permissionsCategories = {
  order: 'order',
  pharmacy: 'pharmacy',
  masterlist: 'masterlist',
  delivery: 'delivery',
  configuration: 'configuration',
  callCenter: 'callCenter',
  partnership: 'partnership',
  catTool: 'catTool',
};

export const dynamicPermissions = {
  14: 'Viewed',
  12: 'PickedUp',
  10: 'Delivered',
  17: 'WaitingForApproval',
  40: 'InsuranceApproved',
  7: 'ReadyForPickUp',
  6: 'Preparing',
  21: 'PaymentSuccess',
  22: 'PaymentFailed',
  39: 'ProcurementSuccess',
  24: 'GettingItems',
};

export const StatusesIdToLabelDefaults = {
  14: 'New',
  6: 'Viewed',
  7: 'Accepted',
  44: 'Confirmed',
  19: 'Payment pending',
  20: 'Payment processing',
  9: 'Ready for pickup',
  10: 'Picked up',
  4: 'Delivered',
};

export const StatusesIdToLabelAborted = {
  22: 'Payment failed',
  5: 'Canceled',
  8: 'Rejected',
  15: 'Rejected - Shortage',
};

export const StatusesIdToLabelSpecial = {
  21: 'Payment succeeded',
  16: 'Rescheduled',
  41: 'Selecting Alternatives',
  42: 'Items Updated',
  43: 'Items Not Updated',
};

export const RejectionReasonsToBeHidden = [
  56, 4, 5, 51, 52, 53, 9, 7, 33, 12, 11, 30, 34, 36, 42, 55, 24, 27, 43, 46, 47, 48, 49, 50, 44,
];
