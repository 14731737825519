import { useRef } from 'react';
import PropTypes from 'prop-types';
import Button from 'modules/common/components/Button';
import { status } from 'constants/general';
import FileIcon from 'assets/images/empty-stock.svg';
import { VIEW } from '../helper';

import {
  FileIconContainer,
  SectionContainer,
  MainMessage,
  SubMessage,
  StatusContainer,
  StatusContainerWrapper,
  extendButtonStyle,
  extendButtonStyleLoading,
  extendLoaderStyle,
  ButtonsContainer,
  extendExportButtonStyle,
} from './IntroStatus.style';

const IntroStatus = ({
  uploadPharmacyStockLoadStatus,
  onUploadStockFile,
  toggleAddStockItemsModal,
  toggleViews,
}) => {
  let fileInput = useRef();
  const isUploadingCSVFile = uploadPharmacyStockLoadStatus === status.SUBMITING;
  return (
    <StatusContainer>
      <StatusContainerWrapper>
        <FileIconContainer>
          <img src={FileIcon} alt="file" />
        </FileIconContainer>
        <SectionContainer>
          <MainMessage>Add your items from stock</MainMessage>
          <SubMessage>
            There will be a list of products for online sale through the Vezeeta e-pharmacy
          </SubMessage>
        </SectionContainer>
        <ButtonsContainer>
          <div>
            <Button
              onClick={() => {
                fileInput.click();
              }}
              isLoading={isUploadingCSVFile}
              btnText="Upload Stock"
              extendLoaderStyle={extendLoaderStyle}
              extendButtonStyle={isUploadingCSVFile ? extendButtonStyleLoading : extendButtonStyle}
              iconName="e90a"
              iconSize={13}
            />
            <input
              type="file"
              id="stock_csv"
              accept=".csv"
              style={{ display: 'none' }}
              ref={input => {
                fileInput = input;
              }}
              onChange={e => {
                onUploadStockFile(e.currentTarget.files[0]);
                e.target.value = '';
              }}
            />
          </div>
          <Button
            onClick={() => {
              toggleAddStockItemsModal(true);
            }}
            btnText="Add Stock"
            extendButtonStyle={extendButtonStyle}
            iconName="e932"
            iconSize={13}
            key="add-stock"
          />
          <Button
            onClick={() => {
              toggleViews(VIEW.STOCK_HISTORY);
            }}
            btnText="Upload History"
            extendButtonStyle={extendExportButtonStyle}
            key="upload-history"
            iconName="e92e"
            iconSize={13}
          />
        </ButtonsContainer>
      </StatusContainerWrapper>
    </StatusContainer>
  );
};

IntroStatus.propTypes = {
  uploadPharmacyStockLoadStatus: PropTypes.string,
  onUploadStockFile: PropTypes.func,
  toggleAddStockItemsModal: PropTypes.func,
  toggleViews: PropTypes.func,
};

export default IntroStatus;
