import styled from 'styled-components';

export const MainWrapper = styled.div`
  display: flex;
`;

export const SidebarWrapper = styled.div`
  flex: 0 0 74px;
`;

export const ContentWrapper = styled.div`
  flex-grow: 1;
`;

export const PageWrapper = styled.div`
  height: calc(100vh - 57px);
`;

export const LoaderWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;
