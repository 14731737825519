import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import store from 'store';
import Router from 'utils/routing';
import GlobalStyle from 'utils/theme/globalStyles';
import themeData from 'utils/theme/themeData';

function App() {
  return (
    <>
      <GlobalStyle />
      <Provider store={store}>
        <BrowserRouter>
          <ThemeProvider theme={themeData}>
            <Router />
          </ThemeProvider>
        </BrowserRouter>
      </Provider>
    </>
  );
}

export default App;
