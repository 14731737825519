/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchInvoices, setInvoice, toggleInvoiceModal } from 'modules/invoices/actions';
import NotificationBar from 'modules/common/components/NotificationBar';
import PushNotificationComponent from 'modules/PushNotification';
import InvoiceModal from './Modals/InvoiceModal';
import InvoicesList from './InvoicesList';
import { formatDateYYYYMMDD, getMainRowItems } from './helper';
import { ListWrapper } from './Invoices.style';

const InvoicesListContainer = ({ ...props }) => {
  const { isInvoiceModalOpened } = props;
  const [hasData, setHasData] = useState(false);
  const [showOrderNotificationBar, toggleOrderNotificationBar] = useState(false);
  const [selectedDate, setSelectedDate] = useState({
    startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
    endDate: new Date(),
  });
  const [invoiceTypeObj, setInvoiceTypeObj] = useState({
    key: '',
    name: 'All Types',
  });
  const [query, setQuery] = useState({
    dateFrom: formatDateYYYYMMDD(selectedDate.startDate),
    dateTo: formatDateYYYYMMDD(selectedDate.endDate),
    invoiceType: '',
    queryString: '',
    currentPage: 1,
  });
  const [stockRecords, setStockRecords] = useState([]);

  useEffect(() => {
    if (props.pharmacyInvoices?.items) {
      setStockRecords(
        getMainRowItems(props.pharmacyInvoices?.items, props.toggleInvoiceModal, props.setInvoice),
      );
    } else {
      setStockRecords([]);
    }
  }, [props.pharmacyInvoicesLoadStatus]);

  useEffect(() => {
    if (props.pharmacyInvoices?.items?.length) {
      setHasData(props.pharmacyInvoices?.items?.length);
    }
  }, [props.pharmacyInvoices]);

  useEffect(() => {
    props.fetchInvoices({
      searchTerm: query.queryString,
      dateFrom: query.dateFrom,
      dateTo: query.dateTo,
      invoiceType: query.invoiceType,
      pageNumber: query.currentPage,
      PageSize: 10,
    });
  }, [query]);

  const onChangeTypes = obj => {
    setInvoiceTypeObj(obj);
    setQuery({
      ...query,
      invoiceType: obj.key,
      currentPage: 1,
    });
  };

  const getStockListPage = (page, searchTerm) => {
    setQuery({
      ...query,
      queryString: page > 1 ? query.queryString : searchTerm,
      currentPage: page,
    });
  };

  const onSearch = searchTerm => {
    setQuery({
      ...query,
      queryString: searchTerm,
      currentPage: 1,
    });
  };

  const showMoreInStock = () => {
    getStockListPage(query.currentPage + 1);
  };

  const pauseNotificationSound = () => {
    const audioElement = document.getElementById('notification-audio');
    if (audioElement) {
      audioElement.loop = false;
      audioElement.pause();
    }
  };

  const onChangeDate = dateObj => {
    setSelectedDate({
      startDate: new Date(`${dateObj?.startDate}Z`),
      endDate: new Date(`${dateObj?.endDate}Z`),
    });
    setQuery({
      ...query,
      dateFrom: formatDateYYYYMMDD(dateObj?.startDate),
      dateTo: formatDateYYYYMMDD(dateObj?.endDate),
      currentPage: 1,
    });
  };

  return (
    <div
      style={{
        height: '100%',
        position: 'relative',
      }}
    >
      <NotificationBar
        key="notification-bar_new-orders"
        message="New Orders Recieved !"
        onClick={() => {
          window.location.href = `${window.location.origin}/orders`;
          toggleOrderNotificationBar(false);
          pauseNotificationSound();
        }}
        onClose={() => {
          toggleOrderNotificationBar(false);
          pauseNotificationSound();
        }}
        showNotification={showOrderNotificationBar}
      />
      <PushNotificationComponent
        onChange={() => {
          toggleOrderNotificationBar(true);
        }}
        callBack={key => {
          window.location.href = `${window.location.origin}/orders/${key}`;
        }}
      />
      <div
        style={{
          height: '100%',
        }}
      >
        <ListWrapper>
          <InvoicesList
            toggleInvoiceModal={props.toggleInvoiceModal}
            onSearch={onSearch}
            currentPage={query.currentPage}
            pharmacyInvoices={props.pharmacyInvoices?.items || []}
            showMore={props.pharmacyInvoices.showMore}
            pharmacyInvoicesLoadStatus={props.pharmacyInvoicesLoadStatus}
            getListPage={value => getStockListPage(value, query.queryString)}
            queryString={query.queryString}
            onClickshowMore={showMoreInStock}
            setInvoice={props.setInvoice}
            onChangeTypes={onChangeTypes}
            invoiceTypeObj={invoiceTypeObj}
            setSelectedDate={onChangeDate}
            selectedDate={selectedDate}
            records={stockRecords}
            hasData={hasData}
          />
        </ListWrapper>
      </div>

      {isInvoiceModalOpened && (
        <InvoiceModal
          onClose={() => {
            props.toggleInvoiceModal(false);
            props.setInvoice();
          }}
        />
      )}
    </div>
  );
};

InvoicesListContainer.propTypes = {
  pharmacyInvoicesLoadStatus: PropTypes.string,
  isInvoiceModalOpened: PropTypes.bool,
  pharmacyInvoices: PropTypes.shape({
    items: PropTypes.arrayOf({}),
    showMore: PropTypes.bool,
  }),
  fetchInvoices: PropTypes.func,
  setInvoice: PropTypes.func,
  toggleInvoiceModal: PropTypes.func,
};

const mapStateToProps = state => ({
  isInvoiceModalOpened: state.pharmacyInvoices.isInvoiceModalOpened,
  pharmacyInvoicesLoadStatus: state.pharmacyInvoices.pharmacyInvoicesLoadStatus,
  pharmacyInvoices: state.pharmacyInvoices.pharmacyInvoices,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchInvoices,
      setInvoice,
      toggleInvoiceModal,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesListContainer);
