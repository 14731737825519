import styled from 'styled-components';
import themeData from 'utils/theme/themeData';

const { typography } = themeData;

export const DropdownMenuWrapper = styled.div`
  position: relative;
`;

export const DropdownMenuSelect = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 12px;
  border: 1px solid ${({ theme }) => theme.colors.grey000};
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  height: 36px;
`;

export const DropdownMenuText = styled(typography.BodyTextOneRegularTypography)`
  flex-grow: 1;
  margin: 0;
  color: ${({ theme }) => theme.colors.black};
`;

export const DropdownMenuIcon = styled.div`
  &:before {
    content: '\\e935';
    font-family: 'Icomoon';
    font-size: 12px;
  }
`;

export const DropdownMenuEnvelop = styled.div`
  position: absolute;
  top: 46px;
  width: 100%;
  max-height: 230px;
  padding: 4px 0;
  background-color: white;
  border: 1px solid #d7dce3;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0px 4px 16px rgb(0 0 0 / 16%);
  z-index: 1;
  overflow: auto;
`;

export const DropdownMenuEnvelopItem = styled(typography.BodyTextOneRegularTypography)`
  margin: 0;
  padding: 6px 16px;
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};
  }
`;
