/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { orderStatusEnum } from 'modules/orders/constants';
import Audio from 'assets/sounds/notification.wav';
import VezeetaImg from 'assets/images/logo-blue.svg';
import { getAllOrders } from 'modules/orders/api';

const PushNotificationComponent = ({ callBack, onChange }) => {
  const FETCHING_INTERVAL_MIN = 1.5;
  const FETCHING_INTERVAL_MILLISEC = FETCHING_INTERVAL_MIN * 60000;

  const [trigger, setTrigger] = useState(false);
  const sendPushNotification = data => {
    const audioElement = document.getElementById('notification-audio');
    if (audioElement) {
      audioElement.loop = true;
      audioElement.play();
    }
    onChange();
    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        const notification = new Notification('New Order Recieved!', {
          title: 'New Order Recieved!',
          body: `Order #${data.key}`,
          image: VezeetaImg,
          icon: VezeetaImg,
          badge: VezeetaImg,
        });

        notification.onclick = () => {
          callBack(data.key);
          if (audioElement) {
            audioElement.loop = false;
            audioElement.pause();
          }
        };
      }
    });
  };

  const getBeginDate = () => {
    const now = new Date();
    now.setMinutes(now.getMinutes() - 120);
    return now;
  };

  const getEndDate = () => {
    const now = new Date();
    now.setMinutes(now.getMinutes() + 120);
    return now;
  };

  useEffect(() => {
    if (trigger) {
      const body = {
        stateId: [
          `${orderStatusEnum.New}`,
          `${orderStatusEnum['Items Updated']}`,
          `${orderStatusEnum['Items Not Updated']}`,
        ],
        beginDate: new Date(`${getBeginDate()}`),
        endDate: new Date(`${getEndDate()}`),
      };
      setTrigger(false);
      getAllOrders(body).then(resp => {
        if (resp?.data?.orderDTOs?.length) {
          sendPushNotification(resp.data.orderDTOs[0]);
        }
      });
    }
  }, [trigger]);

  useEffect(() => {
    Notification.requestPermission();
    document.body.click();
    setTimeout(() => {
      const audioElement = document.getElementById('notification-audio');
      if (audioElement) {
        audioElement.muted = false;
        audioElement.loop = false;
      }
    }, 2000);

    setInterval(() => {
      setTrigger(true);
    }, FETCHING_INTERVAL_MILLISEC);
  }, []);

  return (
    <audio
      muted
      id="notification-audio"
      controls
      src={Audio}
      style={{
        display: 'none',
      }}
    />
  );
};

PushNotificationComponent.propTypes = {
  callBack: PropTypes.func,
  onChange: PropTypes.func,
};

export default PushNotificationComponent;
