import styled, { css } from 'styled-components';

export const ContainerHeader = styled.div`
  padding: 20px;
  min-height: 80px;
  display: flex;
  justify-content: space-between;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const AddServiceBtn = styled.div`
  display: flex;
  flex-direction: row;
  width: 151px;
  background-color: #0070cd;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  justify-content: center;
  height: 40px;
  cursor: pointer;
`;

export const AddServiceLabel = styled.p`
  margin: 0px 5px;
  line-height: 39px;
`;

export const FinancialRecordContainer = styled.div`
  width: 32%;
  height: 100px;
  background-color: #fff;
  display: flex;
  margin: 0px 8px;

  border: 1px solid #e3e6ea;
  box-sizing: border-box;
  border-radius: 8px;
`;

export const FinancialRecordsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 0px 0px;
`;

export const FinanceRecordValue = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
`;
export const FinanceRecordLabel = styled.p`
  font-size: 14px;
  line-height: 22px;
`;

export const ImageConatiner = styled.div`
  display: inline-flex;
  padding: 20px 20px 20px 30px;
  align-items: center;
`;

export const RecordInfoWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;
export const RecordInfoContainer = styled.div``;

export const SearchWithMenuExtended = css`
  & > div {
    height: 40px !important;
    border: 1px solid #e3e6ea;
    box-sizing: border-box;
    border-radius: 8px;
  }
`;

export const extendDropDownStyle = css`
  min-height: 40px;
  height: 40px;
  background-color: #f1f4f6;
`;

export const extendDropDownContent = css`
  display: flex;
  & > #label {
    font-size: 16px;
    line-height: 24px;
  }
  & > #value {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    margin: 0px 4px;
  }
`;

export const BropDownBtnsContainer = styled.div`
  display: flex;
  flex-direction: column;
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.16));
  border-radius: 8px;
  background-color: #fff;
  width: 400px;
  height: 314px;
  padding: 24px;
`;

export const ServiceListWrapper = styled.div`
  background: #eceef0;
  height: 100%;

  overflow: auto;
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background: #9c9c9c;
    border-radius: 15px;
  }
`;

export const FinanceRecordLabelConatiner = styled.div`
  display: flex;
`;

export const IconContainer = styled.div`
  display: flex;
  margin: 0px 5px;
`;

export const FinanceRecordAdditionalContainer = styled(FinanceRecordLabelConatiner)``;

export const AdditionalItemContainer = styled(FinanceRecordAdditionalContainer)``;

export const AdditionalItemLabel = styled.p`
  font-size: 12px;
  line-height: 18px;
  color: #666666;
`;

export const AdditionalItemValue = styled(AdditionalItemLabel)`
  font-weight: 600;
`;

export const GrayOverLay = styled.div`
  background-color: #ffff;
  width: 100%;
  height: 500px;
  position: absolute;
  z-index: 50;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 8px 8px;
`;

export const ViewContainer = styled.div`
  position: relative;
  background-color: #fff;
  border-radius: 8px;
`;

export const SubRow = styled.div`
  display: grid;
  grid-template-columns: 11% 14% 6% 6% 6% 6% 6% 2%;
  width: 100%;
  justify-content: end;
  font-size: 14px;
  line-height: 22px;
  padding: 4px 0px;
  border-bottom: 1px solid #e7e6e6;
  background-color: #f1f4f6;
  border-radius: 8px;
`;

export const SubRowContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  background-color: #fff;
  margin-bottom: 2px;
`;

export const RowItem = styled.p`
  max-width: 100px;
  position: relative;
  ${props => props.theme.media.desktop`
    &:hover > div {
    visibility: visible;
  }
  `};

  text-align: ${props => (props.language === 'ar' ? 'right' : 'unset')};
`;

export const ExceptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 110px 0px;
`;
export const ExceptionImageContainer = styled.div`
  display: inline-flex;
  justify-content: center;
`;
export const ExceptionImage = styled.img`
  /*  width: 90px;
  height: 84px; */
`;

export const ExceptionMessageContainer = styled(ExceptionImageContainer)`
  margin-top: 15px;
`;

export const ExceptionMessage = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #484848;
`;

export const TooltipContainer = styled.div`
  background: #e2e3e4;
  position: absolute;
  display: inline-block;
  width: max-content;
  text-align: center;
  border-radius: 6px;
  font-size: 14px;
  padding: 1px 8px;
  right: ${props => (props.language === 'en' ? '105%' : 'unset')};
  left: ${props => (props.language === 'ar' ? '105%' : 'unset')};
  visibility: hidden;
`;
export const TooltipMessage = styled.p``;

export const ActionTooltipContainer = styled.div`
  position: absolute;
  transform: ${props =>
    props.language === 'en' ? 'translate(-32%, -100%)' : 'translate(-23%,-100%)'};
  padding: 8px 10px;
  background: #484848;
  border-radius: 8px;
  color: #fff;
  font-weight: bold;
  font-size: 11px;
  line-height: 18px;
  justify-content: center;
  align-items: center;
  display: none;
`;

export const EditActionTooltipContainer = styled(ActionTooltipContainer)`
  transform: ${props => (props.isRtl ? 'translate(25%, -123%)' : 'translate(-15%, -123%)')};
`;

export const ServiceRecordActionContainer = styled.div`
  ${props => props.theme.media.desktop`
    &:hover > div {
      display: flex;
    }
  `};
`;

export const Pointer = styled.div`
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-top: 8px solid #484848;
  border-right: 8px solid transparent;
  bottom: -7px;
  position: absolute;
`;

export const RecordActionBtn = styled.div`
  cursor: pointer;
  width: fit-content;
  margin: 0 auto;
  ${props => props.theme.media.phone`
    margin: unset;
  }
  `};
  ${props => props.theme.media.tablet`
    margin: unset;
  `};
`;

export const extendButtonStyle = css`
  width: 99%;
  margin: 10px auto;
  border-radius: 8px;
  border: 1px solid #e3e6ea;
  background: #fff;
`;

export const extendBackButtonStyle = css`
  border-radius: 8px;
  border: 1px solid #d9dbdf;
  background: #f1f4f6;
  width: 14px;
  height: 14px;
`;

export const extendLoaderStyle = css`
  height: 20px;
  width: 20px;
  border: 1px solid #f3f3f3;
  border-top: 3px solid #3498db;
  margin: 0;
`;

export const ViewTitle = styled.p`
  color: #484848;
  font-size: 20px;
  font-weight: 700;
  line-height: 35px;
  margin: 0;
`;

export const StockFileAnchor = styled.a`
  color: #0070cd;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  text-decoration-line: underline;
`;

export const EditIconContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const extendStyle = css`
  cursor: pointer;
`;
