import { checkIfDateIsToday } from 'utils/functions/dateTime';
import { paymentMethodKeys, orderStatusEnum } from 'modules/orders/constants';

export const isEnableOrderPaymentUpdates = (orderStateTypeId, paymentMethodKey) => {
  let isEnabled = false;
  isEnabled =
    orderStateTypeId === orderStatusEnum['Selecting Alternatives'] ||
    orderStateTypeId === orderStatusEnum.New ||
    orderStateTypeId === orderStatusEnum['Payment Pending'] ||
    orderStateTypeId === orderStatusEnum['Payment Processing'] ||
    (orderStateTypeId === orderStatusEnum.Confirmed &&
      !(
        paymentMethodKey === paymentMethodKeys.cash ||
        paymentMethodKey === paymentMethodKeys.cardOnDeliver
      ));
  return isEnabled;
};

export const isSubmitAlternativesEnabled = orderStateTypeId => {
  let isEnabled = false;
  isEnabled = orderStateTypeId === orderStatusEnum.Accepted;
  return isEnabled;
};

export const getOrderElapsedTime = createdOnDate => {
  let elapsedTimeText = '';
  if (createdOnDate) {
    const orderElapsedTimeInMin = parseInt(
      (new Date() - new Date(`${createdOnDate}Z`)) / (1000 * 60),
    );
    const hours = Math.floor(orderElapsedTimeInMin / 60);
    const minutes = orderElapsedTimeInMin % 60;
    elapsedTimeText = `${hours}h:${minutes}min`;
  }
  return elapsedTimeText;
};

export const generateTimeSlotsArr = selectedDate => {
  const timeSlotsArr = [];
  const checkIfToday = checkIfDateIsToday(selectedDate);
  const date = checkIfToday ? new Date() : selectedDate;
  const hours = checkIfToday ? date.getHours() + 3 : date.getHours();
  const minutes = date.getMinutes();
  const dayPart = ['am', 'pm'];

  for (let i = hours; i < 24; i += 1) {
    for (let j = i === hours ? Math.ceil(minutes / 15) : 0; j < 4; j += 1) {
      // eslint-disable-next-line no-bitwise
      timeSlotsArr.push(`${i % 12 || 12}:${j * 15 || '00'} ${dayPart[(i / 12) | 0]}`);
    }
  }

  return timeSlotsArr.map(slot => ({
    key: slot,
    name: slot,
  }));
};

export const invoiceGridItemsToSizeMap = {
  invoiceItemGridHeader: '35fr',
  invoicePriceGridHeader: '6fr',
  invoiceQuantityGridHeader: '6fr',
  invoiceSubTotalGridHeader: '6fr',
};

export const orderInvoiceGridHeaderList = {
  invoiceItemGridHeader: 'Items',
  invoicePriceGridHeader: 'Price',
  invoiceQuantityGridHeader: 'Quantity',
  invoiceSubTotalGridHeader: 'Sub Total',
};

export const orderSuggestAlternatovesGridHeaderList = {
  invoiceItemGridHeader: 'Suggest Alternatives',
  invoicePriceGridHeader: 'Price',
  invoiceQuantityGridHeader: 'Quantity',
  invoiceSubTotalGridHeader: 'Sub Total',
};

export const orderInvoiceGridHeaderKeys = [
  'invoiceItemGridHeader',
  'invoicePriceGridHeader',
  'invoiceQuantityGridHeader',
  'invoiceSubTotalGridHeader',
];

export const TABLE_VIEWS = {
  ORDER_PREPARATION: 0,
  SUGGEST_ALTERNATIVES: 1,
};

export const isOrderToBeConfirmed = orderStateTypeId =>
  orderStateTypeId === orderStatusEnum.Accepted ||
  orderStateTypeId === orderStatusEnum['Items Updated'] ||
  orderStateTypeId === orderStatusEnum['Items Not Updated'];
