/* eslint-disable no-unsafe-optional-chaining */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import Loader from 'modules/common/components/Loader';
import { status } from 'constants/general';
import NoItemsImg from 'assets/images/noItems.svg';
import Button from 'modules/common/components/Button';
import Popup from 'modules/common/components/Popup';
import Checkbox from 'modules/common/components/Checkbox';
import ItemWithImageSearchField from 'modules/common/components/ItemWithImageSearchField';
import { searchStockItems } from 'modules/stock/actions';
import { fetchItemAlternatives, fetchOrderItemsSuggestions } from 'modules/orders/actions';
import { getProductsListForDropDown } from './helper';
import Item from './Item';
import {
  PopupTitleSpan,
  PopupWrapper,
  PopupTitle,
  PopupTitleWrapper,
  Container,
  PopupTitleContainer,
  extendSearchDropDownStyle,
  extendDropDownListItem,
  extendLoaderStyle,
  CartContainer,
  ButtonsContainer,
  extendCancelButtonStyle,
  extendBlueButtonStyle,
  ItemsContainer,
  TableHeader,
  ItemContainerHeader,
  ModalActionBtns,
  NoAlternativesImg,
  NoAlternativesContainer,
  NoAlternativesMsgA,
  NoAlternativesMsgB,
  LoaderWrapper,
} from './AddAlternativeItemsModal.style';

const AddAlternativeItemsModal = ({
  onClose,
  orderItemKey,
  productKey,
  productShapeId,
  modalTitle,
  appendProductAlternatives,
  productsAlternatives,
  showModal,
  ...props
}) => {
  const [suggestedItems, addToSuggestedItemsCart] = useState([]);
  const [isAlternativesNotAvailable, toggleAlternativesAvailability] = useState(false);
  const [checkedItemToBeConfirmed, setCheckedItemsToBeConfirmed] = useState([]);
  const { details } = props.itemAlternatives;

  useEffect(() => {
    if (details?.length) {
      const tempSuggestedItems = [];
      details.forEach(item => {
        const { selectedProductShape } = item;
        const { productShapeName, productShapePrice } = selectedProductShape;
        tempSuggestedItems.push({
          itemName: item.name,
          itemShapeName: productShapeName,
          itemPrice: productShapePrice,
          itemShapeId: selectedProductShape.productShapeId,
          itemProductKey: item.key,
          itemImage: item.imageUrl,
          itemQuantity: 1,
        });
      });

      // Previously Added Alternatives to be edited
      const currentOrderItemAlternativesObj = productsAlternatives?.find(
        item => item.itemProductKey === orderItemKey,
      );

      if (currentOrderItemAlternativesObj?.alternatives?.length) {
        const previouslyAddAlternativesOrderKeys = [];
        currentOrderItemAlternativesObj?.alternatives.forEach(altItem => {
          // If Previously Add order alternatives found in consumed alternatives suggestion call
          const indexOfPreviouslyAddedAlternatveFoundInSuggestions = tempSuggestedItems.findIndex(
            item =>
              altItem.itemProductKey === item.itemProductKey &&
              altItem.itemShapeId === item.itemShapeId,
          );

          if (indexOfPreviouslyAddedAlternatveFoundInSuggestions === -1) {
            tempSuggestedItems.push(altItem);
          } else {
            tempSuggestedItems[indexOfPreviouslyAddedAlternatveFoundInSuggestions] = {
              ...tempSuggestedItems[indexOfPreviouslyAddedAlternatveFoundInSuggestions],
              itemQuantity: altItem.itemQuantity,
            };
          }

          previouslyAddAlternativesOrderKeys.push(
            `${altItem.itemProductKey}-${altItem.itemShapeId}`,
          );
        });
        setCheckedItemsToBeConfirmed(previouslyAddAlternativesOrderKeys);
      }

      addToSuggestedItemsCart(tempSuggestedItems);
    }
  }, [details]);

  useEffect(() => {
    if (showModal) {
      props.fetchItemAlternatives({
        orderProductKey: productKey,
      });
    }
  }, [showModal]);

  const onCloseModal = () => {
    onClose();
    addToSuggestedItemsCart([]);
    setCheckedItemsToBeConfirmed([]);
    toggleAlternativesAvailability(false);
  };

  const onSave = () => {
    const payload = {
      itemShapeId: productShapeId,
      itemProductKey: orderItemKey,
      alternatives: [],
    };

    if (!isAlternativesNotAvailable) {
      suggestedItems.forEach(item => {
        if (checkedItemToBeConfirmed.includes(`${item?.itemProductKey}-${item?.itemShapeId}`)) {
          payload.alternatives.push(item);
        }
      });
    }
    appendProductAlternatives(payload);
    onCloseModal();
  };

  const handleChangeItemQuantity = (itemKey, index, operationType) => {
    const tempsuggestedItems = _.cloneDeep(suggestedItems);
    const tempItemIndex = tempsuggestedItems.findIndex(
      (item, itemIndex) => item.itemProductKey === itemKey && itemIndex === index,
    );
    const tempItem = tempsuggestedItems.find(
      (item, itemIndex) => item.itemProductKey === itemKey && itemIndex === index,
    );
    if (operationType === 'plus') tempItem.itemQuantity += 1;
    else if (operationType === 'minus' && tempItem.itemQuantity > 1) tempItem.itemQuantity -= 1;
    tempsuggestedItems[tempItemIndex] = { ...tempItem };
    addToSuggestedItemsCart([...tempsuggestedItems]);
  };

  const handleInvoiceItemCheckToBeConfirmed = key => {
    let temp = [];
    if (key) {
      if (checkedItemToBeConfirmed.includes(key)) {
        checkedItemToBeConfirmed.forEach(itemKey => {
          if (itemKey !== key) {
            temp.push(itemKey);
          }
        });
        setCheckedItemsToBeConfirmed(temp);
      } else {
        temp = [...checkedItemToBeConfirmed, key];
        setCheckedItemsToBeConfirmed(temp);
      }
    } else if (!checkedItemToBeConfirmed.length) {
      suggestedItems.forEach(item => temp.push(`${item.itemProductKey}-${item.itemShapeId}`));
      setCheckedItemsToBeConfirmed(temp);
    } else {
      setCheckedItemsToBeConfirmed(temp);
    }

    if (temp.length && isAlternativesNotAvailable) {
      toggleAlternativesAvailability(false);
    }
  };

  const onToggleAlternativesAvailability = () => {
    if (!isAlternativesNotAvailable) {
      setCheckedItemsToBeConfirmed([]);
    }
    toggleAlternativesAvailability(!isAlternativesNotAvailable);
  };

  return (
    <Popup showCloseBtn={false}>
      <PopupWrapper>
        <PopupTitleContainer>
          <PopupTitleWrapper>
            <PopupTitle>{modalTitle}</PopupTitle>
            <PopupTitleSpan
              onClick={() => {
                onCloseModal();
              }}
            />
          </PopupTitleWrapper>
        </PopupTitleContainer>

        <Container>
          <div
            style={{
              height: '520px',
            }}
          >
            {/** Search */}
            <div
              style={{
                padding: '16px',
                background: '#F1F4F6',
              }}
            >
              <ItemWithImageSearchField
                isRequired
                isDynamic
                isDropDown
                searchable
                key="medication-search-list"
                componentName="medication-search-list"
                onTypingDelay={500}
                placeHolder="Medicine Name"
                fieldValue=""
                options={getProductsListForDropDown(
                  props.orderItemsSuggestions.details,
                  suggestedItems,
                  'en',
                  {
                    orderItemKey,
                    productKey,
                    productShapeId,
                  },
                )}
                onChanges={payload => {
                  addToSuggestedItemsCart([
                    ...suggestedItems,
                    {
                      itemProductKey: payload?.key,
                      itemImage: payload?.itemImage,
                      itemName: payload?.fieldValue,
                      itemShapeName: payload?.description,
                      itemPrice: payload?.extras?.newPrice,
                      itemQuantity: 1,
                      itemShapeId: payload?.extras?.id,
                    },
                  ]);
                }}
                onSearch={keyWord => {
                  props.fetchOrderItemsSuggestions({
                    searchTerm: keyWord,
                  });
                }}
                language="en"
                isLoading={props.orderItemsSuggestions.status === status.FETCHING}
                extendLoaderStyle={extendLoaderStyle}
                isValid
                extendDropDownStyle={extendSearchDropDownStyle}
                extendDropDownListItem={extendDropDownListItem}
                icon="e927"
              />
            </div>
            {/** Added Items */}
            <div
              style={{
                height: '375px',
              }}
            >
              {props.itemAlternatives.status !== 'FETCHING' ? (
                <div>
                  {suggestedItems?.length > 0 ? (
                    <CartContainer>
                      <ItemContainerHeader key="header-table">
                        <TableHeader>
                          <Checkbox
                            checked={checkedItemToBeConfirmed?.length === suggestedItems.length}
                            key="orderInvoiceGridHeaderKey"
                            handleCheckboxClick={() => {
                              handleInvoiceItemCheckToBeConfirmed();
                            }}
                            checkboxObj={{
                              key: 'orderInvoiceGridHeaderKey',
                              name: (
                                <div
                                  style={{
                                    display: 'flex',
                                    gap: '12px',
                                  }}
                                >
                                  {`Alternative Items (${suggestedItems?.length})`}
                                </div>
                              ),
                            }}
                          />
                        </TableHeader>
                        <TableHeader>Price</TableHeader>
                        <TableHeader>Quantity</TableHeader>
                        <TableHeader>Subtotal</TableHeader>
                      </ItemContainerHeader>
                      <ItemsContainer>
                        {suggestedItems.map((item, index) => (
                          <Item
                            index={index}
                            key={item?.itemProductKey}
                            itemName={item?.itemName}
                            itemShapeName={item?.itemShapeName}
                            itemPrice={item?.itemPrice}
                            itemShapeId={item?.itemShapeId}
                            itemProductKey={item?.itemProductKey}
                            itemImage={item?.itemImage}
                            itemQuantity={item?.itemQuantity}
                            isChecked={checkedItemToBeConfirmed?.includes(
                              `${item?.itemProductKey}-${item?.itemShapeId}`,
                            )}
                            onCheck={handleInvoiceItemCheckToBeConfirmed}
                            onChangeQuantity={handleChangeItemQuantity}
                          />
                        ))}
                      </ItemsContainer>
                    </CartContainer>
                  ) : (
                    <NoAlternativesContainer>
                      <NoAlternativesImg src={NoItemsImg} alt="NoItemsImg" />
                      <NoAlternativesMsgA>No Alternatives</NoAlternativesMsgA>
                      <NoAlternativesMsgB>
                        Add alternative items or inform the customer about out of stock of ordered
                        item without alternatives{' '}
                      </NoAlternativesMsgB>
                    </NoAlternativesContainer>
                  )}
                </div>
              ) : (
                <LoaderWrapper>
                  <Loader width={80} height={80} thickness={5} />
                </LoaderWrapper>
              )}
            </div>
            <ModalActionBtns>
              <Checkbox
                checked={isAlternativesNotAvailable}
                key="no-alternatives-action"
                handleCheckboxClick={() => onToggleAlternativesAvailability()}
                checkboxObj={{
                  key: 'no-alternatives-action',
                  name: (
                    <div
                      style={{
                        display: 'flex',
                        gap: '12px',
                      }}
                    >
                      No Alternatives
                    </div>
                  ),
                }}
              />
              <ButtonsContainer>
                <Button
                  onClick={() => {
                    onCloseModal();
                  }}
                  btnText="Cancel"
                  extendButtonStyle={extendCancelButtonStyle}
                  key="dismiss-popup"
                />
                <Button
                  onClick={() => onSave()}
                  btnText="Proceed"
                  extendButtonStyle={extendBlueButtonStyle}
                  key="proceed-save-view"
                  disabled={
                    !(
                      (checkedItemToBeConfirmed?.length > 0 && suggestedItems?.length > 0) ||
                      isAlternativesNotAvailable
                    )
                  }
                />
              </ButtonsContainer>
            </ModalActionBtns>
          </div>
        </Container>
      </PopupWrapper>
    </Popup>
  );
};

AddAlternativeItemsModal.propTypes = {
  productShapeId: PropTypes.number,
  productKey: PropTypes.string,
  productsAlternatives: PropTypes.arrayOf(PropTypes.shape({})),
  saveStockItems: PropTypes.func,
  editStockItemCart: PropTypes.func,
  removeFromStockItemsCart: PropTypes.func,
  onClose: PropTypes.func,
  searchStockItems: PropTypes.func,
  modalTitle: PropTypes.string,
  orderItemKey: PropTypes.string,
  fetchItemAlternatives: PropTypes.func,
  itemAlternatives: PropTypes.shape({
    status: PropTypes.string,
    details: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  fetchOrderItemsSuggestions: PropTypes.func,
  orderItemsSuggestions: PropTypes.shape({
    status: PropTypes.string,
    details: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  appendProductAlternatives: PropTypes.func,
  showModal: PropTypes.bool,
};

const mapStateToProps = state => ({
  orderItemsSuggestions: state.pharmacyOrders.orderItemsSuggestions,
  itemAlternatives: state.pharmacyOrders.itemAlternatives,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      searchStockItems,
      fetchItemAlternatives,
      fetchOrderItemsSuggestions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddAlternativeItemsModal);
