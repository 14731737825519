/* eslint-disable camelcase */
import { paymentMethodKeys } from 'modules/orders/constants';

export const SIZE_OF_PAGE = 10;

export const INVOICE_TYPES = {
  SALES: 1,
  RETURN: 2,
};

export const getTableHeadersLocalized = () => [
  {
    id: 0,
    name: 'Invoice',
  },
  {
    id: 1,
    name: 'Type',
  },
  {
    id: 2,
    name: 'Time',
  },
  {
    id: 3,
    name: 'Payment Method',
  },
  {
    id: 4,
    name: 'Total',
  },
];

const formatDateTime = (dateString, langCode) => {
  const day = new Date(`${dateString}Z`).toLocaleString(langCode, { day: '2-digit' });
  const month = new Date(`${dateString}Z`).toLocaleString(langCode, { month: 'long' });
  const year = new Date(`${dateString}Z`).toLocaleString(langCode, { year: 'numeric' });
  const time = new Date(`${dateString}Z`)?.toLocaleTimeString('en-EG', {
    hour: '2-digit',
    hour12: true,
    minute: '2-digit',
  });
  return `${day} ${month} ${year}, ${time}`;
};

export const formatDateYYYYMMDD = (dateString, langCode) => {
  const day = new Date(`${dateString}Z`).toLocaleString(langCode || 'en-EG', { day: '2-digit' });
  const month = new Date(`${dateString}Z`).toLocaleString(langCode || 'en-EG', {
    month: '2-digit',
  });
  const year = new Date(`${dateString}Z`).toLocaleString(langCode || 'en-EG', { year: 'numeric' });
  return `${year}-${month}-${day}`;
};

export const getMainRowItems = (pharmacyStock, toggleInvoiceModal, setInvoice) => {
  const recordsRows = [];
  pharmacyStock?.forEach(item => {
    const { key, createdOn, invoiceNumber, type, total, paymentMethod } = item;

    const invoiceType = `${type === 2 ? 'Return' : 'Sales'}`;
    let paymentMethodName = '';

    if (paymentMethod === paymentMethodKeys.cash) paymentMethodName = 'Cash';
    if (paymentMethod === paymentMethodKeys.cardOnDeliver) paymentMethodName = 'Card On Delivery';
    if (paymentMethod === paymentMethodKeys.creditCard) paymentMethodName = 'Online Payment';

    recordsRows.push({
      key,
      mainRow: {
        c0: {
          main: `#${invoiceNumber}`,
          additional: '',
          showToolTip: false,
          isBold: true,
          onClickAction: () => {
            toggleInvoiceModal(true);
            setInvoice(item);
          },
        },
        c1: {
          main: invoiceType,
          additional: '',
        },
        c2: {
          main: `${formatDateTime(createdOn, 'en-EG')}`,
          additional: '',
        },
        c3: {
          main: paymentMethodName,
          additional: '',
        },
        c4: {
          main: `${total} EGP`,
          additional: '',
        },
      },
    });
  });

  return recordsRows;
};
