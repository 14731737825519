import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { usePrevious } from 'utils/functions/hooks';
import { status } from 'constants/general';
import Button from 'modules/common/components/Button';
import Popup from 'modules/common/components/Popup';
import ItemWithImageSearchField from 'modules/common/components/ItemWithImageSearchField';
import {
  searchStockItems,
  addToStockItemsCart,
  removeFromStockItemsCart,
  editStockItemCart,
  saveStockItems,
} from 'modules/stock/actions';
import { getProductsListForDropDown, VIEWS } from './helper';
import {
  PopupTitleSpan,
  PopupWrapper,
  PopupTitle,
  PopupTitleWrapper,
  Container,
  PopupTitleContainer,
  extendSearchDropDownStyle,
  extendDropDownListItem,
  extendLoaderStyle,
  CartContainer,
  ItemsCountConatiner,
  ItemContainer,
  ItemDetails,
  ItemImage,
  ItemName,
  ItemDescription,
  RemoveItemIcon,
  ButtonsContainer,
  extendGrayButtonStyle,
  extendBlueButtonStyle,
  extendGrayBackButtonStyle,
  ItemsContainer,
  ItemWrapper,
  TableHeader,
  ItemContainerHeader,
  InputField,
  ErrorMessage,
} from './AddStockItemsModal.style';

const onChangeAndValidation = (inputedValue, callBack) => {
  let isValidInput = true;
  const regex = /^\d+(?:\.\d{0,2})?$/;
  isValidInput = isValidInput && regex.test(inputedValue);
  if (isValidInput || !inputedValue) {
    if (callBack) callBack();
  }
};

const Item = ({
  itemKey,
  image,
  title,
  subTitle,
  removeItem,
  isEdit,
  sellingPrice,
  stockQuantity,
  pharmacyCode,
  onEditStockItemCart,
  isDuplicateError,
}) => (
  <ItemContainer isEdit={isEdit} key={itemKey}>
    <ItemWrapper isEdit>
      {!isEdit && (
        <RemoveItemIcon
          onClick={() => {
            removeItem();
          }}
        />
      )}
      <ItemImage src={image} alt="item-image" />
      <ItemDetails>
        <ItemName>{title}</ItemName>
        <ItemDescription>{subTitle}</ItemDescription>
      </ItemDetails>
    </ItemWrapper>
    {isEdit && (
      <ItemWrapper isEdit>
        <InputField
          isDuplicateError={isDuplicateError}
          id="code-input"
          value={pharmacyCode}
          onChange={event => {
            onEditStockItemCart({ PharmacyCode: event.target.value });
          }}
        />
      </ItemWrapper>
    )}
    {isEdit && (
      <ItemWrapper isEdit>
        <InputField
          id="quantity-input"
          value={stockQuantity}
          placeholder="0"
          onChange={event => {
            onChangeAndValidation(event.target.value, () =>
              onEditStockItemCart({ StockQuantity: event.target.value || '' }),
            );
          }}
        />
      </ItemWrapper>
    )}
    {isEdit && (
      <ItemWrapper isEdit>
        <InputField
          id="price-login-input"
          value={sellingPrice}
          placeholder="0.00"
          onChange={event => {
            onChangeAndValidation(event.target.value, () =>
              onEditStockItemCart({ SellingPrice: event.target.value || '' }),
            );
          }}
        />
      </ItemWrapper>
    )}
  </ItemContainer>
);

Item.propTypes = {
  isDuplicateError: PropTypes.bool,
  itemKey: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  removeItem: PropTypes.func,
  isEdit: PropTypes.bool,
  sellingPrice: PropTypes.number,
  stockQuantity: PropTypes.number,
  pharmacyCode: PropTypes.string,
  onEditStockItemCart: PropTypes.func,
};

const AddStockItemsModal = ({ onClose, ...props }) => {
  const [view, setView] = useState(VIEWS.ADD_ITEMS);
  const [duplicateCode, setDuplicateCode] = useState('');

  const prevSavepharmacyStockItemsLoadStatus = usePrevious(props.savepharmacyStockItemsLoadStatus);

  useEffect(() => {
    if (
      prevSavepharmacyStockItemsLoadStatus &&
      props.savepharmacyStockItemsLoadStatus !== prevSavepharmacyStockItemsLoadStatus &&
      props.savepharmacyStockItemsLoadStatus === status.SUCCESS
    ) {
      setDuplicateCode('');
      onClose();
    }

    if (
      prevSavepharmacyStockItemsLoadStatus &&
      props.savepharmacyStockItemsLoadStatus !== prevSavepharmacyStockItemsLoadStatus &&
      props.savepharmacyStockItemsLoadStatus === status.FAIL &&
      props.savepharmacyStockItems?.response?.status === 409 &&
      props.savepharmacyStockItems?.response?.data?.PharmacyCode?.length
    ) {
      setDuplicateCode(props.savepharmacyStockItems?.response?.data?.PharmacyCode[0]);
    }
  }, [props.savepharmacyStockItemsLoadStatus]);

  const validateStockListData = () => {
    let isValid = true;
    props.stockItemsToBeAddedCart?.forEach(item => {
      const { SellingPrice, /* StockQuantity, */ PharmacyCode } = item;
      if (!SellingPrice || !PharmacyCode) {
        isValid = false;
      }
    });
    return isValid;
  };

  const onSave = () => {
    const cart = [];
    props.stockItemsToBeAddedCart?.forEach(item => {
      const { ProductKey, SellingPrice, StockQuantity, PharmacyCode } = item;
      cart.push({
        ProductKey,
        PharmacyCode,
        StockQuantity: parseFloat(StockQuantity),
        SellingPrice: parseFloat(SellingPrice),
      });
    });
    setDuplicateCode('');
    props.saveStockItems({
      ProuctStockWithPrices: cart,
    });
  };

  const addView = () => (
    <div
      style={{
        height: '520px',
      }}
    >
      {/** Search */}
      <div
        style={{
          padding: '16px',
          background: '#F1F4F6',
        }}
      >
        <ItemWithImageSearchField
          isRequired
          isDynamic
          isDropDown
          searchable
          key="medication-search-list"
          componentName="medication-search-list"
          onTypingDelay={500}
          placeHolder="Medicine Name"
          fieldValue=""
          options={getProductsListForDropDown(
            props.searchResultsPharmacyStock,
            props.stockItemsToBeAddedCart,
            'en',
          )}
          onChanges={payload => {
            props.addToStockItemsCart({
              ProductKey: payload?.key,
              PharmacyCode: '',
              StockQuantity: 0,
              SellingPrice: 0,
              itemImage: payload?.itemImage,
              itemName: payload?.fieldValue,
              productShape: payload?.description,
            });
          }}
          onSearch={keyWord => {
            props.searchStockItems({
              searchTerm: keyWord,
            });
          }}
          language="en"
          isLoading={props.searchResultsPharmacyStockLoadStatus === status.FETCHING}
          extendLoaderStyle={extendLoaderStyle}
          isValid
          extendDropDownStyle={extendSearchDropDownStyle}
          extendDropDownListItem={extendDropDownListItem}
          icon="e927"
        />
      </div>
      {/** Added Items */}
      <div
        style={{
          height: '375px',
        }}
      >
        {props.stockItemsToBeAddedCart?.length > 0 && (
          <CartContainer>
            <ItemsCountConatiner>
              {`Items (${props.stockItemsToBeAddedCart?.length})`}
            </ItemsCountConatiner>
            <ItemsContainer>
              {props.stockItemsToBeAddedCart.map(item => (
                <Item
                  key={item?.ProductKey}
                  itemKey={item?.ProductKey}
                  image={item?.itemImage}
                  title={item?.itemName}
                  subTitle={item?.productShape}
                  sellingPrice={item?.SellingPrice}
                  stockQuantity={item?.StockQuantity}
                  pharmacyCode={item?.PharmacyCode}
                  removeItem={() => props.removeFromStockItemsCart(item)}
                />
              ))}
            </ItemsContainer>
          </CartContainer>
        )}
      </div>
      <ButtonsContainer>
        <Button
          onClick={() => {
            setDuplicateCode('');
            onClose();
          }}
          btnText="Cancel"
          extendButtonStyle={extendGrayButtonStyle}
          key="dismiss-popup"
        />
        <Button
          onClick={() => {
            setDuplicateCode('');
            setView(VIEWS.SAVE_ITEMS);
          }}
          btnText="Proceed"
          extendButtonStyle={extendBlueButtonStyle}
          key="proceed-save-view"
          disabled={!(props.stockItemsToBeAddedCart?.length > 0)}
        />
      </ButtonsContainer>
    </div>
  );

  const saveView = () => (
    <div
      style={{
        width: '883px',
      }}
    >
      {/** Edit Items */}
      <div>
        {props.stockItemsToBeAddedCart?.length > 0 && (
          <CartContainer>
            <ItemContainerHeader isEdit key="header-table">
              <TableHeader>{`Items (${props.stockItemsToBeAddedCart?.length})`}</TableHeader>
              <TableHeader>Pharmacy Code</TableHeader>
              <TableHeader>Quantity</TableHeader>
              <TableHeader>Price</TableHeader>
            </ItemContainerHeader>
            <ItemsContainer
              style={{
                height: '382px',
              }}
            >
              {props.stockItemsToBeAddedCart.map(item => (
                <Item
                  isEdit
                  isDuplicateError={!!duplicateCode && duplicateCode === item?.PharmacyCode}
                  itemKey={item?.ProductKey}
                  image={item?.itemImage}
                  title={item?.itemName}
                  subTitle={item?.productShape}
                  sellingPrice={item?.SellingPrice}
                  stockQuantity={item?.StockQuantity}
                  pharmacyCode={item?.PharmacyCode}
                  removeItem={() => props.removeFromStockItemsCart(item)}
                  onEditStockItemCart={payload => props.editStockItemCart({ ...item, ...payload })}
                  key={item?.ProductKey}
                />
              ))}
            </ItemsContainer>
          </CartContainer>
        )}
      </div>
      <ErrorMessage>{duplicateCode ? 'Duplicate Pharmacy Code' : ''}</ErrorMessage>

      <ButtonsContainer>
        <Button
          onClick={() => {
            setView(VIEWS.ADD_ITEMS);
          }}
          btnText="Add more items"
          extendButtonStyle={extendGrayBackButtonStyle}
          key="back-add-items"
          disabled={props.savepharmacyStockItemsLoadStatus === status.SUBMITING}
        />
        <Button
          onClick={() => {
            setDuplicateCode('');
            onClose();
          }}
          btnText="Cancel"
          extendButtonStyle={extendGrayButtonStyle}
          key="dismiss-popup"
          disabled={props.savepharmacyStockItemsLoadStatus === status.SUBMITING}
        />
        <Button
          onClick={() => {
            onSave();
          }}
          btnText="Save"
          extendButtonStyle={extendBlueButtonStyle}
          key="proceed-save-view"
          disabled={!validateStockListData()}
          extendLoaderStyle={extendLoaderStyle}
          isLoading={props.savepharmacyStockItemsLoadStatus === status.SUBMITING}
        />
      </ButtonsContainer>
    </div>
  );

  return (
    <Popup showCloseBtn={false}>
      <PopupWrapper>
        <PopupTitleContainer>
          <PopupTitleWrapper>
            <PopupTitle>Add Item</PopupTitle>
            <PopupTitleSpan
              onClick={() => {
                setDuplicateCode('');
                onClose();
              }}
            />
          </PopupTitleWrapper>
        </PopupTitleContainer>

        <Container>
          {view === VIEWS.ADD_ITEMS && addView()}
          {view === VIEWS.SAVE_ITEMS && saveView()}
        </Container>
      </PopupWrapper>
    </Popup>
  );
};

AddStockItemsModal.propTypes = {
  savepharmacyStockItems: PropTypes.shape({
    response: PropTypes.shape({
      status: PropTypes.number,
      data: PropTypes.shape({
        PharmacyCode: PropTypes.arrayOf(PropTypes.string),
      }),
    }),
  }),
  savepharmacyStockItemsLoadStatus: PropTypes.string,
  saveStockItems: PropTypes.func,
  editStockItemCart: PropTypes.func,
  removeFromStockItemsCart: PropTypes.func,
  addToStockItemsCart: PropTypes.func,
  onClose: PropTypes.func,
  searchResultsPharmacyStockLoadStatus: PropTypes.string,
  searchStockItems: PropTypes.func,
  searchResultsPharmacyStock: PropTypes.shape({}),
  stockItemsToBeAddedCart: PropTypes.arrayOf(PropTypes.shape({})),
};

const mapStateToProps = state => ({
  searchResultsPharmacyStockLoadStatus: state.pharmacyStock.searchResultsPharmacyStockLoadStatus,
  searchResultsPharmacyStock: state.pharmacyStock.searchResultsPharmacyStock,
  stockItemsToBeAddedCart: state.pharmacyStock.stockItemsToBeAddedCart,
  savepharmacyStockItemsLoadStatus: state.pharmacyStock.savepharmacyStockItemsLoadStatus,
  savepharmacyStockItems: state.pharmacyStock.savepharmacyStockItems,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      searchStockItems,
      addToStockItemsCart,
      removeFromStockItemsCart,
      editStockItemCart,
      saveStockItems,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddStockItemsModal);
