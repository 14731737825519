import { StatusBadges } from './ItemizationItemsModal.style';

export const getProductsListForDropDown = (productsList, searchList, language) => {
  const list = [];

  if (productsList?.length) {
    productsList.forEach(item => {
      const {
        mainImageUrl,
        productShapeTypeName,
        productShapeTypeNameAr,
        productNameAr,
        productNameEn,
        productKey,
        id,
        newPrice,
        maxAvailableQuantity,
      } = item;
      const isAvailable = maxAvailableQuantity > 0;
      const searchedIndex = searchList?.findIndex(
        itemObj => itemObj.itemProductKey === productKey && itemObj.itemShapeId === id,
      );
      list.push({
        isSelected: searchedIndex !== -1,
        fieldValue: (
          <div
            style={{
              display: 'flex',
              gap: '13px',
            }}
          >
            <p
              style={{
                margin: '0',
              }}
            >
              {language === 'en' ? productNameEn : productNameAr}
            </p>
            {!isAvailable && <StatusBadges>Out Of Stock</StatusBadges>}
          </div>
        ),
        itemImage: mainImageUrl,
        value: id,
        description: language === 'en' ? productShapeTypeName : productShapeTypeNameAr,
        key: productKey,
        extras: {
          mainImageUrl,
          productShapeTypeName,
          productShapeTypeNameAr,
          productNameAr,
          productNameEn,
          productKey,
          id,
          newPrice,
        },
      });
    });
  }
  return list;
};
