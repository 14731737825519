import PropTypes from 'prop-types';
import Icon from 'modules/common/components/Icon';
import ProductItem from './ProductItem';
import {
  Container,
  Wrapper,
  HeaderContainer,
  HeaderContainerWrapper,
  HeaderContainerTitle,
  EditIconContainer,
  extendIcon,
  PopUpArrow,
} from './AlternativesSection.style';

const AlternativesSection = ({ ...props }) => {
  const { alternativesItemsList, appendAlternatives, enableAddAlternatives } = props;

  const renderItem = () => {
    const itemsList = [];
    if (alternativesItemsList?.length) {
      alternativesItemsList.forEach(product => {
        itemsList.push(
          <ProductItem
            key={`${product.itemProductKey}-${product.itemShapeId}`}
            mainImageUrl={product?.itemImage}
            serviceName={product?.itemName}
            productShapeName={product?.itemShapeName}
            price={product?.itemPrice}
            quantity={product?.itemQuantity}
            totalPrice={(product.itemQuantity * product.itemPrice).toFixed(2)}
          />,
        );
      });
    }
    return itemsList;
  };
  return (
    <Container>
      <PopUpArrow isAlternativesNotAvailable={alternativesItemsList?.length === 0} />
      <Wrapper isAlternativesNotAvailable={alternativesItemsList?.length === 0}>
        <HeaderContainer isAlternativesNotAvailable={alternativesItemsList?.length === 0}>
          <HeaderContainerWrapper>
            <HeaderContainerTitle>{props.mainProduct}</HeaderContainerTitle>
            {enableAddAlternatives && (
              <EditIconContainer onClick={() => appendAlternatives()}>
                <Icon icon="e92c" size={14} extendStyle={extendIcon} />
              </EditIconContainer>
            )}
          </HeaderContainerWrapper>
        </HeaderContainer>
        <div>{renderItem()}</div>
      </Wrapper>
    </Container>
  );
};

AlternativesSection.propTypes = {
  appendAlternatives: PropTypes.func,
  mainProduct: PropTypes.string,
  alternativesItemsList: PropTypes.arrayOf(PropTypes.shape({})),
  enableAddAlternatives: PropTypes.bool,
};

export default AlternativesSection;
