import { status } from 'constants/general';
import { initialPharmacyOrdersState } from 'store/initialStates';
import ordersActionTypes from './action-types';

const ordersReducer = (state = initialPharmacyOrdersState, { type, payload }) => {
  switch (type) {
    case ordersActionTypes.FETCH_PHARMACY_ORDER_STATUS_CHANGE_SUBMISSION:
      return {
        ...state,
        updateOrderStateLoadStatus: status.SUBMITING,
      };
    case ordersActionTypes.FETCH_PHARMACY_ORDER_STATUS_CHANGE_SUBMISSION_SUCCESS:
      return {
        ...state,
        updateOrderStateLoadStatus: status.SUCCESS,
      };
    case ordersActionTypes.FETCH_PHARMACY_ORDER_STATUS_CHANGE_SUBMISSION_FAIL:
      return {
        ...state,
        updateOrderStateLoadStatus: status.FAIL,
      };
    case ordersActionTypes.FETCH_ALL_PHARMACY_ORDERS: {
      let orderListTemp;
      if (payload?.pageNumber > 1) {
        orderListTemp = {
          status: status.FETCHING,
          details: { ...state.ordersList.details },
        };
      } else {
        orderListTemp = {
          status: status.FETCHING,
        };
      }

      return {
        ...state,
        ordersList: orderListTemp,
      };
    }
    case ordersActionTypes.FETCH_ALL_PHARMACY_ORDERS_SUCCESS: {
      const currentMappedOrdersList = state?.ordersList?.details?.mappedOrdersList || [];
      const nextMappedOrdersList = payload?.mappedOrdersList;
      const mappedOrdersList = [...currentMappedOrdersList, ...nextMappedOrdersList];
      const currentOrderDTOs = state?.ordersList?.details?.orderDTOs || [];
      const nextOrderDTOs = payload?.orderDTOs;
      const orderDTOs = [...currentOrderDTOs, ...nextOrderDTOs];
      return {
        ...state,
        ordersList: {
          status: status.SUCCESS,
          details: {
            ...payload,
            mappedOrdersList,
            orderDTOs,
          },
        },
      };
    }

    case ordersActionTypes.FETCH_ALL_PHARMACY_ORDERS_FAIL:
      return {
        ...state,
        ordersList: {
          status: status.FAIL,
          details: { ...state.ordersList.details },
        },
      };
    case ordersActionTypes.FETCH_PARTNERSHIP_TYPES:
      return {
        ...state,
        partnershipTypes: {
          status: status.FETCHING,
        },
      };
    case ordersActionTypes.FETCH_PARTNERSHIP_TYPES_SUCCESS:
      return {
        ...state,
        partnershipTypes: {
          status: status.SUCCESS,
          details: payload,
        },
      };
    case ordersActionTypes.FETCH_PARTNERSHIP_TYPES_FAIL:
      return {
        ...state,
        partnershipTypes: {
          status: status.FAIL,
        },
      };
    case ordersActionTypes.FETCH_DETAILED_REASONS:
      return {
        ...state,
        status: status.FETCHING,
      };

    case ordersActionTypes.FETCH_DETAILED_REASONS_SUCCESS:
      return {
        ...state,
        detailedReasons: {
          status: status.SUCCESS,
          details: { ...state?.detailedReasons?.details, ...payload },
        },
      };

    case ordersActionTypes.FETCH_DETAILED_REASONS_FAIL:
      return {
        ...state,
        status: status.FAIL,
      };

    case ordersActionTypes.FETCH_ORDER_STATUS_TYPES:
      return {
        ...state,
        status: status.FETCHING,
      };

    case ordersActionTypes.FETCH_ORDER_STATUS_TYPES_SUCCESS:
      return {
        ...state,
        orderStatusTypes: {
          status: status.SUCCESS,
          details: payload,
        },
      };

    case ordersActionTypes.FETCH_ORDER_STATUS_TYPES_FAIL:
      return {
        ...state,
        status: status.FAIL,
      };

    case ordersActionTypes.FETCH_PHARMACY_ORDER_DETAILS:
      return {
        ...state,
        orderDetails: {
          status: status.FETCHING,
        },
      };

    case ordersActionTypes.SET_ORDER_STATUS_BY_ORDER_ID: {
      const orderCurrentState = payload?.orderStateTypeId;
      const orderCurrentKey = payload?.key;
      const orderReceipt = payload?.orderReceipt;

      const ordersDTOsList = state.ordersList?.details?.ordersDTOs?.map(order => {
        if (order.key === orderCurrentKey) {
          return {
            ...order,
            orderStateTypeId: orderCurrentState,
            orderReceipt,
          };
        }
        return order;
      });
      const mappedOrdersList = state.ordersList?.details?.mappedOrdersList?.map(mappedOrder => {
        if (mappedOrder.key === orderCurrentKey) {
          return {
            ...mappedOrder,
            orderStateTypeId: orderCurrentState,
            orderReceipt,
          };
        }
        return mappedOrder;
      });
      return {
        ...state,
        orderDetails: {
          ...state.orderDetails,
          details: {
            ...state.orderDetails.details,
            orderStateTypeId: payload.orderStateTypeId,
            orderStates: payload?.orderStates,
            orderReceipt,
          },
        },
        ordersList: {
          ...state.ordersList,
          details: {
            ...state.ordersList.details,
            orderDTOs: ordersDTOsList,
            mappedOrdersList,
          },
        },
      };
    }

    case ordersActionTypes.FETCH_PHARMACY_ORDER_DETAILS_SUCCESS: {
      // const orderCurrentState = payload.orderStateTypeId;
      const orderCurrentKey = payload.key;
      const ordersDTOsList = state.ordersList?.details?.ordersDTOs?.map(order => {
        if (order.key === orderCurrentKey) {
          return {
            ...order,
            ...payload,
          };
        }
        return order;
      });
      const mappedOrdersList = state.ordersList?.details?.mappedOrdersList?.map(mappedOrder => {
        if (mappedOrder.key === orderCurrentKey) {
          return {
            ...mappedOrder,
            ...payload,
          };
        }
        return mappedOrder;
      });
      return {
        ...state,
        orderDetails: {
          status: status.SUCCESS,
          details: payload,
        },
        ordersList: {
          ...state.ordersList,
          details: {
            ...state.ordersList.details,
            orderDTOs: ordersDTOsList,
            mappedOrdersList,
          },
        },
      };
    }
    case ordersActionTypes.FETCH_PHARMACY_ORDER_DETAILS_FAIL:
      return {
        ...state,
        orderDetails: {
          status: status.FAIL,
        },
      };

    case ordersActionTypes.FETCH_ORDER_STATUS_CHANGE_SUBMISSION: {
      return {
        ...state,
        orderStatusChangeSubmission: {
          status: status.FETCHING,
        },
      };
    }
    case ordersActionTypes.FETCH_ORDER_STATUS_CHANGE_SUBMISSION_SUCCESS: {
      return {
        ...state,
        orderStatusChangeSubmission: {
          status: status.SUCCESS,
        },
      };
    }
    case ordersActionTypes.FETCH_ORDER_STATUS_CHANGE_SUBMISSION_FAIL: {
      return {
        ...state,
        orderStatusChangeSubmission: {
          status: status.FAIL,
        },
      };
    }

    case ordersActionTypes.FETCH_PHARMACY_ORDER_NOTE_CHANGE_SUBMISSION: {
      return {
        ...state,
        orderNoteChangeSubmission: {
          status: status.FETCHING,
        },
      };
    }
    case ordersActionTypes.FETCH_PHARMACY_ORDER_NOTE_CHANGE_SUBMISSION_SUCCESS: {
      return {
        ...state,
        orderNoteChangeSubmission: {
          status: status.SUCCESS,
        },
      };
    }
    case ordersActionTypes.FETCH_PHARMACY_ORDER_NOTE_CHANGE_SUBMISSION_FAIL: {
      return {
        ...state,
        orderNoteChangeSubmission: {
          status: status.FAIL,
        },
      };
    }

    case ordersActionTypes.ASSIGN_PHARMACY_ORDER_TO_ENTITY:
      return {
        ...state,
        assignOrderToEntity: {
          status: status.FETCHING,
        },
      };
    case ordersActionTypes.ASSIGN_PHARMACY_ORDER_TO_ENTITY_SUCCESS:
      return {
        ...state,
        assignOrderToEntity: {
          status: status.SUCCESS,
        },
      };
    case ordersActionTypes.ASSIGN_PHARMACY_ORDER_TO_ENTITY_FAIL:
      return {
        ...state,
        assignOrderToEntity: {
          status: status.FAIL,
        },
      };

    case ordersActionTypes.SCHEDULE_ORDER_SUBMISSION: {
      return {
        ...state,
        scheduleOrderSubmission: {
          status: status.FETCHING,
        },
      };
    }
    case ordersActionTypes.SCHEDULE_ORDER_SUBMISSION_SUCCESS: {
      return {
        ...state,
        scheduleOrderSubmission: {
          status: status.SUCCESS,
        },
      };
    }
    case ordersActionTypes.SCHEDULE_ORDER_SUBMISSION_FAIL: {
      return {
        ...state,
        scheduleOrderSubmission: {
          status: status.FAIL,
        },
      };
    }

    case ordersActionTypes.FETCH_PHARMACY_SCHEDULE_ORDER_REASONS: {
      return {
        ...state,
        scheduleOrderReasons: {
          status: status.FETCHING,
        },
      };
    }
    case ordersActionTypes.FETCH_PHARMACY_SCHEDULE_ORDER_REASONS_SUCCESS: {
      return {
        ...state,
        scheduleOrderReasons: {
          status: status.SUCCESS,
          data: payload,
        },
      };
    }
    case ordersActionTypes.FETCH_PHARMACY_SCHEDULE_ORDER_REASONS_FAIL: {
      return {
        ...state,
        scheduleOrderReasons: {
          status: status.FAIL,
        },
      };
    }

    case ordersActionTypes.FETCH_PHARMACY_CANCEL_ORDER_REASONS: {
      return {
        ...state,
        cancelOrderReasons: {
          status: status.FETCHING,
        },
      };
    }
    case ordersActionTypes.FETCH_PHARMACY_CANCEL_ORDER_REASONS_SUCCESS: {
      return {
        ...state,
        cancelOrderReasons: {
          status: status.SUCCESS,
          data: payload,
        },
      };
    }
    case ordersActionTypes.FETCH_PHARMACY_CANCEL_ORDER_REASONS_FAIL: {
      return {
        ...state,
        cancelOrderReasons: {
          status: status.FAIL,
        },
      };
    }

    case ordersActionTypes.FETCH_PHARMACY_REJECT_ORDER_REASONS: {
      return {
        ...state,
        rejectOrderReasons: {
          status: status.FETCHING,
        },
      };
    }
    case ordersActionTypes.FETCH_PHARMACY_REJECT_ORDER_REASONS_SUCCESS: {
      return {
        ...state,
        rejectOrderReasons: {
          status: status.SUCCESS,
          data: payload,
        },
      };
    }
    case ordersActionTypes.FETCH_PHARMACY_REJECT_ORDER_REASONS_FAIL: {
      return {
        ...state,
        rejectOrderReasons: {
          status: status.FAIL,
        },
      };
    }

    case ordersActionTypes.FETCH_ORDER_ITEMS_SUGGESTIONS: {
      return {
        ...state,
        orderItemsSuggestions: {
          status: status.FETCHING,
        },
      };
    }

    case ordersActionTypes.FETCH_ORDER_ITEMS_SUGGESTIONS_SUCCESS: {
      return {
        ...state,
        orderItemsSuggestions: {
          status: status.SUCCESS,
          details: payload.productShapes,
        },
      };
    }

    case ordersActionTypes.FETCH_ORDER_ITEMS_SUGGESTIONS_FAIL: {
      return {
        ...state,
        orderItemsSuggestions: {
          status: status.FAIL,
        },
      };
    }

    case ordersActionTypes.RESET_ORDER_ITEMS_SUGGESTIONS: {
      return {
        ...state,
        orderItemsSuggestions: {
          status: status.SHOULD_CALL_API,
        },
      };
    }

    case ordersActionTypes.FETCH_ORDER_ITEMS_SUBMISSION: {
      return {
        ...state,
        orderItemsSubmissionLoadState: status.FETCHING,
      };
    }

    case ordersActionTypes.FETCH_ORDER_ITEMS_SUBMISSION_SUCCESS: {
      return {
        ...state,
        orderItemsSubmissionLoadState: status.SUCCESS,
      };
    }

    case ordersActionTypes.FETCH_ORDER_ITEMS_SUBMISSION_FAIL: {
      return {
        ...state,
        orderItemsSubmissionLoadState: status.FAIL,
      };
    }

    case ordersActionTypes.UPDATE_SHIPPING_ADDRESS: {
      return {
        ...state,
        editShippingAddress: {
          status: status.FETCHING,
        },
      };
    }

    case ordersActionTypes.UPDATE_SHIPPING_ADDRESS_SUCCESS: {
      return {
        ...state,
        editShippingAddress: {
          status: status.SUCCESS,
        },
      };
    }

    case ordersActionTypes.UPDATE_SHIPPING_ADDRESS_FAIL: {
      return {
        ...state,
        editShippingAddress: {
          status: status.FAIL,
        },
      };
    }
    case ordersActionTypes.GET_STOCKS: {
      return {
        ...state,
        stocks: {
          status: status.FETCHING,
        },
      };
    }
    case ordersActionTypes.GET_STOCKS_SUCCESS: {
      return {
        ...state,
        stocks: {
          status: status.SUCCESS,
          details: payload,
        },
      };
    }
    case ordersActionTypes.GET_STOCKS_FAIL: {
      return {
        ...state,
        stocks: {
          status: status.FAIL,
        },
      };
    }
    case ordersActionTypes.FETCH_ITEM_ALTERNATIVES:
      return {
        ...state,
        itemAlternatives: {
          status: status.FETCHING,
        },
      };
    case ordersActionTypes.FETCH_ITEM_ALTERNATIVES_SUCCESS:
      return {
        ...state,
        itemAlternatives: {
          status: status.SUCCESS,
          details: payload,
        },
      };
    case ordersActionTypes.FETCH_ITEM_ALTERNATIVES_FAIL:
      return {
        ...state,
        itemAlternatives: {
          status: status.FAIL,
        },
      };
    case ordersActionTypes.SUBMIT_ORDER_ITEMS_ALTERNATIVES:
      return {
        ...state,
        submitAlternatives: {
          status: status.FETCHING,
        },
      };
    case ordersActionTypes.SUBMIT_ORDER_ITEMS_ALTERNATIVES_SUCCESS:
      return {
        ...state,
        submitAlternatives: {
          status: status.SUCCESS,
          details: payload,
        },
      };
    case ordersActionTypes.SUBMIT_ORDER_ITEMS_ALTERNATIVES_FAIL:
      return {
        ...state,
        submitAlternatives: {
          status: status.FAIL,
        },
      };
    case ordersActionTypes.FETCH_ORDER_RECOMMENDED_ALTERNATIVES:
      return {
        ...state,
        orderAlternatives: {
          status: status.FETCHING,
        },
      };
    case ordersActionTypes.FETCH_ORDER_RECOMMENDED_ALTERNATIVES_SUCCESS:
      return {
        ...state,
        orderAlternatives: {
          status: status.SUCCESS,
          details: payload,
        },
      };
    case ordersActionTypes.FETCH_ORDER_RECOMMENDED_ALTERNATIVES_FAIL:
      return {
        ...state,
        orderAlternatives: {
          status: status.FAIL,
        },
      };
    case ordersActionTypes.CONFIRM_ORDER:
      return {
        ...state,
        confirmOrder: payload,
        confirmOrderLoadStatus: status.SUBMITING,
      };
    case ordersActionTypes.CONFIRM_ORDER_SUCCESS:
      return {
        ...state,
        confirmOrder: payload,
        confirmOrderLoadStatus: status.SUCCESS,
      };
    case ordersActionTypes.CONFIRM_ORDER_FAIL:
      return {
        ...state,
        confirmOrderLoadStatus: status.FAIL,
      };
    case ordersActionTypes.RETURN_ORDER:
      return {
        ...state,
        returnOrder: payload,
        returnOrderLoadStatus: status.SUBMITING,
      };
    case ordersActionTypes.RETURN_ORDER_SUCCESS:
      return {
        ...state,
        returnOrder: payload,
        returnOrderLoadStatus: status.SUCCESS,
      };
    case ordersActionTypes.RETURN_ORDER_FAIL:
      return {
        ...state,
        returnOrderLoadStatus: status.FAIL,
      };
    default:
      return state;
  }
};

export default ordersReducer;
