import PropTypes from 'prop-types';
import Popup from 'modules/common/components/Popup';
import SectionDivider from 'modules/common/components/SectionDivider';
import RadioButtonGroup from 'modules/common/components/RadioButtonGroup';
import Loader from 'modules/common/components/Loader';
import {
  OrderDetailsScheduleOrderPopupWrapper,
  OrderDetailsScheduleOrderPopupTitleWrapper,
  OrderDetailsScheduleOrderPopupTitle,
  OrderDetailsScheduleOrderPopupTitleSpan,
  OrderDetailsScheduleOrderSectionTitle,
  OrderDetailsScheduleOrderReasonsWrapper,
  OrderDetailsScheduleOrderNoteWrapper,
  OrderDetailsScheduleOrderNoteTextArea,
  OrderDetailsScheduleOrderBtnWrapper,
  OrderDetailsScheduleOrderSaveBtn,
  OrderDetailsScheduleOrderCancelBtn,
  OrderDetailsScheduleOrderError,
  OrderDetailsCancelSectionSubTitle,
} from '../OrderActionBtnsSection/OrderDetailsActionBtns.style';

const CancelOrderModal = ({
  themeContext,
  stringsData,
  isCancelOrderPopupLoading,
  cancelOrderReason,
  handleCancelOrderReasonRadioButtonClick,
  cancelOrderReasons,
  handleCancelOrderPopupConfirmBtnClick,
  handleCancelOrderPopupDismissBtnClick,
  cancelOrderReasonIsRequiredError,
  cancelOrderNote,
  handleCancelOrderNoteChange,
}) => (
  <Popup showCloseBtn={false}>
    <OrderDetailsScheduleOrderPopupWrapper>
      <OrderDetailsScheduleOrderPopupTitleWrapper>
        <OrderDetailsScheduleOrderPopupTitle as="p">
          {stringsData.orderDetailsHeader.cancelOrderTitle}
        </OrderDetailsScheduleOrderPopupTitle>
        <OrderDetailsScheduleOrderPopupTitleSpan
          as="span"
          onClick={() => handleCancelOrderPopupDismissBtnClick()}
        />
      </OrderDetailsScheduleOrderPopupTitleWrapper>
      <SectionDivider
        gap={16}
        withSeparator
        separatorGap={16}
        borderColor={themeContext.colors.grey004}
      />
      <OrderDetailsScheduleOrderReasonsWrapper>
        {cancelOrderReasons?.data.map(reasonCategory => {
          let scheduleOrderReasonsArr = [];
          scheduleOrderReasonsArr = reasonCategory.orderStateChildReasons.map(reason => ({
            name: reason.name,
            key: reason.id,
          }));
          return (
            <>
              <OrderDetailsCancelSectionSubTitle as="p">
                {reasonCategory.name}
              </OrderDetailsCancelSectionSubTitle>
              <RadioButtonGroup
                RadioButtonGroupArr={scheduleOrderReasonsArr}
                selectedRadioButton={cancelOrderReason}
                handleRadioButtonClick={handleCancelOrderReasonRadioButtonClick}
              />
              <SectionDivider
                gap={16}
                withSeparator
                separatorGap={16}
                borderColor={themeContext.colors.grey004}
              />
            </>
          );
        })}
        {cancelOrderReasonIsRequiredError && (
          <OrderDetailsScheduleOrderError>
            {stringsData.orderDetailsHeader.cancelOrderReasonIsRequired}
          </OrderDetailsScheduleOrderError>
        )}
      </OrderDetailsScheduleOrderReasonsWrapper>
      <OrderDetailsScheduleOrderNoteWrapper>
        <OrderDetailsScheduleOrderSectionTitle as="p">
          {stringsData.orderDetailsHeader.scheduleOrderNoteTitle}
        </OrderDetailsScheduleOrderSectionTitle>
        <SectionDivider gap={8} />
        <OrderDetailsScheduleOrderNoteTextArea
          placeholder={stringsData.orderDetailsHeader.scheduleOrderNotePlaceholder}
          value={cancelOrderNote}
          onChange={event => handleCancelOrderNoteChange(event)}
        />
      </OrderDetailsScheduleOrderNoteWrapper>
      <SectionDivider
        gap={22}
        withSeparator
        separatorGap={16}
        borderColor={themeContext.colors.grey004}
      />
      <OrderDetailsScheduleOrderBtnWrapper>
        <OrderDetailsScheduleOrderCancelBtn
          as="button"
          onClick={() => handleCancelOrderPopupDismissBtnClick()}
        >
          {stringsData.orderDetailsHeader.scheduleOrderCancelBtn}
        </OrderDetailsScheduleOrderCancelBtn>
        <OrderDetailsScheduleOrderSaveBtn
          as="button"
          onClick={() => handleCancelOrderPopupConfirmBtnClick()}
        >
          {(isCancelOrderPopupLoading && <Loader width={14} height={14} thickness={4} />) ||
            stringsData.orderDetailsHeader.scheduleOrderSaveBtn}
        </OrderDetailsScheduleOrderSaveBtn>
      </OrderDetailsScheduleOrderBtnWrapper>
    </OrderDetailsScheduleOrderPopupWrapper>
  </Popup>
);

CancelOrderModal.propTypes = {
  themeContext: PropTypes.shape({
    colors: PropTypes.shape({
      grey004: PropTypes.string,
    }),
  }).isRequired,
  stringsData: PropTypes.shape({
    orderDetailsHeader: PropTypes.shape({
      cancelOrderTitle: PropTypes.string,
      scheduleOrderSaveBtn: PropTypes.string,
      scheduleOrderCancelBtn: PropTypes.string,
      cancelOrderReasonIsRequired: PropTypes.string,
      scheduleOrderNoteTitle: PropTypes.string,
      scheduleOrderNotePlaceholder: PropTypes.string,
    }),
  }).isRequired,
  isCancelOrderPopupLoading: PropTypes.bool.isRequired,
  cancelOrderReason: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  handleCancelOrderReasonRadioButtonClick: PropTypes.func.isRequired,
  handleCancelOrderPopupConfirmBtnClick: PropTypes.func.isRequired,
  handleCancelOrderPopupDismissBtnClick: PropTypes.func.isRequired,
  cancelOrderReasons: PropTypes.shape({
    status: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  cancelOrderReasonIsRequiredError: PropTypes.bool,
  cancelOrderNote: PropTypes.string.isRequired,
  handleCancelOrderNoteChange: PropTypes.func.isRequired,
};

CancelOrderModal.defaultProps = {
  cancelOrderReasonIsRequiredError: false,
};

export default CancelOrderModal;
