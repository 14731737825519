import styled from 'styled-components';
import themeData from 'utils/theme/themeData';

const { typography } = themeData;

export const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
`;

export const LoginLogo = styled.img`
  width: 98px;
  height: 20px;
`;

export const LoginInput = styled.input`
  width: 100%;
  margin-top: 4px;
  padding: 8px 12px;
  font-size: 13px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black};
  box-sizing: border-box;

  border-radius: 8px;
  border: 1px solid #c9cbce;

  ::-webkit-input-placeholder {
    font-size: 13px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.grey002};
  }
  ::-moz-placeholder {
    font-size: 13px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.grey002};
  }
  :-ms-input-placeholder {
    font-size: 13px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.grey002};
  }
  :-moz-placeholder {
    font-size: 13px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.grey002};
  }

  &:focus {
    border: 2px solid ${({ theme }) => theme.colors.blueMain};
    outline: none;
  }
`;

export const LoginValidationErrMsg = styled(typography.BodyTextOneRegularTypography)`
  margin: 0;
  color: ${({ theme }) => theme.colors.redMain};
  width: 100%;
`;

export const LoginBtn = styled(typography.BodyTextOneRegularTypography)`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.blueMain};
  border: 1px solid ${({ theme }) => theme.colors.blueMain};
  cursor: pointer;
  border-radius: 8px;
  height: 40px;
  padding: 10px 16px;
`;

export const LoginContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #d9dbdf;
`;

export const LoginFieldsContainer = styled.div`
  display: flex;
  width: 407px;
  padding: 32px;
  flex-direction: column;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(44, 53, 68, 0.08);
`;

export const FieldLabel = styled.p`
  color: #484848;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  margin: 0;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
