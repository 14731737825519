import PropTypes from 'prop-types';
import { status } from 'constants/general';
import Loader from 'modules/common/components/Loader';
import {
  OrderDetailsInvoiceSearchBarLoaderWrapper,
  OrderDetailsInvoiceSearchBarNoDataText,
  OrderDetailsInvoiceSearchBarItemWrapper,
  OrderDetailsInvoiceSearchBarItem,
  OrderDetailsInvoiceSearchBarItemSubText,
  StatusBadges,
} from '../OrderInvoice.style';

const SearchBarEnvelopContent = ({
  stringsData,
  orderServicesSuggestions,
  handleServicesSuggestionsItemClick,
  objectMapper,
}) => {
  const { status: servicesDataStatus } = orderServicesSuggestions;
  const servicesData = orderServicesSuggestions.details;

  if (servicesDataStatus === status.SHOULD_CALL_API || servicesDataStatus === status.FETCHING)
    return (
      <OrderDetailsInvoiceSearchBarLoaderWrapper>
        <Loader width={40} height={40} thickness={6} />
      </OrderDetailsInvoiceSearchBarLoaderWrapper>
    );

  if (
    servicesDataStatus === status.FAIL ||
    (servicesDataStatus === status.SUCCESS && servicesData?.length === 0)
  )
    return (
      <OrderDetailsInvoiceSearchBarLoaderWrapper>
        <OrderDetailsInvoiceSearchBarNoDataText>
          {stringsData.orderDetailsContent.orderInvoiceServicesNoResultText}
        </OrderDetailsInvoiceSearchBarNoDataText>
      </OrderDetailsInvoiceSearchBarLoaderWrapper>
    );

  return servicesData?.map(service => {
    const isOutOFStock = service?.maxAvailableQuantity < 1;
    return (
      <OrderDetailsInvoiceSearchBarItemWrapper
        key={objectMapper(service)?.key}
        onClick={() => {
          if (!isOutOFStock) handleServicesSuggestionsItemClick(service);
        }}
        isSearchable={objectMapper(service)?.isSearchable}
        isOutOFStock={isOutOFStock}
      >
        <div
          style={{
            display: 'flex',
            gap: '16px',
          }}
        >
          <OrderDetailsInvoiceSearchBarItem>
            {`${objectMapper(service)?.name} - ${objectMapper(service)?.productShapeName}`}
          </OrderDetailsInvoiceSearchBarItem>
          {isOutOFStock && <StatusBadges>Out Of Stock</StatusBadges>}
        </div>
        <OrderDetailsInvoiceSearchBarItemSubText>{`${objectMapper(service)?.price?.toFixed(2)} ${
          stringsData.orderDetailsContent.orderInvoiceServicesCurrency
        }`}</OrderDetailsInvoiceSearchBarItemSubText>
      </OrderDetailsInvoiceSearchBarItemWrapper>
    );
  });
};
SearchBarEnvelopContent.propTypes = {
  stringsData: PropTypes.shape({
    orderDetailsContent: PropTypes.shape({
      orderInvoiceServicesNoResultText: PropTypes.string,
      orderInvoiceServicesCurrency: PropTypes.string,
    }),
  }).isRequired,
  orderServicesSuggestions: PropTypes.shape({
    status: PropTypes.string,
    details: PropTypes.arrayOf(
      PropTypes.shape({
        Data: PropTypes.shape({
          Services: PropTypes.arrayOf(PropTypes.shape({})),
        }),
      }),
    ),
  }).isRequired,
  handleServicesSuggestionsItemClick: PropTypes.func.isRequired,
  objectMapper: PropTypes.func.isRequired,
};

export default SearchBarEnvelopContent;
