import PropTypes from 'prop-types';
import Loader from 'modules/common/components/Loader';
import Button from 'modules/common/components/Button';
import { status } from 'constants/general';
import ErrorImg from 'assets/images/error.png';
import SearchImg from 'assets/images/search.png';
import TableViewContainer from 'modules/common/components/TableViewContainer';
import IntroStatus from './IntroStatus';
import SearchAndFilterContainer from './SearchAndFilterContainer';
import { getTableHeadersLocalized } from './helper';
import {
  extendAdditionalItem,
  extendMainItem,
  extendTableGrid,
  extendTableHeaderRow,
  extendTableRow,
} from './TableViewContainer.style';

import {
  GrayOverLay,
  ViewContainer,
  ExceptionContainer,
  ExceptionImage,
  ExceptionImageContainer,
  ExceptionMessage,
  ExceptionMessageContainer,
  extendButtonStyle,
  extendLoaderStyle,
} from './Invoices.style';

const StockList = ({
  queryString,
  currentPage,
  pharmacyInvoicesLoadStatus,
  pharmacyInvoices,
  getListPage,
  showMore,
  onClickshowMore,
  onSearch,
  toggleInvoiceModal,
  setInvoice,
  onChangeTypes,
  invoiceTypeObj,
  setSelectedDate,
  selectedDate,
  records,
  hasData,
  ...props
}) => {
  const getExceptionComponent = (loadingStatus, data) => {
    let exceptionImage;
    let exceptionMessage;
    if (loadingStatus === status.SUCCESS && (!data || !data.length)) {
      exceptionImage = SearchImg;
      exceptionMessage = 'No Invoices Found';
    }
    if (loadingStatus === status.FAIL) {
      exceptionImage = ErrorImg;
      exceptionMessage = 'Something went wrong!';
    }

    if (exceptionImage && exceptionMessage) {
      return (
        <ExceptionContainer>
          <ExceptionImageContainer>
            <ExceptionImage src={exceptionImage} alt="exception" />
          </ExceptionImageContainer>
          <ExceptionMessageContainer>
            <ExceptionMessage>{exceptionMessage}</ExceptionMessage>
          </ExceptionMessageContainer>
        </ExceptionContainer>
      );
    }
    return '';
  };

  const renderList = () => {
    if (
      !pharmacyInvoices?.length &&
      (pharmacyInvoicesLoadStatus === status.FETCHING ||
        pharmacyInvoicesLoadStatus === status.SHOULD_CALL_API)
    ) {
      return (
        <GrayOverLay>
          <Loader />
        </GrayOverLay>
      );
    }
    if (records.length) {
      return (
        <div>
          <div>
            <TableViewContainer
              {...props}
              tableRecords={records}
              tableColumnHeaders={getTableHeadersLocalized()}
              getListPage={getListPage}
              extendAdditionalItem={extendAdditionalItem}
              extendMainItem={extendMainItem}
              extendTableGrid={extendTableGrid}
              extendTableHeaderRow={extendTableHeaderRow}
              extendTableRow={extendTableRow}
            />
          </div>
        </div>
      );
    }
    if (
      pharmacyInvoicesLoadStatus === status.FAIL ||
      (pharmacyInvoicesLoadStatus === status.SUCCESS &&
        pharmacyInvoices &&
        !pharmacyInvoices.length)
    ) {
      return <div>{getExceptionComponent(pharmacyInvoicesLoadStatus, pharmacyInvoices)}</div>;
    }

    return (
      <div>
        <div>
          <TableViewContainer
            {...props}
            currentPage={currentPage}
            tableRecords={records}
            tableColumnHeaders={getTableHeadersLocalized()}
            getStockListPage={getListPage}
            extendAdditionalItem={extendAdditionalItem}
            extendMainItem={extendMainItem}
            extendTableGrid={extendTableGrid}
            extendTableHeaderRow={extendTableHeaderRow}
            extendTableRow={extendTableRow}
          />
        </div>
      </div>
    );
  };

  const displayShowMoreBtn = pharmacyInvoices?.length > 0 && showMore;
  return (
    <div
      style={{
        padding: '16px',
      }}
    >
      <SearchAndFilterContainer
        queryString={queryString}
        onSearch={onSearch}
        onChangeTypes={onChangeTypes}
        invoiceTypeObj={invoiceTypeObj}
        setSelectedDate={setSelectedDate}
        selectedDate={selectedDate}
      />
      {hasData ? (
        <ViewContainer>{renderList()}</ViewContainer>
      ) : (
        <IntroStatus {...props} hasData={hasData} loadStatus={pharmacyInvoicesLoadStatus} />
      )}
      {displayShowMoreBtn && (
        <Button
          onClick={() => onClickshowMore()}
          isLoading={pharmacyInvoicesLoadStatus === status.FETCHING}
          btnText="Show More"
          extendLoaderStyle={extendLoaderStyle}
          extendButtonStyle={extendButtonStyle}
        />
      )}
    </div>
  );
};

StockList.propTypes = {
  pharmacyInvoices: PropTypes.arrayOf(PropTypes.shape({})),
  records: PropTypes.arrayOf(PropTypes.shape({})),
  queryString: PropTypes.string,
  currentPage: PropTypes.number,
  getListPage: PropTypes.func,
  showMore: PropTypes.bool,
  onClickshowMore: PropTypes.func,
  onSearch: PropTypes.func,
  pharmacyInvoicesLoadStatus: PropTypes.string,
  toggleInvoiceModal: PropTypes.func,
  setInvoice: PropTypes.func,
  onChangeTypes: PropTypes.func,
  invoiceTypeObj: PropTypes.shape({}),
  selectedDate: PropTypes.shape({}),
  setSelectedDate: PropTypes.func,
  hasData: PropTypes.bool,
};

export default StockList;
