import { useState, useEffect, useContext, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { status, paymentMethodsKeys } from 'constants/general';
import { getHoursAndMinutes } from 'utils/functions/dateTime';
import Button from 'modules/common/components/Button';
import { generateTimeSlotsArr, isOrderToBeConfirmed } from '../../helper';
import {
  initialNextOrderStateLabelDefault,
  initialNextOrderStateLabelPayment,
  initialNextOrderStateDefault,
  initialNextOrderStateOnlinePayment,
} from '../../../../enums';
import RejectOrderModal from '../Modals/RejectOrderModal';
import RescheduleOrderModal from '../Modals/RescheduleOrderModal';
import CancelOrderModal from '../Modals/CancelOrderModal';
import OrderActionsModal from '../Modals/OrderActionsModal';
import ReturnOrderModal from '../Modals/ReturnOrderModal';
import AddAlternativeItemsModal from '../Modals/AddAlternativeItemsModal';

import {
  OrderDetailsHeaderWrapper,
  OrderDetailsHeaderOrderBtnWrapper,
  OrderDetailsHeaderIconBtnWrapper,
  extendNextStateButtonStyle,
  extendButtonStyle,
  extendLoaderStyle,
} from './OrderDetailsActionBtns.style';

const OrderDetailsActionBtns = ({
  stringsData,
  orderDetails,
  actionPopupStatus,
  handleOrderRefreshBtnClick,
  actionPopupDismissBtn,
  actionPopupConfirmBtn,
  actionPopupConfirmBtnCallback,
  scheduleOrderPopupConfirmBtn,
  cancelOrderPopupConfirmBtn,
  rejectOrderPopupConfirmBtn,
  cancelOrderReasons,
  rejectOrderReasons,
  scheduleOrderReasons,
  loginInfo,
  orderStatusEnum,
  selectItemsSearchApi,
  orderStateTypeId,
  reloadOrderDetailsPage,
  fetchOrderStatusChangeSubmission,
  confirmOrderAction,
  returnOrderAction,
  dispensedOrderItems,
  isAllInvoiceItemsToBeConfirmedChecked,
  suggestAlternativesModal,
  setSuggestAllternativeModal,
  appendProductAlternatives,
  productsAlternatives,
  isSuggestAlternativesMode,
  onClickSendToCustomer,
}) => {
  const orderData = orderDetails.details;

  const initialNextOrderState =
    orderData?.onlinePaymentCardKey ||
    orderData?.optionalPaymentMethodKey === paymentMethodsKeys.vezeetaCash
      ? initialNextOrderStateOnlinePayment[orderStateTypeId]
      : initialNextOrderStateDefault[orderStateTypeId];
  const initialNextOrderStateLabel =
    orderData?.onlinePaymentCardKey ||
    orderData?.optionalPaymentMethodKey === paymentMethodsKeys.vezeetaCash
      ? initialNextOrderStateLabelPayment[orderStateTypeId]
      : initialNextOrderStateLabelDefault[orderStateTypeId];

  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const updateOrderStateLoadStatus = useSelector(
    state => state.pharmacyOrders.updateOrderStateLoadStatus,
  );
  const confirmOrderLoadStatus = useSelector(state => state.pharmacyOrders.confirmOrderLoadStatus);

  const returnOrderLoadStatus = useSelector(state => state.pharmacyOrders.returnOrderLoadStatus);

  const extraActionMenuwrapperRef = useRef(null);
  // const [isExtraActionsMenuOpen, setIsExtraActionsMenuOpen] = useState(false);
  const [isActionPopupOpen, setIsActionPopupOpen] = useState(false);
  const [isActionPopupLoading, setIsActionPopupLoading] = useState(false);
  const [isScheduleOrderPopupOpen, setIsScheduleOrderPopupOpen] = useState(false);
  const [isScheduleOrderPopupLoading, setIsScheduleOrderPopupLoading] = useState(false);
  const [scheduleOrderSelectedDate, setScheduleOrderSelectedDate] = useState(new Date());
  const [scheduleOrderReason, setScheduleOrderReason] = useState('');
  const [scheduleOrderNote, setScheduleOrderNote] = useState('');

  const scheduleOrderTimeSlotsArr = useMemo(
    () => generateTimeSlotsArr(scheduleOrderSelectedDate),
    [scheduleOrderSelectedDate],
  );
  const [scheduleOrderSelectedTime, setScheduleOrderSelectedTime] = useState(
    scheduleOrderTimeSlotsArr[0],
  );
  const [scheduleOrderReasonIsRequiredError, setScheduleOrderReasonIsRequiredError] =
    useState(false);

  const [isCancelOrderPopupOpen, setIsCancelOrderPopupOpen] = useState(false);
  const [isCancelOrderPopupLoading, setIsCancelOrderPopupLoading] = useState(false);
  const [cancelOrderReason, setCancelOrderReason] = useState('');
  const [cancelOrderReasonIsRequiredError, setCancelOrderReasonIsRequiredError] = useState(false);
  const [cancelOrderNote, setCancelOrderNote] = useState('');

  const [isRejectOrderPopupOpen, setIsRejectOrderPopupOpen] = useState(false);
  const [isRetrunOrderPopupOpen, setIsReturnOrderPopupOpen] = useState(false);
  const [isRejectOrderPopupLoading, setIsRejectOrderPopupLoading] = useState(false);
  const [rejectOrderReason, setRejectOrderReason] = useState('');
  const [rejectOrderReasonIsRequiredError, setRejectOrderReasonIsRequiredError] = useState(false);
  const [rejectOrderNote, setRejectOrderNote] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);

  const handleNextStateBtn = () => {
    if (
      status.SUBMITING !== confirmOrderLoadStatus ||
      status.SUBMITING !== updateOrderStateLoadStatus
    ) {
      if (isOrderToBeConfirmed(orderStateTypeId)) {
        // Confirm and reload
        const productList = [];
        dispensedOrderItems?.forEach(orderItem => {
          productList.push({
            productKey: orderItem?.serviceKey,
            ProductShapeId: orderItem?.productShapeId,
            Quantity: orderItem?.quantity,
          });
        });

        const body = {
          orderKey: orderData.key,
          data: {
            entityId: loginInfo.details.data.key,
            EntityTypeId: 1,
            orderItems: productList,
          },
          callbackAction: reloadOrderDetailsPage,
        };
        confirmOrderAction(body);
      } else {
        // Change state and reload
        const body = {
          data: {
            entityId: loginInfo.details.data.key,
            EntityTypeId: 1,
            orderKey: orderData.key,
            orderStateTypeId: initialNextOrderState,
          },
          callbackAction: reloadOrderDetailsPage,
        };
        dispatch(fetchOrderStatusChangeSubmission(body));
      }
    }
  };

  useEffect(() => {
    if (orderStateTypeId === orderStatusEnum.New) {
      const body = {
        data: {
          entityId: loginInfo.details.data.key,
          EntityTypeId: 1,
          orderKey: orderData.key,
          orderStateTypeId: initialNextOrderState,
        },
      };
      dispatch(fetchOrderStatusChangeSubmission(body));
    }
  }, [orderStateTypeId]);

  useEffect(() => {
    setScheduleOrderSelectedTime(scheduleOrderTimeSlotsArr[0]);
  }, [scheduleOrderSelectedDate]);

  const actionPopupConfirmBtnCallbackFn = shouldReloadOrderDetails => {
    setIsActionPopupLoading(false);
    setIsActionPopupOpen(false);
    actionPopupConfirmBtnCallback(shouldReloadOrderDetails);
  };

  const scheduleOrderPopupConfirmBtnCallbackFn = shouldReloadOrderDetails => {
    setIsScheduleOrderPopupLoading(false);
    setIsScheduleOrderPopupOpen(false);

    actionPopupConfirmBtnCallback(shouldReloadOrderDetails);
  };

  const cancelOrderPopupConfirmBtnCallbackFn = shouldReloadOrderDetails => {
    setIsCancelOrderPopupLoading(false);
    setIsCancelOrderPopupOpen(false);

    actionPopupConfirmBtnCallback(shouldReloadOrderDetails);
  };

  const rejectOrderPopupConfirmBtnCallbackFn = shouldReloadOrderDetails => {
    setIsRejectOrderPopupLoading(false);
    setIsRejectOrderPopupOpen(false);
    setIsReturnOrderPopupOpen(false);
    actionPopupConfirmBtnCallback(shouldReloadOrderDetails);
  };

  const returnOrderHandler = itemsList => {
    const productList = [];
    itemsList?.forEach(orderItem => {
      productList.push({
        productKey: orderItem?.serviceKey,
        ProductShapeId: orderItem?.productShapeId,
        Quantity: orderItem?.quantity,
      });
    });

    const body = {
      orderKey: orderData.key,
      data: {
        entityId: loginInfo.details.data.key,
        EntityTypeId: 1,
        orderItems: productList,
      },
      callbackAction: () => {
        reloadOrderDetailsPage();
        setIsReturnOrderPopupOpen(false);
      },
    };
    returnOrderAction(body);
  };

  /* const handleActionBtnClick = actionStatus => {
    setIsActionPopupOpen(true);
    actionBtnClick(actionStatus);
  }; */

  const handleActionPopupDismissBtnClick = () => {
    setIsActionPopupOpen(false);
    actionPopupDismissBtn();
  };

  const handleActionPopupConfirmBtnClick = () => {
    setIsActionPopupLoading(true);
    actionPopupConfirmBtn(actionPopupConfirmBtnCallbackFn);
  };

  /* const handleExtraActionsBtnClick = () => {
    setIsExtraActionsMenuOpen(prevState => !prevState);
  };

  const handleCancelActionBtnClick = () => {
    setIsExtraActionsMenuOpen(false);
    setIsCancelOrderPopupOpen(true);
    // handleActionBtnClick(orderStatusEnum.Cancelled);
  }; */

  const handleReturnActionBtnClick = isOpen => {
    setIsReturnOrderPopupOpen(isOpen);
  };

  const handleRejectActionBtnClick = () => {
    // setIsExtraActionsMenuOpen(false);
    setIsRejectOrderPopupOpen(true);
  };

  const handleScheduleOrderBtnClick = () => {
    setIsScheduleOrderPopupOpen(true);
  };

  const handleScheduleOrderDatePickerChange = date => {
    setScheduleOrderSelectedDate(date);
  };

  const handleScheduleOrderTimeSlotDropdownMenuChange = timeSlot => {
    setScheduleOrderSelectedTime(timeSlot);
  };

  const handleScheduleOrderPopupDismissBtnClick = () => {
    setIsScheduleOrderPopupOpen(false);
    setSelectedItems([]);
  };

  const handleScheduleOrderReasonRadioButtonClick = reason => {
    setScheduleOrderReasonIsRequiredError(false);
    setScheduleOrderReason(reason);
    setSelectedItems([]);
  };

  const handleScheduleOrderNoteChange = event => {
    setScheduleOrderNote(event?.target?.value);
  };

  const handleSendAlternativesToCustomer = () => {
    // if all order items has no alternatives ? Reject Order Handler : send alternatives to customer
    let allOrderItemsHasNoAlternatives = true;
    productsAlternatives?.find(item => {
      const { alternatives } = item;
      if (alternatives?.length > 0) {
        allOrderItemsHasNoAlternatives = false;
        return true;
      }
      return false;
    });

    if (
      allOrderItemsHasNoAlternatives &&
      productsAlternatives?.length === orderData?.orderItems?.length
    ) {
      // Reject
      handleRejectActionBtnClick();
    } else {
      // Send altenratives to customer
      onClickSendToCustomer();
    }
  };

  const handleScheduleOrderPopupConfirmBtnClick = () => {
    if (!scheduleOrderReason) {
      setScheduleOrderReasonIsRequiredError(true);
      return;
    }
    setIsScheduleOrderPopupLoading(true);
    const { hours, minutes } = getHoursAndMinutes(scheduleOrderSelectedTime.key);
    const choosenDate = new Date(scheduleOrderSelectedDate);
    choosenDate.setHours(0, 0, 0, 0);
    choosenDate.setHours(choosenDate.getHours() + hours);
    choosenDate.setMinutes(choosenDate.getMinutes() + minutes);
    const dateGMT = new Date(choosenDate);
    const ScheduledToDataTime = dateGMT.toISOString();

    const orderScheduleStatus = {
      entityTypeId: 1,
      orderStateTypeId: 16,
    };
    const data = {
      entityId: loginInfo.details.data.key,
      entityTypeId: orderScheduleStatus.entityTypeId,
      orderKey: orderData.key,
      orderStateTypeId: orderScheduleStatus.orderStateTypeId,
      orderStateReason: {
        orderStateChildReasonId: scheduleOrderReason,
        ...(selectedItems.length > 0 && {
          productKeys: selectedItems.map(({ serviceKey }) => serviceKey),
        }),
      },
      scheduledTo: ScheduledToDataTime,
    };
    scheduleOrderPopupConfirmBtn(data, scheduleOrderPopupConfirmBtnCallbackFn);
  };

  const handleCancelOrderReasonRadioButtonClick = reason => {
    setCancelOrderReasonIsRequiredError(false);
    setCancelOrderReason(reason);
  };

  const handleCancelOrderPopupDismissBtnClick = () => {
    setIsCancelOrderPopupOpen(false);
  };

  const handleCancelOrderPopupConfirmBtnClick = () => {
    if (!cancelOrderReason) {
      setCancelOrderReasonIsRequiredError(true);
      return;
    }
    setIsCancelOrderPopupLoading(true);
    const orderCancelStatus = {
      entityTypeId: 1,
      orderStateTypeId: 5,
    };

    const data = {
      entityId: loginInfo.details.data.key,
      entityTypeId: orderCancelStatus.entityTypeId,
      orderKey: orderData.key,
      orderStateTypeId: orderCancelStatus.orderStateTypeId,
      note: cancelOrderNote,
      orderStateReason: {
        orderStateChildReasonId: cancelOrderReason,
      },
    };
    cancelOrderPopupConfirmBtn(data, cancelOrderPopupConfirmBtnCallbackFn);
  };

  const handleCancelOrderNoteChange = event => {
    const { value } = event.target;
    setCancelOrderNote(value);
  };

  const handleRejectOrderReasonRadioButtonClick = reason => {
    setRejectOrderReasonIsRequiredError(false);
    setRejectOrderReason(reason);
    setSelectedItems([]);
  };

  const handleRejectOrderPopupDismissBtnClick = () => {
    setIsRejectOrderPopupOpen(false);
    setSelectedItems([]);
  };

  const handleRejectOrderPopupConfirmBtnClick = () => {
    if (!rejectOrderReason) {
      setRejectOrderReasonIsRequiredError(true);
      return;
    }
    setIsRejectOrderPopupLoading(true);
    const data = {
      entityId: loginInfo.details.data.key,
      entityTypeId: 1,
      orderKey: orderData.key,
      orderStateTypeId: 8,
      note: rejectOrderNote,
      orderStateReason: {
        orderStateChildReasonId: rejectOrderReason,
        ...(selectedItems.length > 0 && {
          productKeys: selectedItems.map(({ serviceKey }) => serviceKey),
        }),
      },
    };
    rejectOrderPopupConfirmBtn(data, rejectOrderPopupConfirmBtnCallbackFn);
  };

  const handleRejectOrderNoteChange = event => {
    const { value } = event.target;
    setRejectOrderNote(value);
  };

  let rejectionReasons = [];
  if (rejectOrderReasons?.data?.length) {
    rejectionReasons = rejectOrderReasons.data.map(reason => {
      if (reason.id === 4) {
        const reasons = [...reason.orderStateChildReasons];
        const patientIssueCancelReasons = cancelOrderReasons?.data?.find(
          x => x.id === 7,
        )?.orderStateChildReasons;
        patientIssueCancelReasons?.forEach(cancelReason => {
          if (cancelReason.id === 13 || cancelReason.id === 14 || cancelReason.id === 26) {
            reasons.push(cancelReason);
          }
        });
        return {
          ...reason,
          orderStateChildReasons: reasons,
        };
      }
      return reason;
    });
  }

  /* const showRescheduleBtn = !(
    orderStateTypeId === orderStatusEnum.Delivered ||
    orderStateTypeId === orderStatusEnum['Ready for Pickup'] ||
    orderStateTypeId === orderStatusEnum.Cancelled ||
    orderStateTypeId === orderStatusEnum['Ph. Rejected']
  ); */

  const showRescheduleBtn = false;
  const isAssignedToPharmacy = orderData?.orderStates?.find(
    stateObj => stateObj.orderStateTypeId === orderStatusEnum.New,
  );
  const showNextStateActionBtn = !(
    (orderStateTypeId === orderStatusEnum.Scheduled && !isAssignedToPharmacy) ||
    orderStateTypeId === orderStatusEnum.New ||
    orderStateTypeId === orderStatusEnum['Ph. Rejected'] ||
    orderStateTypeId === orderStatusEnum.Cancelled ||
    orderStateTypeId === orderStatusEnum['Payment Pending'] ||
    orderStateTypeId === orderStatusEnum['Payment Processing'] ||
    orderStateTypeId === orderStatusEnum.Delivered ||
    orderStateTypeId === orderStatusEnum['Selecting Alternatives'] ||
    orderStateTypeId === orderStatusEnum.Rejected ||
    (orderStateTypeId === orderStatusEnum.Confirmed && orderData?.onlinePaymentCardKey)
  );

  const showRejectionBtn =
    orderStateTypeId === orderStatusEnum.New ||
    orderStateTypeId === orderStatusEnum.Viewed ||
    orderStateTypeId === orderStatusEnum.Accepted;

  const showReturnBtn = orderData?.isReturnable;

  return (
    <>
      <OrderDetailsHeaderWrapper>
        {isSuggestAlternativesMode ? (
          <OrderDetailsHeaderOrderBtnWrapper>
            <Button
              onClick={() => {
                handleSendAlternativesToCustomer();
              }}
              key="send-alternatives"
              btnText="Send Customer"
              extendButtonStyle={extendNextStateButtonStyle}
              isLoading={
                status.SUBMITING === confirmOrderLoadStatus ||
                status.SUBMITING === updateOrderStateLoadStatus
              }
              extendLoaderStyle={extendLoaderStyle}
              disabled={!productsAlternatives?.length}
            />
          </OrderDetailsHeaderOrderBtnWrapper>
        ) : (
          <OrderDetailsHeaderOrderBtnWrapper>
            {showRescheduleBtn && (
              <Button
                onClick={() => handleScheduleOrderBtnClick()}
                key="order-reschedule"
                iconName="e92e"
                iconSize={13}
                btnText="Reschedule"
                extendButtonStyle={extendButtonStyle}
              />
            )}
            {showRejectionBtn && (
              <OrderDetailsHeaderIconBtnWrapper ref={extraActionMenuwrapperRef}>
                <Button
                  onClick={() => handleRejectActionBtnClick()}
                  key="order-cancel-reject"
                  iconName="e93d"
                  iconSize={13}
                  btnText="Reject Order"
                  extendButtonStyle={extendButtonStyle}
                />
                {/* {isExtraActionsMenuOpen && (
              <OrderDetailsHeaderIconBtnMenu>
                <OrderDetailsHeaderIconBtnMenuItem onClick={() => handleCancelActionBtnClick()}>
                  Cancel
                </OrderDetailsHeaderIconBtnMenuItem>
                {showRejectButton && (
                  <OrderDetailsHeaderIconBtnMenuItem onClick={() => handleRejectActionBtnClick()}>
                    Reject
                  </OrderDetailsHeaderIconBtnMenuItem>
                )}
              </OrderDetailsHeaderIconBtnMenu>
            )} */}
              </OrderDetailsHeaderIconBtnWrapper>
            )}
            {showNextStateActionBtn && (
              <Button
                onClick={() => handleNextStateBtn()}
                key="order-next-state"
                btnText={initialNextOrderStateLabel}
                extendButtonStyle={extendNextStateButtonStyle}
                isLoading={
                  status.SUBMITING === confirmOrderLoadStatus ||
                  status.SUBMITING === updateOrderStateLoadStatus
                }
                extendLoaderStyle={extendLoaderStyle}
                disabled={
                  !isAllInvoiceItemsToBeConfirmedChecked &&
                  orderStateTypeId === orderStatusEnum.Accepted
                }
              />
            )}
            <Button
              onClick={() => handleOrderRefreshBtnClick()}
              key="order-refresh"
              btnText="Refresh"
              extendButtonStyle={extendButtonStyle}
            />
            {showReturnBtn && (
              <Button
                onClick={() => {
                  handleReturnActionBtnClick(true);
                }}
                key="order-return"
                iconName="e92d"
                iconSize={13}
                btnText="Returning"
                extendButtonStyle={extendButtonStyle}
              />
            )}
          </OrderDetailsHeaderOrderBtnWrapper>
        )}
      </OrderDetailsHeaderWrapper>
      {/** Modals */}
      {isRetrunOrderPopupOpen && (
        <ReturnOrderModal
          themeContext={themeContext}
          stringsData={stringsData}
          dispensedOrderItems={dispensedOrderItems}
          orderData={orderData}
          handleReturnOrder={returnOrderHandler}
          onClose={() => handleReturnActionBtnClick(false)}
          isRetrunOrderPopupOpen={isRetrunOrderPopupOpen}
          returnOrderLoadStatus={returnOrderLoadStatus}
        />
      )}
      {isCancelOrderPopupOpen && (
        <CancelOrderModal
          themeContext={themeContext}
          stringsData={stringsData}
          isCancelOrderPopupLoading={isCancelOrderPopupLoading}
          cancelOrderReason={cancelOrderReason}
          handleCancelOrderReasonRadioButtonClick={handleCancelOrderReasonRadioButtonClick}
          cancelOrderReasons={cancelOrderReasons}
          cancelOrderReasonIsRequiredError={cancelOrderReasonIsRequiredError}
          handleCancelOrderPopupConfirmBtnClick={handleCancelOrderPopupConfirmBtnClick}
          handleCancelOrderPopupDismissBtnClick={handleCancelOrderPopupDismissBtnClick}
          handleCancelOrderNoteChange={handleCancelOrderNoteChange}
          cancelOrderNote={cancelOrderNote}
        />
      )}
      {isRejectOrderPopupOpen && (
        <RejectOrderModal
          themeContext={themeContext}
          stringsData={stringsData}
          isRejectOrderPopupLoading={isRejectOrderPopupLoading}
          rejectOrderReason={rejectOrderReason}
          handleRejectOrderReasonRadioButtonClick={handleRejectOrderReasonRadioButtonClick}
          rejectOrderReasons={rejectionReasons}
          rejectOrderReasonIsRequiredError={rejectOrderReasonIsRequiredError}
          handleRejectOrderPopupConfirmBtnClick={handleRejectOrderPopupConfirmBtnClick}
          handleRejectOrderPopupDismissBtnClick={handleRejectOrderPopupDismissBtnClick}
          handleRejectOrderNoteChange={handleRejectOrderNoteChange}
          rejectOrderNote={rejectOrderNote}
          orderData={orderData}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          selectItemsSearchApi={selectItemsSearchApi}
        />
      )}
      {isActionPopupOpen && (
        <OrderActionsModal
          themeContext={themeContext}
          stringsData={stringsData}
          isActionPopupLoading={isActionPopupLoading}
          actionStatus={actionPopupStatus}
          handleActionPopupConfirmBtnClick={handleActionPopupConfirmBtnClick}
          handleActionPopupDismissBtnClick={handleActionPopupDismissBtnClick}
          orderStatusEnum={orderStatusEnum}
        />
      )}
      {isScheduleOrderPopupOpen && (
        <RescheduleOrderModal
          themeContext={themeContext}
          stringsData={stringsData}
          isScheduleOrderPopupLoading={isScheduleOrderPopupLoading}
          scheduleOrderSelectedDate={scheduleOrderSelectedDate}
          scheduleOrderTimeSlotsArr={scheduleOrderTimeSlotsArr}
          scheduleOrderSelectedTime={scheduleOrderSelectedTime}
          scheduleOrderReason={scheduleOrderReason}
          scheduleOrderNote={scheduleOrderNote}
          handleScheduleOrderDatePickerChange={handleScheduleOrderDatePickerChange}
          handleScheduleOrderTimeSlotDropdownMenuChange={
            handleScheduleOrderTimeSlotDropdownMenuChange
          }
          handleScheduleOrderReasonRadioButtonClick={handleScheduleOrderReasonRadioButtonClick}
          handleScheduleOrderNoteChange={handleScheduleOrderNoteChange}
          handleScheduleOrderPopupConfirmBtnClick={handleScheduleOrderPopupConfirmBtnClick}
          handleScheduleOrderPopupDismissBtnClick={handleScheduleOrderPopupDismissBtnClick}
          scheduleOrderReasons={scheduleOrderReasons}
          scheduleOrderReasonIsRequiredError={scheduleOrderReasonIsRequiredError}
          orderData={orderData}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          selectItemsSearchApi={selectItemsSearchApi}
        />
      )}
      {suggestAlternativesModal.isOpened && (
        <AddAlternativeItemsModal
          showModal={suggestAlternativesModal.isOpened}
          orderItemKey={suggestAlternativesModal.orderItemKey}
          productKey={suggestAlternativesModal.productKey}
          productShapeId={suggestAlternativesModal.productShapeId}
          modalTitle={suggestAlternativesModal.productName}
          appendProductAlternatives={appendProductAlternatives}
          productsAlternatives={productsAlternatives}
          onClose={() => {
            setSuggestAllternativeModal({
              isOpened: false,
              productKey: null,
              orderItemKey: null,
              productName: null,
              productShapeId: null,
            });
          }}
        />
      )}
    </>
  );
};

OrderDetailsActionBtns.propTypes = {
  onClickSendToCustomer: PropTypes.func,
  productsAlternatives: PropTypes.arrayOf(PropTypes.shape({})),
  appendProductAlternatives: PropTypes.func,
  setSuggestAllternativeModal: PropTypes.func,
  suggestAlternativesModal: PropTypes.shape({
    isOpened: PropTypes.bool,
    productKey: PropTypes.string,
    orderItemKey: PropTypes.string,
    productName: PropTypes.string,
    productShapeId: PropTypes.number,
  }),
  returnOrderAction: PropTypes.func,
  isAllInvoiceItemsToBeConfirmedChecked: PropTypes.bool,
  fetchOrderStatusChangeSubmission: PropTypes.func,
  confirmOrderAction: PropTypes.func,
  dispensedOrderItems: PropTypes.arrayOf(PropTypes.shape({})),
  reloadOrderDetailsPage: PropTypes.func,
  orderStateTypeId: PropTypes.string,
  stringsData: PropTypes.shape({
    orderDetailsHeader: PropTypes.shape({
      orderId: PropTypes.string,
      viewBtnText: PropTypes.string,
      processedBtnText: PropTypes.string,
      resultsDoneBtnText: PropTypes.string,
      scheduleBtnText: PropTypes.string,
      cancelBtnText: PropTypes.string,
      rejectBtnText: PropTypes.string,
    }),
  }).isRequired,
  orderDetails: PropTypes.shape({
    details: PropTypes.shape({
      isReturnable: PropTypes.bool,
      orderStates: PropTypes.arrayOf(PropTypes.shape({})),
      onlinePaymentCardKey: PropTypes.string,
      key: PropTypes.string,
      orderNumber: PropTypes.string,
      orderStateTypeId: PropTypes.number,
      orderItems: PropTypes.arrayOf(PropTypes.shape({})),
      attachedResults: PropTypes.oneOfType([
        PropTypes.shape({
          attachments: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        PropTypes.string,
      ]),
      orderReceipt: PropTypes.shape({}),
      optionalPaymentMethodKey: PropTypes.string,
    }),
  }).isRequired,
  loginInfo: PropTypes.shape({
    details: PropTypes.shape({
      data: PropTypes.shape({
        key: PropTypes.string,
      }),
    }),
  }).isRequired,
  actionPopupStatus: PropTypes.number.isRequired,
  // showRejectButton: PropTypes.bool,
  handleOrderRefreshBtnClick: PropTypes.func.isRequired,
  actionPopupDismissBtn: PropTypes.func.isRequired,
  actionPopupConfirmBtn: PropTypes.func.isRequired,
  actionPopupConfirmBtnCallback: PropTypes.func.isRequired,
  scheduleOrderPopupConfirmBtn: PropTypes.func.isRequired,
  cancelOrderPopupConfirmBtn: PropTypes.func.isRequired,
  rejectOrderPopupConfirmBtn: PropTypes.func,
  scheduleOrderReasons: PropTypes.shape({
    status: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  cancelOrderReasons: PropTypes.shape({
    status: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  rejectOrderReasons: PropTypes.shape({
    status: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  orderStatusEnum: PropTypes.shape({
    'Selecting Alternatives': PropTypes.number,
    'Payment Pending': PropTypes.number,
    'Payment Processing': PropTypes.number,
    Confirmed: PropTypes.number,
    Scheduled: PropTypes.number,
    'Ready for Pickup': PropTypes.number,
    Delivered: PropTypes.number,
    'Ph. Rejected': PropTypes.number,
    Viewed: PropTypes.number,
    Accepted: PropTypes.number,
    New: PropTypes.number,
    Rejected: PropTypes.number,
    'Ready To Be Processed': PropTypes.number,
    Received: PropTypes.number,
    'Preparation Call And Itemization': PropTypes.number,
    'Sample Dropped': PropTypes.number,
    'Results Sent': PropTypes.number,
    'Preparation Completed': PropTypes.number,
    Cancelled: PropTypes.number,
  }).isRequired,
  hideButtonBasedOnState: PropTypes.shape({
    next: PropTypes.shape({}),
    schedule: PropTypes.shape({}),
    cancel: PropTypes.shape({}),
  }).isRequired,
  selectItemsSearchApi: PropTypes.func,
  buttonDisabledOptions: PropTypes.shape({}),
  dynamicPermissions: PropTypes.shape({}),
  initialNextOrderState: PropTypes.shape({}),
  permissionsCategories: PropTypes.shape({
    order: PropTypes.string,
  }),
  isSuggestAlternativesMode: PropTypes.bool,
};

OrderDetailsActionBtns.defaultProps = {
  // showRejectButton: false,
  rejectOrderReasons: {},
  buttonDisabledOptions: {},
  dynamicPermissions: {},
  initialNextOrderState: {},
  permissionsCategories: {},
  rejectOrderPopupConfirmBtn: () => {},
  selectItemsSearchApi: () => {},
};

export default OrderDetailsActionBtns;
