import styled, { keyframes } from 'styled-components';

const rotationAnimation = keyframes`
  from {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
`;

export const LoaderWrapper = styled.div`
  ${({ width, height, thickness, outerColor, innerColor, theme }) => `
    width: ${width}px;
    height: ${height}px;
    border: ${thickness}px solid ${innerColor || theme.colors.blueMain};
    border-top: ${thickness}px solid ${outerColor || theme.colors.white};
    border-radius: 50%;
    margin: auto;
  `}

  animation: 1.5s ${rotationAnimation} linear infinte;
  -webkit-animation: 1.5s ${rotationAnimation} linear infinite;

  ${({ extendStyle }) => extendStyle || ''};
`;
