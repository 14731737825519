import { status } from 'constants/general';

export const initialCommonState = {
  context: '',
  labsList: {
    status: status.SHOULD_CALL_API,
    details: [],
  },
  chemistsList: {
    status: status.SHOULD_CALL_API,
    details: {},
  },
  usersList: {
    status: status.SHOULD_CALL_API,
    details: {},
  },
  uploadContentSubmission: {
    status: status.SHOULD_CALL_API,
  },
};

export const initialAccountManagementState = {
  userAuthenticationInfo: {
    status: status.SHOULD_CALL_API,
    details: [],
  },
  loginInfo: {
    status: status.SHOULD_CALL_API,
    details: {},
  },
  permissions: {
    status: status.SHOULD_CALL_API,
    details: {},
  },
};

export const initialPharmacyOrdersState = {
  updateOrderStateLoadStatus: status.SHOULD_CALL_API,
  pharmacyStock: {
    items: [],
    showMore: true,
  },
  pharmacyStockLoadStatus: status.SHOULD_CALL_API,
  uploadPharmacyStock: {},
  uploadPharmacyStockLoadStatus: status.SHOULD_CALL_API,
  confirmOrder: {},
  confirmOrderLoadStatus: status.SHOULD_CALL_API,
  returnOrder: {},
  returnOrderLoadStatus: status.SHOULD_CALL_API,
  ordersList: {
    status: status.SHOULD_CALL_API,
    details: {},
  },
  detailedReasons: {
    status: status.SHOULD_CALL_API,
    details: {},
  },
  orderDetails: {
    status: status.SHOULD_CALL_API,
    details: {},
  },
  orderDispensedItems: [],
  assignOrderToEntity: {
    status: status.SHOULD_CALL_API,
  },
  orderItemsSuggestions: {
    status: status.SHOULD_CALL_API,
    details: {},
  },
  orderItemsSubmissionLoadState: status.SHOULD_CALL_API,
  orderStatusChangeSubmission: {
    status: status.SHOULD_CALL_API,
  },
  orderNoteChangeSubmission: {
    status: status.SHOULD_CALL_API,
  },
  scheduleOrderSubmission: {
    status: status.SHOULD_CALL_API,
  },
  scheduleOrderReasons: {
    status: status.SHOULD_CALL_API,
  },
  cancelOrderReasons: {
    status: status.SHOULD_CALL_API,
  },
  stocks: {
    status: status.SHOULD_CALL_API,
    details: [],
  },
  itemAlternatives: {
    status: status.SHOULD_CALL_API,
    details: [],
  },
  submitAlternatives: {
    status: status.SHOULD_CALL_API,
    details: [],
  },
  orderAlternatives: {
    status: status.SHOULD_CALL_API,
    details: [],
  },
};

export const initialPharmacyStockState = {
  pharmacyStock: {
    items: [],
    showMore: true,
  },
  pharmacyStockLoadStatus: status.SHOULD_CALL_API,
  pharmacyStocksHistory: {},
  pharmacyStocksHistoryLoadStatus: status.SHOULD_CALL_API,
  uploadPharmacyStock: {},
  uploadPharmacyStockLoadStatus: status.SHOULD_CALL_API,
  pharmacyExportStockLoadStatus: status.SHOULD_CALL_API,
  isAddStockItemsModalOpened: false,
  isEditStockItemModalOpened: false,
  searchResultsPharmacyStock: {},
  searchResultsPharmacyStockLoadStatus: status.SHOULD_CALL_API,
  stockItemsToBeAddedCart: [],
  savepharmacyStockItems: {},
  savepharmacyStockItemsLoadStatus: status.SHOULD_CALL_API,
};

export const initialPharmacyInvoiceState = {
  pharmacyInvoices: {
    items: [],
    showMore: true,
  },
  pharmacyInvoicesLoadStatus: status.SHOULD_CALL_API,
  invoiceDetails: {},
  isInvoiceModalOpened: false,
};

export const initialPharmacyCommonState = {
  context: '',
  pharmaciesList: {
    status: status.SHOULD_CALL_API,
    details: [],
  },
  couriersList: {
    status: status.SHOULD_CALL_API,
    details: {},
  },
  orderDetails: {
    status: status.SHOULD_CALL_API,
    details: {},
  },
};

export const initialPharmaciesState = {
  pharmaciesList: {
    status: status.SHOULD_CALL_API,
    details: [],
  },
};
