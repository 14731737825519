import accountManagementActionTypes from './action-types';

export const fetchUserAuthenticationInfo = payload => ({
  type: accountManagementActionTypes.FETCH_PHARMACY_USER_AUTHENTICATION_INFO,
  payload,
});

export const fetchUserAuthenticationInfoSuccess = payload => ({
  type: accountManagementActionTypes.FETCH_PHARMACY_USER_AUTHENTICATION_INFO_SUCCESS,
  payload,
});

export const fetchUserAuthenticationInfoFail = payload => ({
  type: accountManagementActionTypes.FETCH_PHARMACY_USER_AUTHENTICATION_INFO_FAIL,
  payload,
});

export const fetchLoginInfo = payload => ({
  type: accountManagementActionTypes.FETCH_PHARMACY_LOGIN_INFO,
  payload,
});

export const fetchLoginInfoSuccess = payload => ({
  type: accountManagementActionTypes.FETCH_PHARMACY_LOGIN_INFO_SUCCESS,
  payload,
});

export const fetchLoginInfoFail = payload => ({
  type: accountManagementActionTypes.FETCH_PHARMACY_LOGIN_INFO_FAIL,
  payload,
});

export const fetchUserPermissions = payload => ({
  type: accountManagementActionTypes.FETCH_USER_PERMISSIONS,
  payload,
});

export const fetchUserPermissionsSuccess = payload => ({
  type: accountManagementActionTypes.FETCH_USER_PERMISSIONS_SUCCESS,
  payload,
});

export const fetchUserPermissionsFail = payload => ({
  type: accountManagementActionTypes.FETCH_USER_PERMISSIONS_FAIL,
  payload,
});
