import PropTypes from 'prop-types';
import { status } from 'constants/general';
import FileIco from 'assets/images/order-show.svg';
import OrderDetails from './OrderDetailsContainer';
import OrdersSideList from './OrdersSideList';
import {
  OrderDetailsContainer,
  EmptyStateMsgContainer,
  EmptyStateMsg,
  EmptyStateImg,
  EmptyStateSecondaryMsg,
  EmptyStateContainer,
} from './OrdersContainer.style';

const OrdersComponent = ({
  selectedDateFilterObj,
  selectedPageFilter,
  ordersList,
  mappedOrdersList,
  orderStatusColorEnum,
  stringsData,
  ordersApiCallParams,
  filters,
  handleOrderSearchBarInputChange,
  handleOrderDateFilterChange,
  handleOrderPaginationClick,
  handleGenericFilterClick,
  showPartnersColumn,
  showPaymentColumn,
  setSelectedOrderId,
  selectedOrderId,
  selectItemsSearchApi,
  entityTypeId,
  orderStatusEnum,
  totalCount,
  selectedData,
  setSelectedData,
  searchQuery,
  setSearchQuery,
}) => (
  <div
    style={{
      display: 'flex',
    }}
  >
    <OrdersSideList
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      selectedData={selectedData}
      setSelectedData={setSelectedData}
      mappedOrdersList={mappedOrdersList}
      orderStatusColorEnum={orderStatusColorEnum}
      stringsData={stringsData}
      showPartnersColumn={showPartnersColumn}
      showPaymentColumn={showPaymentColumn}
      entityTypeId={entityTypeId}
      orderStatusEnum={orderStatusEnum}
      isLoading={!(ordersList.status === status.SUCCESS || ordersList.status === status.FAIL)}
      // Filters
      selectedDateFilterObj={selectedDateFilterObj}
      handleOrderSearchBarInputChange={handleOrderSearchBarInputChange}
      handleOrderDateFilterChange={handleOrderDateFilterChange}
      handleGenericFilterClick={handleGenericFilterClick}
      ordersApiCallParams={ordersApiCallParams}
      filters={filters}
      selectItemsSearchApi={selectItemsSearchApi}
      // Pagination
      currentPage={selectedPageFilter}
      totalCount={totalCount}
      handleOrderPaginationClick={() => handleOrderPaginationClick(selectedPageFilter + 1)}
      setSelectedOrderId={setSelectedOrderId}
      selectedOrderId={selectedOrderId}
    />
    <OrderDetailsContainer>
      {selectedOrderId ? (
        <OrderDetails selectedOrderId={selectedOrderId} />
      ) : (
        <EmptyStateContainer>
          <EmptyStateMsgContainer>
            <EmptyStateImg src={FileIco} alt="order" />
            <EmptyStateMsg>Your orders will show here</EmptyStateMsg>
            <EmptyStateSecondaryMsg>
              There will be online orders with delivery from Vezeeta customers
            </EmptyStateSecondaryMsg>
          </EmptyStateMsgContainer>
        </EmptyStateContainer>
      )}
    </OrderDetailsContainer>
  </div>
);

OrdersComponent.propTypes = {
  searchQuery: PropTypes.string,
  setSearchQuery: PropTypes.func,
  selectedData: PropTypes.shape({}),
  setSelectedData: PropTypes.func,
  selectedOrderId: PropTypes.string,
  selectedDateFilterObj: PropTypes.shape({}).isRequired,
  selectedPageFilter: PropTypes.number.isRequired,
  ordersList: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
  mappedOrdersList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  orderStatusColorEnum: PropTypes.func.isRequired,
  stringsData: PropTypes.shape({}).isRequired,
  selectedStatusFilter: PropTypes.shape({}),
  ordersApiCallParams: PropTypes.shape({}),
  filters: PropTypes.shape({}),
  setSelectedOrderId: PropTypes.func.isRequired,
  handleOrderSearchBarInputChange: PropTypes.func.isRequired,
  handleOrderDateFilterChange: PropTypes.func.isRequired,
  handleOrderPaginationClick: PropTypes.func.isRequired,
  handleGenericFilterClick: PropTypes.func,
  showPartnersColumn: PropTypes.bool,
  showPaymentColumn: PropTypes.bool,
  permissionsCategories: PropTypes.shape({
    order: PropTypes.string,
  }),
  selectItemsSearchApi: PropTypes.func,
  entityTypeId: PropTypes.shape({}),
  orderStatusEnum: PropTypes.shape({
    Scheduled: PropTypes.number,
  }).isRequired,
  totalCount: PropTypes.number,
};

OrdersComponent.defaultProps = {
  selectedOrderId: '',
  ordersApiCallParams: {},
  filters: {},
  showPartnersColumn: false,
  showPaymentColumn: false,
  permissionsCategories: {},
  entityTypeId: {},
  handleGenericFilterClick: () => {},
  selectItemsSearchApi: () => {},
  totalCount: 0,
};

export default OrdersComponent;
