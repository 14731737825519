import stockActionTypes from './action-types';

export const exportStock = payload => ({
  type: stockActionTypes.EXPORT_STOCK,
  payload,
});

export const exportStockSuccess = payload => ({
  type: stockActionTypes.EXPORT_STOCK_SUCCESS,
  payload,
});

export const exportStockFail = payload => ({
  type: stockActionTypes.EXPORT_STOCK_FAIL,
  payload,
});

export const fetchStock = payload => ({
  type: stockActionTypes.FETCH_STOCK,
  payload,
});

export const fetchStockSuccess = payload => ({
  type: stockActionTypes.FETCH_STOCK_SUCCESS,
  payload,
});

export const fetchStockFail = payload => ({
  type: stockActionTypes.FETCH_STOCK_FAIL,
  payload,
});

export const uploadStock = payload => ({
  type: stockActionTypes.UPLOAD_STOCK,
  payload,
});

export const uploadStockSuccess = payload => ({
  type: stockActionTypes.UPLOAD_STOCK_SUCCESS,
  payload,
});

export const uploadStockFail = payload => ({
  type: stockActionTypes.UPLOAD_STOCK_FAIL,
  payload,
});

export const fetchStocksHistory = payload => ({
  type: stockActionTypes.FETCH_STOCK_HISTORY,
  payload,
});

export const fetchStocksHistorySuccess = payload => ({
  type: stockActionTypes.FETCH_STOCK_HISTORY_SUCCESS,
  payload,
});

export const fetchStocksHistoryFail = payload => ({
  type: stockActionTypes.FETCH_STOCK_HISTORY_FAIL,
  payload,
});

export const toggleAddStockItemsModal = payload => ({
  type: stockActionTypes.TOGGLE_ADD_STOCK_ITEMS_MODAL,
  payload,
});

export const toggleEditStockItemModal = payload => ({
  type: stockActionTypes.TOGGLE_EDIT_STOCK_ITEM_MODAL,
  payload,
});

export const searchStockItems = payload => ({
  type: stockActionTypes.SEARCH_STOCK_ITEMS,
  payload,
});

export const searchStockItemsSuccess = payload => ({
  type: stockActionTypes.SEARCH_STOCK_ITEMS_SUCCESS,
  payload,
});

export const searchStockItemsFail = payload => ({
  type: stockActionTypes.SEARCH_STOCK_ITEMS_FAIL,
  payload,
});

export const addToStockItemsCart = payload => ({
  type: stockActionTypes.ADD_STOCK_ITEM,
  payload,
});

export const removeFromStockItemsCart = payload => ({
  type: stockActionTypes.REMOVE_STOCK_ITEM,
  payload,
});

export const editStockItemCart = payload => ({
  type: stockActionTypes.EDIT_STOCK_ITEM,
  payload,
});

export const resetToStockItemsCart = payload => ({
  type: stockActionTypes.RESET_STOCK_ITEM,
  payload,
});

export const saveStockItems = payload => ({
  type: stockActionTypes.SAVE_STOCK_ITEMS,
  payload,
});

export const saveStockItemsSuccess = payload => ({
  type: stockActionTypes.SAVE_STOCK_ITEMS_SUCCESS,
  payload,
});

export const saveStockItemsFail = payload => ({
  type: stockActionTypes.SAVE_STOCK_ITEMS_FAIL,
  payload,
});
