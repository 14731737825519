const commonStrings = {
  sidebar: {
    logoImageAltText: 'vezeeta logo',
    ordersItemName: 'Orders',
    stockItemName: 'Stock',
    invoicesItemName: 'Invoices',
    pharmaciesItemName: 'Pharmacies',
    masterListItemName: 'Master List',
    deliveryItemName: 'Delivery',
    partnershipsItemName: 'Partnerships',
    callCenterItemName: 'Call Center',
    catToolItemName: 'Cat Tool',
    configurationItemName: 'Configuration',
  },

  header: {
    userNameText: 'Hi,',
    logoutText: 'Logout',
  },
};

export default commonStrings;
