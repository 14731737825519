import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Loader from 'modules/common/components/Loader';
import Button from 'modules/common/components/Button';
import { status } from 'constants/general';
import ErrorImg from 'assets/images/error.png';
import SearchImg from 'assets/images/search.png';
import TableViewContainer from 'modules/common/components/TableViewContainer';
import { getTableHeadersStockHistoryLocalized, VIEW, getMainRowItemsStockHistory } from './helper';
import {
  extendAdditionalItem,
  extendMainItem,
  extendTableGrid,
  extendTableHeaderRow,
  extendTableRow,
  extendTableGridHistory,
} from './TableViewContainer.style';
import {
  GrayOverLay,
  ViewContainer,
  ExceptionContainer,
  ExceptionImage,
  ExceptionImageContainer,
  ExceptionMessage,
  ExceptionMessageContainer,
  extendButtonStyle,
  extendLoaderStyle,
  ViewTitle,
  extendBackButtonStyle,
} from './Stock.style';

const StockHistoryList = ({
  currentPage,
  pharmacyStocksHistoryLoadStatus,
  pharmacyStocksHistory,
  showMore,
  showMoreInHistoryStock,
  toggleViews,
  ...props
}) => {
  const [stockRecords, setStockRecords] = useState([]);

  /** *****************************************************************************************
   * Set Records
   ***************************************************************************************** */
  useEffect(() => {
    if (pharmacyStocksHistory?.length) {
      setStockRecords(getMainRowItemsStockHistory(pharmacyStocksHistory));
    } else {
      setStockRecords([]);
    }
  }, [pharmacyStocksHistoryLoadStatus]);

  const getExceptionComponent = (loadingStatus, data) => {
    let exceptionImage;
    let exceptionMessage;
    if (loadingStatus === status.SUCCESS && (!data || !data.length)) {
      exceptionImage = SearchImg;
      exceptionMessage = 'No Stock Found';
    }
    if (loadingStatus === status.FAIL) {
      exceptionImage = ErrorImg;
      exceptionMessage = 'Something went wrong!';
    }

    if (exceptionImage && exceptionMessage) {
      return (
        <ExceptionContainer>
          <ExceptionImageContainer>
            <ExceptionImage src={exceptionImage} alt="exception" />
          </ExceptionImageContainer>
          <ExceptionMessageContainer>
            <ExceptionMessage>{exceptionMessage}</ExceptionMessage>
          </ExceptionMessageContainer>
        </ExceptionContainer>
      );
    }
    return '';
  };

  const renderStockList = () => {
    if (
      !pharmacyStocksHistory?.length &&
      (pharmacyStocksHistoryLoadStatus === status.FETCHING ||
        pharmacyStocksHistoryLoadStatus === status.SHOULD_CALL_API)
    ) {
      return (
        <GrayOverLay>
          <Loader />
        </GrayOverLay>
      );
    }
    if (stockRecords.length) {
      return (
        <div>
          <div>
            <TableViewContainer
              {...props}
              tableRecords={stockRecords}
              tableColumnHeaders={getTableHeadersStockHistoryLocalized()}
              extendAdditionalItem={extendAdditionalItem}
              extendMainItem={extendMainItem}
              extendTableGrid={extendTableGridHistory}
              extendTableHeaderRow={extendTableHeaderRow}
              extendTableRow={extendTableRow}
            />
          </div>
        </div>
      );
    }
    if (
      pharmacyStocksHistoryLoadStatus === status.FAIL ||
      (pharmacyStocksHistoryLoadStatus === status.SUCCESS &&
        pharmacyStocksHistory &&
        !pharmacyStocksHistory.length)
    ) {
      return (
        <div>{getExceptionComponent(pharmacyStocksHistoryLoadStatus, pharmacyStocksHistory)}</div>
      );
    }

    return (
      <div>
        <div>
          <TableViewContainer
            {...props}
            currentPage={currentPage}
            tableRecords={stockRecords}
            tableColumnHeaders={getTableHeadersStockHistoryLocalized()}
            extendAdditionalItem={extendAdditionalItem}
            extendMainItem={extendMainItem}
            extendTableGrid={extendTableGrid}
            extendTableHeaderRow={extendTableHeaderRow}
            extendTableRow={extendTableRow}
          />
        </div>
      </div>
    );
  };

  const displayShowMoreBtn = pharmacyStocksHistory?.length > 0 && showMore;
  return (
    <div
      style={{
        padding: '16px',
      }}
    >
      <div
        style={{
          display: 'flex',
          gap: '16px',
          marginBottom: '16px',
        }}
      >
        <Button
          onClick={() => toggleViews(VIEW.CURRENT_STOCK)}
          extendButtonStyle={extendBackButtonStyle}
          iconName="e909"
          iconSize={13}
          key="toggle-view"
        />
        <ViewTitle>Upload History</ViewTitle>
      </div>
      <ViewContainer>{renderStockList()}</ViewContainer>
      {displayShowMoreBtn && (
        <Button
          onClick={() => showMoreInHistoryStock()}
          isLoading={pharmacyStocksHistoryLoadStatus === status.FETCHING}
          btnText="Show More"
          extendLoaderStyle={extendLoaderStyle}
          extendButtonStyle={extendButtonStyle}
        />
      )}
    </div>
  );
};

StockHistoryList.propTypes = {
  pharmacyStocksHistory: PropTypes.arrayOf(PropTypes.shape({})),
  pharmacyStocksHistoryLoadStatus: PropTypes.string,
  queryString: PropTypes.string,
  currentPage: PropTypes.number,
  showMore: PropTypes.bool,
  showMoreInHistoryStock: PropTypes.func,
  onSearchStock: PropTypes.func,
  uploadPharmacyStockLoadStatus: PropTypes.string,
  onUploadStockFile: PropTypes.func,
  pharmacyExportStockLoadStatus: PropTypes.string,
  onExportStock: PropTypes.func,
  toggleViews: PropTypes.func,
};

export default StockHistoryList;
