import Counter from 'modules/common/components/Counter';
import {
  ProductName,
  ProductShapeName,
  ItemImage,
  extendGrayButtonStyle,
  extendCounterWrapper,
} from '../OrderActionBtnsSection/OrderDetailsActionBtns.style';

export const VIEWS = {
  PARTIALY_RETURN: 0,
  TOTALLY_RETURN: 1,
};

export const getTableHeadersLocalized = viewId => {
  if (viewId === VIEWS.TOTALLY_RETURN) {
    return [
      {
        id: 0,
        name: 'Returned Items',
      },
      {
        id: 1,
        name: 'Price',
      },
      {
        id: 2,
        name: 'Quantity Returned',
      },
      {
        id: 3,
        name: 'Returned Subtotal',
      },
    ];
  }

  return [
    {
      id: 0,
      name: 'Returned Items',
    },
    {
      id: 1,
      name: 'Price',
    },
    {
      id: 2,
      name: 'Ordered Quantity',
    },
    {
      id: 3,
      name: 'Sold Quantity',
    },
    {
      id: 4,
      name: 'Returned Quantity',
    },
  ];
};

export const getMainRowItems = (
  itemsList,
  selectedView,
  handleChangeItemQuantity,
  dispensedOrderItems,
) => {
  const recordsRows = [];

  if (selectedView === VIEWS.TOTALLY_RETURN) {
    itemsList?.forEach(item => {
      const {
        mainImageUrl,
        price,
        productShapeName,
        serviceName,
        serviceKey,
        quantity,
        productShapeId,
      } = item;
      recordsRows.push({
        key: serviceKey,
        mainRow: {
          c0: {
            main: (
              <div
                style={{
                  display: 'flex',
                  gap: '12px',
                  padding: '12px 0px',
                }}
              >
                <ItemImage src={mainImageUrl} alt={`${serviceKey}-${productShapeId}`} />
                <div>
                  <ProductName>{serviceName}</ProductName>
                  <ProductShapeName>{productShapeName}</ProductShapeName>
                </div>
              </div>
            ),
            additional: '',
          },
          c1: {
            main: `${price} EGP`,
            additional: '',
          },
          c2: {
            main: `${quantity}`,
            additional: '',
          },
          c3: {
            main: `${(quantity * price)?.toFixed(2)} EGP`,
            additional: '',
          },
        },
      });
    });
  } else {
    itemsList?.forEach((item, index) => {
      const {
        mainImageUrl,
        price,
        productShapeName,
        serviceName,
        serviceKey,
        quantity,
        productShapeId,
      } = item;
      const originalOrderedItem = dispensedOrderItems.find(
        (itemObj, itemIndex) => itemObj.serviceKey === serviceKey && itemIndex === index,
      );

      const originalOrderedQuantity = originalOrderedItem.quantity;
      const soldQuantity = quantity;
      const returnedQuantity = originalOrderedQuantity - soldQuantity;

      recordsRows.push({
        key: serviceKey,
        mainRow: {
          c0: {
            main: (
              <div
                style={{
                  display: 'flex',
                  gap: '12px',
                  padding: '12px 0px',
                }}
              >
                <ItemImage src={mainImageUrl} alt={`${serviceKey}-${productShapeId}`} />
                <div>
                  <ProductName>{serviceName}</ProductName>
                  <ProductShapeName>{productShapeName}</ProductShapeName>
                </div>
              </div>
            ),
            additional: '',
          },
          c1: {
            main: `${price} EGP`,
            additional: '',
          },
          c2: {
            main: `${originalOrderedQuantity}`,
            additional: '',
          },
          c3: {
            main: `${soldQuantity}`,
            additional: '',
          },
          c4: {
            main: (
              <div
                style={{
                  marginTop: '12px',
                }}
              >
                <Counter
                  count={returnedQuantity}
                  extendButtonStyle={extendGrayButtonStyle}
                  extendCounterWrapper={extendCounterWrapper}
                  key={serviceKey}
                  onClickDecrease={() => handleChangeItemQuantity(serviceKey, index, 'minus')}
                  onClickIncrease={() => handleChangeItemQuantity(serviceKey, index, 'plus')}
                  minLimit={0}
                  maxLimit={originalOrderedQuantity}
                />
              </div>
            ),
            additional: (
              <div
                style={{
                  marginBottom: '4px',
                }}
              >{`${(returnedQuantity * price)?.toFixed(2)} EGP`}</div>
            ),
          },
        },
      });
    });
  }

  return recordsRows;
};
