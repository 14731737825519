import PropTypes from 'prop-types';
import {
  ItemContainer,
  ItemDescription,
  ItemDetails,
  ItemImage,
  ItemName,
  ItemWrapper,
} from './ProductItem.style';

const Item = ({
  key,
  mainImageUrl,
  serviceName,
  productShapeName,
  price,
  quantity,
  totalPrice,
}) => (
  <ItemContainer key={key}>
    <ItemWrapper>
      <div
        style={{
          display: 'flex',
          gap: '8px',
        }}
      >
        {mainImageUrl && <ItemImage src={mainImageUrl} alt="item-image" />}
        <ItemDetails>
          <ItemName>{serviceName}</ItemName>
          {productShapeName && <ItemDescription>{productShapeName}</ItemDescription>}
        </ItemDetails>
      </div>
      <ItemDetails>
        <ItemName>{price}</ItemName>
      </ItemDetails>
      <ItemDetails>
        <ItemName>{`${quantity} Items`}</ItemName>
      </ItemDetails>
      <ItemDetails>
        <ItemName>{totalPrice}</ItemName>
      </ItemDetails>
    </ItemWrapper>
  </ItemContainer>
);

Item.propTypes = {
  key: PropTypes.string,
  mainImageUrl: PropTypes.string,
  serviceName: PropTypes.string,
  productShapeName: PropTypes.string,
  price: PropTypes.number,
  quantity: PropTypes.number,
  totalPrice: PropTypes.number,
};

export default Item;
